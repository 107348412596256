import { useState, useEffect } from 'react';
import DataService from '../../data/DataService';

export const useSettings = (load) => {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSettings = (tenantId) => {
    if (!tenantId) {
      return;
    }
    setLoading(true);
    const dataService = new DataService();
    dataService.getItems(`/api/ui/${tenantId}/settings/v1/settings`)
      .then((resp) => dataService.handleResponse(resp, false))
      .then((response) => {
        setSettings(response);
        setLoading(false);
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const saveSettings = (tenantId, data, callback) => {
    setLoading(true);
    if (data) {
      const dataService = new DataService();
      if (data._id) {
        console.log('put settings');
        dataService.put(`/api/ui/${tenantId}/settings/v1/settings/${data._id}`, data)
          .then(dataService.handleResponse)
          .then((response) => {
            console.log(':::::::response from edit saveSettings', response);
            setSettings(response);
            setLoading(false);
            callback();
          }).catch((err) => {
            console.log('Error::', err);
            setError(err);
            setLoading(false);
          });
      } else {
        console.log('post settings');
        dataService.post(`/api/ui/${tenantId}/settings/v1/settings`, data)
          .then(dataService.handleResponse)
          .then((response) => {
            console.log(':::::::response from saveSettings', response);
            setSettings(response);
            setLoading(false);
            callback();
          }).catch((err) => {
            console.log('Error::', err);
            setError(err);
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (load !== false) {
      fetchSettings();
    }
    return () => {
      setSettings({});
    };
  }, []);

  return {
    settings,
    loading,
    error,
    fetchSettings,
    saveSettings
  };
};

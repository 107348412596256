import React, { useContext, useMemo, useState } from 'react';
import { format } from 'date-fns';
import SlidingPane from 'react-sliding-pane';
import XLSExporter from '../../utils/XLSExporter';
import {
  CommonTable as Table, ActiveRenderer, OverflowMenu, OverflowMenuItem, Search, ToggleButton
} from '@aeblondon-uk/common-ui-components';

import UserForm from '../forms/UserForm';
import Loading from '../Loading';
import Footer from './Footer';

import MdDelete from '../../styles/assets/delete.svg';
import Unlock from '../../styles/assets/unlock.svg';
import MdMenu from '../../styles/assets/menu.svg';
import FaChevronRight from '../../styles/assets/chevron.svg';
import NoData from '../NoData';
import HasPermissions from '../HasPermissions';

import AppContext from '../AppContext';
import { DATE_FORMAT_UTC } from '../../utils/DateUtils';
import {hasPermissions} from '../../utils/hasPermissions';
import {
  VIEW_USERS_PERMISSION, ADD_USERS_PERMISSION, EDIT_USERS_PERMISSION, DELETE_USERS_PERMISSION
} from '../../utils/permissions';

import { UserRolesRenderer, UserFullNameRenderer } from '../tables/TableCellRenderer';

import { useUserRoles } from '../hooks/useUserRoles';
import { useUserData } from '../hooks/useUserData';
import { User } from '../hooks/InitialCopies';

const UserList = () => {
  const appContext = useContext(AppContext);
  const {
    users, saveUser, deleteUser, loading, unlockUser
  } = useUserData();
  const {userRoles} = useUserRoles();

  const canEditUsers = hasPermissions([EDIT_USERS_PERMISSION])
  const [deleteUserId, setDeleteUserId] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showUserPanel, setShowUserPanel] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [mode, setMode] = useState('add');
  const [filter, setFilter] = useState({
    activeFilter: 'Active',
    searchText: ''
  });

  const filteredUsers = useMemo(() => {
    const fUsers = [];
    users.forEach((user) => {
      const searchAttribs = [user.itsId, user.firstName, user.lastName, user.email];
      if (!filter.searchText || searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
        if (filter.activeFilter === 'All' || (user.active !== false && filter.activeFilter === 'Active') || (user.active === false && filter.activeFilter === 'Inactive')) {
          fUsers.push(user);
        }
      }
    });
    return fUsers;
  }, [users, filter]);

  const exportUsers = () => {
    if (filteredUsers.length > 0) {
      const usrs = [];
      filteredUsers.forEach((user) => {
        usrs.push(
          {
            'First name': user.firstName,
            'Last name': user.lastName,
            'ITS Id': user.itsId,
            Email: user.email,
            Phone: user.phone,
            'Address line 1': user.addressLine1,
            'Address line 2': user.addressLine2,
            City: user.city,
            County: user.county,
            'Post code': user.postCode
          }
        );
      });

      XLSExporter.export({
        items: usrs,
        sheetName: 'Users',
        fileName: `Madrasah-Users_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`
      });
    }
  };

  const renderDeleteConfirmation = () => {
    const getUserName = () => {
      let userName = '';
      const user = filteredUsers.find((c) => c._id === deleteUserId);
      if (user) {
        userName = `${user.firstName} ${user.lastName}`;
      }
      return userName;
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showDeleteConfirmation}
        title={`Delete User - ${getUserName()}`}
        from='right'
        width='50vw'
        onRequestClose={() => {
          setShowDeleteConfirmation(false);
          setDeleteUserId('');
        }}
      >
        <div style={{ fontSize: '18px' }}>Are you sure you want to delete the user?</div>
        <Footer
          onCancel={() => {
            setShowDeleteConfirmation(false);
            setDeleteUserId('');
          }}
          onSave={() => {
            deleteUser(deleteUserId, () => {
              setShowDeleteConfirmation(false);
              setDeleteUserId('');
            });
          }}
        />
      </SlidingPane>
    );
  };

  const renderUserPanel = () => {
    const getTitle = () => {
      let title = 'Add user';
      if (mode === 'edit') {
        title = 'Edit user';
        let clsName = '';
        if (selectedUser) {
          clsName = `${selectedUser.firstName} ${selectedUser.lastName}`;
        }
        return `${title} - ${clsName}`;
      }
      return title;
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showUserPanel}
        title={getTitle()}
        from='right'
        width='75vw'
        onRequestClose={() => {
          setShowUserPanel(false);
        }}
      >
        <UserForm
          user={selectedUser}
          mode={mode}
          onCancel={() => {
            setShowUserPanel(false);
          }}
          onSave={(fields) => {
            saveUser(fields, mode, () => {
              setShowUserPanel(false);
            });
          }}
        />
      </SlidingPane>
    );
  };

  const renderActionsMenu = () => {
    return (
      <OverflowMenu
        trigger={<MdMenu className='overflow-menu__icon' focusable={false} />}
        onClick={() => setShowActionsMenu(!showActionsMenu)}
        show={showActionsMenu}
        onHideOverflowMenu={() => {
          setShowActionsMenu(false);
        }}
        onMenuTriggerClick={() => setShowActionsMenu(!showActionsMenu)}
      >
        <HasPermissions permissions={[ADD_USERS_PERMISSION]}>
          <OverflowMenuItem
            id='add_user'
            label='Add user'
            showDivider={true}
            onClick={() => {
              setShowActionsMenu(false);
              setMode('add');
              setSelectedUser(User);
              setShowUserPanel(true);
            }}
          />
        </HasPermissions>
        <OverflowMenuItem
          id='export_users'
          label='Export users'
          onClick={() => {
            setShowActionsMenu(false);
            exportUsers();
          }}
        />
      </OverflowMenu>
    );
  };

  const renderActions = (user) => {
    return (
      <HasPermissions permissions={[DELETE_USERS_PERMISSION]}>
        <div className='actions'>
          <div className='actions-icons'>
            <MdDelete
              className='action-icon'
              onClick={() => {
                setDeleteUserId(user._id);
                setShowDeleteConfirmation(true);
              }}
            />
            {
              user.failedLoginAttempts >= 5 ?
                <Unlock
                  className='action-icon'
                  onClick={async () => {
                    await unlockUser(user._id);
                  }}
                /> :
                null
            }
          </div>
        </div>
      </HasPermissions>
    );
  };

  const getTitleRow = () => {
    return (
      <div className='users--title-row'>
        <Search
          placeholder='Search'
          value={filter.searchText}
          onChange={(e) => setFilter({ ...filter, searchText: e.target.value })}
          onClear={() => setFilter({ ...filter, searchText: '' })}
        />
        <div className='count'>
          <span>{`Displaying ${filteredUsers.length} of ${users.length} users`}</span>
        </div>
        <div className='buttons-cell'>
          <ToggleButton
            onClick={(active) => {
              setFilter({ ...filter, activeFilter: active });
            }}
            items={[
              { name: 'All', label: 'All', pressed: filter.activeFilter === 'All' },
              { name: 'Active', label: 'Active', pressed: filter.activeFilter === 'Active' },
              { name: 'Inactive', label: 'Inactive', pressed: filter.activeFilter === 'Inactive' }]}
          />
          {renderActionsMenu()}
          <div style={{ width: '10px' }} />
        </div>
      </div>
    );
  };

  let mouseDown = 0;
  const rowEventHandlers = {
    onMouseUp: (e) => {
      const elementClicked = e.event.target.tagName.toLowerCase();
      console.log(elementClicked);
      if (elementClicked !== 'svg' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
        setSelectedUser(e.rowData);
        if (canEditUsers) {
          setMode('edit');
        } else {
          setMode('view');
        }
        setShowUserPanel(true);
      }
    },
    onMouseDown: (e) => {
      mouseDown = e.event.clientX;
    }
  };

  const renderUserList = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'fullName') {
        return UserFullNameRenderer(cell.rowData);
      } if (cell.column.key === 'roles') {
        return UserRolesRenderer(cell.rowData, userRoles);
      } if (cell.column.key === 'active') {
        return ActiveRenderer(cell.rowData);
      } if (cell.column.key === 'actions') {
        return renderActions(cell.rowData);
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    const columns = [
      {
        width: 100, title: 'ITS Id', dataKey: 'itsId', key: 'itsId', resizable: true, frozen: true
      },
      {
        width: 300, title: 'Full name', dataKey: 'fullName', key: 'fullName', resizable: true, frozen: true
      },
      {
        width: 300, title: 'Email', dataKey: 'email', key: 'email', resizable: true
      },
      {
        width: 250, title: 'Roles', dataKey: 'roles', key: 'roles', resizable: true
      },
      {
        width: 100, title: 'Active', dataKey: 'active', key: 'active', resizable: true
      }
    ];
    if (hasPermissions([DELETE_USERS_PERMISSION])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        selectable
        rowEventHandlers={rowEventHandlers}
        columns={columns}
        data={filteredUsers}
      />
    );
  };

  return (
    <HasPermissions permissions={[
      VIEW_USERS_PERMISSION, ADD_USERS_PERMISSION, EDIT_USERS_PERMISSION, DELETE_USERS_PERMISSION
    ]}>
      <div className='user-list-wrapper'>
      	{/* showLoading() */}
        {renderDeleteConfirmation()}
      	{getTitleRow()}
        {renderUserList()}
        {renderUserPanel()}
      </div>
    </HasPermissions>
  );
};

export default UserList;

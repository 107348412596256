import React, {
  useContext, useMemo, useCallback, useState, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  compareAsc, format, isBefore, isAfter, isEqual
} from 'date-fns';
import SlidingPane from 'react-sliding-pane';
import {
  CommonTable as Table, Search, ToggleButton, OverflowMenu, OverflowMenuItem
} from '@aeblondon-uk/common-ui-components';
import MdMenu from '../../styles/assets/menu.svg';
import Loading from '../Loading';
// import Notification 				from './Notification';
import XLSExporter from '../../utils/XLSExporter';
import HasPermissions from '../HasPermissions';

import NoData from '../NoData';
import { DATE_FORMAT_UTC, DATE_FORMAT_MONTH_YEAR, DATE_FORMAT_LONG_WITH_TIME } from '../../utils/DateUtils';
import {hasPermissions} from '../../utils/hasPermissions';
import {VIEW_ASSETS_PERMISSION, ADD_ASSETS_PERMISSION,
  EDIT_ASSETS_PERMISSION, DELETE_ASSETS_PERMISSION
} from '../../utils/permissions';
import { getCurrentAcademicYear } from '../../utils/AcademicYears';
import FaChevronRight from '../../styles/assets/chevron.svg';
import AssetForm from '../forms/AssetForm';
import AppContext from '../AppContext';
import {
  AmountRenderer, PriorityRenderer
} from '../tables/TableCellRenderer';
import { useAssetData } from '../hooks/useAssetData';
import '../../styles/components/visualization/asset-list.scss';

const AssetList = ({ selectedClass, tenantId }) => {
  const appContext = useContext(AppContext);
  const {fetchAssets, assets, loading} = useAssetData();

  const [filter, setFilter] = useState({
    activeFilter: 'Active',
    searchText: ''
  });
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showAssetPanel, setShowAssetPanel] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const titleRowRef = useRef(null);

  const currentAcademicYear = useMemo(() => {
    return getCurrentAcademicYear(appContext.settings);
  }, [appContext.settings]);

  useEffect(() => {
    if (currentAcademicYear) {
      setStartDate(format(new Date(currentAcademicYear.startDate), DATE_FORMAT_UTC));
      setEndDate(format(new Date(currentAcademicYear.endDate), DATE_FORMAT_UTC));
    }
  }, [currentAcademicYear]);

  const filteredAssets = useMemo(() => {
    return assets.filter((asset) => {
      const searchAttribs = [
        asset.name, asset.description, asset.owner, asset.createdBy, asset.category, asset.type, asset.state
      ];
      if (!filter.searchText || searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
        if (filter.activeFilter === 'All' || ((asset.active || asset.active !== false) && filter.activeFilter === 'Active') || (asset.active === false && filter.activeFilter === 'Inactive')) {
          return true;
        }
      }
      return false;
    });
  }, [assets, filter]);

  const renderAssetPanel = () => {
      return (
        <SlidingPane
          className='mbl--sliding-pane'
          closeIcon={<FaChevronRight className='active-icon' />}
          isOpen={showAssetPanel}
          title={'Asset'}
          from='right'
          width='80vw'
          onRequestClose={() => {
            // closeInvoicePanel();
            setShowAssetPanel(false);
          }}
        >
          <AssetForm />
          {/* <StudentInvoiceForm
            studentId={selectedStudent._id}
            invoice={selectedInvoice || {...INVOICE, academicYear: currentAcademicYear?.academicYear}}
            academicYears={invoiceYearOptions}
            onCancel={() => {
              closeInvoicePanel();
            }}
            onSave={(fields) => {
              console.log(fields);
              const invoice = { ...fields };
              if (invoice.paymentOption === 'Waiver') {
                invoice.payments = [];
              }
              invoice.studentId = selectedStudent._id;
              saveInvoice(mode, invoice, (updatedInvoice) => {
                if (mode === 'edit') {
                  const index = selectedStudent.invoices.findIndex((inv) => inv._id === invoice._id);
                  const student = { ...selectedStudent };
                  student.invoices[index] = updatedInvoice;
                  setSelectedStudent(student);
                }
                closeInvoicePanel();
              });
            }}
          /> */}
        </SlidingPane>
      );
  };

  const getAssetList = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'submittedBy') {
        const submitter = cell.rowData[cell.column.key];
        return `${submitter.firstName} ${submitter.lastName}`;
      } else if (cell.column.key === 'actions') {
        // return renderActions(cell.rowData);
      } else if (cell.column.key === 'items') {
        return cell.rowData[cell.column.key].length;
      } else if (cell.column.key === 'updatedAt') {
        return format(new Date(cell.rowData[cell.column.key]), DATE_FORMAT_LONG_WITH_TIME);
      } else if (cell.column.key === 'amount') {
        return AmountRenderer(cell.rowData[cell.column.key])
      } else if (cell.column.key === 'description') {
        return <div className='table-cell' title={cell.rowData['justification']}>{cell.rowData[cell.column.key]}</div>;
      } else if (cell.column.key === 'priority') {
        return PriorityRenderer(cell.rowData[cell.column.key]);
      }
      return <div className='table-cell' title={cell.rowData[cell.column.key]}>{cell.rowData[cell.column.key]}</div>;
    };

    const columns = [
      {
        width: 250, title: 'Name', dataKey: 'name', key: 'name', resizable: true
      },
      {
        width: 450, title: 'Description', dataKey: 'description', key: 'description', resizable: true
      },
      {
        width: 100, title: 'Category', dataKey: 'category', key: 'category', resizable: true
      },
      {
        width: 100, title: 'Type', dataKey: 'type', key: 'type', resizable: true
      },
      {
        width: 100, title: 'Location', dataKey: 'location', key: 'location', resizable: true
      },
      {
        width: 100, title: 'Owner', dataKey: 'owner', key: 'owner', resizable: true
      },
      {
        width: 100, title: 'Status', dataKey: 'state', key: 'state', resizable: true
      },
      {
        width: 150, title: 'Created by', dataKey: 'createdBy', key: 'createdBy', resizable: true
      },
      {
        width: 150, title: 'Last updated', dataKey: 'updatedAt', key: 'updatedAt', resizable: true
      }
    ];
    if (hasPermissions([DELETE_ASSETS_PERMISSION])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        rowClassName='mbl--rewards-list-row'
        selectable
        columns={columns}
        data={filteredAssets}
      />
    );
  };

  const getTitleRow = useCallback(() => {
    return (
      <div className='asset--title-row' ref={titleRowRef}>
        <div>
          <Search
            placeholder='Search'
            value={filter.searchText}
            onChange={(e) => setFilter({ ...filter, searchText: e.target.value })}
            onClear={() => setFilter({ ...filter, searchText: '' })}
          />
        </div>
        <div className='items-count'>
          {`Displaying ${filteredAssets.length} of ${assets.length} assets`}
        </div>
        <div className='buttons-cell'>
          <div style={{ width: '10px' }} />
          <ToggleButton
            onClick={(active) => {
              setFilter({ ...filter, activeFilter: active });
            }}
            items={[
              { name: 'All', label: 'All', pressed: filter.activeFilter === 'All' },
              { name: 'Active', label: 'Active', pressed: filter.activeFilter === 'Active' },
              { name: 'Inactive', label: 'Inactive', pressed: filter.activeFilter === 'Inactive' }]}
          />
          {renderActionsMenu()}
        </div>
      </div>
    );
  }, [filteredAssets, showAssetPanel]);

  const exportReport = ({ startDate, endDate }) => {
    if (filteredAssets.length > 0) {
      const studentsArr = [];
      const months = [];
      // create an array of list of months
      filteredAssets.forEach((student) => {
        if (student.coinsEarned) {
          student.coinsEarned.forEach((coin) => {
            if (isAfter(new Date(coin.date), new Date(startDate)) && (isBefore(new Date(coin.date), new Date(endDate)) || isEqual(new Date(coin.date), new Date(endDate)))) {
              const month = format(new Date(coin.date), DATE_FORMAT_MONTH_YEAR);
              if (months.indexOf(month) === -1) {
                months.push(month);
              }
            }
          });
        }
      });

      // sort the months array
      months.sort((a, b) => {
        return compareAsc(new Date(a), new Date(b));
      });

      // now update coins for each students per month
      filteredAssets.forEach((student) => {
        const st = {
          'ITS Id': student.itsId,
          'First name': student.firstName,
          'Middle name': student.father.firstName,
          'Last name': student.lastName,
          Year: student.mblClass.name,
          Division: student.mblClass.division
        };
        if (student.coinsEarned) {
          // add all months to the student object as keys with empty value
          months.forEach((mth) => {
            st[mth] = '';
          });
          // now add coins for each month
          student.coinsEarned.forEach((coin) => {
            if (isAfter(new Date(coin.date), new Date(startDate)) && (isBefore(new Date(coin.date), new Date(endDate)) || isEqual(new Date(coin.date), new Date(endDate)))) {
              const month = format(new Date(coin.date), DATE_FORMAT_MONTH_YEAR);
              st[month] = st[month] ? (st[month] + coin.count) : coin.count;
            }
          });
          st.Total = getCoinsBalance(student);
        }
        studentsArr.push(st);
      });

      XLSExporter.export({
        items: studentsArr,
        sheetName: 'Coins Earned',
        fileName: `Madrasah-Coins-Earned-Report_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`,
        colsWidth: [8, 15, 15, 15, 6, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8] // provide width for 12 month cols
      });
    }
  };

  const renderActionsMenu = useCallback(() => {
    return (
      <OverflowMenu
        trigger={<MdMenu className='overflow-menu__icon' focusable={true} />}
        onClick={() => setShowActionsMenu(!showActionsMenu)}
        show={showActionsMenu}
        onHideOverflowMenu={() => {
          setShowActionsMenu(false);
        }}
        onMenuTriggerClick={() => setShowActionsMenu(!showActionsMenu)}
      >
        <OverflowMenuItem
          id='add_asset'
          key='add_asset'
          label='Add asset'
          onClick={() => {
            setShowActionsMenu(false);
            setShowAssetPanel(true);
          }}
        />
      </OverflowMenu>
    );
  }, [showAssetPanel]);

  return (
    <HasPermissions permissions={[
      VIEW_ASSETS_PERMISSION, ADD_ASSETS_PERMISSION, EDIT_ASSETS_PERMISSION,
      DELETE_ASSETS_PERMISSION
    ]} >
      <div className='asset-list-wrapper'>
        {/* showLoading() */}
        {renderAssetPanel()}
        {getTitleRow()}
        {getAssetList()}
      </div>
    </HasPermissions>
  );
};

AssetList.propTypes = {
  selectedClass: PropTypes.string,
  tenantId: PropTypes.string
};

export default AssetList;

import React from 'react';
import SignupForm from '../forms/SignupForm';
import useTenantData from '../hooks/useTenantData';

const SignupPanel = () => {
  const {
    saveTenant, loading
  } = useTenantData();

  const handleSignup = async (fields) => {
    await saveTenant(fields);
  };

  return (
    <section>
      <SignupForm onSubmit={handleSignup} loading={loading} />
    </section>
  );
};

export default SignupPanel;

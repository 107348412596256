class DataService {
  getItems(url) {
  return fetch(`${url}`, {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json'
    }
  });
}

  post(url, payload) {
    return this.putOrPost(url, payload, 'POST');
  }

  put(url, payload) {
    return this.putOrPost(url, payload, 'PUT');
  }

  putOrPost(url, payload, method) {
    return fetch(`${url}`, {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method,
      body: JSON.stringify(payload)
    });
  }

  delete(url) {
    return fetch(`${url}`, {
      credentials: 'same-origin',
      method: 'DELETE'
    });
  }

  handleResponse(response, redirect) {
    if (!response.ok) {
      if (response.status === 401 && redirect !== false) {
        window.location = `${window.location.origin}/login`;
      }
      return Promise.reject(response);
    }
    return response.json();
  }
}

export default DataService;

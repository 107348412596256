import { compareDesc } from 'date-fns';

export const getSortedAcademicYears = (settings) => {
  const academicYears = settings?.academicYears;
  if (academicYears) {
    academicYears.sort((a, b) => compareDesc(new Date(a.startDate), new Date(b.startDate)));
  }
  return academicYears;
};

export const getCurrentAcademicYear = (settings) => {
  const academicYears = settings?.academicYears;
  if (academicYears) {
    return academicYears.find((yr) => yr.isCurrent);
  }
  return null;
};

import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import {
  Breadcrumbs, Breadcrumb, Header, OverflowMenu, OverflowMenuItem
} from '@aeblondon-uk/common-ui-components';

import { AppContextProvider } from './AppContext';
import appWithIntl from './appWithIntl';
import { useSettings } from './hooks/useSettings';
import { useLogin } from './hooks/useLogin';
import useTenantData from './hooks/useTenantData';
import { stringToColor } from '../utils/ColorGenerator';

import UserForm from './forms/UserForm';
import { useUserData } from './hooks/useUserData';
import FaChevronRight from '../styles/assets/chevron.svg';

import '../styles/styles.scss';

const messages = defineMessages({
  profileMenu: {
    description: 'Profile menu label',
    defaultMessage: 'Profile'
  },
  changePasswordMenu: {
    description: 'Change password menu label',
    defaultMessage: 'Change password'
  },
  logoutMenu: {
    description: 'Logout menu label',
    defaultMessage: 'Logout'
  }
});

export const resolveRoute = (routes, currentRoute, params) => {
  const rt = { ...routes };
  if (!routes[currentRoute]) {
    // if route match not found then replace the params in the route with values
    Object.keys(params).forEach((paramKey) => {
      Object.keys(routes).forEach((route) => {
        if (route.indexOf(paramKey) !== -1) {
          rt[route.replace(`:${paramKey}`, params[paramKey])] = routes[route];
        }
      });
    });
  }
  return rt[currentRoute];
};

const App = ({
  breadcrumbs, params, routes, router, locale, children
}) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [tenantSettings, setTenantSettings] = useState({});
  const [showOverflowMenu, setShowOverflowMenu] = useState(false);
  const [showUserPanel, setShowUserPanel] = useState(false);

  const { settings, fetchSettings, saveSettings } = useSettings(false);
  const {
    user, loggedIn: isLoggedIn, checkLogin, loginUser, logoutUser, error, loading
  } = useLogin();
  const { tenant, fetchTenant } = useTenantData();
  const {
    saveUser, loading: userDataloading
  } = useUserData(false);

  const intl = useIntl();

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (user.tenantId) {
      fetchTenant(user.tenantId);
    }
  }, [user]);

  useEffect(() => {
    setLoggedIn(isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    if (loggedIn && tenant._id) {
      fetchSettings(tenant._id);
    }
  }, [loggedIn, tenant]);

  useEffect(() => {
    setTenantSettings({ ...settings, isEmailConfigured });
  }, [settings]);

  const renderUserPanel = () => {
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showUserPanel && !userDataloading}
        title='Edit profile'
        from='right'
        width='75vw'
        onRequestClose={() => {
          setShowUserPanel(false);
        }}
      >
        <UserForm
          user={user}
          mode='edit'
          isProfileEdit={true}
          onCancel={() => {
            setShowUserPanel(false);
          }}
          onSave={(fields) => {
            saveUser(fields, 'edit', () => {
              setShowUserPanel(false);
            }, false, user.tenantId);
          }}
        />
      </SlidingPane>
    );
  };

  const breadcrumbItems = useMemo(() => {
    const b = [];
    if (breadcrumbs) {
      breadcrumbs.forEach((breadcrumb, index) => {
        b.push(
          <Breadcrumb
            key={`${breadcrumb}-${index}`}
            show={true}
            label={routes[breadcrumb] ? routes[breadcrumb].label : ''}
            route={breadcrumb}
            isCurrent={false}
            onClick={() => {
              router.setRoute(breadcrumb);
            }}
          />
        );
      });
      // add current breadcrumb if it is not a current page
      if (breadcrumbs.length > 0) {
        b.push(
          <Breadcrumb
            key='current-breadcrumb'
            show={true}
            label={resolveRoute(routes, window.location.pathname, params).label}
            isCurrent={true}
          />
        );
      }
    }
    return b;
  }, [breadcrumbs]);

  const renderMenu = () => {
    if (loggedIn) {
      const firstName = user.firstName?.toUpperCase();
      const lastName = user.lastName?.toUpperCase();
      const userInitials = `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`;

      return (
        <OverflowMenu
          trigger={(
            <div className='login--user' style={{ backgroundColor: stringToColor(userInitials, 100, 20) }}>
              {userInitials}
            </div>
          )}
          show={showOverflowMenu}
          onHideOverflowMenu={() => {
            setShowOverflowMenu(false);
          }}
          onMenuTriggerClick={() => {
            setShowOverflowMenu(!showOverflowMenu);
          }}
          menuPosition='right'
        >
          <OverflowMenuItem
            id='user_profile'
            label={intl.formatMessage(messages.profileMenu)}
            onClick={() => {
              setShowOverflowMenu(false);
              setShowUserPanel(true);
            }}
          />
          <OverflowMenuItem
            id='change_password'
            label={intl.formatMessage(messages.changePasswordMenu)}
            showDivider={true}
            onClick={async () => {
              setShowOverflowMenu(false);
              router.setRoute(`/${tenant?.id}/change-password`);
            }}
          />
          <OverflowMenuItem
            id='logout_user'
            label={intl.formatMessage(messages.logoutMenu)}
            onClick={async () => {
              await logoutUser();
              setShowOverflowMenu(false);
              setTimeout(() => {
                router.setRoute('/login');
              }, 200);
            }}
          />
        </OverflowMenu>
      );
    }
    return null;
  };

  const isEmailConfigured = useMemo(() => {
    if (settings.emailServer) {
      const {
        host, port, username
      } = settings.emailServer;
      return !!(host && port && username);
    }
    return false;
  }, [settings.emailServer]);

  return (
    <AppContextProvider value={{
      settings: tenantSettings, saveSettings, setLoggedIn, loginUser, error, loading, loggedIn, user, tenant, locale, router
    }}
    >
      {
        tenant._id || !loggedIn ? (
          <>
            {renderUserPanel()}
            <Header
              headerLabel={tenant?.name || 'Al-Madrasah Al-Burhaniyah'}
              onHeaderLabelClick={() => router.setRoute(`/${tenant?._id}/home`)}
              showMenuIcon={false}
              menu={renderMenu()}
              onNavCloseClick={() => {}}
              onNavOpenClick={() => {}}
            />
            <main className='mbl--main'>
              {/* <Navigation
                currentRoute={window.location.pathname}
                show={loggedIn}
                expanded={showNavMenu}
                routes={routes}
              /> */}
              <div className='content'>
                <Breadcrumbs show={loggedIn}>
                  {breadcrumbItems}
                </Breadcrumbs>
                {children}
              </div>
            </main>
          </>
        ) :
          null
      }
    </AppContextProvider>
  );
};

App.propTypes = {
  children: PropTypes.element,
  locale: PropTypes.string,
  breadcrumbs: PropTypes.array,
  routes: PropTypes.object,
  router: PropTypes.object,
  params: PropTypes.object
};

export default (appWithIntl()(App));

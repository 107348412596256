import { useState, useEffect, useContext } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

const useStudentData = (load) => {
  const appContext = useContext(AppContext);

  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [addingTimelineEntry, setAddingTimelineEntry] = useState(false);

  const fetchStudents = () => {
    if (!appContext.tenant._id) {
      return;
    }

    setLoading(true);
    const dataService = new DataService();
    dataService.getItems(`/api/ui/${appContext.tenant._id}/students/v1/students`)
      .then(dataService.handleResponse)
      .then((response) => {
        setStudents(response);
        setLoading(false);
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const saveStudent = (student, mode, callback) => {
    setLoading(true);
    const dataService = new DataService();
    delete student.createdAt;
    delete student.updatedAt;
    delete student.__v;
    delete student.mblClass;
    if (mode === 'edit') {
      dataService.put(`/api/ui/${appContext.tenant._id}/students/v1/students/${student._id}`, student)
        .then(dataService.handleResponse)
        .then((response) => {
          console.log(':::::::::response', response);
          setLoading(false);
          fetchStudents();
          callback();
        }).catch((err) => {
          console.log('Error::', err);
          setError(err);
          setLoading(false);
        });
    } else {
      delete student._id;
      dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students`, student)
        .then(dataService.handleResponse)
        .then((response) => {
          // setStudents(response.students);
          console.log(':::::::::response', response);
          setLoading(false);
          fetchStudents();
          callback();
        }).catch((err) => {
          console.log('Error::', err);
          setError(err);
          setLoading(false);
        });
    }
  };

  const changeStudentsClass = (studentIds, newClassId, callback) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/action/changeClass`, { students: studentIds, newClassId })
      .then(response => response.json())
      .then((response) => {
        console.log(':::::::::response', response);
        setLoading(false);
        fetchStudents();
        callback();
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const promoteStudents = (callback) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/action/promoteStudents`)
      .then(dataService.handleResponse)
      .then((response) => {
        console.log(':::::::::response', response);
        setLoading(false);
        fetchStudents();
        callback();
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
      });
  };

  const deleteStudent = (studentId, callback) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.delete(`/api/ui/${appContext.tenant._id}/students/v1/students/${studentId}`)
      .then(response => response.json())
      .then((response) => {
        console.log(':::::::::response', response);
        setLoading(false);
        fetchStudents();
        callback();
      }).catch((err) => {
        console.log('Error::', err);
        setLoading(false);
      });
  };

  const importStudents = (importData, mode, callback) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/action/${mode === 'add' ? 'bulkAdd' : 'bulkUpdate'}`, importData)
      .then(dataService.handleResponse)
      .then((response) => {
        console.log(':::::::::response', response);
        setLoading(false);
        fetchStudents();
        callback();
      }).catch((err) => {
        console.log('Error::', err);
        setLoading(false);
      });
  };

  const addCoins = (data, callback) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/coins/add/${data.studentId}`, data.coins)
      .then(dataService.handleResponse)
      .then((response) => {
        console.log(':::::::::add coins response', response);
        setLoading(false);
        fetchStudents();
        callback();
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const redeemCoins = (data, callback) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/coins/redeem/${data.studentId}`, data.coins)
      .then(dataService.handleResponse)
      .then((response) => {
        console.log(':::::::::redeem coins response', response);
        setLoading(false);
        fetchStudents();
        callback();
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const addWarning = async (studentId, warning) => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/${studentId}/actions/addwarning`, warning);
      console.log(`::::::::Warning add for student with id ${studentId}`, resp);
      await dataService.handleResponse(resp);
    } catch (ex) {
      console.log(':::::Exception add warning for student', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const removeWarning = async (studentId, warningId) => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/${studentId}/actions/removewarning`, { warningId });
      console.log(`::::::::Warning removed for student with id ${studentId}`, resp);
      await dataService.handleResponse(resp);
    } catch (ex) {
      console.log(':::::Exception while removing warning for student', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const addHifzTimelineEntry = async (studentId, hifzTimelineEntry) => {
    setAddingTimelineEntry(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/${studentId}/actions/addhifztimelineentry`, hifzTimelineEntry);
      console.log(`::::::::Hifz timeline entry added for student with id ${studentId}`, resp);
      await dataService.handleResponse(resp);
    } catch (ex) {
      console.log(':::::Exception adding hifz timeline entry for student', ex);
      setError(ex);
    }
    setAddingTimelineEntry(false);
  };

  const removeHifzTimelineEntry = async (studentId, hifzTimelineEntryId) => {
    setAddingTimelineEntry(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.post(`/api/ui/${appContext.tenant._id}/students/v1/students/${studentId}/actions/removehifztimelineentry`, { hifzTimelineEntryId });
      console.log(`::::::::Hifz timeline entry removed for student with id ${studentId}`, resp);
      await dataService.handleResponse(resp);
    } catch (ex) {
      console.log(':::::Exception removing hifz timeline entry for student', ex);
      setError(ex);
    }
    setAddingTimelineEntry(false);
  };

  useEffect(() => {
    if (load !== false && appContext.tenant._id) {
      fetchStudents();
    }
    return () => {
      setStudents([]);
    };
  }, []);

  return {
    students,
    loading,
    error,
    addingTimelineEntry,
    fetchStudents,
    saveStudent,
    changeStudentsClass,
    promoteStudents,
    deleteStudent,
    importStudents,
    addCoins,
    redeemCoins,
    addWarning,
    removeWarning,
    addHifzTimelineEntry,
    removeHifzTimelineEntry
  };
};

export default useStudentData;

import { useContext, useState, useEffect } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useInvoiceAndPaymentData = (fetchOnLoad = true) => {
  const appContext = useContext(AppContext);
  const [invoices, setInvoices] = useState([]);
  const [metricData, setMetricData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchInvoices = async () => {
    if (!appContext.tenant._id) {
      return;
    }
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/invoices/v1/invoices`);
      const jsonData = await dataService.handleResponse(resp);
      setInvoices(jsonData.data);
    } catch (ex) {
      console.log(':::::Exception fetching invoices', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const generateInvoices = async (students, invoiceData) => {
    setLoading(true);

    const newInvoices = [];
    students.forEach((student) => {
      const invoice = student.invoices && student.invoices.find((inv) => {
        return inv.academicYear === invoiceData.academicYear && inv.type === invoiceData.type;
      });
      // Create invoice if it doesnt exist and the student belongs to the active class
      if (!invoice && student.mblClass.active !== false) {
        const newInvoice = { ...invoiceData };
        newInvoice.studentId = student._id;
        newInvoices.push(newInvoice);
      }
    });

    console.log(':::::newInvoices', newInvoices);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.post(`/api/ui/${appContext.tenant._id}/invoices/v1/invoices/action/bulkAdd`, newInvoices);
      await dataService.handleResponse(resp);
      await fetchInvoices();
    } catch (ex) {
      console.log(':::::Exception generate invoices', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const sendEmail = async ({
    to, bcc, subject, content
  }) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.post(`/api/ui/${appContext.tenant._id}/invoices/v1/invoices/email/reminderEmail`, {
        to, bcc, subject, content
      });
      await dataService.handleResponse(resp);
      setError(null);
    } catch (ex) {
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  const saveInvoice = async (mode, invoice, callback) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      if (mode === 'add') {
        resp = await dataService.post(`/api/ui/${appContext.tenant._id}/invoices/v1/invoices`, invoice);
      } else {
        resp = await dataService.put(`/api/ui/${appContext.tenant._id}/invoices/v1/invoices/${invoice._id}`, invoice);
      }
      const jsonData = await dataService.handleResponse(resp);
      await fetchInvoices();
      setError(null);
      callback(jsonData.data);
    } catch (ex) {
      console.log(':::::Exception saving email template', mode, ex);
      setLoading(false);
      setError(resp);
      throw Error(ex);
    }
    setLoading(false);
  };

  const deleteInvoice = async (invoiceId) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.delete(`/api/ui/${appContext.tenant._id}/invoices/v1/invoices/${invoiceId}`);
      await dataService.handleResponse(resp);
      await fetchInvoices();
    } catch (ex) {
      console.log(':::::Exception deleting invoice', ex);
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (fetchOnLoad) {
      fetchInvoices();
    }
    return () => {
      setInvoices([]);
    };
  }, []);

  const fetchMetricData = async (year) => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/invoices/v1/invoices/year/${year}`);
      const jsonData = await dataService.handleResponse(resp);
      setMetricData(jsonData.data);
    } catch (ex) {
      console.log(':::::Exception fetching invoices metrics', ex);
      setError(ex);
    }
    setLoading(false);
  };

  return {
    invoices,
    loading,
    error,
    fetchInvoices,
    generateInvoices,
    saveInvoice,
    deleteInvoice,
    fetchMetricData,
    metricData,
    sendEmail
  };
};

import React, {
  useContext, useState, useMemo, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  format, lastDayOfMonth, isBefore, isEqual
} from 'date-fns';
import SlidingPane from 'react-sliding-pane';
import {
  CommonTable as Table, DropDown, Search, OverflowMenu, OverflowMenuItem, OverflowCheckboxItem, ToggleButton
} from '@aeblondon-uk/common-ui-components';

import router from '../../Routes';

import Loading from '../Loading';
import XLSExporter from '../../utils/XLSExporter';
import InvoiceHelper from '../../utils/InvoiceHelper';
import StudentInvoiceForm from '../forms/StudentInvoiceForm';
import PaymentMetrics from './InvoiceAndPaymentMetrics';
import PaymentEmailForm from '../forms/PaymentEmailForm';
import GenerateInvoicesForm from '../forms/GenerateInvoicesForm';
import ExportPaymentsForm from '../forms/ExportPaymentsForm';
import ExportInvoicesForm from '../forms/ExportInvoicesForm';
import StudentForm from '../forms/StudentForm';
import Footer from './Footer';
import AttendanceChart from '../panel/AttendanceChartPanel';

import MdDelete from '../../styles/assets/delete.svg';
import MdMenu from '../../styles/assets/menu.svg';
import FaFilter from '../../styles/assets/filter.svg';
import MdAddCircle from '../../styles/assets/add.svg';
import StudentIcon from '../../styles/assets/student.svg';
import ChartIcon from '../../styles/assets/chart.svg';
import FaChevronRight from '../../styles/assets/chevron.svg';
import NoData from '../NoData';
import HasPermissions from '../HasPermissions';
import AppContext from '../AppContext';
import { DATE_FORMAT_UTC } from '../../utils/DateUtils';

import { useInvoiceAndPaymentData } from '../hooks/useInvoiceAndPaymentData';
import { useClassData } from '../hooks/useClassData';
import { INVOICE_DATA, INVOICE } from '../hooks/InitialCopies';
import {
  VIEW_INVOICES_PERMISSION, VIEW_INVOICE_METRICS_PERMISSION, ADD_INVOICES_PERMISSION, EDIT_INVOICES_PERMISSION,
  DELETE_INVOICES_PERMISSION, EDIT_STUDENTS_PERMISSION, VIEW_STUDENTS_PERMISSION, VIEW_ALL_CLASS_ATTENDANCE_PERMISSION
} from '../../utils/permissions';
import { hasPermissions } from '../../utils/hasPermissions';
import { getSortedAcademicYears, getCurrentAcademicYear } from '../../utils/AcademicYears';

import {
  FullNameRenderer, ClassRenderer, InvoiceStatusRenderer, DateRenderer
} from '../tables/TableCellRenderer';

const InvoiceAndPaymentList = ({ invType }) => {
  const appContext = useContext(AppContext);
  const {
    invoices: students, generateInvoices, saveInvoice, deleteInvoice, sendEmail, loading
  } = useInvoiceAndPaymentData();
  const { classes } = useClassData();
  const canEditInvoices = hasPermissions([EDIT_INVOICES_PERMISSION]);
  const canEditStudents = hasPermissions([EDIT_STUDENTS_PERMISSION]);

  const sortedAcademicYears = useMemo(() => {
    return getSortedAcademicYears(appContext.settings);
  }, [appContext.settings]);

  const currentAcademicYear = useMemo(() => {
    return getCurrentAcademicYear(appContext.settings);
  }, [appContext.settings]);

  const invoiceYearOptions = useMemo(() => {
    const options = [];
    sortedAcademicYears?.forEach(yr => {
      options.push(<option key={yr.academicYear} value={yr.academicYear}>{yr.academicYear}</option>);
    });
    return options;
  }, [sortedAcademicYears]);

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showGenerateInvoicePanel, setShowGenerateInvoicePanel] = useState(false);
  const [showExportPaymentsPanel, setShowExportPaymentsPanel] = useState(false);
  const [showExportInvoicesPanel, setShowExportInvoicesPanel] = useState(false);
  const [showSendEmailPanel, setShowSendEmailPanel] = useState(false);
  const [showInvoicePanel, setShowInvoicePanel] = useState(false);
  const [showStudentPanel, setShowStudentPanel] = useState(false);
  const [showStudentInvoicesPanel, setShowStudentInvoicesPanel] = useState(false);
  const [showDeleteInvoicePanel, setShowDeleteInvoicePanel] = useState(false);
  const [showMetricsPanel, setShowMetricsPanel] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [showStudentAttendanceHistory, setShowStudentAttendanceHistory] = useState(false);
  const [mode, setMode] = useState('add');
  const [filter, setFilter] = useState({
    searchText: '',
    // invType: 'All',
    invStatus: [],
    paymentMode: [],
    selectedYear: 0
  });

  useEffect(() => {
    if (currentAcademicYear) {
      setFilter({ ...filter, selectedYear: currentAcademicYear?.academicYear });
    }
  }, [currentAcademicYear]);

  const teachersEmail = useMemo(() => {
    if (classes?.length > 0) {
      const activeClasses = classes.filter(cls => cls.active);
      const emails = new Set(activeClasses.map(cls => cls.teacher.email));
      return Array.from(emails).join(',');
    }
    return '';
  }, [classes]);

  const hasSelectedYear = useCallback((invoice) => {
    return invoice.academicYear === filter.selectedYear;
  }, [filter.selectedYear]);

  const hasMatchingSearchText = useCallback((student) => {
    if (!filter.searchText) {
      return true;
    }
    const searchAttribs = [student.itsId, student.firstName, student.lastName];
    if (student.father) {
      searchAttribs.push(student.father.firstName);
      searchAttribs.push(student.father.email);
      searchAttribs.push(student.father.phone);
    }
    if (student.mother) {
      searchAttribs.push(student.mother.firstName);
      searchAttribs.push(student.mother.email);
      searchAttribs.push(student.mother.phone);
    }

    // enable search by orderNumber
    if (student.invoices) {
      student.invoices.forEach((inv) => {
        if (inv.payments) {
          inv.payments.forEach((p) => {
            searchAttribs.push(p.orderNumber);
          });
        }
      });
    }

    if (searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
      return true;
    }
    return false;
  }, [filter.searchText]);

  const hasMatchingInvType = useCallback((invoice) => {
    if (invType === 'all' || invoice.type.toLowerCase() === invType) {
      return true;
    }
    return false;
  }, [invType]);

  const hasMatchingInvStatus = useCallback((invoice) => {
    if (filter.invStatus.length === 0 || filter.invStatus.includes(InvoiceHelper.getInvoiceStatus(invoice).text) ||
      (filter.invStatus.includes('Expected') && isInvoicePaymentExpected(invoice))) {
      return true;
    }
    return false;
  }, [filter.invStatus]);

  const isInvoicePaymentExpected = useCallback((invoice) => {
    if (filter.invStatus.length === 0 || !filter.invStatus.includes('Expected')) {
      return true;
    }

    const lastDay = lastDayOfMonth(new Date());
    return invoice.payments.find((p) => p.status === 'Unpaid' && (isBefore(new Date(p.dueDate), lastDay) || isEqual(new Date(p.dueDate), lastDay)));
  }, [filter.invStatus]);

  const hasMatchingPaymentMode = useCallback((invoice) => {
    if (filter.paymentMode.length === 0) {
      return true;
    }
    return invoice.payments.find((payment) => filter.paymentMode.includes(payment.paymentMode));
  }, [filter.paymentMode]);

  const filteredInvoices = useMemo(() => {
    const fs = [];
    if (students) {
      students.forEach((student) => {
        if (hasMatchingSearchText(student)) {
          if (invType === 'no-invoice') {
            const selectedYearInvoice = student.invoices && student.invoices.find(
              (inv) => inv.type === 'Fees' && hasSelectedYear(inv)
            );
            if (!selectedYearInvoice) {
              fs.push(student);
            }
          } else {
            const invcs = student.invoices;
            for (let i = 0; invcs && i < invcs.length; i++) {
              if (hasSelectedYear(invcs[i]) && hasMatchingInvType(invcs[i]) && hasMatchingInvStatus(invcs[i]) && hasMatchingPaymentMode(invcs[i])) {
                fs.push(student);
                break;
              }
            }
          }
        }
      });
    }
    return fs;
  }, [students, filter, invType]);

  const renderActions = (student) => {
    return (
      <div className='actions'>
        <div className='actions-icons'>
          <HasPermissions permissions={[ADD_INVOICES_PERMISSION]}>
            <MdAddCircle
              title='Add invoice'
              className='action-icon'
              onClick={() => {
                setMode('add');
                setSelectedInvoice(null);
                setSelectedStudent(student);
                setShowInvoicePanel(true);
              }}
            />
          </HasPermissions>
          <HasPermissions permissions={[VIEW_STUDENTS_PERMISSION]}>
            <StudentIcon
              title='View student'
              className='action-icon'
              onClick={() => {
                setMode('view');
                setSelectedInvoice(null);
                setSelectedStudent(student);
                setShowStudentPanel(true);
              }}
            />
          </HasPermissions>
          <HasPermissions permissions={[VIEW_ALL_CLASS_ATTENDANCE_PERMISSION]}>
            <ChartIcon
              title='Attendance history'
              className='action-icon'
              onClick={() => {
                setSelectedStudent(student);
                setShowStudentAttendanceHistory(true);
              }}
            />
          </HasPermissions>
        </div>
      </div>
    );
  };

  const renderInvoiceActions = (invoice) => {
    return (
      <HasPermissions permissions={[DELETE_INVOICES_PERMISSION]}>
        <div className='actions'>
          <div className='actions-icons'>
            <MdDelete
              title='Delete Invoice'
              className='action-icon'
              onClick={() => {
                setSelectedInvoice(invoice);
                setShowDeleteInvoicePanel(true);
                setShowStudentInvoicesPanel(false);
              }}
            />
          </div>
        </div>
      </HasPermissions>
    );
  };

  const renderStudentDetails = () => {
    const getTitle = () => {
      let studentName = '';
      if (selectedStudent && selectedStudent.father) {
        studentName = `${selectedStudent.firstName} ${selectedStudent.father.firstName} ${selectedStudent.lastName}`;
      }

      return studentName ? `View Student - ${studentName}` : 'View Student';
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showStudentPanel}
        title={getTitle()}
        from='right'
        width='75vw'
        onRequestClose={() => {
          setShowStudentPanel(false);
          setSelectedStudent({});
        }}
      >
        <StudentForm
          student={selectedStudent}
          mode={!canEditStudents ? 'view' : mode}
          classes={classes}
          onCancel={() => {
            setShowStudentPanel(false);
          }}
          loading={loading}
        />
      </SlidingPane>
    );
  };

  const renderStudentAttendanceHistory = () => {
    const studentName = `${selectedStudent.firstName} ${selectedStudent.father ? selectedStudent.father.firstName : ''} ${selectedStudent.lastName}`;
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showStudentAttendanceHistory}
        title={`Attendance History - ${studentName}`}
        from='right'
        width='80vw'
        onRequestClose={() => {
          setShowStudentAttendanceHistory(false);
        }}
      >
        <AttendanceChart
          student={selectedStudent}
          id={selectedStudent._id}
          startDate={format(new Date(currentAcademicYear?.startDate || null), DATE_FORMAT_UTC)}
          endDate={format(new Date(), DATE_FORMAT_UTC)}
        />
      </SlidingPane>
    );
  };

  const renderDelteInvoicePanel = () => {
    const closeDeleteInvoicePanel = () => {
      setShowDeleteInvoicePanel(false);
      setShowStudentInvoicesPanel(true);
    };

    const getTitle = () => {
      const title = 'Delete Invoice';
      let studentName = '';
      if (selectedStudent.firstName) {
        if (selectedStudent.father) {
          studentName = `${selectedStudent.firstName} ${selectedStudent.father.firstName} ${selectedStudent.lastName}`;
        } else {
          studentName = `${selectedStudent.firstName} ${selectedStudent.lastName}`;
        }
      }
      if (studentName) {
        return `${title} - ${studentName}`;
      }
      return title;
    };

    const inv = selectedInvoice || {};
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showDeleteInvoicePanel}
        title={getTitle()}
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeDeleteInvoicePanel();
        }}
      >
        <div style={{ fontSize: '18px' }}>{`Are you sure you want to delete ${inv.type} invoice #${inv.invoiceNumber} for the Academic year ${inv.academicYear}H?`}</div>
        <Footer
          onCancel={() => closeDeleteInvoicePanel()}
          onSave={async () => {
            await deleteInvoice(selectedInvoice._id);
            const fInvoices = [...selectedStudent.invoices].filter((invoice1) => invoice1._id !== selectedInvoice._id);
            setSelectedStudent({ ...selectedStudent, invoices: fInvoices });
            closeDeleteInvoicePanel();
          }}
          saveButtonLabel='Delete'
        />
      </SlidingPane>
    );
  };

  const renderStudentInvoicesPanel = () => {
    const closeStudentInvoicesPanel = () => {
      setShowStudentInvoicesPanel(false);
    };

    const getTitle = () => {
      const title = 'Invoices';
      let studentName = '';
      if (selectedStudent.firstName) {
        if (selectedStudent.father) {
          studentName = `${selectedStudent.firstName} ${selectedStudent.father.firstName} ${selectedStudent.lastName}`;
        } else {
          studentName = `${selectedStudent.firstName} ${selectedStudent.lastName}`;
        }
      }
      if (studentName) {
        return `${title} - ${studentName}`;
      }
      return title;
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showStudentInvoicesPanel}
        title={getTitle()}
        from='right'
        width='80vw'
        onRequestClose={() => {
          closeStudentInvoicesPanel();
        }}
      >
        {getStudentInvoices(selectedStudent)}
      </SlidingPane>
    );
  };

  const renderInvoicePanel = () => {
    const closeInvoicePanel = () => {
      setShowInvoicePanel(false);
    };

    const getTitle = () => {
      let title = 'Add invoice';
      let studentName = '';
      if (mode === 'edit') {
        title = 'Edit invoice';
      }
      if (selectedStudent.firstName) {
        if (selectedStudent.father) {
          studentName = `${selectedStudent.firstName} ${selectedStudent.father.firstName} ${selectedStudent.lastName}`;
        } else {
          studentName = `${selectedStudent.firstName} ${selectedStudent.lastName}`;
        }
      }
      if (studentName) {
        return `${title} - ${studentName}`;
      }
      return title;
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showInvoicePanel}
        title={getTitle()}
        from='right'
        width='80vw'
        onRequestClose={() => {
          closeInvoicePanel();
        }}
      >
        <StudentInvoiceForm
          studentId={selectedStudent._id}
          invoice={selectedInvoice || { ...INVOICE, academicYear: currentAcademicYear?.academicYear }}
          academicYears={invoiceYearOptions}
          onCancel={() => {
            closeInvoicePanel();
          }}
          onSave={(fields) => {
            console.log(fields);
            const invoice = { ...fields };
            if (invoice.paymentOption === 'Waiver') {
              invoice.payments = [];
            }
            invoice.studentId = selectedStudent._id;
            saveInvoice(mode, invoice, (updatedInvoice) => {
              if (mode === 'edit') {
                const index = selectedStudent.invoices.findIndex((inv) => inv._id === invoice._id);
                const student = { ...selectedStudent };
                student.invoices[index] = updatedInvoice;
                setSelectedStudent(student);
              }
              closeInvoicePanel();
            });
          }}
        />
      </SlidingPane>
    );
  };

  const renderSendEmailPanel = () => {
    const closeSendEmailPanel = () => {
      setShowSendEmailPanel(false);
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showSendEmailPanel}
        title='Send email'
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeSendEmailPanel();
        }}
      >
        <PaymentEmailForm
          isLoading={loading}
          students={students}
          teachersEmail={teachersEmail}
          onCancel={() => {
            closeSendEmailPanel();
          }}
          onSave={async (fields) => {
            try {
              await sendEmail(fields);
              closeSendEmailPanel();
            } catch (ex) {
              console.log('Exception sending emails', ex);
            }
          }}
        />
      </SlidingPane>
    );
  };

  const performExportInvoices = (params) => {
    const { type, academicYear, status } = params;

    if (students.length > 0) {
      const exportInvoices = [];
      students.forEach((student) => {
        if (student.invoices) {
          student.invoices.forEach((invoice) => {
            if ((type === 'All' || invoice.type === type) && (academicYear === invoice.academicYear || academicYear === '0')) {
              const invoiceStatus = InvoiceHelper.getInvoiceStatus(invoice);
              if (status === 'All' || invoiceStatus.text === status) {
                exportInvoices.push(
                  {
                    'Invoice No.': invoice.invoiceNumber,
                    Type: invoice.type,
                    Status: invoiceStatus.text,
                    'Academic year': invoice.academicYear,
                    Amount: invoice.amount,
                    'Created date': invoice.createdAt ? format(new Date(invoice.createdAt), DATE_FORMAT_UTC) : '',
                    'Due date': invoice.dueDate ? format(new Date(invoice.dueDate), DATE_FORMAT_UTC) : '',
                    'First name': student.firstName,
                    'Last name': student.lastName,
                    'ITS Id': student.itsId,
                    Year: `${student.mblClass.name} ${student.mblClass.division}`,
                    'Father ITS': student.father.itsId,
                    'Father Name': student.father.firstName,
                    'Father Email': student.father.email,
                    'Father Phone': student.father.phone,
                    'Mother ITS': student.mother.itsId,
                    'Mother Name': student.mother.firstName,
                    'Mother Email': student.mother.email,
                    'Mother Phone': student.mother.phone,
                    Comment: invoice.comment
                  }
                );
              }
            }
          });
        }
      });
      XLSExporter.export({
        items: exportInvoices,
        sheetName: 'Invoices',
        fileName: `Madrasah-Invoices_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`
      });
    }
  };

  const renderExportInvoicesPanel = () => {
    const closeExportInvoicesPanel = () => {
      setShowExportInvoicesPanel(false);
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showExportInvoicesPanel}
        title='Export invoices'
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeExportInvoicesPanel();
        }}
      >
        <ExportInvoicesForm
          currentAcademicYear={currentAcademicYear?.academicYear}
          onCancel={() => {
            closeExportInvoicesPanel();
          }}
          onSave={(fields) => {
            performExportInvoices(fields);
            closeExportInvoicesPanel();
          }}
        />
      </SlidingPane>
    );
  };

  const performExportPayments = (params) => {
    const {
      academicYear, paymentMode, status, startDate, endDate
    } = params;
    if (students.length > 0) {
      const payments = [];
      students.forEach((student) => {
        if (student.invoices) {
          student.invoices.forEach((invoice) => {
            if (academicYear === invoice.academicYear || academicYear === '0') {
              invoice.payments.forEach((payment) => {
                if ((paymentMode === 'All' || paymentMode === payment.paymentMode) && (status === 'All' || status === payment.status) && ((new Date(payment.paidDate) >= startDate && new Date(payment.paidDate) <= endDate) || !payment.paidDate)) {
                  payments.push(
                    {
                      'First name': student.firstName,
                      'Last name': student.lastName,
                      'ITS Id': student.itsId,
                      Year: `${student.mblClass.name} ${student.mblClass.division}`,
                      'Father Name': student.father.firstName,
                      'Mother Name': student.mother.firstName,
                      'Invoice Number': invoice.invoiceNumber,
                      'Invoice type': invoice.type,
                      'Invoice amount': invoice.amount,
                      'Invoice created date': invoice.createdAt ? format(new Date(invoice.createdAt), DATE_FORMAT_UTC) : '',
                      'Invoice due date': invoice.dueDate ? format(new Date(invoice.dueDate), DATE_FORMAT_UTC) : '',
                      'Payment option': invoice.paymentOption,
                      'Academic year': invoice.academicYear,
                      'Payment mode': payment.paymentMode,
                      'Payment Status': payment.status,
                      'Payment Amount': payment.amount,
                      'Order Number': payment.orderNumber,
                      'Payment due date': payment.dueDate ? format(new Date(payment.dueDate), DATE_FORMAT_UTC) : '',
                      'Payment date': payment.paidDate ? format(new Date(payment.paidDate), DATE_FORMAT_UTC) : '',
                      Comment: payment.comment
                    }
                  );
                }
              });
            }
          });
        }
      });
      payments.sort((a, b) => {
        return new Date(b['Payment date']) - new Date(a['Payment date']);
      });
      XLSExporter.export({
        items: payments,
        sheetName: 'Payments',
        fileName: `Madrasah-Payments_${format(new Date(startDate), DATE_FORMAT_UTC)}_to_${format(new Date(endDate), DATE_FORMAT_UTC)}.xlsx`
      });
    }
  };

  const renderExportPaymentPanel = () => {
    const closeExportPaymentPanel = () => {
      setShowExportPaymentsPanel(false);
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showExportPaymentsPanel}
        title='Export payments'
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeExportPaymentPanel();
        }}
      >
        <ExportPaymentsForm
          currentAcademicYear={currentAcademicYear?.academicYear}
          onCancel={() => {
            closeExportPaymentPanel();
          }}
          onSave={(fields) => {
            console.log(fields);
            performExportPayments(fields);
            closeExportPaymentPanel();
          }}
        />
      </SlidingPane>
    );
  };

  const renderGenerateInoicePanel = () => {
    const closeGenerateInvoicePanel = () => {
      setShowGenerateInvoicePanel(false);
      setSelectedStudent({});
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showGenerateInvoicePanel}
        title='Generate Bulk Invoices'
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeGenerateInvoicePanel();
        }}
      >
        <GenerateInvoicesForm
          invoice={{ ...INVOICE_DATA, academicYear: currentAcademicYear?.academicYear }}
          onCancel={() => {
            closeGenerateInvoicePanel();
          }}
          onSave={async (fields) => {
            await generateInvoices(students, fields);
            closeGenerateInvoicePanel();
          }}
        />
      </SlidingPane>
    );
  };

  const renderActionsMenu = () => {
    return (
      <OverflowMenu
        trigger={<MdMenu className='overflow-menu__icon' focusable={false} />}
        onClick={() => setShowActionsMenu(!showActionsMenu)}
        show={showActionsMenu}
        onHideOverflowMenu={() => {
          setShowActionsMenu(false);
        }}
        onMenuTriggerClick={() => setShowActionsMenu(!showActionsMenu)}
      >
        <OverflowMenuItem
          id='generate_invoices'
          label='Generate invoices'
          showDivider={true}
          onClick={() => {
            setShowGenerateInvoicePanel(true);
            setShowActionsMenu(false);
          }}
        />
        <OverflowMenuItem
          id='export_payments'
          label='Export payments'
          onClick={() => {
            setShowExportPaymentsPanel(true);
            setShowActionsMenu(false);
          }}
        />
        <OverflowMenuItem
          id='export_invoices'
          label='Export invoices'
          showDivider={true}
          onClick={() => {
            setShowExportInvoicesPanel(true);
            setShowActionsMenu(false);
          }}
        />
        {
          appContext.settings.isEmailConfigured ? (
            <OverflowMenuItem
              id='send_email'
              label='Send email'
              onClick={() => {
                setShowSendEmailPanel(true);
                setShowActionsMenu(false);
              }}
            />
          ) :
            null
        }
      </OverflowMenu>
    );
  };

  const getTitleRow = () => {
    const updateStatusFilter = (filterType, value) => {
      const tmpFilter = { ...filter };
      if (tmpFilter[filterType].includes(value)) {
        tmpFilter[filterType].splice(tmpFilter[filterType].indexOf(value), 1);
        setFilter(tmpFilter);
      } else {
        tmpFilter[filterType].push(value);
        setFilter(tmpFilter);
      }
    };

    return (
      <div className='invoices--title-row'>
        <div className='leading-container'>
          <Search
            placeholder='Search'
            value={filter.searchText}
            onChange={(e) => setFilter({ ...filter, searchText: e.target.value })}
            onClear={() => setFilter({ ...filter, searchText: '' })}
          />
          <DropDown
            label=''
            className='year-selector'
            value={filter.selectedYear}
            onChange={(e) => setFilter({ ...filter, selectedYear: e.target.value })}
          >
            {invoiceYearOptions}
          </DropDown>
        </div>
        <div className='student-count'>
          {`Displaying ${filteredInvoices.length} of ${students.length} students`}
        </div>
        <div className='buttons-cell'>
          <HasPermissions permissions={[VIEW_INVOICE_METRICS_PERMISSION]}>
            <ChartIcon
              className='metric-icon'
              onClick={() => {
              // this.setState({showMetrics: !this.state.showMetrics});
                setShowMetricsPanel(!showMetricsPanel);
              }}
            />
          </HasPermissions>
          <ToggleButton
            onClick={(type) => {
              // setFilter({ ...filter, invType: type });
              router.setRoute(type);
            }}
            items={[
              { name: 'all', label: 'All', pressed: invType === 'all' },
              { name: 'fees', label: 'Fees', pressed: invType === 'fees' },
              { name: 'niyaz', label: 'Niyaz', pressed: invType === 'niyaz' },
              { name: 'no-invoice', label: 'No invoice', pressed: invType === 'no-invoice' }]}
          />
          {renderActionsMenu()}
          <OverflowMenu
            trigger={<FaFilter className='overflow-menu__icon' focusable={false} />}
            onClick={() => setShowFilterMenu(!showFilterMenu)}
            disabled={invType === 'no-invoice'}
            show={showFilterMenu}
            onHideOverflowMenu={() => {
              setShowFilterMenu(false);
            }}
            onMenuTriggerClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            <OverflowCheckboxItem
              id='fees_paid'
              label='Paid'
              checked={filter.invStatus.includes('Paid')}
              onSelectionChange={() => {
                updateStatusFilter('invStatus', 'Paid');
              }}
            />
            <OverflowCheckboxItem
              id='fees_unpaid'
              label='Unpaid'
              checked={filter.invStatus.includes('Unpaid')}
              onSelectionChange={() => {
                updateStatusFilter('invStatus', 'Unpaid');
              }}
            />
            <OverflowCheckboxItem
              id='fees_inst_unpaid'
              label='Installment unpaid'
              checked={filter.invStatus.includes('Inst. unpaid')}
              onSelectionChange={() => {
                updateStatusFilter('invStatus', 'Inst. unpaid');
              }}
            />
            <OverflowCheckboxItem
              id='fees_part_paid'
              label='Part paid'
              checked={filter.invStatus.includes('Part paid')}
              onSelectionChange={() => {
                updateStatusFilter('invStatus', 'Part paid');
              }}
            />
            <OverflowCheckboxItem
              id='fees_expected'
              label='Expected this month'
              checked={filter.invStatus.includes('Expected')}
              onSelectionChange={() => {
                updateStatusFilter('invStatus', 'Expected');
              }}
            />
            <OverflowCheckboxItem
              id='fees_waiver'
              label='Waiver'
              checked={filter.invStatus.includes('Waiver')}
              showDivider={true}
              onSelectionChange={() => {
                updateStatusFilter('invStatus', 'Waiver');
              }}
            />
            <OverflowCheckboxItem
              id='online_payments'
              label='Online'
              checked={filter.paymentMode.includes('Online')}
              onSelectionChange={() => {
                updateStatusFilter('paymentMode', 'Online');
              }}
            />
            <OverflowCheckboxItem
              id='cheque_payments'
              label='Cheque'
              checked={filter.paymentMode.includes('Cheque')}
              onSelectionChange={() => {
                updateStatusFilter('paymentMode', 'Cheque');
              }}
            />
            <OverflowCheckboxItem
              id='cash_payments'
              label='Cash'
              checked={filter.paymentMode.includes('Cash')}
              onSelectionChange={() => {
                updateStatusFilter('paymentMode', 'Cash');
              }}
            />
          </OverflowMenu>
        </div>
      </div>
    );
  };

  const renderMetrics = () => {
    const closeMeticsPanel = () => {
      setShowMetricsPanel(false);
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showMetricsPanel}
        title='Invoice & payment metrics'
        from='right'
        width='80vw'
        onRequestClose={() => {
          closeMeticsPanel();
        }}
      >
        <PaymentMetrics />
      </SlidingPane>
    );
  };

  const getStudentInvoices = (student) => {
    const TableCell = (cell) => {
      if (cell.column.key === 'status') {
        return InvoiceStatusRenderer(cell.rowData);
      } if (cell.column.key === 'dueDate' || cell.column.key === 'createdAt') {
        return DateRenderer(cell.rowData.dueDate);
      } if (cell.column.key === 'actions') {
        return renderInvoiceActions(cell.rowData);
      } if (cell.column.key === 'paymentOption' && cell.rowData.paymentOption === 'Installment') {
        return `${cell.rowData.payments.length} ${cell.rowData.paymentOption}s`;
      } if (cell.column.key === 'lastPaymentDate' && cell.rowData.paymentOption !== 'None') {
        const payments = JSON.parse(JSON.stringify(cell.rowData.payments));
        payments.reverse();
        const p = payments.find((payment) => {
          return payment.paidDate !== null;
        });
        return p && DateRenderer(p.paidDate);
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    let mouseDown = 0;
    const rowEventHandlers = {
      onMouseUp: (e) => {
        const elementClicked = e.event.target.tagName.toLowerCase();
        if (elementClicked !== 'svg' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
          if (canEditInvoices) {
            setMode('edit');
          } else {
            setMode('view');
          }
          setSelectedInvoice(e.rowData);
          setShowInvoicePanel(true);
        }
      },
      onMouseDown: (e) => {
        mouseDown = e.event.clientX;
      }
    };

    const columns = [
      {
        width: 100, title: 'Invoice #', dataKey: 'invoiceNumber', key: 'invoiceNumber', resizable: true, frozen: true
      },
      {
        width: 120, title: 'Academic year', dataKey: 'academicYear', key: 'academicYear', resizable: true, frozen: true
      },
      {
        width: 165, title: 'Invoice date', dataKey: 'createdAt', key: 'createdAt', resizable: true
      },
      {
        width: 165, title: 'Due date', dataKey: 'dueDate', key: 'dueDate', resizable: true
      },
      {
        width: 165, title: 'Last payment date', dataKey: 'lastPaymentDate', key: 'lastPaymentDate', resizable: true
      },
      {
        width: 100, title: 'Type', dataKey: 'type', key: 'type', resizable: true
      },
      {
        width: 100, title: 'Amount', dataKey: 'amount', key: 'amount', resizable: true
      },
      {
        width: 150, title: 'Status', dataKey: 'status', key: 'status', resizable: true
      },
      {
        width: 150, title: 'Payment option', dataKey: 'paymentOption', key: 'paymentOption', resizable: true
      }
    ];

    if (hasPermissions([DELETE_INVOICES_PERMISSION])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        selectable
        rowEventHandlers={rowEventHandlers}
        columns={columns}
        data={student.invoices}
      />
    );
  };

  const getStudentList = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'fullName') {
        return FullNameRenderer(cell.rowData);
      } if (cell.column.key === 'year') {
        return ClassRenderer(cell.rowData);
      } if (cell.column.key === 'Fees' || cell.column.key === 'Niyaz') {
        const selectedYearInvoice = cell.rowData && cell.rowData.invoices && cell.rowData.invoices.find(
          (inv) => inv.type === cell.column.key && inv.academicYear === filter.selectedYear
        );
        if (cell.column.key === 'Niyaz' && !selectedYearInvoice) {
          return 'N/A';
        }
        const statusClickHandler = (invoice) => {
          if (canEditInvoices) {
            setMode('edit');
          } else {
            setMode('view');
          }
          setSelectedStudent(cell.rowData);
          setSelectedInvoice(invoice);
          setShowInvoicePanel(true);
        };
        return InvoiceStatusRenderer(selectedYearInvoice, statusClickHandler);
      } if (cell.column.key === 'actions') {
        return renderActions(cell.rowData);
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    let mouseDown = 0;
    const rowEventHandlers = {
      onMouseUp: (e) => {
        const target = e.event.target;
        const elementClicked = target.tagName.toLowerCase();
        console.log(elementClicked);
        if (target.className?.includes && target.className?.includes('tag')) {
          // let the tag onClick handler handle this click
          return;
        }
        if (elementClicked !== 'svg' && elementClicked !== 'circle' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
          if (canEditInvoices) {
            setMode('edit');
          } else {
            setMode('view');
          }
          setSelectedStudent(e.rowData);
          setShowStudentInvoicesPanel(true);
        }
      },
      onMouseDown: (e) => {
        mouseDown = e.event.clientX;
      }
    };

    const columns = [
      {
        width: 100, title: 'ITS Id', dataKey: 'itsId', key: 'itsId', resizable: true, frozen: true
      },
      {
        width: 300, title: 'Full name', dataKey: 'fullName', key: 'fullName', resizable: true, frozen: true
      },
      {
        width: 150, title: 'Year', dataKey: 'year', key: 'year', resizable: true
      },
      {
        width: 150, title: 'Fees status', dataKey: 'fees-status', key: 'Fees', resizable: true
      },
      {
        width: 150, title: 'Niyaz status', dataKey: 'niyaz-status', key: 'Niyaz', resizable: true
      }
    ];
    if (hasPermissions([
      ADD_INVOICES_PERMISSION, VIEW_STUDENTS_PERMISSION, VIEW_ALL_CLASS_ATTENDANCE_PERMISSION
    ])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        selectable
        rowEventHandlers={rowEventHandlers}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        columns={columns}
        data={filteredInvoices}
      />
    );
  };

  return (
    <HasPermissions permissions={[
      VIEW_INVOICES_PERMISSION, VIEW_INVOICE_METRICS_PERMISSION, ADD_INVOICES_PERMISSION, EDIT_INVOICES_PERMISSION,
      DELETE_INVOICES_PERMISSION
    ]}
    >
      <div className='student-invoice-wrapper'>
        {/* showLoading() */}
        {renderSendEmailPanel()}
        {renderDelteInvoicePanel()}
        {renderStudentInvoicesPanel()}
        {renderStudentDetails()}
        {renderStudentAttendanceHistory()}
        {renderInvoicePanel()}
        {renderGenerateInoicePanel()}
        {renderExportInvoicesPanel()}
        {renderExportPaymentPanel()}
        {getTitleRow()}
        {renderMetrics()}
        {getStudentList()}
      </div>
    </HasPermissions>
  );
};

InvoiceAndPaymentList.propTypes = {
  invType: PropTypes.string
};

export default InvoiceAndPaymentList;

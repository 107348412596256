import React from 'react';
import PropTypes from 'prop-types';
// import Loading from '../Loading';
import { DropDown, TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import { COUNTRY_LIST } from './Constants';
import { Tenant } from '../hooks/InitialCopies';
import { useForm } from '../hooks/useForm';

const SignupForm = ({ onSubmit = () => {}, loading }) => {
  const [fields, handleFieldChange] = useForm(Tenant);

  const getCountryList = () => {
    return COUNTRY_LIST.map((c) => {
      return <option key={c.Name} value={c.Name}>{c.Name}</option>;
    });
  };

  const showLoading = () => {
    if (loading) {
      return (<Loading />);
    }
    return null;
  };

  return (
    <div className='mb-signup'>
      {showLoading()}
      <div className='signup-form'>
        <TextField
          autoFocus
          label='Display name'
          required
          placeholder='Type display name of your tenant'
          maxLength={75}
          value={fields.name || ''}
          onChange={(e) => {
            handleFieldChange('name', e.target.value);
          }}
        />
        <div className='row one-two'>
          <TextField
            label='ITS Id'
            type='number'
            required={true}
            maxLength={10}
            value={fields.customer.itsId || 0}
            onChange={(e) => {
              let itsId = fields.customer.itsId;
              try {
                itsId = parseInt(e.target.value, 10);
              } catch (ex) {
                console.log('Incorrect ITS Id provided', ex);
              }
              handleFieldChange('customer', { ...fields.customer, itsId });
            }}
          />
          <TextField
            label='First name'
            required
            maxLength={50}
            value={fields.customer.firstName || ''}
            onChange={(e) => {
              const cust = fields.customer;
              cust.firstName = e.target.value;
              handleFieldChange('customer', cust);
            }}
          />
          <TextField
            label='Last name'
            required
            maxLength={50}
            value={fields.customer.lastName || ''}
            onChange={(e) => {
              const cust = fields.customer;
              cust.lastName = e.target.value;
              handleFieldChange('customer', cust);
            }}
          />
        </div>
        <DropDown
          label='Gender'
          required={true}
          value={fields.customer.gender}
          onChange={(e) => {
            handleFieldChange('customer', { ...fields.customer, gender: e.target.value });
          }}
        >
          <option value='M'>Male</option>
          <option value='F'>Female</option>
        </DropDown>
        <TextField
          label='Email'
          required
          maxLength={75}
          value={fields.customer.email || ''}
          onChange={(e) => {
            const cust = fields.customer;
            cust.email = e.target.value;
            handleFieldChange('customer', cust);
          }}
        />
        <TextField
          label='Phone'
          required={true}
          maxLength={15}
          value={fields.customer.phone || ''}
          onChange={(e) => {
            const cust = fields.customer;
            cust.phone = e.target.value;
            handleFieldChange('customer', cust);
          }}
        />
        <TextField
          label='Address line 1'
          required={true}
          maxLength={75}
          value={(fields.customer.address && fields.customer.address.addressLine1) || ''}
          onChange={(e) => {
            const cust = fields.customer;
            cust.address.addressLine1 = e.target.value;
            handleFieldChange('customer', cust);
          }}
        />
        <TextField
          label='Address line 2'
          maxLength={75}
          value={(fields.customer.address && fields.customer.address.addressLine2) || ''}
          onChange={(e) => {
            const cust = fields.customer;
            cust.address.addressLine2 = e.target.value;
            handleFieldChange('customer', cust);
          }}
        />
        <TextField
          label='City'
          required={true}
          maxLength={25}
          value={(fields.customer.address && fields.customer.address.city) || ''}
          onChange={(e) => {
            const cust = fields.customer;
            cust.address.city = e.target.value;
            handleFieldChange('customer', cust);
          }}
        />
        <TextField
          label='County / State'
          maxLength={25}
          value={(fields.customer.address && fields.customer.address.county) || ''}
          onChange={(e) => {
            const cust = fields.customer;
            cust.address.county = e.target.value;
            handleFieldChange('customer', cust);
          }}
        />
        <TextField
          label='Post code'
          required={true}
          maxLength={10}
          value={(fields.customer.address && fields.customer.address.postCode) || ''}
          onChange={(e) => {
            const cust = fields.customer;
            cust.address.postCode = e.target.value;
            handleFieldChange('customer', cust);
          }}
        />
        <DropDown
          label='Country'
          required={true}
          value={(fields.address && fields.address.country) || 'United Kingdom'}
          onChange={(e) => {
            const cust = fields.customer;
            cust.address.country = e.target.value;
            handleFieldChange('customer', cust);
          }}
        >
          {getCountryList()}
        </DropDown>
        <div className='button-strip'>
          <input
            type='button'
            value='Signup'
            tabIndex='0'
            className='mbl-button primary-button'
            onClick={() => {
              onSubmit(fields);
            }}
          />
        </div>
      </div>
    </div>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default SignupForm;

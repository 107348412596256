import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import Footer from '../visualization/Footer';
import { useForm } from '../hooks/useForm';
import { coinsReport } from '../hooks/InitialCopies';
import { DATE_FORMAT_UTC, DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';

const CoinsReportForm = ({ onCancel, onSave }) => {
  const [fields, handleFieldChange] = useForm(coinsReport);
  return (
    <div className='coins-report-form'>
      <div className='form--field'>
        <div>Start date</div>
        <DatePicker
          selected={new Date(fields.startDate)}
          dateFormat={DATE_FORMAT_FULL_MONTH}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          className='date-picker-input'
          onChange={(date) => handleFieldChange('startDate', format(new Date(date), DATE_FORMAT_UTC))}
        />
      </div>
      <div className='form--field'>
        <div>End date</div>
        <DatePicker
          selected={new Date(fields.endDate)}
          dateFormat={DATE_FORMAT_FULL_MONTH}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          className='date-picker-input'
          onChange={(date) => handleFieldChange('endDate', format(new Date(date), DATE_FORMAT_UTC))}
        />
      </div>
      <Footer
        onCancel={onCancel}
        onSave={() => {
          onSave(fields);
        }}
      />
    </div>
  );
};

CoinsReportForm.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default CoinsReportForm;

import React, {
  useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { DropDown, TextField } from '@aeblondon-uk/common-ui-components';
import { QURAN_DIRECTORY } from '../../utils/QuranDirectory';
import { useForm } from '../hooks/useForm';
import { HIFZ_TIMELINE_ENTRY } from '../hooks/InitialCopies';
import Footer from '../visualization/Footer';

import Loading from '../Loading';
import { getHifzSanadList } from '../../utils/getHifzSanadList';
import { getTilawatIkhtebarResultList } from '../../utils/getTilawatIkhtebarResultList';
import { DATE_FORMAT_UTC, DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';
import { hasPermissions } from '../../utils/hasPermissions';
import { ADD_STUDENTS_HIFZ_SANAD_ENTRY } from '../../utils/permissions';

const HifzEntryForm = ({ loading = false, onCancel = () => {}, onSave }) => {
  const [fields, handleFieldChange, setFields] = useForm();

  useEffect(() => {
    if (fields) {
      setFields(HIFZ_TIMELINE_ENTRY);
    }
  }, []);

  const surahNameSelectOptions = useMemo(() => {
    const surahList = [];
    surahList.push(<option key='unselected' value=''>--- select surah ---</option>);
    return surahList.concat(QURAN_DIRECTORY.map(item => (<option key={item.surahName} value={item.surahName}>{item.surahName}</option>)));
  }, []);

  const siparoSelectOptions = useMemo(() => {
    const siparoList = [];
    siparoList.push(<option key='unselected' value=''>--- select siparo ---</option>);
    if (fields?.surah) {
      const siparas = QURAN_DIRECTORY.find(q => q.surahName === fields.surah).surahInSipara;
      return siparoList.concat(siparas.map(s => <option key={s} value={s}>{s}</option>));
    }
    return siparoList;
  }, [fields?.surah]);

  const availableAayats = useMemo(() => {
    if (fields?.surah) {
      return QURAN_DIRECTORY.find(q => q.surahName === fields.surah).totalAayats;
    }
    return 0;
  }, [surahNameSelectOptions, siparoSelectOptions, fields?.siparo]);

  const isSaveDisabled = useMemo(() => {
    if (fields) {
      if (fields.entryType === 'MILESTONE' && !fields.milestoneType) {
        return true;
      }
      if (fields.entryType === 'TILAWAT_IKHTEBAR' && !fields.milestoneResult) {
        return true;
      }
      if (fields.entryType !== 'MILESTONE' && fields.entryType !== 'TILAWAT_IKHTEBAR' && (!fields.surah || !fields.siparo || !fields.aayat)) {
        return true;
      }

      return false;
    }
    return true;
  }, [fields]);

  const renderLoading = () => {
    if (loading) {
      return <Loading />;
    }
  };

  const renderConditionElements = () => {
    if (fields?.entryType === 'MILESTONE') {
      return (
        <div className='elements'>
          <DropDown
            label='Sanad'
            required={true}
            value={fields?.milestoneType || ''}
            onChange={(e) => {
              handleFieldChange('milestoneType', e.target.value);
            }}
          >
            <option value=''>--- Select sanad ---</option>
            {
              getHifzSanadList().map(sanad => <option key={sanad.value} value={sanad.value}>{sanad.label}</option>)
            }
          </DropDown>
          <div className='form--field'>
            <div>Sanad date</div>
            <DatePicker
              selected={new Date(fields.date)}
              dateFormat={DATE_FORMAT_FULL_MONTH}
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              className='date-picker-input'
              onChange={(date) => handleFieldChange('date', format(new Date(date), DATE_FORMAT_UTC))}
            />
          </div>
        </div>
      );
    }
    if (fields?.entryType === 'TILAWAT_IKHTEBAR') {
      return (
        <div className='elements'>
          <DropDown
            label='Ikhtebar result'
            required={true}
            value={fields?.milestoneResult || ''}
            onChange={(e) => {
              handleFieldChange('milestoneResult', e.target.value);
            }}
          >
            <option value=''>--- Select result ---</option>
            {
              getTilawatIkhtebarResultList().map(result => <option key={result.value} value={result.value}>{result.label}</option>)
            }
          </DropDown>
          <div className='form--field'>
            <div>Result date</div>
            <DatePicker
              selected={new Date(fields.date)}
              dateFormat={DATE_FORMAT_FULL_MONTH}
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              className='date-picker-input'
              onChange={(date) => handleFieldChange('date', format(new Date(date), DATE_FORMAT_UTC))}
            />
          </div>
        </div>
      );
    }

    return (
      <div className='elements'>
        <DropDown
          label='Surah'
          required={true}
          value={fields?.surah || ''}
          onChange={(e) => {
            handleFieldChange('surah', e.target.value);
          }}
        >
          {surahNameSelectOptions}
        </DropDown>
        <DropDown
          label='Siparo'
          required={true}
          value={fields?.siparo || ''}
          onChange={(e) => {
            handleFieldChange('siparo', parseInt(e.target.value, 10));
          }}
        >
          {siparoSelectOptions}
        </DropDown>
        <TextField
          label='Aayat'
          type='number'
          placeholder={availableAayats ? `Total ${availableAayats}` : ''}
          required={true}
          value={fields?.aayat || ''}
          onChange={(e) => {
            const aayat = parseInt(e.target.value, 10);
            if (aayat > availableAayats) {
              return;
            }
            handleFieldChange('aayat', aayat);
          }}
        />
      </div>
    );
  };

  return (
    <div className='mbl--hifz-entry-form'>
      {renderLoading()}
      <DropDown
        label='Entry type'
        required={true}
        value={fields?.entryType || ''}
        onChange={(e) => {
          if (e.target.value === 'MILESTONE') {
            setFields({ ...HIFZ_TIMELINE_ENTRY, entryType: e.target.value, text: fields?.text });
          } else {
            setFields({ ...fields, entryType: e.target.value, date: new Date() });
          }
        }}
      >
        <option value=''>--- Select entry type ---</option>
        <option value='UPDATE'>Update</option>
        <option value='TARGET'>Target</option>
        {
          hasPermissions([ADD_STUDENTS_HIFZ_SANAD_ENTRY]) ?
            (
              <>
                <option value='TILAWAT_IKHTEBAR'>Tilawat ikhtebar</option>
                <option value='MILESTONE'>Sanad</option>
              </>
            ) :
            null
        }
      </DropDown>
      {renderConditionElements()}
      <TextField
        label='Comment'
        value={fields?.text || ''}
        onChange={(e) => {
          handleFieldChange('text', e.target.value);
        }}
      />
      <Footer
        saveDisabled={isSaveDisabled}
        onCancel={onCancel}
        onSave={() => {
          onSave(fields);
          setFields(HIFZ_TIMELINE_ENTRY);
        }}
      />
    </div>
  );
};

HifzEntryForm.propTypes = {
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default HifzEntryForm;

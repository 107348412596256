import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import {
  TextField
} from '@aeblondon-uk/common-ui-components';

import Footer from '../visualization/Footer';
import { DATE_FORMAT_UTC, DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';

import { useForm } from '../hooks/useForm';

const SettingsForm = ({
  academicYear,
  onCancel,
  onSave
}) => {
  const [fields, handleFieldChange] = useForm(academicYear);

  return (
    <>
      <TextField
        label='Academic year'
        className='textfield-medium'
        required={true}
        maxLength={20}
        value={fields.academicYear}
        onChange={(e) => {
          handleFieldChange('academicYear', e.target.value);
        }}
      />
      <div className='required'>Start date</div>
      <DatePicker
        selected={new Date(fields.startDate)}
        todayButton='Today'
        dateFormat={DATE_FORMAT_FULL_MONTH}
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        className='date-picker-input textfield-medium'
        onChange={(dt) => handleFieldChange('startDate', format(new Date(dt), DATE_FORMAT_UTC))}
      />
      <div className='required'>End date</div>
      <DatePicker
        selected={new Date(fields.endDate)}
        todayButton='Today'
        dateFormat={DATE_FORMAT_FULL_MONTH}
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        className='date-picker-input textfield-medium'
        onChange={(dt) => handleFieldChange('endDate', format(new Date(dt), DATE_FORMAT_UTC))}
      />
      <div>
        <label className='active-field'>
          <input
            type='checkbox'
            className='checkbox'
            onChange={(e) => handleFieldChange('isCurrent', e.target.checked)}
            checked={fields.isCurrent}
          />
          <span>Set current</span>
        </label>
      </div>
      <Footer
        onCancel={() => {
          onCancel();
        }}
        onSave={() => {
          onSave(fields);
        }}
        saveButtonLabel='Save'
      />
    </>
  );
};

SettingsForm.propTypes = {
  academicYear: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export default SettingsForm;

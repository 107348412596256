import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { DropDown, TextField } from '@aeblondon-uk/common-ui-components';
import Footer from '../visualization/Footer';

import { useForm } from '../hooks/useForm';

const EXPORT_PARAMS = {
  academicYear: '',
  type: 'All',
  status: 'Paid'
};

const ExportInvoicesForm = ({ currentAcademicYear, onCancel, onSave }) => {
  const [fields, handleFieldChange] = useForm(EXPORT_PARAMS);
  useEffect(() => {
    handleFieldChange('academicYear', currentAcademicYear);
  }, [currentAcademicYear]);

  return (
    <div className='generate-fees-invoices'>
      <div style={{ marginBottom: '20px' }}>
        All the invoices for selected
        {' '}
        <b>Academic year</b>
        ,
        {' '}
        <b>Type</b>
        {' '}
        and
        {' '}
        <b>Status</b>
        {' '}
        will be exported to an excel file.
      </div>
      <span className='payment-header'>
        <TextField
          label='Academic year'
          value={fields.academicYear}
          required={true}
          onChange={(e) => {
            handleFieldChange('academicYear', e.target.value);
          }}
        />
      </span>
      <span className='payment-header'>
        <DropDown
          label='Type'
          value={fields.type}
          onChange={(e) => {
            handleFieldChange('type', e.target.value);
          }}
        >
          <option value='All'>All</option>
          <option value='Fees'>Fees</option>
          <option value='Niyaz'>Niyaz</option>
        </DropDown>
      </span>
      <span className='payment-header'>
        <DropDown
          label='Status'
          value={fields.status}
          onChange={(e) => {
            handleFieldChange('status', e.target.value);
          }}
        >
          <option value='All'>All</option>
          <option value='Paid'>Paid</option>
          <option value='Unpaid'>Unpaid</option>
          <option value='Inst. unpaid'>Inst. unpaid</option>
          <option value='Waiver'>Waiver</option>
          <option value='Part paid'>Part paid</option>
        </DropDown>
      </span>
      <Footer
        onCancel={onCancel}
        onSave={() => onSave(fields)}
      />
    </div>
  );
};

ExportInvoicesForm.propTypes = {
  currentAcademicYear: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default ExportInvoicesForm;

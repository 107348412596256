import { useState, useEffect, useContext } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useClassData = () => {
  const appContext = useContext(AppContext);

  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchClasses = async () => {
    if (!appContext.tenant._id) {
      return;
    }

    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/classes/v1/classes`);
      const jsonData = await dataService.handleResponse(resp);
      setClasses(jsonData);
    } catch (ex) {
      console.log(':::::Exception fetching classes', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const deleteClass = async (id) => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.delete(`/api/ui/${appContext.tenant._id}/classes/v1/classes/${id}`);
      await dataService.handleResponse(resp);
      await fetchClasses();
    } catch (ex) {
      console.log(':::::Exception logout user', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const saveClass = async (mblClass, mode) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      if (mode === 'edit') {
        resp = await dataService.put(`/api/ui/${appContext.tenant._id}/classes/v1/classes/${mblClass._id}`, mblClass);
      } else {
        resp = await dataService.post(`/api/ui/${appContext.tenant._id}/classes/v1/classes`, mblClass);
      }
      await dataService.handleResponse(resp);
      await fetchClasses();
    } catch (ex) {
      console.log(':::::Exception saving class', mode, resp);
      setLoading(false);
      throw Error(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchClasses();
    return () => {
      setClasses([]);
    };
  }, []);

  return {
    classes,
    loading,
    error,
    fetchClasses,
    deleteClass,
    saveClass
  };
};

import React from 'react';
import StudentList from '../visualization/StudentList';

const StudentListPanel = (props) => {
  return (
    <section>
      <StudentList {...props} />
    </section>
  );
};

export default StudentListPanel;

import React from 'react';
import UserList from '../visualization/UserList';

const UserListPanel = () => {
  return (
    <section>
      <UserList />
    </section>
  );
};

export default UserListPanel;

import React, { useState, useEffect, useMemo } from 'react';
import owasp from 'owasp-password-strength-test';
import { TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import { useLogin } from '../hooks/useLogin';

const ChangePasswordForm = () => {
  const {
    changePassword, changePasswordSuccess, loading, error
  } = useLogin();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [pwdStrength, setPwdStrength] = useState({ errors: [] });
  useEffect(() => {
    owasp.config({
      allowPassphrases: true,
      maxLength: 128,
      minLength: 10,
      minPhraseLength: 20,
      minOptionalTestsToPass: 4
    });
  }, []);

  const renderErrors = useMemo(() => {
    const errMsgs = [];
    pwdStrength.errors.forEach((errMsg, i) => {
      errMsgs.push(
        <div key={`err-${i}`}>{errMsg}</div>
      );
    });
    return errMsgs;
  }, [pwdStrength]);

  const changePasswordEnabled = useMemo(() => {
    if (!password || !newPassword || !newPasswordConfirm || newPassword !== newPasswordConfirm) {
      return false;
    }
    if (pwdStrength.errors.length > 0) {
      return false;
    }
    return true;
  }, [password, newPassword, newPasswordConfirm, pwdStrength]);

  const handleKeyUp = (evt) => {
    if (evt.key === 'Enter') {
      changePassword();
    }
  };

  const showLoading = () => {
    if (loading) {
      return (
        <Loading />
      );
    }
    return null;
  };

  const getSuccessMessage = () => {
    if (changePasswordSuccess) {
      return <div>Your password have been changed successfully</div>;
    }
    return null;
  };

  return (
    <div className='mb-login'>
      {showLoading()}
      <div className='login-form change-password'>
        <span className='error-message'>{getSuccessMessage() || error}</span>
        <TextField
          autoFocus={true}
          label='Enter current password'
          type='password'
          required={true}
          maxLength={75}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={handleKeyUp.bind(this)}
        />
        <div className='vertical-spacer-20' />
        <TextField
          label='Enter new password'
          type='password'
          required={true}
          maxLength={75}
          value={newPassword}
          enableRevealPasword={false}
          onBlur={() => {
            setPwdStrength(owasp.test(newPassword));
          }}
          onChange={(e) => setNewPassword(e.target.value)}
          onKeyUp={handleKeyUp.bind(this)}
        />
        <div className='vertical-spacer-20' />
        <TextField
          label='Confirm new password'
          type='password'
          required={true}
          maxLength={75}
          value={newPasswordConfirm}
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
          onKeyUp={handleKeyUp.bind(this)}
        />
        <div className='error-messages'>
          {renderErrors}
        </div>
        <div className='vertical-spacer-20' />
        <div className='button-strip'>
          <input
            type='button'
            value='Change password'
            tabIndex='0'
            disabled={!changePasswordEnabled}
            className={!changePasswordEnabled ? 'button-disabled mbl-button primary-button' : 'mbl-button primary-button'}
            onClick={() => changePassword(password, newPassword)}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;

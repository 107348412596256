import React, {
  useContext, useEffect, useState
} from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import AppContext from '../AppContext';

const messages = defineMessages({
  email: {
    defaultMessage: 'Email',
    description: 'Login form email label'
  },
  password: {
    defaultMessage: 'Password',
    description: 'Login form password label'
  },
  loginButton: {
    defaultMessage: 'Login',
    description: 'Login button label'
  }
});

const LoginForm = (props) => {
  const appContext = useContext(AppContext);
  const intl = useIntl();
  const {router} = appContext;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (appContext.loggedIn === true) {
      setTimeout(() => {
        router.setRoute(`/${appContext.tenant._id}/home`);
      });
    } else {
      router.setRoute('/login');
    }
  }, [appContext.loggedIn]);

  const showLoading = () => {
    if (appContext.loading) {
      return (
        <Loading />
      );
    }
    return null;
  };

  const handleForgotPassword = () => {
    router.setRoute('/reset-password');
  };

  const handleSignup = () => {
    router.setRoute('/signup');
  };

  const loginDisabled = (username === '' || password === '');
  return (
    <div className='mb-login'>
      {showLoading(props)}
      <div className='login-form'>
        <span className='error-message'>{appContext.error ? appContext.error.message : ''}</span>
        <TextField
          autoFocus
          label={intl.formatMessage(messages.email)}
          required
          maxLength={75}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              appContext.loginUser(username, password);
            }
          }}
        />
        <div className='vertical-spacer-20' />
        <TextField
          label={intl.formatMessage(messages.password)}
          type='password'
          required
          maxLength={30}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              appContext.loginUser(username, password);
            }
          }}
        />
        <div className='vertical-spacer-20' />
        <div className='link-bar'>
          <span
            tabIndex='0'
            role='link'
            className='forgot-password-link'
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleForgotPassword();
              }
            }}
            onClick={() => { handleForgotPassword(); }}
          >
            <FormattedMessage
              defaultMessage='Forgot password'
              description='Login form forgot password link'
            />
          </span>
          <span
            tabIndex='0'
            role='link'
            className='signup-link'
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleSignup();
              }
            }}
            onClick={() => { handleSignup(); }}
          >
            {/* Signup */}
          </span>
        </div>
        <div className='vertical-spacer-20' />
        <div className='button-strip'>
          <input
            type='button'
            value={intl.formatMessage(messages.loginButton)}
            tabIndex='0'
            disabled={loginDisabled}
            className={loginDisabled ? 'button-disabled mbl-button primary-button' : 'mbl-button primary-button'}
            onClick={() => appContext.loginUser(username, password)}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

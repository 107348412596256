import React from 'react';
import PropTypes from 'prop-types';
import { hasPermissions } from '../utils/hasPermissions';

const HasPermissions = ({ permissions, children }) => {
  if (hasPermissions(permissions)) {
    return (
      <>
        {children}
      </>
    );
  }
  return null;
};

HasPermissions.propTypes = {
  permissions: PropTypes.array,
  children: PropTypes.node
};

export default HasPermissions;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import Loading from '../Loading';
import { DropDown, TextField } from '@aeblondon-uk/common-ui-components';
import Footer from '../visualization/Footer';

import { useForm } from '../hooks/useForm';
import { useUserData } from '../hooks/useUserData';

const ClassForm = ({
  mblClass, mode, onCancel, onSave
}) => {
  const [fields, handleFieldChange] = useForm(mblClass);
  const { users } = useUserData();

  useEffect(() => {
    if (mode === 'add' && users?.length > 0) {
      handleFieldChange('teacher', users[0]._id);
    }
  }, [users]);

  const renderTeachersList = () => {
    return (
      <DropDown
        label='Teacher'
        disabled={mode === 'view'}
        required={true}
        value={fields.teacher}
        onChange={(e) => {
          handleFieldChange('teacher', e.target.value);
        }}
      >
        {
          users.map((user) => {
            return <option key={user._id} value={user._id}>{`${user.firstName} ${user.lastName}`}</option>;
          })
        }
      </DropDown>
    );
  };

  const renderFooter = () => {
    if (mode === 'edit' || mode === 'add') {
      return (
        <Footer
          onCancel={onCancel}
          onSave={() => {
            onSave(fields);
          }}
        />
      );
    }
    return null;
  };

  // 	showLoading() {
  //     if (this.props.isLoading) {
  //       return (<Loading />);
  //     }
  // 	}

  return (
    <div className='student-form'>
      <fieldset className='student-form__section'>
        <legend>Class details</legend>
        <div className='row one-two'>
          <TextField
            autoFocus={true}
            label='Year'
            disabled={mode === 'view'}
            required={true}
            maxLength={15}
            value={fields.name}
            placeholder='Example: KG or 1 or 2 and so on'
            onChange={(e) => {
              handleFieldChange('name', e.target.value);
            }}
          />
          <TextField
            label='Division'
            disabled={mode === 'view'}
            required={true}
            maxLength={15}
            value={fields.division}
            onChange={(e) => {
              handleFieldChange('division', e.target.value);
            }}
          />
          <TextField
            label='Room #'
            disabled={mode === 'view'}
            type='number'
            required={true}
            maxLength={15}
            value={fields.room}
            onChange={(e) => {
              handleFieldChange('room', e.target.value);
            }}
          />
        </div>
        <div className='row one-two'>
          {renderTeachersList()}
          <TextField
            label='Display order'
            disabled={mode === 'view'}
            type='number'
            required={true}
            maxLength={15}
            value={fields.order}
            onChange={(e) => {
              let order = fields.order;
              try {
                order = parseInt(e.target.value, 10);
              } catch (ex) {
                console.log('Incorrect order value');
              }
              handleFieldChange('order', isNaN(order) ? 0 : order);
            }}
          />
        </div>
        <label className='row one-two'>
          <input
            type='checkbox'
            disabled={mode === 'view'}
            className='checkbox'
            onChange={(e) => {
              handleFieldChange('active', e.target.checked);
            }}
            checked={fields.active !== false}
          />
          <span>Active</span>
        </label>
      </fieldset>
      {renderFooter()}
    </div>
  );
};

ClassForm.propTypes = {
  mode: PropTypes.string,
  mblClass: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default ClassForm;

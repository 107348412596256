import React, { useCallback } from 'react';
import { format } from 'date-fns';
import { Tag } from '@aeblondon-uk/common-ui-components';

import { stringToColor } from '../../utils/ColorGenerator';
import InvoiceHelper from '../../utils/InvoiceHelper';
import MedicalIon from '../visualization/MedicalIcon';
import { DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';
import { getPriroityConversion } from '../../utils/Priority';

export const UserFullNameRenderer = (rowData) => {
  return <div>{`${rowData.firstName} ${rowData.lastName}`}</div>;
};

export const UserRolesRenderer = (rowData, userRoles) => {
  const getRoles = useCallback(() => {
    const roles = [];
    rowData.roles.forEach((role, i) => {
      roles.push(
        <Tag
          key={`role-${rowData._id}-${i}`}
          style={{ backgroundColor: stringToColor(role) }}
          type='custom'
          text={userRoles.find(r => r._id === role)?.name}
        />
      );
    });
    return roles;
  }, [rowData.roles, userRoles]);
  return getRoles();
};

export const FullNameRenderer = (rowData) => {
  return (
    <div style={{ display: 'flex' }}>
      <div>{`${rowData.firstName} ${rowData.father.firstName} ${rowData.lastName}`}</div>
      <MedicalIon student={rowData} />
    </div>
  );
};

export const ClassRenderer = (rowData) => {
  if (rowData.mblClass) {
    return <div>{`${rowData.mblClass.name} ${rowData.mblClass.division}`}</div>;
  }
  // used for classes panel
  return <div>{rowData.name}</div>;
};

export const ClassTeacherRenderer = (rowData) => {
  if (rowData.teacher) {
    return <div>{`${rowData.teacher.firstName} ${rowData.teacher.lastName}`}</div>;
  }
  return null;
};

export const InvoiceStatusRenderer = (invoice, onClick) => {
  const status = InvoiceHelper.getInvoiceStatus(invoice);
  return (
    <Tag
      {...status}
      onClick={() => {
        onClick(invoice);
      }}
    />
  );
};

export const DateRenderer = (date) => {
  return <div>{format(new Date(date), DATE_FORMAT_FULL_MONTH)}</div>;
};

export const AmountRenderer = (amount) => {
  return <div>{`£${amount.toFixed(2)}`}</div>;
};

export const PriorityRenderer = (priority) => {
  return <Tag {...getPriroityConversion(priority)} />;
};

import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import router from '../../Routes';
import AppContext from '../AppContext';

const RedirectTenantPanel = ({ redirectRoute }) => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (appContext.tenant._id) {
      setTimeout(() => {
        router.setRoute(`/${appContext.tenant._id}${redirectRoute}`);
      }, 400);
    }
  }, [appContext.tenant._id]);

  return (
    <div />
  );
};

RedirectTenantPanel.propTypes = {
  redirectRoute: PropTypes.string
};

export default RedirectTenantPanel;

import { useState } from 'react';
import DataService from '../../data/DataService';

const useTenantData = () => {
  const [tenant, setTenant] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTenant = async (id) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.getItems(`/tenants/v1/tenants/${id}`);
      setTenant(await dataService.handleResponse(resp));
    } catch (ex) {
      console.log(':::::Exception fetching tenant', resp);
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  const saveTenant = async (tenantObj) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.post('/tenants/v1/tenants', tenantObj);
      // await dataService.handleResponse(resp);
      // fetchTenant();
    } catch (ex) {
      console.log(':::::Exception saving tenant', resp);
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  return {
    tenant,
    loading,
    error,
    fetchTenant,
    saveTenant
  };
};

export default useTenantData;

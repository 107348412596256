import { useContext, useState, useEffect } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useUserData = (load) => {
  const appContext = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUsers = () => {
    if (!appContext.tenant._id) {
      return;
    }
    setLoading(true);
    const dataService = new DataService();
    dataService.getItems(`/api/ui/${appContext.tenant._id}/users/v1/users`)
      .then(dataService.handleResponse)
      .then((response) => {
        setUsers(response);
        setLoading(false);
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const deleteUser = (id, callback = () => {}) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.delete(`/api/ui/${appContext.tenant._id}/users/v1/users/${id}`)
      .then(dataService.handleResponse)
      .then((response) => {
        console.log(':::::::::delete user response', response);
        setLoading(false);
        fetchUsers();
        callback();
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const unlockUser = async (id) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/users/v1/users/unlock/${id}`);
      fetchUsers();
    } catch (ex) {
      console.log(':::::Exception unlocking user', resp);
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  const saveUser = (user, mode, callback = () => {}, refetch, tenantId) => {
    setLoading(true);
    const dataService = new DataService();
    const userPayload = user;
    userPayload.username = userPayload.email;
    console.log('::::::::;appContext', appContext);
    if (mode === 'edit') {
      dataService.put(`/api/ui/${appContext?.tenant?._id || tenantId}/users/v1/users/${userPayload._id}`, userPayload)
        .then(dataService.handleResponse)
        .then((response) => {
          console.log(':::::::::edit user response', response);
          setLoading(false);
          // do not refetch on profile edit
          if (refetch !== false) {
            fetchUsers();
          }
          callback();
        }).catch((err) => {
          console.log('Error::', err);
          setError(err);
          setLoading(false);
        });
    } else {
      dataService.post(`/api/ui/${appContext.tenant._id}/users/v1/users`, userPayload)
        .then(dataService.handleResponse)
        .then((response) => {
          console.log(':::::::::add user response', response);
          setLoading(false);
          fetchUsers();
          callback();
        }).catch((err) => {
          console.log('Error::', err);
          setError(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (load !== false) {
      fetchUsers();
    }
    return () => {
      setUsers([]);
    };
  }, []);

  return {
    users,
    loading,
    error,
    deleteUser,
    saveUser,
    unlockUser
  };
};

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import Carousel from 'nuka-carousel';
import HasPermissions from '../HasPermissions';
import Loading from '../Loading';
import {
  VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION,
  ADD_REWARDS_PERMISSION, REDEEM_REWARDS_PERMISSION
} from '../../utils/permissions';
import FaChevronCircleLeft from '../../styles/assets/chevroncircleleft.svg';

import { useClassData } from '../hooks/useClassData';

const ClassList = (props) => {
  const { classes, loading } = useClassData();
  const [slidesToShow, setSlidesToShow] = useState(10);
  const classRef = useRef(null);

  const handleResize = () => {
    if (classRef && classRef.current) {
      const width = classRef.current.getBoundingClientRect().width;
      setSlidesToShow(Math.floor((width / 40) / 2));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectClass = (selectedClass) => {
    props.onClassSelected(selectedClass);
  };

  const renderClasses = () => {
    const classArray = [];
    if (classes) {
      classes.forEach((obj) => {
        if (obj.active !== false && obj.name !== 'New') {
          classArray.push(
            <Tooltip
              key={`class-${ obj._id}`}
              placement='bottom'
              mouseEnterDelay={0}
              mouseLeaveDelay={0.1}
              destroyTooltipOnHide={false}
              trigger={['hover']}
              overlay={`${obj.teacher.firstName } ${ obj.teacher.lastName}`}
              overlayClassName='mbl--tooltip'
              align={{
                offset: [0, 0]
              }}
            >
              <div key='class-all-end' tabIndex='0' role='button' className={`class--bubble${ props.selectedClass === obj._id ? ' selected' : ''}`} onClick={selectClass.bind(this, obj._id)}>
                <span className='class-text'>{obj.name + (obj.division ? (` ${ obj.division}`) : '')}</span>
              </div>
            </Tooltip>
          );
        }
      });
      const classAll = (
        <div key='class-all' tabIndex='0' role='button' className={`class--bubble${ props.selectedClass === 'All' ? ' selected' : ''}`} onClick={selectClass.bind(this, 'All')}>
          <span className='class-text'>All</span>
        </div>
      );
      if (props.showAllLast) {
        classArray.push(classAll);
      } else if (props.showAllFirst) {
        classArray.unshift(classAll);
      }
    }
    return classArray;
  };

  const showLoading = () => {
    if (loading) {
      return (<Loading />);
    }
    return null;
  };

  return (
    <HasPermissions permissions={[
      VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION,
      ADD_REWARDS_PERMISSION, REDEEM_REWARDS_PERMISSION
    ]}
    >
      <div className='classes' style={{ width: '100%' }} ref={classRef}>
        <Carousel
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToShow}
          renderCenterLeftControls={({ previousSlide }) => (
            <FaChevronCircleLeft className='slider__arrows' onClick={previousSlide} aria-hidden='true' />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <FaChevronCircleLeft className='slider__arrows right' onClick={nextSlide} aria-hidden='true' />
          )}
        >
          {renderClasses()}
        </Carousel>
        {showLoading()}
      </div>
    </HasPermissions>
  );
};

ClassList.propTypes = {
  selectedClass: PropTypes.string,
  onClassSelected: PropTypes.func,
  showAllFirst: PropTypes.bool,
  showAllLast: PropTypes.bool
};

export default ClassList;

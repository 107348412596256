import React, { useState, useMemo } from 'react';
import SlidingPane from 'react-sliding-pane';
import {
  CommonTable as Table, OverflowMenu, OverflowMenuItem, Search
} from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import EmailTemplateForm from '../forms/EmailTemplateFormNew';
import MdMenu from '../../styles/assets/menu.svg';
import MdDelete from '../../styles/assets/delete.svg';
import FaChevronRight from '../../styles/assets/chevron.svg';
import NoData from '../NoData';

import Footer from './Footer';
import { useEmailTemplateData } from '../hooks/useEmailTemplateData';
import { emailTemplate } from '../hooks/InitialCopies';
import {
  VIEW_EMAIL_TEMPLATES_PERMISSION, ADD_EMAIL_TEMPLATES_PERMISSION,
  EDIT_EMAIL_TEMPLATES_PERMISSION, DELETE_EMAIL_TEMPLATES_PERMISSION
} from '../../utils/permissions';
import { hasPermissions } from '../../utils/hasPermissions';
import HasPermissions from '../HasPermissions';

const EmailTemplateList = () => {
  const {
    templates, saveTemplate, deleteTemplate, loading
  } = useEmailTemplateData();
  const canEditEmailTemplates = hasPermissions([EDIT_EMAIL_TEMPLATES_PERMISSION]);

  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showTemplatePanel, setShowTemplatePanel] = useState(false);
  const [mode, setMode] = useState('add');
  const [filter, setFilter] = useState({
    searchText: ''
  });

  const filteredTemplates = useMemo(() => {
    const fs = [];
    templates.forEach((template) => {
      const searchAttribs = [template.name, template.description];
      if (!filter.searchText || searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
        fs.push(template);
      }
    });
    return fs;
  }, [templates, filter]);

  const renderActionsMenu = () => {
    return (
      <HasPermissions permissions={[ADD_EMAIL_TEMPLATES_PERMISSION]}>
        <OverflowMenu
          trigger={<MdMenu className='overflow-menu__icon' focusable={false} />}
          onClick={() => setShowActionsMenu(!showActionsMenu)}
          show={showActionsMenu}
          onHideOverflowMenu={() => {
            setShowActionsMenu(false);
          }}
          onMenuTriggerClick={() => setShowActionsMenu(!showActionsMenu)}
        >
          <OverflowMenuItem
            id='add_email_template'
            label='Add email template'
            onClick={() => {
              setMode('add');
              setSelectedTemplate(emailTemplate);
              setShowTemplatePanel(true);
              setShowActionsMenu(false);
            }}
          />
        </OverflowMenu>
      </HasPermissions>
    );
  };

  const renderTemplatePanel = () => {
    const closeTemplatePanel = () => {
      setShowTemplatePanel(false);
      setSelectedTemplate({});
    };

    const getTitle = () => {
      if (mode === 'edit') {
        return `Edit email template - ${selectedTemplate.name}`;
      }
      if (mode === 'view') {
        return `View email template - ${selectedTemplate.name}`;
      }
      return 'Add email template';
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showTemplatePanel}
        title={getTitle()}
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeTemplatePanel();
        }}
      >
        <EmailTemplateForm
          mode={mode}
          template={selectedTemplate}
          onCancel={() => {
            closeTemplatePanel();
          }}
          onSave={async (fields) => {
            await saveTemplate(fields, mode);
            closeTemplatePanel();
          }}
        />
      </SlidingPane>
    );
  };

  const renderDeleteConfirmation = () => {
    const closeDeleteConfirmation = () => {
      setShowDeleteConfirmation(false);
      setSelectedTemplate({});
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showDeleteConfirmation}
        title={`Delete email template - ${selectedTemplate.name}`}
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeDeleteConfirmation();
        }}
      >
        <div style={{ fontSize: '18px' }}>Are you sure you want to delete the selected email template?</div>
        <Footer
          onCancel={() => {
            closeDeleteConfirmation();
          }}
          onSave={async () => {
            await deleteTemplate(selectedTemplate._id);
            closeDeleteConfirmation();
          }}
        />
      </SlidingPane>
    );
  };

  const renderActions = (template) => {
    return (
      <HasPermissions permissions={[DELETE_EMAIL_TEMPLATES_PERMISSION]}>
        <div className='actions'>
          <div className='actions-icons'>
            <MdDelete
              className='action-icon'
              onClick={() => {
                setSelectedTemplate(template);
                setShowDeleteConfirmation(true);
              }}
            />
          </div>
        </div>
      </HasPermissions>
    );
  };

  const getTitleRow = () => {
    return (
      <div className='mbl--panel--title-row'>
        <div>
          <Search
            placeholder='Search'
            value={filter.searchText}
            onChange={(e) => setFilter({ ...filter, searchText: e.target.value })}
            onClear={() => setFilter({ ...filter, searchText: '' })}
          />
        </div>
        <div className='count-info'>
          {`Displaying ${filteredTemplates.length} of ${templates.length} email templates`}
        </div>
        <div className='buttons-cell'>
          {renderActionsMenu()}
        </div>
      </div>
    );
  };

  const getTemplateList = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'actions') {
        return renderActions(cell.rowData);
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    let mouseDown = 0;
    const rowEventHandlers = {
      onMouseUp: (e) => {
        const elementClicked = e.event.target.tagName.toLowerCase();
        console.log(elementClicked);
        if (elementClicked !== 'svg' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
          setSelectedTemplate(e.rowData);
          if (canEditEmailTemplates) {
            setMode('edit');
          } else {
            setMode('view');
          }
          setShowTemplatePanel(true);
        }
      },
      onMouseDown: (e) => {
        mouseDown = e.event.clientX;
      }
    };

    const columns = [
      {
        width: 250, title: 'Name', dataKey: 'name', key: 'name', resizable: true
      },
      {
        width: 450, title: 'Description', dataKey: 'description', key: 'description', resizable: true
      }
    ];
    if (hasPermissions([DELETE_EMAIL_TEMPLATES_PERMISSION])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        rowClassName='mbl--template-list-row'
        selectable
        rowEventHandlers={rowEventHandlers}
        columns={columns}
        data={filteredTemplates}
      />
    );
  };

  return (
    <HasPermissions permissions={[
      VIEW_EMAIL_TEMPLATES_PERMISSION, ADD_EMAIL_TEMPLATES_PERMISSION,
      EDIT_EMAIL_TEMPLATES_PERMISSION, DELETE_EMAIL_TEMPLATES_PERMISSION
    ]}
    >
      <div className='email-template-list-wrapper'>
        {/* showLoading() */}
        {renderTemplatePanel()}
        {renderDeleteConfirmation()}
        {/* showFloatingNotification() */}
        {getTitleRow()}
        {getTemplateList()}
      </div>
    </HasPermissions>
  );
};

export default EmailTemplateList;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MdClose from '../../styles/assets/cross.svg'

class Notification extends Component {
  render() {
    return (
      <div className={'notification' + (this.props.type === 'success' ? ' success' : ' error')}>
        <span className='text-container'>
          {this.props.text}
        </span>
        {
          this.props.closable ?
            <span className='close-icon-wrapper' onClick={this.props.onClose.bind(this)}>
              <MdClose className='close-icon'/>
            </span> :
            null
        }
      </div>
    );
  }
}

Notification.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func,
  closable: PropTypes.bool
};

Notification.defaultProps = {
  onClose: () => {}
};

export default Notification;

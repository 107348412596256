import { useEffect, useState } from 'react';

export const useForm = (initialState) => {
  const [fields, setValues] = useState(initialState);
  useEffect(() => {
    setValues(initialState);
  }, [initialState]);

  return [
    fields,
    (field, value) => {
      setValues({
        ...fields,
        [field]: value
      });
    },
    (state) => {
      setValues(state);
    }
  ];
};

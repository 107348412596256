import { useContext, useState } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useStudentAttendanceData = () => {
  const appContext = useContext(AppContext);
  const [students, setStudents] = useState([]);
  const [attendanceHistory, setAttendanceHistory] = useState({});
  const [studentAttendanceHistory, setStudentAttendanceHistory] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchClassAttendance = async (clsId, dt, sess) => {
    if (!appContext.tenant._id) {
      return;
    }
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/attendance/${clsId}/${dt}/${sess}`);
      const jsonData = await dataService.handleResponse(resp);
      setStudents(jsonData.students);
    } catch (ex) {
      console.log(':::::Exception fetching class attendance', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const saveClassAttendance = async (attendance) => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.post(`/api/ui/${appContext.tenant._id}/attendance/v1/attendance/classAttendance`, attendance);
      await dataService.handleResponse(resp);
    } catch (ex) {
      console.log(':::::Exception saving class attendance', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const fetchClassAttendanceReport = async (classId, startDate, endDate, session = 'Morning') => {
    if (!appContext.tenant._id) {
      return;
    }

    if (classId && startDate && endDate) {
      setLoading(true);
      const dataService = new DataService();
      try {
        const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/attendancereport/${startDate}/${endDate}/${session}/${classId}`);
        const jsonData = await dataService.handleResponse(resp);
        setStudents(jsonData.students);
      } catch (ex) {
        console.log(':::::Exception fetching class attendance report', ex);
        setError(ex);
      }
      setLoading(false);
    }
  };

  const fetchClassAttendanceHistory = async (classId, date, endDate, session = 'Morning') => {
    if (!appContext.tenant._id) {
      return;
    }

    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/classattendancehistory/${classId}/${date}/${endDate}/${session}`);
      const jsonData = await dataService.handleResponse(resp);
      setAttendanceHistory(jsonData.attendanceHistory);
    } catch (ex) {
      console.log(':::::Exception fetching class attendance history', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const fetchStudentAttendanceHistory = async (studentId, startDate, endDate, session = 'Morning') => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/attendancehistory/${startDate}/${endDate}/${session}/${studentId}`);
      console.log('::::::::fetchStudentAttendanceHistory', resp);
      const jsonData = await dataService.handleResponse(resp);
      setStudentAttendanceHistory(jsonData);
    } catch (ex) {
      console.log(':::::Exception fetching student attendance history', ex);
      setError(ex);
    }
    setLoading(false);
  };

  return {
    students,
    loading,
    error,
    fetchClassAttendance,
    saveClassAttendance,
    fetchClassAttendanceReport,
    fetchClassAttendanceHistory,
    attendanceHistory,
    fetchStudentAttendanceHistory,
    studentAttendanceHistory
  };
};

import {utils, write} from 'xlsx';
import saveAs from 'file-saver';

const XLSExporter = {
  export({
    items, sheetName, fileName, colsWidth
  }) {
    /* make the worksheet */
    const ws = utils.json_to_sheet(items, { cellDates: true });
    if (colsWidth) {
      const wscols = [];
      colsWidth.forEach((width) => {
        wscols.push({ wch: width });
      });
      ws['!cols'] = wscols;
    }

    /* add to workbook */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    /* write workbook (use type 'binary') */
    const wbout = write(wb, { bookType: 'xlsx', type: 'binary', cellDates: true });
    saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), fileName);
  },

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

};

export default XLSExporter;

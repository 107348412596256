export const COUNTRY_LIST = [
  {
    Name: 'Argentina'
  },
  {
    Name: 'Aruba'
  },
  {
    Name: 'Australia'
  },
  {
    Name: 'Austria'
  },
  {
    Name: 'Azerbaijan'
  },
  {
    Name: 'Bahrain'
  },
  {
    Name: 'Bangladesh'
  },
  {
    Name: 'Barbados'
  },
  {
    Name: 'Belgium'
  },
  {
    Name: 'Benin'
  },
  {
    Name: 'Bhutan'
  },
  {
    Name: 'Brazil'
  },
  {
    Name: 'Brunei Darussalam'
  },
  {
    Name: 'Bulgaria'
  },
  {
    Name: 'Canada'
  },
  {
    Name: 'Cayman Islands'
  },
  {
    Name: 'China'
  },
  {

    Name: 'Colombia'
  },
  {
    Name: 'Costa Rica'
  },
  {
    Name: 'Croatia'
  },
  {
    Name: 'Cuba'
  },
  {
    Name: 'Cyprus'
  },
  {
    Name: 'Czech Republic'
  },
  {
    Name: 'Denmark'
  },
  {
    Name: 'Egypt'
  },
  {
    Name: 'Estonia'
  },
  {
    Name: 'Ethiopia'
  },
  {
    Name: 'Fiji'
  },
  {
    Name: 'Finland'
  },
  {
    Name: 'France'
  },
  {
    Name: 'Georgia'
  },
  {
    Name: 'Germany'
  },
  {
    Name: 'Ghana'
  },
  {
    Name: 'Greece'
  },
  {
    Name: 'Hong Kong'
  },
  {
    Name: 'Hungary'
  },
  {
    Name: 'Iceland'
  },
  {
    Name: 'India'
  },
  {
    Name: 'Indonesia'
  },
  {
    Name: 'Iran, Islamic Republic of'
  },
  {
    Name: 'Iraq'
  },
  {
    Name: 'Ireland'
  },
  {
    Name: 'Israel'
  },
  {
    Name: 'Italy'
  },
  {
    Name: 'Japan'
  },
  {
    Name: 'Jordan'
  },
  {
    Name: 'Kenya'
  },
  {
    Name: 'Kuwait'
  },
  {
    Name: 'Lebanon'
  },
  {
    Name: 'Libya'
  },
  {
    Name: 'Luxembourg'
  },
  {
    Name: 'Madagascar'
  },
  {
    Name: 'Malawi'
  },
  {
    Name: 'Malaysia'
  },
  {
    Name: 'Maldives'
  },
  {
    Name: 'Mali'
  },
  {
    Name: 'Malta'
  },
  {
    Name: 'Mauritius'
  },
  {
    Name: 'Mayotte'
  },
  {
    Name: 'Mexico'
  },
  {
    Name: 'Morocco'
  },
  {
    Name: 'Mozambique'
  },
  {
    Name: 'Myanmar'
  },
  {
    Name: 'Nepal'
  },
  {
    Name: 'Netherlands'
  },
  {
    Name: 'New Zealand'
  },
  {
    Name: 'Nigeria'
  },
  {
    Name: 'Norway'
  },
  {
    Name: 'Oman'
  },
  {
    Name: 'Pakistan'
  },
  {
    Name: 'Papua New Guinea'
  },
  {
    Name: 'Paraguay'
  },
  {
    Name: 'Peru'
  },
  {
    Name: 'Philippines'
  },
  {
    Name: 'Poland'
  },
  {
    Name: 'Portugal'
  },
  {
    Name: 'Qatar'
  },
  {
    Name: 'R\u00e9union'
  },
  {
    Name: 'Romania'
  },
  {
    Name: 'Russian Federation'
  },
  {
    Name: 'Rwanda'
  },
  {
    Name: 'Saudi Arabia'
  },
  {
    Name: 'Singapore'
  },
  {
    Name: 'Somalia'
  },
  {
    Name: 'South Africa'
  },
  {
    Name: 'Spain'
  },
  {
    Name: 'Sri Lanka'
  },
  {
    Name: 'Sweden'
  },
  {
    Name: 'Switzerland'
  },
  {
    Name: 'Syrian Arab Republic'
  },
  {
    Name: 'Taiwan, Province of China'
  },
  {
    Name: 'Tanzania'
  },
  {
    Name: 'Thailand'
  },
  {
    Name: 'Tunisia'
  },
  {
    Name: 'Turkey'
  },
  {
    Name: 'Uganda'
  },
  {
    Name: 'Ukraine'
  },
  {
    Name: 'United Arab Emirates'
  },
  {
    Name: 'United Kingdom'
  },
  {
    Name: 'United States of America'
  },
  {
    Name: 'Yemen'
  },
  {
    Name: 'Zimbabwe'
  }
];

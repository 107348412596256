import React from 'react';
import ClassList from '../visualization/Classes';

const ClassListPanel = () => {
  return (
    <section>
		  <ClassList />
    </section>
  );
};

export default ClassListPanel;

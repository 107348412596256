import React from 'react';
import UserRoles from '../visualization/UserRoles';

const UserRolesPanel = () => {
  return (
    <section>
      <UserRoles />
    </section>
  );
};

export default UserRolesPanel;

import React from 'react';
import PropTypes from 'prop-types';
import { DropDown, TextField } from '@aeblondon-uk/common-ui-components';

// import Loading from '../Loading';
import Footer from '../visualization/Footer';

import { useForm } from '../hooks/useForm';
import { useUserRoles } from '../hooks/useUserRoles';

const UserForm = ({
  user, mode, isProfileEdit = false, onCancel, onSave
}) => {
  const [fields, handleFieldChange] = useForm(user);
  const { userRoles } = useUserRoles(!isProfileEdit);

  const getRoles = () => {
    return userRoles.map((role) => {
      return (
        <div key={role._id}>
          <label>
            <input
              type='checkbox'
              disabled={mode === 'view'}
              className='checkbox'
              value={role._id}
              onChange={(e) => {
                const selectedRoles = [...fields.roles];
                if (selectedRoles.includes(e.target.value)) {
                  selectedRoles.splice(selectedRoles.indexOf(e.target.value));
                } else {
                  selectedRoles.push(e.target.value);
                }
                console.log('selectedRoles', selectedRoles);
                handleFieldChange('roles', selectedRoles);
              }}
              checked={fields.roles?.includes(role._id)}
            />
            {role.name}
          </label>
        </div>
      );
    });
  };

  const renderFooter = () => {
    if (mode === 'edit' || mode === 'add') {
      return (
        <Footer
          onCancel={onCancel}
          onSave={() => {
            onSave(fields);
          }}
        />
      );
    }
    return null;
  };

  return (
    <div className='student-form'>
      <fieldset className='student-form__section'>
        <legend>Personal details</legend>
        <div className='row one-two'>
          <TextField
            label='ITS Id'
            disabled={mode === 'view'}
            type='number'
            required={true}
            className='its-field'
            maxLength={15}
            value={fields.itsId}
            onChange={(e) => {
              let itsId = fields.itsId;
              try {
                itsId = parseInt(e.target.value, 10);
              } catch (ex) {
                console.log('Incorrect ITS Id provided', ex);
              }
              handleFieldChange('itsId', itsId);
            }}
          />
          <TextField
            label='First name'
            disabled={mode === 'view'}
            required={true}
            maxLength={25}
            value={fields.firstName}
            onChange={(e) => {
              handleFieldChange('firstName', e.target.value);
            }}
          />
          <TextField
            label='Last name'
            disabled={mode === 'view'}
            required={true}
            maxLength={25}
            value={fields.lastName}
            onChange={(e) => {
              handleFieldChange('lastName', e.target.value);
            }}
          />
        </div>
        <div className='row one-two'>
          <DropDown
            label='Gender'
            disabled={mode === 'view'}
            required={true}
            value={fields.gender}
            onChange={(e) => {
              handleFieldChange('gender', e.target.value);
            }}
          >
            <option value='M'>Male</option>
            <option value='F'>Female</option>
          </DropDown>
          <TextField
            label='Email'
            disabled={mode === 'view'}
            required={true}
            maxLength={75}
            value={fields.email}
            onChange={(e) => {
              handleFieldChange('email', e.target.value);
            }}
          />
        </div>
        {
          !isProfileEdit ?
            (
              <>
                <span className='form--field required'>Roles:</span>
                {getRoles()}
                <br />
                <label>
                  <input
                    type='checkbox'
                    disabled={mode === 'view'}
                    className='checkbox'
                    onChange={(e) => {
                      handleFieldChange('active', e.target.checked);
                    }}
                    checked={fields.active !== false}
                  />
                  <span>Active:</span>
                </label>
              </>
            ) :
            null
        }
      </fieldset>
      <p />

      <fieldset className='student-form__section'>
        <legend>Address</legend>
        <div className='row one-two'>
          <TextField
            label='Address line 1'
            disabled={mode === 'view'}
            required={true}
            maxLength={75}
            value={fields.addressLine1}
            onChange={(e) => {
              handleFieldChange('addressLine1', e.target.value);
            }}
          />
          <TextField
            label='Address line 2'
            disabled={mode === 'view'}
            maxLength={75}
            value={fields.addressLine2}
            onChange={(e) => {
              handleFieldChange('addressLine2', e.target.value);
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='City'
            disabled={mode === 'view'}
            required={true}
            maxLength={50}
            value={fields.city}
            onChange={(e) => {
              handleFieldChange('city', e.target.value);
            }}
          />
          <TextField
            label='County'
            disabled={mode === 'view'}
            required={true}
            maxLength={50}
            value={fields.county}
            onChange={(e) => {
              handleFieldChange('county', e.target.value);
            }}
          />
          <TextField
            label='Post code'
            disabled={mode === 'view'}
            required={true}
            maxLength={15}
            value={fields.postCode}
            onChange={(e) => {
              handleFieldChange('postCode', e.target.value);
            }}
          />
          <TextField
            label='Phone'
            disabled={mode === 'view'}
            required={true}
            maxLength={15}
            value={fields.phone}
            onChange={(e) => {
              handleFieldChange('phone', e.target.value);
            }}
          />
        </div>
      </fieldset>
      {renderFooter()}
    </div>
  );
};

UserForm.propTypes = {
  user: PropTypes.object,
  mode: PropTypes.string,
  isProfileEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default UserForm;

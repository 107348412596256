import React from 'react';
import Dashboard from '../visualization/Dashboard';

const DashboardPanel = () => {
  return (
    <section>
      <Dashboard />
    </section>
  );
};

export default DashboardPanel;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';
import Loading from './Loading';

const intlCache = createIntlCache();

function getIntl() {
  const intlDefaultValue = createIntl(
    {
      locale: 'en',
      message: {}
    },
    intlCache
  );
  return intlDefaultValue;
}

const generateMockMessages = (msgs, lang) => {
  const messages = {};

  Object.keys(msgs).forEach(msgKey => {
    let langChars = '十十十十十十';
    // use bidi chars for Arabic and Hebrew
    if (lang.indexOf('ar') !== -1) {
      langChars = 'ههههههه';
    }

    // no need to have mock translations for english
    if (lang.indexOf('en') === -1) {
      messages[msgKey] = `[(\')${langChars} ${msgs[msgKey]}]`;
    } else {
      messages[msgKey] = msgs[msgKey];
    }
  });

  return messages;
};

async function loadMessages(locale, loadMock) {
  if (locale === 'en' || !locale) {
    return {};
  }

  const bundle = loadMock ? 'en' : locale;
  try {
    // load the translation bundle for required locale if it isn't en
    const { messages } = await import(`../i18n/${bundle}.js`);
    console.log('Loaded messages for locale', locale);
    return loadMock ? generateMockMessages(messages, locale) : messages;
  } catch (ex) {
    console.log('Error retrieving translation bundle for locale', locale, ex);
  }

  return {};
}

export default () => function appWithIntl(Component) {
  const AppWithIntl = (props) => {
    const [messages, setMessages] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const loc = props.locale;
      let loadMock = false;

      if (loc) {
        if (props.mocknls === 'false') {
          // eslint-disable-next-line
          window.sessionStorage.removeItem('mocknls');
          // eslint-disable-next-line
        } else if (window.sessionStorage.getItem('mocknls')) {
          loadMock = true;
        } else if (props.mocknls) {
          loadMock = true;
          // eslint-disable-next-line
          window.sessionStorage.setItem('mocknls', 'true');
        }
      }

      const loadedMessages = loadMessages(loc, loadMock);
      loadedMessages.then((msgs) => {
        setMessages(msgs);
        setLoading(false);
        });
    }, []);

    return (
      !loading && messages
        ? (
          <RawIntlProvider
            value={createIntl({ locale: props.locale, messages }, intlCache) || getIntl()}
          >
            <Component {...props} />
          </RawIntlProvider>
        )
        : <Loading />
    );
  };
  AppWithIntl.propTypes = {
    locale: PropTypes.string,
    mocknls: PropTypes.string
  };

  return (
    AppWithIntl
  );
};

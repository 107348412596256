import React, { Component } from 'react';
import PropTypes from 'prop-types';

require('../styles/components/loading.scss');

class Loading extends Component {

  constructor() {
    super();
  }

  render() {
    return (
      <div className='loader-wrapper'>
        <div className="loader">Loading...</div>
      </div>
    );
  }
}

export default Loading;

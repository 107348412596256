import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { TextArea, TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
// import Notification from '../visualization/Notification';
import Footer from '../visualization/Footer';

import {
  getDashboardPermissions,
  getStudentsPermissions,
  getClassAttendancePermissions,
  getTeachersAttendancePermissions,
  getClassPermissions,
  getUsersPermissions,
  getRewardsPermissions,
  getInvoicesPermissions,
  getEmailTemplatesPermissions,
  getUserRolesPermissions,
  getSettingsPermissions,
  getPurchaseOrdersPermissions,
  getAssetsPermissions
} from '../../utils/permissions';
import { useForm } from '../hooks/useForm';

const messages = defineMessages({
  roleName: {
    defaultMessage: 'Name',
    description: 'User role name'
  },
  description: {
    defaultMessage: 'Description',
    description: 'User role description'
  },
  activeLabel: {
    defaultMessage: 'Active',
    description: 'User role active'
  }
});

const UserRoleForm = ({
  mode, userRole, onCancel, onSave, isLoading
}) => {
  const intl = useIntl();
  const [fields, handleFieldChange] = useForm(userRole);

  const showLoading = () => {
    if (isLoading) {
      return (<Loading />);
    }
    return null;
  };

  const renderPermissions = (permissions) => {
    return permissions.map((p) => {
      return (
        <label key={p.value}>
          <input
            type='checkbox'
            disabled={mode === 'view'}
            className='checkbox'
            value={p.value}
            onChange={(e) => {
              const selectedPermissions = [...fields.permissions];
              if (selectedPermissions.includes(e.target.value)) {
                selectedPermissions.splice(selectedPermissions.indexOf(e.target.value), 1);
              } else {
                selectedPermissions.push(e.target.value);
              }
              handleFieldChange('permissions', selectedPermissions);
            }}
            checked={fields.permissions?.includes(p.value)}
          />
          {intl.formatMessage(p.labelKey)}
        </label>
      );
    });
  };

  const renderForm = () => {
    return (
      <>
        <fieldset className='role-form__section'>
          <div className='row one-two'>
            <TextField
              label={intl.formatMessage(messages.roleName)}
              disabled={mode === 'view'}
              required={true}
              maxLength={50}
              value={fields.name}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />
          </div>
          <div className='row one-two'>
            <TextArea
              label={intl.formatMessage(messages.description)}
              disabled={mode === 'view'}
              maxLength={250}
              rows='4'
              value={fields.description}
              onChange={(e) => handleFieldChange('description', e.target.value)}
            />
          </div>
          <div className='row one-two'>
            <label>
              <input
                type='checkbox'
                disabled={mode === 'view'}
                className='checkbox'
                value={fields.active}
                onChange={(e) => handleFieldChange('description', e.target.checked)}
                checked={fields.active}
              />
              {intl.formatMessage(messages.activeLabel)}
            </label>
          </div>
        </fieldset>
        <p />
        <fieldset className='role-form__section'>
          <legend>Permissions</legend>
          <div className='permission-section-row'>
            <div className='permission-section'>
              <div>Dashboard</div>
              {renderPermissions(getDashboardPermissions())}
            </div>
            <div className='permission-section'>
              <div>Rewards</div>
              {renderPermissions(getRewardsPermissions())}
            </div>
            <div className='permission-section'>
              <div>Settings</div>
              {renderPermissions(getSettingsPermissions())}
            </div>
          </div>
          <div className='permission-section-row'>
            <div className='permission-section'>
              <div>Class attendance</div>
              {renderPermissions(getClassAttendancePermissions())}
            </div>
            <div className='permission-section'>
              <div>Teachers attendance</div>
              {renderPermissions(getTeachersAttendancePermissions())}
            </div>
            <div className='permission-section'>
              <div>Users</div>
              {renderPermissions(getUsersPermissions())}
            </div>
          </div>
          <div className='permission-section-row'>
            <div className='permission-section'>
              <div>User roles</div>
              {renderPermissions(getUserRolesPermissions())}
            </div>
            <div className='permission-section'>
              <div>Invoices</div>
              {renderPermissions(getInvoicesPermissions())}
            </div>
            <div className='permission-section'>
              <div>Email templates</div>
              {renderPermissions(getEmailTemplatesPermissions())}
            </div>
          </div>
          <div className='permission-section-row'>
            <div className='permission-section two'>
              <div>Purchase orders</div>
              {renderPermissions(getPurchaseOrdersPermissions())}
            </div>
            <div className='permission-section two'>
              <div>Students</div>
              {renderPermissions(getStudentsPermissions())}
            </div>
            <div className='permission-section'>
              <div>Classes</div>
              {renderPermissions(getClassPermissions())}
            </div>
          </div>
          <div className='permission-section-row'>
            <div className='permission-section two'>
              <div>Assets</div>
              {renderPermissions(getAssetsPermissions())}
            </div>
          </div>
        </fieldset>
        <p />
        {
          mode !== 'view' ? (
            <Footer
              onCancel={onCancel}
              onSave={() => {
                onSave(fields);
              }}
            />
          ) :
            null
        }
      </>
    );
  };

  return (
    <div className='role-form'>
      {showLoading()}
      {/* renderNotification() */}
      {renderForm()}
    </div>
  );
};

UserRoleForm.propTypes = {
  mode: PropTypes.string,
  userRole: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool
};

export default UserRoleForm;

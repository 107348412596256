import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './dashboard-widget.scss';

const DashboardWidget = ({
  title, metric, className, children
}) => {
  const widgetClass = classnames(
    className,
    'dashboard--widget-wrapper'
  );
  return (
    <div className={widgetClass}>
      <div className='widget-title-bar'>
        {title}
      </div>
      <div className='widget-content'>
        {isNaN(metric) ? children : metric}
      </div>
    </div>
  );
};

DashboardWidget.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  metric: PropTypes.number,
  className: PropTypes.string
};

export default DashboardWidget;

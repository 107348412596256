import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { sub } from 'date-fns';
import { DropDown, TextField } from '@aeblondon-uk/common-ui-components';

import Footer from '../visualization/Footer';
import { DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';

import { useForm } from '../hooks/useForm';

const EXPORT_PARAMS = {
  academicYear: '',
  paymentMode: 'All',
  status: 'Paid',
  startDate: sub(new Date(), { months: 3 }),
  endDate: new Date()
};

const ExportPaymentsForm = ({ currentAcademicYear, onCancel, onSave }) => {
  const [fields, handleFieldChange] = useForm(EXPORT_PARAMS);
  useEffect(() => {
    handleFieldChange('academicYear', currentAcademicYear);
  }, [currentAcademicYear]);

  return (
    <div className='generate-fees-invoices'>
      <div style={{ marginBottom: '20px' }}>
          All the payments for selected
        {' '}
        <b>Academic year</b>
        ,
        {' '}
        <b>Payment mode</b>
        {' '}
        and
        {' '}
        <b>Status</b>
        {' '}
        will be exported to an excel file.
      </div>
      <span className='payment-header'>
        <TextField
          label='Academic year'
          value={fields.academicYear}
          required={true}
          onChange={(e) => {
            handleFieldChange('academicYear', e.target.value);
          }}
        />
      </span>
      <span className='payment-header'>
        <DropDown
          label='Payment mode'
          value={fields.paymentMode}
          onChange={(e) => {
            handleFieldChange('paymentMode', e.target.value);
          }}
        >
          <option value='All'>All</option>
          <option value='Cash'>Cash</option>
          <option value='Cheque'>Cheque</option>
          <option value='Online'>Online</option>
        </DropDown>
      </span>
      <span className='payment-header'>
        <DropDown
          label='Status'
          value={fields.status}
          onChange={(e) => {
            handleFieldChange('status', e.target.value);
          }}
        >
          <option value='All'>All</option>
          <option value='Paid'>Paid</option>
          <option value='Unpaid'>Unpaid</option>
          <option value='Refunded'>Refunded</option>
          <option value='Rejected'>Rejected</option>
        </DropDown>
      </span>
      <span className='payment-header'>
        <div>Start date</div>
        <DatePicker
          selected={new Date(fields.startDate)}
          todayButton='Today'
          dateFormat={DATE_FORMAT_FULL_MONTH}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          className='date-picker-input'
          onChange={(date) => {
            handleFieldChange('startDate', new Date(date));
          }}
        />
      </span>
      <span className='payment-header'>
        <div>End date</div>
        <DatePicker
          selected={new Date(fields.endDate)}
          todayButton='Today'
          dateFormat={DATE_FORMAT_FULL_MONTH}
          minDate={fields.startDate}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          className='date-picker-input'
          onChange={(date) => {
            handleFieldChange('endDate', new Date(date));
          }}
        />
      </span>
      <Footer
        onCancel={onCancel}
        onSave={() => onSave(fields)}
      />
    </div>
  );
};

ExportPaymentsForm.propTypes = {
  currentAcademicYear: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default ExportPaymentsForm;

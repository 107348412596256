import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './sidepanel.scss';

const SidePanel = ({
  title, open = false, children, saveButton, cancelButtonLabel, onClear = () => {}, onClose = () => {}
}) => {
  const classes = classnames({
    'aeb--sidepanel': true,
    open
  });

  return (
    <div className={classes}>
      <div className='aeb--sidepanel__title'>{title}</div>
      <div className='aeb--sidepanel__content'>{children}</div>
      <div className='aeb--sidepanel__footer'>
        <button className='aeb-button mbl-button secondary-button footer-button' onClick={onClear}>
        {
          cancelButtonLabel ||
            (
              <FormattedMessage
                description='Reset button label for sidepanel'
                defaultMessage='Reset'
              />
            )
        }
        </button>
        {
          saveButton || (
            <button className='aeb-button mbl-button primary-button footer-button' onClick={onClose}>
              <FormattedMessage
                description='Done button label for sidepanel'
                defaultMessage='Done'
              />
            </button>
          )
        }
      </div>
    </div>
  );
};

SidePanel.propTypes = {
  title: PropTypes.any,
  open: PropTypes.bool,
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  saveButton: PropTypes.element,
  cancelButtonLabel: PropTypes.string
};

export default SidePanel;

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { add, format, compareDesc, isPast } from 'date-fns';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import {
  DropDown, NumberInput, TextArea, TextField
} from '@aeblondon-uk/common-ui-components';

import MdClose from '../../styles/assets/cross.svg';
import Footer from '../visualization/Footer';
import { DATE_FORMAT_UTC, DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';

import { useForm } from '../hooks/useForm';
import { PAYMENT } from '../hooks/InitialCopies';

const StudentInvoiceForm = ({
  invoice, academicYears, onCancel, onSave
}) => {
  const [fields, handleFieldChange] = useForm(invoice);

  const sortedPayments = useMemo(() => {
    const payments = [...fields.payments]?.map((p, index) => ({...p, index: index}));

    const Paid = payments.filter((p) => p.status === 'Paid');
    const rest = payments.filter((p) => p.status !== 'Paid');
    rest.sort((a, b) => {
      compareDesc(a.dueDate, b.dueDate);
    });
    return [...rest, ...Paid];
  }, [fields, invoice]);

  const renderPayments = () => {
    const payments = [];
    sortedPayments.forEach((payment) => {
      const index = payment.index;
      const fieldSetClasses = classnames(
        'payment-container',
        {
          'unpaid': payment.status === 'Unpaid' && (isPast(new Date(payment.dueDate)))
        }
      );
      payments.push(
        <fieldset key={`payment${payment.index + 1}`} className={fieldSetClasses}>
          <legend>{`Payment #${payment.index + 1}`}</legend>
          <div className='remove-payment'>
            <MdClose
              className='action-icon'
              onClick={() => {
                const p = [...fields.payments];
                p.splice(index, 1);
                handleFieldChange('payments', p);
              }}
            />
          </div>
          <div className='payment-details__wrapper'>
            <div className='payment-details'>
              <span className='cell'>
                <NumberInput
                  label='Order #'
                  type='number'
                  maxLength={15}
                  value={payment.orderNumber}
                  onChange={(value) => {
                    const p = [...fields.payments];
                    p[index].orderNumber = value;
                    handleFieldChange('payments', p);
                  }}
                />
              </span>
              <span className='cell'>
                <DropDown
                  label='Payment mode'
                  value={payment.paymentMode}
                  onChange={(e) => {
                    const p = [...fields.payments];
                    p[index].paymentMode = e.target.value;
                    handleFieldChange('payments', p);
                  }}
                >
                  <option value='NA'>-- Select Payment mode --</option>
                  <option value='Cash'>Cash</option>
                  <option value='Cheque'>Cheque</option>
                  <option value='Online'>Online</option>
                </DropDown>
              </span>
              <span className='cell'>
                <NumberInput
                  label='Amount'
                  required={true}
                  value={payment.amount}
                  onChange={(value) => {
                    const p = [...fields.payments];
                    p[index].amount = value;
                    handleFieldChange('payments', p);
                  }}
                />
              </span>
              <span className='cell'>
                <div className='payment-header'>Due date</div>
                <DatePicker
                  selected={payment.dueDate ? new Date(payment.dueDate) : null}
                  dateFormat={DATE_FORMAT_FULL_MONTH}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='date-picker-input'
                  onChange={(date) => {
                    if (date) {
                      const p = [...fields.payments];
                      p[index].dueDate = format(new Date(date), DATE_FORMAT_UTC);
                      handleFieldChange('payments', p);
                    }
                  }}
                />
              </span>
              <span className='cell'>
                <TextField
                  label='Paid by'
                  value={payment.paidBy}
                  onChange={(e) => {
                    const p = [...fields.payments];
                    p[index].paidBy = e.target.value;
                    handleFieldChange('payments', p);
                  }}
                />
              </span>
              <span className='cell'>
                <div className='payment-header'>Payment date</div>
                <DatePicker
                  selected={payment.paidDate ? new Date(payment.paidDate) : null}
                  dateFormat={DATE_FORMAT_FULL_MONTH}
                  maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='date-picker-input'
                  onChange={(date) => {
                    const p = [...fields.payments];
                    p[index].paidDate = date ? format(new Date(date), DATE_FORMAT_UTC) : date;
                    handleFieldChange('payments', p);
                  }}
                />
              </span>
              <span className='cell'>
                <DropDown
                  label='Status'
                  value={payment.status}
                  onChange={(e) => {
                    const p = [...fields.payments];
                    p[index].status = e.target.value;
                    handleFieldChange('payments', p);
                  }}
                >
                  <option value='Paid'>Paid</option>
                  <option value='Unpaid'>Unpaid</option>
                  <option value='Refunded'>Refunded</option>
                  <option value='Rejected'>Rejected</option>
                </DropDown>
              </span>
            </div>
            <div className='comment-cell'>
              <span className='cell'>
                <TextArea
                  label='Comments'
                  maxLength={500}
                  rows='3'
                  value={payment.comment}
                  onChange={(e) => {
                    const p = [...fields.payments];
                    p[index].comment = e.target.value;
                    handleFieldChange('payments', p);
                  }}
                />
              </span>
            </div>
          </div>
        </fieldset>
      );
    });
    return payments;
  };

  const createPayments = (count) => {
    const paymentsArr = [...fields.payments];
    const payments = { ...PAYMENT, amount: fields.amount / count };
    for (let i = 0; i < count; i++) {
      paymentsArr.push({ ...payments, dueDate: format(add(new Date(), { months: i }), DATE_FORMAT_UTC) });
    }
    return paymentsArr;
  };

  const renderStudentInvoice = () => {
    return (
      <div className='invoice-wrapper'>
        <div>
          <div className='invoice-details'>
            <div className='row'>
              <span>
                <div className='invoice-header'>Invoice #</div>
                <div className='invoice-value'>{fields.invoiceNumber || ''}</div>
              </span>
              <span className='payment-header'>
              <DropDown
                  label='Academic year'
                  value={fields.academicYear}
                  onChange={(e) => {
                    handleFieldChange('academicYear', e.target.value);
                  }}
                >
                  {academicYears}
                </DropDown>
              </span>
              <span>
                <div className='payment-header'>Due date</div>
                <DatePicker
                  selected={new Date(fields.dueDate)}
                  dateFormat={DATE_FORMAT_FULL_MONTH}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='date-picker-input'
                  onChange={(date) => {
                    handleFieldChange('dueDate', format(new Date(date), DATE_FORMAT_UTC));
                  }}
                />
              </span>
              <span className='payment-header'>
                <DropDown
                  label='Type'
                  value={fields.type}
                  onChange={(e) => {
                    handleFieldChange('type', e.target.value);
                  }}
                >
                  <option value='Fees'>Fees</option>
                  <option value='Niyaz'>Niyaz</option>
                </DropDown>
              </span>
              <span className='payment-header'>
                <NumberInput
                  label='Amount'
                  type='number'
                  required={true}
                  maxLength={15}
                  value={fields.amount}
                  onChange={(value) => {
                    handleFieldChange('amount', value);
                  }}
                />
              </span>
              <span className='payment-header'>
                <DropDown
                  label='Payment option'
                  value={fields.paymentOption}
                  onChange={(e) => {
                    handleFieldChange('paymentOption', e.target.value);
                  }}
                >
                  <option value='None'>None</option>
                  <option value='Full Payment'>Full payment</option>
                  <option value='Installment'>Installment</option>
                  <option value='Waiver'>Waiver</option>
                </DropDown>
              </span>
            </div>
            <div className='row'>
              <TextArea
                label='Comments'
                className='payment-header'
                maxLength={500}
                rows='3'
                value={fields.comment}
                onChange={(e) => {
                  handleFieldChange('comment', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className='button-bar'>
          <button
            disabled={isAddPaymentDisabled()}
            className='mbl-button primary-button'
            onClick={() => {
              handleFieldChange('payments', createPayments(1));
            }}
          >
            Add payment
          </button>
          &nbsp;
          <button
            disabled={isAddPaymentDisabled()}
            className='mbl-button primary-button'
            onClick={() => {
              handleFieldChange('payments', createPayments(4));
            }}
          >
            Add 4 payments
          </button>
          &nbsp;
          <button
            disabled={isAddPaymentDisabled()}
            className='mbl-button primary-button'
            onClick={() => {
              handleFieldChange('payments', createPayments(6));
            }}
          >
            Add 6 payments
          </button>
          &nbsp;
          <button
            disabled={isAddPaymentDisabled()}
            className='mbl-button primary-button'
            onClick={() => {
              handleFieldChange('payments', createPayments(10));
            }}
          >
            Add 10 payments
          </button>
        </div>
        {renderPayments()}
      </div>
    );
  };

  const isAddPaymentDisabled = () => {
    if (fields.paymentOption === 'Full Payment' && fields.payments && fields.payments.length > 0) {
      return true;
    }
    if (fields.paymentOption === 'Waiver') {
      return true;
    }
    return false;
  };

  const isSaveDisabled = () => {
    let saveDisabled = false;
    // if (!fields.amount) {
    //   saveDisabled = true;
    // }
    if (fields.amount && fields.paymentOption !== 'None' && fields.paymentOption !== 'Waiver' && fields.payments.length === 0) {
      saveDisabled = true;
    }
    if (fields.payments.length > 0) {
      if (fields.paymentOption === 'None') {
        return true;
      }

      let paymentAmount = 0;
      fields.payments.forEach((payment) => {
        paymentAmount += payment.amount;
        if (!payment.amount || !payment.dueDate) {
          saveDisabled = true;
        } else if ((payment.paymentMode === 'Online' || payment.paymentMode === 'Cheque') && (!payment.orderNumber)) {
          saveDisabled = true;
        } else if (payment.status === 'Paid' && !payment.paidDate) {
          saveDisabled = true;
        } else if (fields.paymentOption === 'Installment' && fields.payments.length <= 1) {
          saveDisabled = true;
        } else if (fields.paymentOption === 'Full Payment' && fields.payments.length > 1) {
          saveDisabled = true;
        }
      });
      if (fields.amount !== paymentAmount) {
        saveDisabled = true;
      }
    }
    return saveDisabled;
  };

  return (
    <div className='mbl--invoice-form'>
      {renderStudentInvoice()}
      <p />
      <br />
      <Footer
        onCancel={() => onCancel()}
        onSave={() => onSave(fields)}
        saveDisabled={isSaveDisabled()}
      />
    </div>
  );
};

StudentInvoiceForm.propTypes = {
  invoice: PropTypes.object,
  academicYears: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default StudentInvoiceForm;

import React from 'react';

import Settings from '../visualization/Settings';

const SettingsPanel = () => {
  return (
    <section>
      <div className='settings-panel'>
        <Settings />
      </div>
    </section>
  );
};

export default SettingsPanel;

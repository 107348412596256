import React, { useState, useMemo } from 'react';
// import { useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import {
  CommonTable as Table, OverflowMenu, OverflowMenuItem, Search
} from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import UserRoleForm from '../forms/UserRoleForm';

import MdMenu from '../../styles/assets/menu.svg';
import MdDelete from '../../styles/assets/delete.svg';
import FaChevronRight from '../../styles/assets/chevron.svg';
import NoData from '../NoData';
import HasPermissions from '../HasPermissions';
import Footer from './Footer';

import { useUserRoles } from '../hooks/useUserRoles';
import { userRole } from '../hooks/InitialCopies';
import {
  VIEW_ROLES_PERMISSION, ADD_ROLES_PERMISSION, EDIT_ROLES_PERMISSION, DELETE_ROLES_PERMISSION
} from '../../utils/permissions';
import { hasPermissions } from '../../utils/hasPermissions';

const UserRoles = () => {
  // const intl = useIntl();
  const {
    userRoles, saveUserRole, deleteUserRole, loading
  } = useUserRoles();
  const canEditRoles = hasPermissions([EDIT_ROLES_PERMISSION]);

  const [selectedUserRole, setSelectedUserRole] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showUserRolePanel, setShowUserRolePanel] = useState(false);
  const [mode, setMode] = useState('add');
  const [filter, setFilter] = useState({
    searchText: ''
  });

  const filteredUserRoles = useMemo(() => {
    const fs = [];
    userRoles.forEach((role) => {
      const searchAttribs = [role.name, role.description, role.permissions.join(',')];
      if (!filter.searchText || searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
        fs.push(role);
      }
    });
    return fs;
  }, [userRoles, filter]);

  const renderActionsMenu = () => {
    return (
      <HasPermissions permissions={[ADD_ROLES_PERMISSION]}>
        <OverflowMenu
          trigger={<MdMenu className='overflow-menu__icon' focusable={false} />}
          onClick={() => setShowActionsMenu(!showActionsMenu)}
          show={showActionsMenu}
          onHideOverflowMenu={() => {
            setShowActionsMenu(false);
          }}
          onMenuTriggerClick={() => setShowActionsMenu(!showActionsMenu)}
        >
          <OverflowMenuItem
            id='add_user_role'
            label='Add user role'
            onClick={() => {
              setMode('add');
              setSelectedUserRole(userRole);
              setShowUserRolePanel(true);
              setShowActionsMenu(false);
            }}
          />
        </OverflowMenu>
      </HasPermissions>
    );
  };

  const renderUserRolePanel = () => {
    const closeUserRolePanel = () => {
      setShowUserRolePanel(false);
      setSelectedUserRole({});
    };

    const getTitle = () => {
      if (mode === 'edit') {
        return `Edit user role - ${selectedUserRole.name}`;
      }
      if (mode === 'view') {
        return `View user role - ${selectedUserRole.name}`;
      }
      return 'Add user role';
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showUserRolePanel}
        title={getTitle()}
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeUserRolePanel();
        }}
      >
        <UserRoleForm
          mode={mode}
          userRole={selectedUserRole}
          onCancel={() => {
            closeUserRolePanel();
          }}
          onSave={async (fields) => {
            console.log(fields);
            await saveUserRole(fields, mode);
            closeUserRolePanel();
          }}
        />
      </SlidingPane>
    );
  };

  const renderDeleteConfirmation = () => {
    const closeDeleteConfirmation = () => {
      setShowDeleteConfirmation(false);
      setSelectedUserRole({});
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showDeleteConfirmation}
        title={`Delete user role - ${selectedUserRole.name}`}
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeDeleteConfirmation();
        }}
      >
        <div style={{ fontSize: '18px' }}>Are you sure you want to delete the selected user role?</div>
        <Footer
          onCancel={() => {
            closeDeleteConfirmation();
          }}
          onSave={async () => {
            await deleteUserRole(selectedUserRole._id);
            closeDeleteConfirmation();
          }}
        />
      </SlidingPane>
    );
  };

  const renderActions = (role) => {
    return (
      <HasPermissions permissions={[DELETE_ROLES_PERMISSION]}>
        <div className='actions'>
          <div className='actions-icons'>
            <MdDelete
              className='action-icon'
              onClick={() => {
                setSelectedUserRole(role);
                setShowDeleteConfirmation(true);
              }}
            />
          </div>
        </div>
      </HasPermissions>
    );
  };

  const getTitleRow = () => {
    return (
      <div className='mbl--panel--title-row'>
        <div>
          <Search
            placeholder='Search'
            value={filter.searchText}
            onChange={(e) => setFilter({ ...filter, searchText: e.target.value })}
            onClear={() => setFilter({ ...filter, searchText: '' })}
          />
        </div>
        <div className='count-info'>
          {`Displaying ${filteredUserRoles.length} of ${userRoles.length} user roles`}
        </div>
        <div className='buttons-cell'>
          {renderActionsMenu()}
        </div>
      </div>
    );
  };

  const getUserRoleList = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'actions') {
        return renderActions(cell.rowData);
      }
      if (cell.column.key === 'permissions') {
        return (
          <div>{`${cell.rowData[cell.column.key].length} permissions`}</div>
        );
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    let mouseDown = 0;
    const rowEventHandlers = {
      onMouseUp: (e) => {
        const elementClicked = e.event.target.tagName.toLowerCase();
        console.log(elementClicked);
        if (elementClicked !== 'svg' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
          setSelectedUserRole(e.rowData);
          if (canEditRoles) {
            setMode('edit');
          } else {
            setMode('view');
          }
          setShowUserRolePanel(true);
        }
      },
      onMouseDown: (e) => {
        mouseDown = e.event.clientX;
      }
    };

    const columns = [
      {
        width: 250, title: 'Name', dataKey: 'name', key: 'name', resizable: true
      },
      {
        width: 450, title: 'Description', dataKey: 'description', key: 'description', resizable: true
      },
      {
        width: 250, title: 'Permissions', dataKey: 'permissions', key: 'permissions', resizable: true
      }
    ];
    if (hasPermissions([DELETE_ROLES_PERMISSION])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        selectable
        rowEventHandlers={rowEventHandlers}
        columns={columns}
        data={filteredUserRoles}
      />
    );
  };

  return (
    <HasPermissions permissions={[
      VIEW_ROLES_PERMISSION, ADD_ROLES_PERMISSION, EDIT_ROLES_PERMISSION,
      DELETE_ROLES_PERMISSION
    ]}
    >
      <div className='user-role-list-wrapper'>
        {/* showLoading() */}
        {renderUserRolePanel()}
        {renderDeleteConfirmation()}
        {getTitleRow()}
        {getUserRoleList()}
      </div>
    </HasPermissions>
  );
};

export default UserRoles;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { DropDown, TextArea, TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import Footer from '../visualization/Footer';
import { DATE_FORMAT_UTC, DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';

// import Notification from '../visualization/Notification';
import { useForm } from '../hooks/useForm';

const StudentForm = ({
  student, mode, classes, onCancel, onSave, loading
}) => {
  const [fields, handleFieldChange] = useForm(student);

  useEffect(() => {
    if (student && classes && !student.classId) {
      handleFieldChange('classId', classes[0]._id);
    }
  }, []);

  const renderClassList = () => {
    const classesArr = [];
    classes.forEach((aClass) => {
      classesArr.push(
        <option key={aClass._id} value={aClass._id}>
          {aClass.name + (aClass.division ? ` ${ aClass.division}` : '')}
        </option>
      );
    });

    return (
      <DropDown
        label='Class'
        disabled={mode === 'view'}
        required={true}
        value={fields.classId}
        onChange={(e) => {
          handleFieldChange('classId', e.target.value);
        }}
      >
        {classesArr}
      </DropDown>
    );
  };

  const renderPersonalDetails = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 4);
    return (
      <fieldset className='student-form__section'>
        <legend>Personal details</legend>
        <div className='row one-two'>
          <TextField
            label='ITS Id'
            disabled={mode === 'view'}
            type='number'
            required={true}
            className='its-field'
            maxLength={15}
            value={fields.itsId || 0}
            onChange={(e) => {
              let itsId = fields.itsId;
              try {
                itsId = parseInt(e.target.value, 10);
              } catch (ex) {
                console.log('Incorrect ITS Id provided', ex);
              }
              handleFieldChange('itsId', itsId);
            }}
          />
          <TextField
            label='First name'
            disabled={mode === 'view'}
            required={true}
            maxLength={20}
            value={fields.firstName || ''}
            onChange={(e) => {
              handleFieldChange('firstName', e.target.value);
            }}
          />
          <TextField
            label='Last name'
            disabled={mode === 'view'}
            required={true}
            maxLength={20}
            value={fields.lastName || ''}
            onChange={(e) => {
              handleFieldChange('lastName', e.target.value);
            }}
          />
        </div>
        <div className='row one-two'>
          <div className='date-field'>
            <span className='required'>Date of birth</span>
            <DatePicker
              disabled={mode === 'view'}
              selected={fields.DOB ? new Date(fields.DOB) : null}
              dateFormat={DATE_FORMAT_FULL_MONTH}
              maxDate={date}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              className='date-picker-input'
              onChange={(dt) => {
                handleFieldChange('DOB', format(new Date(dt), DATE_FORMAT_UTC));
              }}
            />
          </div>
          <div className='date-field'>
            <DropDown
              label='Gender'
              disabled={mode === 'view'}
              required={true}
              value={fields.gender}
              onChange={(e) => {
                handleFieldChange('gender', e.target.value);
              }}
            >
              <option value='M'>Male</option>
              <option value='F'>Female</option>
            </DropDown>
          </div>
          <div className='class-field'>
            {renderClassList()}
          </div>
          <label className='active-field'>
            <input
              disabled={mode === 'view'}
              type='checkbox'
              className='checkbox'
              onChange={(e) => { handleFieldChange('active', e.target.checked); }}
              checked={fields.active !== false}
            />
            <span>Active</span>
          </label>
        </div>
        <div className=''>
          <TextArea
            label='Remarks'
            disabled={mode === 'view'}
            maxLength={500}
            rows='4'
            value={fields.remarks || ''}
            onChange={(e) => {
              handleFieldChange('remarks', e.target.value);
            }}
          />
        </div>
      </fieldset>
    );
  };

  const renderFatherDetails = () => {
    return (
      <fieldset className='student-form__section'>
        <legend>Father</legend>
        <div className='row one-two'>
          <TextField
            label='ITS Id'
            disabled={mode === 'view'}
            type='number'
            required={true}
            className='its-field'
            maxLength={15}
            value={(fields.father && fields.father.itsId) || 0}
            onChange={(e) => {
              let itsId = fields.father.itsId;
              try {
                itsId = parseInt(e.target.value, 10);
              } catch (ex) {
                console.log('Incorrect Father ITS Id provided', ex);
              }
              handleFieldChange('father', { ...fields.father, itsId });
            }}
          />
          <TextField
            label='First name'
            disabled={mode === 'view'}
            required={true}
            maxLength={25}
            value={(fields.father && fields.father.firstName) || ''}
            onChange={(e) => {
              handleFieldChange('father', { ...fields.father, firstName: e.target.value });
            }}
          />
          <TextField
            label='Last name'
            disabled={mode === 'view'}
            required={true}
            maxLength={25}
            value={(fields.father && fields.father.lastName) || ''}
            onChange={(e) => {
              handleFieldChange('father', { ...fields.father, lastName: e.target.value });
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='Email'
            disabled={mode === 'view'}
            required={true}
            maxLength={75}
            value={(fields.father && fields.father.email) || ''}
            onChange={(e) => {
              handleFieldChange('father', { ...fields.father, email: e.target.value });
            }}
          />
          <TextField
            label='Phone'
            disabled={mode === 'view'}
            required={true}
            maxLength={15}
            value={(fields.father && fields.father.phone) || ''}
            onChange={(e) => {
              handleFieldChange('father', { ...fields.father, phone: e.target.value });
            }}
          />
        </div>
      </fieldset>
    );
  };

  const renderMotherDetails = () => {
    return (
      <fieldset className='student-form__section'>
        <legend>Mother</legend>
        <div className='row one-two'>
          <TextField
            label='ITS Id'
            disabled={mode === 'view'}
            type='number'
            required={true}
            className='its-field'
            maxLength={15}
            value={(fields.mother && fields.mother.itsId) || 0}
            onChange={(e) => {
              let itsId = fields.mother.itsId;
              try {
                itsId = parseInt(e.target.value, 10);
              } catch (ex) {
                console.log('Incorrect Father ITS Id provided', ex);
              }
              handleFieldChange('mother', { ...fields.mother, itsId });
            }}
          />
          <TextField
            label='First name'
            disabled={mode === 'view'}
            required={true}
            maxLength={25}
            value={(fields.mother && fields.mother.firstName) || ''}
            onChange={(e) => {
              handleFieldChange('mother', { ...fields.mother, firstName: e.target.value });
            }}
          />
          <TextField
            label='Last name'
            disabled={mode === 'view'}
            required={true}
            maxLength={25}
            value={(fields.mother && fields.mother.lastName) || ''}
            onChange={(e) => {
              handleFieldChange('mother', { ...fields.mother, lastName: e.target.value });
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='Email'
            disabled={mode === 'view'}
            required={true}
            maxLength={75}
            value={(fields.mother && fields.mother.email) || ''}
            onChange={(e) => {
              handleFieldChange('mother', { ...fields.mother, email: e.target.value });
            }}
          />
          <TextField
            label='Phone'
            disabled={mode === 'view'}
            required={true}
            maxLength={15}
            value={(fields.mother && fields.mother.phone) || ''}
            onChange={(e) => {
              handleFieldChange('mother', { ...fields.mother, phone: e.target.value });
            }}
          />
        </div>
      </fieldset>
    );
  };

  const renderAddressDetails = () => {
    return (
      <fieldset className='student-form__section'>
        <legend>Address</legend>
        <div className='row one-two'>
          <TextField
            label='Address line 1'
            disabled={mode === 'view'}
            required={true}
            value={(fields.address && fields.address.addressLine1) || ''}
            onChange={(e) => {
              handleFieldChange('address', { ...fields.address, addressLine1: e.target.value });
            }}
          />
          <TextField
            label='Address line 2'
            disabled={mode === 'view'}
            value={(fields.address && fields.address.addressLine2) || ''}
            onChange={(e) => {
              handleFieldChange('address', { ...fields.address, addressLine2: e.target.value });
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='City'
            disabled={mode === 'view'}
            required={true}
            maxLength={20}
            value={(fields.address && fields.address.city) || ''}
            onChange={(e) => {
              handleFieldChange('address', { ...fields.address, city: e.target.value });
            }}
          />
          <TextField
            label='County'
            disabled={mode === 'view'}
            maxLength={25}
            value={(fields.address && fields.address.county) || ''}
            onChange={(e) => {
              handleFieldChange('address', { ...fields.address, county: e.target.value });
            }}
          />
          <TextField
            label='Post code'
            required={true}
            disabled={mode === 'view'}
            maxLength={10}
            value={(fields.address && fields.address.postCode) || ''}
            onChange={(e) => {
              handleFieldChange('address', { ...fields.address, postCode: e.target.value });
            }}
          />
        </div>
      </fieldset>
    );
  };

  const renderSchoolDetails = () => {
    return (
      <fieldset className='student-form__section'>
        <legend>School</legend>
        <div className='row'>
          <TextField
            label='School name'
            disabled={mode === 'view'}
            maxLength={50}
            value={(fields.school && fields.school.name) || ''}
            onChange={(e) => {
              handleFieldChange('school', { ...fields.school, name: e.target.value });
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='Address line 1'
            disabled={mode === 'view'}
            value={(fields.school && fields.school.addressLine1) || ''}
            onChange={(e) => {
              handleFieldChange('school', { ...fields.school, addressLine1: e.target.value });
            }}
          />
          <TextField
            label='Address line 2'
            disabled={mode === 'view'}
            value={(fields.school && fields.school.addressLine2) || ''}
            onChange={(e) => {
              handleFieldChange('school', { ...fields.school, addressLine2: e.target.value });
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='City'
            disabled={mode === 'view'}
            maxLength={20}
            value={(fields.school && fields.school.city) || ''}
            onChange={(e) => {
              handleFieldChange('school', { ...fields.school, city: e.target.value });
            }}
          />
          <TextField
            label='County'
            disabled={mode === 'view'}
            maxLength={25}
            value={(fields.school && fields.school.county) || ''}
            onChange={(e) => {
              handleFieldChange('school', { ...fields.school, county: e.target.value });
            }}
          />
          <TextField
            label='Post code'
            disabled={mode === 'view'}
            maxLength={10}
            value={(fields.school && fields.school.postCode) || ''}
            onChange={(e) => {
              handleFieldChange('school', { ...fields.school, postCode: e.target.value });
            }}
          />
        </div>
      </fieldset>
    );
  };

  const renderMedicalDetails = () => {
    return (
      <fieldset className='student-form__section'>
        <legend>Medical history</legend>

        <div className='row one-two'>
          <div className='medical-dropdown'>
            <DropDown
              label='Known allergies'
              disabled={mode === 'view'}
              required={true}
              value={fields.knownAllergies || 'No'}
              onChange={(e) => {
                handleFieldChange('knownAllergies', e.target.value);
              }}
            >
              <option value='No'>No</option>
              <option value='Yes'>Yes</option>
            </DropDown>
          </div>
          <TextField
            label='Allergy details'
            disabled={mode === 'view'}
            required={false}
            value={fields.allergyDetails || ''}
            onChange={(e) => {
              handleFieldChange('allergyDetails', e.target.value);
            }}
          />
        </div>
        <div className='row one-two'>
          <div className='medical-dropdown'>
            <DropDown
              label='Known illness'
              disabled={mode === 'view'}
              required={true}
              value={fields.knownIllness || 'No'}
              onChange={(e) => {
                handleFieldChange('knownIllness', e.target.value);
              }}
            >
              <option value='No'>No</option>
              <option value='Yes'>Yes</option>
            </DropDown>
          </div>
          <TextField
            label='Illness details'
            disabled={mode === 'view'}
            required={false}
            value={fields.illnessDetails || ''}
            onChange={(e) => {
              handleFieldChange('illnessDetails', e.target.value);
            }}
          />
        </div>
        <div className='row one-two'>
          <div className='medical-dropdown'>
            <DropDown
              label='Special needs'
              disabled={mode === 'view'}
              required={true}
              value={fields.specialNeeds || 'No'}
              onChange={(e) => {
                handleFieldChange('specialNeeds', e.target.value);
              }}
            >
              <option value='No'>No</option>
              <option value='Yes'>Yes</option>
            </DropDown>
          </div>
          <TextField
            label='Special needs details'
            disabled={mode === 'view'}
            required={false}
            value={fields.specialNeedsDetails || ''}
            onChange={(e) => {
              handleFieldChange('specialNeedsDetails', e.target.value);
            }}
          />
        </div>
      </fieldset>
    );
  };

  const renderGPDetails = () => {
    return (
      <fieldset className='student-form__section'>
        <legend>GP practice</legend>
        <div className='row'>
          <TextField
            label='Practice name'
            disabled={mode === 'view'}
            maxLength={50}
            value={(fields.gpPractice && fields.gpPractice.name) || ''}
            onChange={(e) => {
              handleFieldChange('gpPractice', { ...fields.gpPractice, name: e.target.value });
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='Address line 1'
            disabled={mode === 'view'}
            value={(fields.gpPractice && fields.gpPractice.addressLine1) || ''}
            onChange={(e) => {
              handleFieldChange('gpPractice', { ...fields.gpPractice, addressLine1: e.target.value });
            }}
          />
          <TextField
            label='Address line 2'
            disabled={mode === 'view'}
            value={(fields.gpPractice && fields.gpPractice.addressLine2) || ''}
            onChange={(e) => {
              handleFieldChange('gpPractice', { ...fields.gpPractice, addressLine2: e.target.value });
            }}
          />
        </div>
        <div className='row one-two'>
          <TextField
            label='City'
            disabled={mode === 'view'}
            maxLength={20}
            value={(fields.gpPractice && fields.gpPractice.city) || ''}
            onChange={(e) => {
              handleFieldChange('gpPractice', { ...fields.gpPractice, city: e.target.value });
            }}
          />
          <TextField
            label='County'
            disabled={mode === 'view'}
            maxLength={25}
            value={(fields.gpPractice && fields.gpPractice.county) || ''}
            onChange={(e) => {
              handleFieldChange('gpPractice', { ...fields.gpPractice, county: e.target.value });
            }}
          />
          <TextField
            label='Post code'
            disabled={mode === 'view'}
            maxLength={10}
            value={(fields.gpPractice && fields.gpPractice.postCode) || ''}
            onChange={(e) => {
              handleFieldChange('gpPractice', { ...fields.gpPractice, postCode: e.target.value });
            }}
          />
          <TextField
            label='Phone'
            disabled={mode === 'view'}
            maxLength={15}
            value={(fields.gpPractice && fields.gpPractice.phone) || ''}
            onChange={(e) => {
              handleFieldChange('gpPractice', { ...fields.gpPractice, phone: e.target.value });
            }}
          />
        </div>
      </fieldset>
    );
  };

  const renderFooter = () => {
    if (mode !== 'view') {
      return (
        <Footer
          onCancel={onCancel}
          onSave={() => {
            onSave(fields);
          }}
        />
      );
    }
    return null;
  };

  const showLoading = () => {
    if (loading) {
      return (<Loading />);
    }
    return null;
  };

  // const renderNotification = () => {
  //   if (this.props.status.get('isError')) {
  //     return (
  //       <div>
  //         <Notification
  //           text={this.props.status.get('error')}
  //           type='error'
  //           closable={false}
  //         />
  //         <p />
  //       </div>
  //     );
  //   }
  // };

  return (
    <div className='student-form'>
      {showLoading()}
      {/* renderNotification() */}
      {renderPersonalDetails()}
      <p />
      {renderFatherDetails()}
      <p />
      {renderMotherDetails()}
      <p />
      {renderAddressDetails()}
      <p />
      {renderSchoolDetails()}
      <p />
      {renderMedicalDetails()}
      <p />
      {renderGPDetails()}
      <p />
      {renderFooter()}
    </div>
  );
};

StudentForm.propTypes = {
  student: PropTypes.object,
  mode: PropTypes.string,
  classes: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool
};

export default StudentForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChangePasswordForm from '../forms/ChangePasswordForm';

class ChangePasswordPanel extends Component {

  render() {
    return (
      <section>
        <ChangePasswordForm />
      </section>
    );
  }
}

export default ChangePasswordPanel;

import { useContext, useState, useEffect } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useDashboardData = () => {
  const appContext = useContext(AppContext);
  const [teachersStats, setTeachersStats] = useState({});
  const [studentsStats, setStudentsStats] = useState({});
  const [pendingAttendance, setPendingAttendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTeachersStats = async () => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/dashboard/v1/teachersStats`);
      const jsonData = await dataService.handleResponse(resp);
      setTeachersStats(jsonData);
    } catch (ex) {
      console.log(':::::Exception fetching teachers attendance stats', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const fetchStudentsStats = async () => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/dashboard/v1/studentsStats`);
      const jsonData = await dataService.handleResponse(resp);
      setStudentsStats(jsonData);
    } catch (ex) {
      console.log(':::::Exception fetching students attendance stats', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const fetchPendingAttendance = async () => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/pendingAttendance`);
      const jsonData = await dataService.handleResponse(resp);
      setPendingAttendance(jsonData.data);
    } catch (ex) {
      console.log(':::::Exception fetching pending attendance', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const fetchData = () => {
    fetchTeachersStats();
    fetchStudentsStats();
    fetchPendingAttendance();
  };

  useEffect(() => {
    let refreshInterval = null;
    if (appContext.tenant._id) {
      fetchData();
      refreshInterval = setInterval(() => {
        fetchData();
      }, 20000);
    }

    return () => {
      clearInterval(refreshInterval);
      setTeachersStats({});
      setStudentsStats({});
      setPendingAttendance([]);
    };
  }, []);

  return {
    teachersStats,
    studentsStats,
    pendingAttendance,
    loading,
    error
  };
};

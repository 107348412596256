import React, {
  useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import SlidingPane from 'react-sliding-pane';
import { CommonTable, ToggleButton } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import AttendanceCard from './AttendanceCard';
import AttendanceReportCard from './AttendanceReportCard';
import XLSExporter from '../../utils/XLSExporter';
import FaChevronRight from '../../styles/assets/chevron.svg';
import AttendanceChart from '../panel/AttendanceChartPanel';
import StudentForm from '../forms/StudentForm';
import AppContext from '../AppContext';
import HasPermissions from '../HasPermissions';
import {
  DATE_FORMAT_UTC, DATE_FORMAT_WITH_TIME, DATE_FORMAT_FULL_MONTH,
  DATE_FORMAT_MONTH_YEAR, DATE_FORMAT_SHORT_MONTH
} from '../../utils/DateUtils';
import {
  EDIT_STUDENTS_PERMISSION, VIEW_MY_CLASS_ATTENDANCE_PERMISSION,
  TAKE_MY_CLASS_ATTENDANCE_PERMISSION, VIEW_ALL_CLASS_ATTENDANCE_PERMISSION,
  TAKE_ALL_CLASS_ATTENDANCE_PERMISSION
} from '../../utils/permissions';
import { hasPermissions } from '../../utils/hasPermissions';
import { getCurrentAcademicYear } from '../../utils/AcademicYears';

import { useStudentAttendanceData } from '../hooks/useStudentAttendanceData';
import { useClassData } from '../hooks/useClassData';
import useStudentData from '../hooks/useStudentData';

const getRowHeight = (mode, selectedClass) => {
  if (window.innerWidth >= 800) {
    return 75;
  }
  if (mode === 'report') {
    return selectedClass === 'All' ? 250 : 190;
  }
  return 370;
};

const AttendanceList = ({ selectedClass, mode }) => {
  const appContext = useContext(AppContext);
  const canTakeAttendance = hasPermissions([
    TAKE_MY_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION
  ]);
  // startDate is used for report panel
  const [startDate, setStartDate] = useState(null);
  const [date, setDate] = useState(format(new Date(), DATE_FORMAT_UTC));
  // const currentHour = Number(format(new Date(), DATE_FORMAT_HOUR));
  const defaultSession = 'Morning';
  // if (mode !== 'report') {
  //   defaultSession = currentHour < 12 ? 'Morning' : 'Afternoon'
  // }
  const [session, setSession] = useState(defaultSession);
  const [rowHeight, setRowHeight] = useState(getRowHeight(mode, selectedClass));
  const [tableHeight, setTableHeight] = useState(window.innerHeight - 230);
  const [showStudentAttendanceHistory, setShowStudentAttendanceHistory] = useState(false);
  const [showStudentDetails, setShowStudentDetails] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});

  const {
    students,
    fetchClassAttendance,
    saveClassAttendance,
    fetchClassAttendanceReport,
    fetchClassAttendanceHistory,
    attendanceHistory,
    loading: attendanceLoading
  } = useStudentAttendanceData();
  const { classes } = useClassData();
  const {
    saveStudent, addWarning, removeWarning, loading: studentsLoading
  } = useStudentData(false);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize.bind(this));
  }, []);

  useEffect(() => {
    const academicYear = getCurrentAcademicYear(appContext?.settings);
    setStartDate(academicYear?.startDate || format(new Date(), DATE_FORMAT_UTC));
  }, [appContext.settings]);

  useEffect(() => {
    setRowHeight(getRowHeight(mode, selectedClass));
    setTableHeight(window.innerHeight - 230);
    // fetch once we have the startDate set from settings
    if (mode === 'report' && startDate) {
      fetchClassAttendanceReport(selectedClass, format(new Date(startDate), DATE_FORMAT_UTC), date, session);
      fetchClassAttendanceHistory(selectedClass, format(new Date(startDate), DATE_FORMAT_UTC), date, session);
    }
  }, [selectedClass, date, startDate, session]);

  useEffect(() => {
    if (mode !== 'report') {
      fetchClassAttendance(selectedClass, date, session);
    }
  }, [selectedClass, date, session]);

  const onWindowResize = () => {
    setRowHeight(getRowHeight(mode, selectedClass));
    setTableHeight(window.innerHeight - 230);
  };

  const renderStudentDetails = () => {
    const studentName = `${selectedStudent.firstName} ${selectedStudent.father ?
      selectedStudent.father.firstName : ''} ${selectedStudent.lastName}`;
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showStudentDetails}
        title={`${hasPermissions([EDIT_STUDENTS_PERMISSION]) ? 'Edit student' : 'View Details'} - ${studentName}`}
        from='right'
        width='80vw'
        onRequestClose={() => {
          setShowStudentDetails(false);
          setSelectedStudent({});
        }}
      >
        <StudentForm
          student={selectedStudent}
          mode={!hasPermissions([EDIT_STUDENTS_PERMISSION]) ? 'view' : 'edit'}
          classes={classes}
          onCancel={() => {
            setShowStudentDetails(false);
            setSelectedStudent({});
          }}
          onSave={(fields) => {
            saveStudent(fields, 'edit', async () => {
              await fetchClassAttendanceReport(selectedClass, format(new Date(startDate), DATE_FORMAT_UTC), date);
              setShowStudentDetails(false);
              setSelectedStudent({});
            });
          }}
        />
      </SlidingPane>
    );
  };

  const renderStudentAttendanceHistory = () => {
    const studentName = `${selectedStudent.firstName} ${selectedStudent.father ? selectedStudent.father.firstName : ''} ${selectedStudent.lastName}`;
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showStudentAttendanceHistory}
        title={`Attendance History - ${studentName}`}
        from='right'
        width='80vw'
        onRequestClose={() => {
          setShowStudentAttendanceHistory(false);
          setSelectedStudent({});
        }}
      >
        <AttendanceChart
          student={selectedStudent}
          id={selectedStudent._id}
          startDate={startDate}
          endDate={date}
          session={session}
        />
      </SlidingPane>
    );
  };

  const downloadReport = () => {
    const classAttendance = [];
    let classYear = '';
    console.log(attendanceHistory);
    Object.keys(attendanceHistory).forEach((studentId) => {
      const student = students.find((stu) => {
        return stu._id === studentId;
      });

      if (student) {
        classYear = `${student.mblClass.name}${student.mblClass.division}`;
        const studentAttHistory = {
          Student: `${student.firstName} ${student.father ? student.father.firstName : ''} ${student.lastName}`,
          Year: classYear
        };
        attendanceHistory[studentId].forEach((att) => {
          let late = '';
          if (att.late) {
            late = `[L ${att.late}]`;
          }
          const month = format(new Date(`${att.year}-${att.month}-01`), DATE_FORMAT_MONTH_YEAR);
          studentAttHistory[`${month}`] = `${att.actual} / ${att.expected} ${late}`;
        });
        classAttendance.push(studentAttHistory);
      }
    });

    classAttendance.sort((a, b) => {
      return a.Year.localeCompare(b.Year);
    });

    const cls = classes.find((cl) => {
      return cl._id === selectedClass;
    });

    const clsName = cls ? `${cls.name}${cls.division}` : 'All';
    XLSExporter.export({
      items: classAttendance,
      sheetName: `${clsName} Attendance History`,
      fileName: `ClassAttendance-${clsName}__${format(new Date(), DATE_FORMAT_UTC)}.xlsx`,
      colsWidth: [50, 11, 11, 11, 11, 11, 11, 11]
    });
  };

  const renderDateSessionSelector = () => {
    return (
      <div className='date-session-strip'>
        <div className='header' />
        <div className='student-count'>
          {`${students.length} Students`}
        </div>
        <div className='date-wrapper'>
          {
            mode === 'report' ? (
              <>
                <div className='button-strip'>
                  <input
                    type='button'
                    value='Generate report'
                    tabIndex='0'
                    style={{ height: '45px' }}
                    className='mbl-button primary-button'
                    onClick={() => {
                      downloadReport();
                    }}
                  />
                </div>
                <div className='horizontal-spacer-10' />
                <DatePicker
                  selected={new Date(startDate)}
                  todayButton='Today'
                  dateFormat={DATE_FORMAT_FULL_MONTH}
                  maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='date-picker-input'
                  onChange={(dt) => setStartDate(format(new Date(dt), DATE_FORMAT_UTC))}
                />
                <div className='horizontal-spacer-10' />
              </>
            ) :
              null
          }
          <DatePicker
            selected={new Date(date)}
            todayButton='Today'
            dateFormat={DATE_FORMAT_SHORT_MONTH}
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            className='date-picker-input'
            onChange={(dt) => setDate(format(new Date(dt), DATE_FORMAT_UTC))}
          />
            <div className='horizontal-spacer-10' />
            <ToggleButton
              onClick={(value) => setSession(value)}
              items={[
                { name: 'Morning', label: 'Morning', pressed: session === 'Morning' },
                { name: 'Afternoon', label: 'Afternoon', pressed: session === 'Afternoon' }]}
            />
        </div>
      </div>
    );
  };

  const onAttendanceChange = async (data, fields) => {
    const params = { ...fields };
    if (params.present) {
      params.authorised = false;
      if (params.late) {
        params.time = format(new Date(), DATE_FORMAT_WITH_TIME);
      } else {
        delete params.time;
      }
    } else {
      // if present then set late to false and remove late time
      params.late = false;
      delete params.time;
    }
    params.classId = selectedClass;
    params.date = date;
    params.session = session;

    const attendance = {};
    students.forEach((student) => {
      const studentId = student._id;
      attendance[studentId] = {};
      if (data.rowData._id === studentId) {
        attendance[studentId] = params;
      } else {
        attendance[studentId].present = student.attendance && student.attendance.length > 0 ? student.attendance[0].present : false;
        attendance[studentId].late = student.attendance && student.attendance.length > 0 ? student.attendance[0].late : false;
        attendance[studentId].authorised = student.attendance && student.attendance.length > 0 ? student.attendance[0].authorised : false;
        attendance[studentId].date = student.attendance && student.attendance.length > 0 ? student.attendance[0].date : date;
        attendance[studentId].session = student.attendance && student.attendance.length > 0 ? student.attendance[0].session : session;
        attendance[studentId].classId = student.attendance && student.attendance.length > 0 ? student.attendance[0].classId : selectedClass;
      }
    });
    await saveClassAttendance(attendance);
    await fetchClassAttendance(selectedClass, date, session);
  };

  const rowRenderer = (data) => {
    const attParams = { ...data.rowData };
    if (mode === 'report') {
      return (
        <AttendanceReportCard
          student={attParams}
          showYear={selectedClass === 'All'}
          onShowAttendanceHistory={(student) => {
            setSelectedStudent(student);
            setShowStudentAttendanceHistory(true);
          }}
          onShowDetails={(student) => {
            setSelectedStudent(student);
            setShowStudentDetails(true);
          }}
        />
      );
    }
    if (!attParams.attendance || attParams.attendance.length === 0) {
      attParams.attendance = [{
        present: false,
        authorised: false,
        late: false
      }];
    }
    return (
      <AttendanceCard
        readOnly={!canTakeAttendance}
        showWarnings={true}
        showHifzStatus={true}
        onWarningChange={async ({ action, warning }) => {
          if (action === 'ADD') {
            await addWarning(attParams._id, { date: format(new Date(), DATE_FORMAT_WITH_TIME), comment: '' });
          } else {
            await removeWarning(attParams._id, warning._id);
          }
          await fetchClassAttendance(selectedClass, date, session);
        }}
        student={attParams}
        onAttendanceChange={(fields) => {
          onAttendanceChange(data, fields);
        }}
      />
    );
  };

  const showLoading = () => {
    if (attendanceLoading || studentsLoading) {
      return <Loading />;
    }
    return null;
  };

  const renderStudentList = () => {
    return (
      <CommonTable
        fixed
        rowKey='_id'
        rowHeight={rowHeight}
        maxHeight={tableHeight}
        selectable
        rowRenderer={rowRenderer}
        emptyRenderer={<Loading />}
        headerHeight={0}
        columns={[]}
        data={students}
      />
    );
  };

  return (
    <HasPermissions permissions={[
      VIEW_MY_CLASS_ATTENDANCE_PERMISSION, TAKE_MY_CLASS_ATTENDANCE_PERMISSION,
      VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION
    ]}
    >
      {showLoading()}
      {renderStudentDetails()}
      {renderStudentAttendanceHistory()}
      {renderDateSessionSelector()}
      {renderStudentList()}
    </HasPermissions>
  );
};

AttendanceList.propTypes = {
  selectedClass: PropTypes.string,
  mode: PropTypes.string
};

export default AttendanceList;

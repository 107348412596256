import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import MedicalFlag from '../../styles/assets/medical-flag1.svg';
import '../../styles/components/visualization/medical-icon.scss';

const MedicalIcon = ({ student }) => {
  const medicalDetails = [];
  if (student.knownAllergies && student.knownAllergies.toLowerCase() === 'yes') {
    medicalDetails.push(
      <div key={`${student._id }_allergies`}>
        <span className='medical-label'>Allergies:</span>
        {' '}
        {student.allergyDetails ? student.allergyDetails : 'Allergy details not provided'}
      </div>
    );
  }
  if (student.knownIllness && student.knownIllness.toLowerCase() === 'yes') {
    medicalDetails.push(
      <div key={`${student._id }_illness`}>
        <span className='medical-label'>Illness:</span>
        {' '}
        {student.illnessDetails ? student.illnessDetails : 'Illness details not provided'}
      </div>
    );
  }
  if (student.specialNeeds && student.specialNeeds.toLowerCase() === 'yes') {
    medicalDetails.push(
      <div key={`${student._id }_specialNeeds`}>
        <span className='medical-label'>Special Needs:</span>
        {' '}
        {student.specialNeedsDetails ? student.specialNeedsDetails : 'Special needs details not provided'}
      </div>
    );
  }

  if (medicalDetails.length > 0) {
    return (
      <Tooltip
        key={`class-${ student._id}`}
        placement='bottom'
        mouseEnterDelay={0}
        mouseLeaveDelay={0.1}
        destroyTooltipOnHide={false}
        trigger={['hover']}
        overlay={(
          <div>
            {medicalDetails}
          </div>
        )}
        overlayClassName='mbl--tooltip'
        align={{
          offset: [0, 0]
        }}
      >
        <MedicalFlag className='medical-icon' />
      </Tooltip>
    );
  }
  return null;
};

MedicalIcon.propTypes = {
  student: PropTypes.object
};

export default MedicalIcon;

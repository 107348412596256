import { isAfter } from 'date-fns';

const InvoiceHelper = {
  getEmailIdsForGivenStatus(students, filter, currentAcademicYear) {
    const emailIds = [];
    students.forEach((student) => {
      if (student.invoices) {
        student.invoices.forEach((inv) => {
          if (inv.type === filter.type && inv.academicYear === currentAcademicYear) {
            const invStatus = this.getInvoiceStatus(inv);
            if (invStatus.text === filter.status) {
              // console.log(inv);
              if (student.father.email && !emailIds.includes(student.father.email)) {
                emailIds.push(student.father.email);
              }
              if (student.mother.email && !emailIds.includes(student.mother.email)) {
                emailIds.push(student.mother.email);
              }
            }
          }
        });
      }
    });
    return emailIds;
  },

  getInvoiceStatus(invoice) {
    if (invoice) {
      let status = { text: 'Unpaid', type: 'bad' };
      const invoiceAmt = invoice.amount;
      let paidAmt = 0;
      let paymentOverdue = false;
      if (invoice.payments) {
        invoice.payments.forEach((payment) => {
          if (payment.status === 'Paid') {
            paidAmt += payment.amount;
          } else if (payment.status === 'Unpaid' && isAfter(new Date(), new Date(payment.dueDate))) {
            paymentOverdue = true;
          }
        });
      }

      if (!paymentOverdue) {
        if (invoice.paymentOption === 'Waiver') {
          status = { text: 'Waiver', type: 'neutral' };
        } else if (paidAmt > 0 && paidAmt < invoiceAmt) {
          status = { text: 'Part paid', type: 'neutral' };
        } else if (paidAmt >= invoiceAmt) {
          status = { text: 'Paid', type: 'good' };
        }
      } else if (invoice.paymentOption === 'Installment') {
        status = { text: 'Inst. unpaid', type: 'bad' };
      }
      return status;
    }
    return { text: 'No Invoice', type: 'bad' };
  }
};

export default InvoiceHelper;

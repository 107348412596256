import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResetPasswordForm from '../forms/ResetPasswordForm';

class ResetPasswordPanel extends Component {

  render() {
    return (
      <section>
        <ResetPasswordForm />
      </section>
    );
  }
}

export default ResetPasswordPanel;

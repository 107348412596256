import React from 'react';
import PropTypes from 'prop-types';

import InvoiceAndPaymentList from '../visualization/InvoiceAndPaymentList';

const InvoiceAndPaymentPanel = ({ type }) => {
  return (
    <section>
      <InvoiceAndPaymentList invType={type} />
    </section>
  );
};

InvoiceAndPaymentPanel.propTypes = {
  type: PropTypes.string
};

export default InvoiceAndPaymentPanel;

import React from 'react';
import { Router } from 'director/build/director';
import { createRoot } from 'react-dom/client';

import App from './components/App';

import HomePanel from './components/panel/HomePanel';
import LoginPanel from './components/panel/LoginPanel';
import DashboardPanel from './components/panel/DashboardPanel';
import ResetPasswordPanel from './components/panel/ResetPasswordPanel';
import SignupPanel from './components/panel/SignupPanel';
import ResetPasswordSuccessPanel from './components/panel/ResetPasswordSuccessPanel';
import ChangePasswordPanel from './components/panel/ChangePasswordPanel';
import AttendanceOptionsPanel from './components/panel/AttendanceOptionsPanel';
import StudentAttendancePanel from './components/panel/StudentAttendancePanel';
import TeachersAttendancePanel from './components/panel/TeachersAttendancePanel';
import StudentListPanel from './components/panel/StudentListPanel';
import ClassListPanel from './components/panel/ClassListPanel';
import UserListPanel from './components/panel/UserListPanel';
import SettingsPanel from './components/panel/SettingsPanel';
import InvoiceAndPaymentPanel from './components/panel/InvoiceAndPaymentPanel';
import EmailTemplatesPanel from './components/panel/EmailTemplatesPanel';
import CollaboratePanel from './components/panel/CollaboratePanel';
import RewardsPanel from './components/panel/RewardsPanel';
import UserRolesPanel from './components/panel/UserRolesPanel';
import PurchaseOrdersPanel from './components/panel/AssetsPanel';
// import AssetsPanel from './components/panel/PurchaseOrdersPanel';
// import Panel404 from './components/panel/Panel404';
import RedirectPanel from './components/panel/RedirectTenantPanel';

export const routes = {
  // '/': {
  //   on: () => {
  //     if (window.location.pathname === '/') {
  //       setTimeout(() => {
  //         router.setRoute('/home');
  //       }, 500);
  //     }
  //   }
  // },
  '/': {
    on: () => {
      renderComponent({
        Component: RedirectPanel,
        params: { redirectRoute: '/home' }
      });
    }
  },
  '/:tenantId/home': {
    nav: true,
    label: 'Home',
    on: (tenantId) => {
      renderComponent({
        Component: HomePanel,
        params: { tenantId }
      });
    }
  },
  '/:tenantId/dashboard': {
    nav: true,
    label: 'Dashboard',
    on: (tenantId) => {
      renderComponent({
        Component: DashboardPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/login': {
    nav: false,
    on: () => {
      renderComponent({
        Component: LoginPanel
      });
    }
  },
  '/reset-password': {
    nav: false,
    on: () => {
      renderComponent({
        Component: ResetPasswordPanel
      });
    }
  },
  '/signup': {
    nav: false,
    on: () => {
      renderComponent({
        Component: SignupPanel
      });
    }
  },
  '/reset-password-success': {
    nav: false,
    on: () => {
      renderComponent({
        Component: ResetPasswordSuccessPanel
      });
    }
  },
  '/:tenantId/change-password': {
    nav: false,
    label: 'Change password',
    on: (tenantId) => {
      renderComponent({
        Component: ChangePasswordPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/attendance': {
    nav: true,
    label: 'Attendance',
    on: (tenantId) => {
      renderComponent({
        Component: AttendanceOptionsPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/attendance/mark-attendance/:selectedClass': {
    nav: false,
    label: 'Mark attendance',
    on: (tenantId, selectedClass) => {
      renderComponent({
        Component: StudentAttendancePanel,
        params: { mode: 'mark-attendance', tenantId, selectedClass },
        breadcrumbs: [`/${tenantId}/home`, `/${tenantId}/attendance`]
      });
    }
  },
  '/:tenantId/attendance/report/:selectedClass': {
    nav: false,
    label: 'Student attendance report',
    on: (tenantId, selectedClass) => {
      renderComponent({
        Component: StudentAttendancePanel,
        params: { mode: 'report', tenantId, selectedClass },
        breadcrumbs: [`/${tenantId}/home`, `/${tenantId}/attendance`]
      });
    }
  },
  '/:tenantId/attendance/mark-teachers-attendance': {
    nav: false,
    label: 'Mark teacher attendance',
    on: (tenantId) => {
      renderComponent({
        Component: TeachersAttendancePanel,
        params: { mode: 'mark-attendance', tenantId },
        breadcrumbs: [`/${tenantId}/home`, `/${tenantId}/attendance`]
      });
    }
  },
  '/:tenantId/attendance/teachers-attendance-report': {
    nav: false,
    label: 'Teacher attendance report',
    on: (tenantId) => {
      renderComponent({
        Component: TeachersAttendancePanel,
        params: { mode: 'report', tenantId },
        breadcrumbs: [`/${tenantId}/home`, `/${tenantId}/attendance`]
      });
    }
  },
  '/:tenantId/students': {
    nav: true,
    label: 'Students',
    on: (tenantId) => {
      renderComponent({
        Component: StudentListPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/rewards': {
    nav: true,
    label: 'Rewards',
    on: (tenantId) => {
      setTimeout(() => {
        router.setRoute(`/${tenantId}/rewards/All`);
      }, 500);
    }
  },
  '/:tenantId/rewards/:selectedClass': {
    label: 'Rewards',
    on: (tenantId, selectedClass) => {
      console.log('/:tenantId/rewards/:selectedClass', tenantId, selectedClass);
      renderComponent({
        Component: RewardsPanel,
        params: { tenantId, selectedClass },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/classes': {
    nav: true,
    label: 'Classes',
    on: (tenantId) => {
      renderComponent({
        Component: ClassListPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/users': {
    nav: true,
    label: 'Users',
    on: (tenantId) => {
      renderComponent({
        Component: UserListPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/user-roles': {
    nav: true,
    label: 'User roles',
    on: (tenantId) => {
      renderComponent({
        Component: UserRolesPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/purchase-orders': {
    nav: true,
    label: 'Purchase orders',
    on: (tenantId) => {
      renderComponent({
        Component: PurchaseOrdersPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/assets': {
    nav: true,
    label: 'Assets',
    on: (tenantId) => {
      renderComponent({
        Component: PurchaseOrdersPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/settings': {
    nav: true,
    label: 'Settings',
    on: (tenantId) => {
      renderComponent({
        Component: SettingsPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/payments/:type': {
    nav: true,
    label: 'Payments',
    on: (tenantId, type) => {
      renderComponent({
        Component: InvoiceAndPaymentPanel,
        params: { tenantId, type },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/:tenantId/email-templates': {
    nav: true,
    label: 'Email templates',
    on: (tenantId) => {
      renderComponent({
        Component: EmailTemplatesPanel,
        params: { tenantId },
        breadcrumbs: [`/${tenantId}/home`]
      });
    }
  },
  '/collaborate': () => {
    renderComponent({ Component: CollaboratePanel });
  }
};

const router = Router(routes).configure({ html5history: true });
router.configureRoutes = () => {
  router.init(window.initialRoute.replace(/amp;/g, ''));
};

router.routeTo = (route) => {
  if (window.onpopstate) {
    router.setRoute(route);
  } else {
    let interval = setInterval(() => {
      if (window.onpopstate) {
        clearInterval(interval);
        interval = null;
        router.setRoute(route);
      }
    }, 50);
  }
};

const resolveRoute = (routePath, params) => {
  if (!routePath.includes(':')) {
    return routePath;
  }
  const splitRoutePath = routePath.split('/');
  splitRoutePath.forEach((p, i) => {
    if (p.startsWith(':')) {
      splitRoutePath[i] = params[p.substring(1)];
    }
  });
  return splitRoutePath.join('/');
};

const getResolvedRoutes = (params) => {
  const resolvedRoutes = {};
  if (params) {
    Object.entries(routes).forEach(([key, value]) => {
      const resRoute = resolveRoute(key, params);
      if (resRoute !== key) {
        resolvedRoutes[resRoute] = value;
      }
    });
    return resolvedRoutes;
  }
  return routes;
};

const container = document.getElementById('mbl-main-div');
const root = createRoot(container);

const renderComponent = ({ Component, params, breadcrumbs }) => {
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};
  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  // inject query params as props
  const props = {
    locale: getBrowserLocale(),
    ...queryParams,
    ...params
  };

  if (Component) {
    root.render(
      <App
        breadcrumbs={breadcrumbs}
        routes={getResolvedRoutes(params)}
        resolveRoute={resolveRoute}
        router={{
          setRoute: router.routeTo
        }}
        params={{ ...params, ...queryParams }}
        {...props}
      >
        <Component {...{ ...params, ...queryParams }} />
      </App>
    );
  }
};

function getBrowserLocale() {
  const locale = window.navigator.userLanguage || window.navigator.language;
  // if (locale !== 'pt-BR' || locale !== 'zh-CN' || locale !== 'zh-TW') {
  // locale = locale.split('-')[0];
  // }
  return locale.split('-')[0];
}

export default router;

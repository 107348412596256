import React, { useState } from 'react';
import { TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';

import { useLogin } from '../hooks/useLogin';

const ResetPasswordForm = () => {
  const {
    resetPassword, resetPasswordSuccess, loading, error
  } = useLogin();
  const [username, setUsername] = useState('');

  const handleKeyUp = (evt) => {
    if (evt.key === 'Enter') {
      resetPassword(username);
    }
  };

  const showLoading = () => {
    if (loading) {
      return (
        <Loading />
      );
    }
    return null;
  };

  const getMessage = () => {
    if (resetPasswordSuccess) {
      return 'An email has been sent to your given email id with instructions to reset your password.';
    } if (error) {
      return error.message;
    }
    return null;
  };

  const loginDisabled = (username === '');
  return (
    <div className='mb-login'>
    	{showLoading()}
      <div className='login-form'>
      	<span className='error-message'>{getMessage()}</span>
        <TextField
          autoFocus={true}
          label='Email'
          required={true}
          maxLength={75}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyUp={handleKeyUp.bind(this)}
        />
        <div className='vertical-spacer-20' />
        <div className='vertical-spacer-20' />
        <div className='button-strip'>
        	<input type='button' value='Reset Password' tabIndex='0' disabled={loginDisabled} className={loginDisabled ? 'button-disabled mbl-button primary-button' : 'mbl-button primary-button'} onClick={() => resetPassword(username)} />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;

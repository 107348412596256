import { useContext, useState, useEffect } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useAssetData = () => {
  const appContext = useContext(AppContext);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAssets = async (startDate, endDate) => {
    if (!appContext.tenant._id && !startDate && !endDate) {
      return;
    }
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(
        `/api/ui/${appContext.tenant._id}/assets/v1/assets`
      );
      const jsonData = await dataService.handleResponse(resp);
      setAssets(jsonData);
    } catch (ex) {
      console.log(':::::Exception fetching assets', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const saveAsset = async (mode, asset, callback) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      if (mode === 'add') {
        resp = await dataService.post(`/api/ui/${appContext.tenant._id}/assets/v1/assets`, asset);
      } else {
        resp = await dataService.put(`/api/ui/${appContext.tenant._id}/assets/v1/assets/${asset._id}`, asset);
      }
      const jsonData = await dataService.handleResponse(resp);
      await fetchAssets();
      setError(null);
      callback(jsonData.data);
    } catch (ex) {
      console.log(':::::Exception saving email template', mode, ex);
      setLoading(false);
      setError(resp);
      throw Error(ex);
    }
    setLoading(false);
  };

  const deleteAsset = async (assetId) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.delete(`/api/ui/${appContext.tenant._id}/assets/v1/assets/${assetId}`);
      await dataService.handleResponse(resp);
      await fetchAssets();
    } catch (ex) {
      console.log(':::::Exception deleting asset', ex);
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAssets();
    return () => {
      setAssets([]);
    };
  }, []);

  return {
    assets,
    loading,
    error,
    fetchAssets,
    saveAsset,
    deleteAsset
  };
};

import React from 'react';

const ResetPasswordSuccessPanel = () => {
  return (
    <section>
      <div style={{ position: 'absolute', top: '100px', color: 'red' }}>Your password has been reset successfully. You will receive an email with your new password.</div>
    </section>
  );
};

export default ResetPasswordSuccessPanel;

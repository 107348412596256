export const QURAN_DIRECTORY = [
  {
    surahNumber: 1,
    surahName: 'Al-Fatiha',
    totalAayats: 7,
    surahInSipara: [1]
  },
  {
    surahNumber: 2,
    surahName: 'Al-Baqarah',
    totalAayats: 286,
    surahInSipara: [1, 2, 3]
  },
  {
    surahNumber: 3,
    surahName: 'Ale-Imran',
    totalAayats: 200,
    surahInSipara: [3, 4]
  },
  {
    surahNumber: 4,
    surahName: 'An-Nisa',
    totalAayats: 176,
    surahInSipara: [4, 5, 6]
  },
  {
    surahNumber: 5,
    surahName: "Al-Ma'ida",
    totalAayats: 120,
    surahInSipara: [6, 7]
  },
  {
    surahNumber: 6,
    surahName: "Al-An'am",
    totalAayats: 165,
    surahInSipara: [7, 8]
  },
  {
    surahNumber: 7,
    surahName: 'Al-Araf',
    totalAayats: 206,
    surahInSipara: [8, 9]
  },
  {
    surahNumber: 8,
    surahName: 'Al-Anfal',
    totalAayats: 75,
    surahInSipara: [9, 10]
  },
  {
    surahNumber: 9,
    surahName: 'Al-Taubah',
    totalAayats: 129,
    surahInSipara: [10, 11]
  },
  {
    surahNumber: 10,
    surahName: 'Yunus',
    totalAayats: 109,
    surahInSipara: [11]
  },
  {
    surahNumber: 11,
    surahName: 'Hud',
    totalAayats: 123,
    surahInSipara: [11, 12]
  },
  {
    surahNumber: 12,
    surahName: 'Yusuf ',
    totalAayats: 111,
    surahInSipara: [12, 13]
  },
  {
    surahNumber: 13,
    surahName: "Ar-Ra'd",
    totalAayats: 43,
    surahInSipara: [13]
  },
  {
    surahNumber: 14,
    surahName: 'Ibrahim ',
    totalAayats: 52,
    surahInSipara: [13]
  },
  {
    surahNumber: 15,
    surahName: 'Al-Hijr',
    totalAayats: 99,
    surahInSipara: [13, 14]
  },
  {
    surahNumber: 16,
    surahName: 'An-Nahl',
    totalAayats: 128,
    surahInSipara: [14]
  },
  {
    surahNumber: 17,
    surahName: 'Al-Isra',
    totalAayats: 111,
    surahInSipara: [15]
  },
  {
    surahNumber: 18,
    surahName: 'Al-Kahf',
    totalAayats: 110,
    surahInSipara: [15, 16]
  },
  {
    surahNumber: 19,
    surahName: 'Maryam ',
    totalAayats: 98,
    surahInSipara: [16]
  },
  {
    surahNumber: 20,
    surahName: 'Ta-Ha ',
    totalAayats: 135,
    surahInSipara: [16]
  },
  {
    surahNumber: 21,
    surahName: 'Al-Anbiya',
    totalAayats: 112,
    surahInSipara: [17]
  },
  {
    surahNumber: 22,
    surahName: 'Al-Hajj',
    totalAayats: 78,
    surahInSipara: [17]
  },
  {
    surahNumber: 23,
    surahName: "Al-Mu'minun",
    totalAayats: 118,
    surahInSipara: [18]
  },
  {
    surahNumber: 24,
    surahName: 'An-Noor',
    totalAayats: 64,
    surahInSipara: [18]
  },
  {
    surahNumber: 25,
    surahName: 'Al-Furqan',
    totalAayats: 77,
    surahInSipara: [18, 19]
  },
  {
    surahNumber: 26,
    surahName: 'Ash-Shuara',
    totalAayats: 227,
    surahInSipara: [19]
  },
  {
    surahNumber: 27,
    surahName: 'An-Naml',
    totalAayats: 93,
    surahInSipara: [19, 20]
  },
  {
    surahNumber: 28,
    surahName: 'Al-Qasas',
    totalAayats: 88,
    surahInSipara: [20]
  },
  {
    surahNumber: 29,
    surahName: 'Al-Ankaboot ',
    totalAayats: 69,
    surahInSipara: [20, 21]
  },
  {
    surahNumber: 30,
    surahName: 'Ar-Room',
    totalAayats: 60,
    surahInSipara: [21]
  },
  {
    surahNumber: 31,
    surahName: 'Luqman',
    totalAayats: 34,
    surahInSipara: [21]
  },
  {
    surahNumber: 32,
    surahName: 'As-Sajda',
    totalAayats: 30,
    surahInSipara: [21]
  },
  {
    surahNumber: 33,
    surahName: 'Al-Ahzab',
    totalAayats: 73,
    surahInSipara: [21, 22]
  },
  {
    surahNumber: 34,
    surahName: 'Saba ',
    totalAayats: 54,
    surahInSipara: [22]
  },
  {
    surahNumber: 35,
    surahName: 'Fatir ',
    totalAayats: 45,
    surahInSipara: [22]
  },
  {
    surahNumber: 36,
    surahName: 'Ya-Seen',
    totalAayats: 83,
    surahInSipara: [22, 23]
  },
  {
    surahNumber: 37,
    surahName: 'As-Saffat',
    totalAayats: 182,
    surahInSipara: [23]
  },
  {
    surahNumber: 38,
    surahName: 'Saad ',
    totalAayats: 88,
    surahInSipara: [23]
  },
  {
    surahNumber: 39,
    surahName: 'Az-Zumar',
    totalAayats: 75,
    surahInSipara: [23, 24]
  },
  {
    surahNumber: 40,
    surahName: 'Ghafir ',
    totalAayats: 85,
    surahInSipara: [24]
  },
  {
    surahNumber: 41,
    surahName: 'Fussilat ',
    totalAayats: 54,
    surahInSipara: [24, 25]
  },
  {
    surahNumber: 42,
    surahName: 'Ash-Shura',
    totalAayats: 53,
    surahInSipara: [25]
  },
  {
    surahNumber: 43,
    surahName: 'Az-Zukhruf',
    totalAayats: 89,
    surahInSipara: [25]
  },
  {
    surahNumber: 44,
    surahName: 'Ad-Dukhan',
    totalAayats: 59,
    surahInSipara: [25]
  },
  {
    surahNumber: 45,
    surahName: 'Al-Jathiya',
    totalAayats: 37,
    surahInSipara: [25]
  },
  {
    surahNumber: 46,
    surahName: 'Al-Ahqaf',
    totalAayats: 35,
    surahInSipara: [26]
  },
  {
    surahNumber: 47,
    surahName: 'Muhammad ',
    totalAayats: 38,
    surahInSipara: [26]
  },
  {
    surahNumber: 48,
    surahName: 'Al-Fath',
    totalAayats: 29,
    surahInSipara: [26]
  },
  {
    surahNumber: 49,
    surahName: 'Al-Hujraat',
    totalAayats: 18,
    surahInSipara: [26]
  },
  {
    surahNumber: 50,
    surahName: 'Qaf ',
    totalAayats: 45,
    surahInSipara: [26]
  },
  {
    surahNumber: 51,
    surahName: 'Adh-Dhariyat',
    totalAayats: 60,
    surahInSipara: [26, 27]
  },
  {
    surahNumber: 52,
    surahName: 'At-Tur',
    totalAayats: 49,
    surahInSipara: [27]
  },
  {
    surahNumber: 53,
    surahName: 'An-Najm',
    totalAayats: 62,
    surahInSipara: [27]
  },
  {
    surahNumber: 54,
    surahName: 'Al-Qamar',
    totalAayats: 55,
    surahInSipara: [27]
  },
  {
    surahNumber: 55,
    surahName: 'Ar-Rahman',
    totalAayats: 78,
    surahInSipara: [27]
  },
  {
    surahNumber: 56,
    surahName: 'Al-Waqia',
    totalAayats: 96,
    surahInSipara: [27]
  },
  {
    surahNumber: 57,
    surahName: 'Al-Hadid',
    totalAayats: 29,
    surahInSipara: [27]
  },
  {
    surahNumber: 58,
    surahName: 'Al-Mujadila',
    totalAayats: 22,
    surahInSipara: [28]
  },
  {
    surahNumber: 59,
    surahName: 'Al-Hashr',
    totalAayats: 24,
    surahInSipara: [28]
  },
  {
    surahNumber: 60,
    surahName: 'Al-Mumtahana',
    totalAayats: 13,
    surahInSipara: [28]
  },
  {
    surahNumber: 61,
    surahName: 'As-Saff',
    totalAayats: 14,
    surahInSipara: [28]
  },
  {
    surahNumber: 62,
    surahName: 'Al-Jumua',
    totalAayats: 11,
    surahInSipara: [28]
  },
  {
    surahNumber: 63,
    surahName: 'Al-Munafiqoon',
    totalAayats: 11,
    surahInSipara: [28]
  },
  {
    surahNumber: 64,
    surahName: 'At-Taghabun',
    totalAayats: 18,
    surahInSipara: [28]
  },
  {
    surahNumber: 65,
    surahName: 'At-Talaq',
    totalAayats: 12,
    surahInSipara: [28]
  },
  {
    surahNumber: 66,
    surahName: 'At-Tahrim',
    totalAayats: 12,
    surahInSipara: [28]
  },
  {
    surahNumber: 67,
    surahName: 'Al-Mulk',
    totalAayats: 30,
    surahInSipara: [29]
  },
  {
    surahNumber: 68,
    surahName: 'Al-Qalam',
    totalAayats: 52,
    surahInSipara: [29]
  },
  {
    surahNumber: 69,
    surahName: 'Al-Haaqqa',
    totalAayats: 52,
    surahInSipara: [29]
  },
  {
    surahNumber: 70,
    surahName: 'Al-Maarij',
    totalAayats: 44,
    surahInSipara: [29]
  },
  {
    surahNumber: 71,
    surahName: 'Nooh ',
    totalAayats: 28,
    surahInSipara: [29]
  },
  {
    surahNumber: 72,
    surahName: 'Al-Jinn',
    totalAayats: 28,
    surahInSipara: [29]
  },
  {
    surahNumber: 73,
    surahName: 'Al-Muzzammil',
    totalAayats: 20,
    surahInSipara: [29]
  },
  {
    surahNumber: 74,
    surahName: 'Al-Muddaththir',
    totalAayats: 56,
    surahInSipara: [29]
  },
  {
    surahNumber: 75,
    surahName: 'Al-Qiyama',
    totalAayats: 40,
    surahInSipara: [29]
  },
  {
    surahNumber: 76,
    surahName: 'Al-Insan',
    totalAayats: 31,
    surahInSipara: [29]
  },
  {
    surahNumber: 77,
    surahName: 'Al-Mursalat',
    totalAayats: 50,
    surahInSipara: [29]
  },
  {
    surahNumber: 78,
    surahName: 'An-Naba',
    totalAayats: 40,
    surahInSipara: [30]
  },
  {
    surahNumber: 79,
    surahName: 'An-Naziat',
    totalAayats: 46,
    surahInSipara: [30]
  },
  {
    surahNumber: 80,
    surahName: 'Abasa ',
    totalAayats: 42,
    surahInSipara: [30]
  },
  {
    surahNumber: 81,
    surahName: 'At-Takwir',
    totalAayats: 29,
    surahInSipara: [30]
  },
  {
    surahNumber: 82,
    surahName: 'Al-Infitar',
    totalAayats: 19,
    surahInSipara: [30]
  },
  {
    surahNumber: 83,
    surahName: 'Al-Mutaffifin',
    totalAayats: 36,
    surahInSipara: [30]
  },
  {
    surahNumber: 84,
    surahName: 'Al-Inshiqaq',
    totalAayats: 25,
    surahInSipara: [30]
  },
  {
    surahNumber: 85,
    surahName: 'Al-Burooj',
    totalAayats: 22,
    surahInSipara: [30]
  },
  {
    surahNumber: 86,
    surahName: 'At-Tariq',
    totalAayats: 17,
    surahInSipara: [30]
  },
  {
    surahNumber: 87,
    surahName: 'Al-Ala',
    totalAayats: 19,
    surahInSipara: [30]
  },
  {
    surahNumber: 88,
    surahName: 'Al-Ghashiya',
    totalAayats: 26,
    surahInSipara: [30]
  },
  {
    surahNumber: 89,
    surahName: 'Al-Fajr',
    totalAayats: 30,
    surahInSipara: [30]
  },
  {
    surahNumber: 90,
    surahName: 'Al-Balad',
    totalAayats: 20,
    surahInSipara: [30]
  },
  {
    surahNumber: 91,
    surahName: 'Ash-Shams',
    totalAayats: 15,
    surahInSipara: [30]
  },
  {
    surahNumber: 92,
    surahName: 'Al-Lail',
    totalAayats: 21,
    surahInSipara: [30]
  },
  {
    surahNumber: 93,
    surahName: 'Ad-Dhuha',
    totalAayats: 11,
    surahInSipara: [30]
  },
  {
    surahNumber: 94,
    surahName: 'Ash-Sharh',
    totalAayats: 8,
    surahInSipara: [30]
  },
  {
    surahNumber: 95,
    surahName: 'At-Tin',
    totalAayats: 8,
    surahInSipara: [30]
  },
  {
    surahNumber: 96,
    surahName: 'Al-Alaq',
    totalAayats: 19,
    surahInSipara: [30]
  },
  {
    surahNumber: 97,
    surahName: 'Al-Qadr',
    totalAayats: 5,
    surahInSipara: [30]
  },
  {
    surahNumber: 98,
    surahName: 'Al-Bayyina',
    totalAayats: 8,
    surahInSipara: [30]
  },
  {
    surahNumber: 99,
    surahName: 'Al-Zalzala',
    totalAayats: 8,
    surahInSipara: [30]
  },
  {
    surahNumber: 100,
    surahName: 'Al-Adiyaat',
    totalAayats: 11,
    surahInSipara: [30]
  },
  {
    surahNumber: 101,
    surahName: 'Al-Qaria',
    totalAayats: 11,
    surahInSipara: [30]
  },
  {
    surahNumber: 102,
    surahName: 'At-Takaathur',
    totalAayats: 8,
    surahInSipara: [30]
  },
  {
    surahNumber: 103,
    surahName: 'Al-Asr',
    totalAayats: 3,
    surahInSipara: [30]
  },
  {
    surahNumber: 104,
    surahName: 'Al-Humaza',
    totalAayats: 9,
    surahInSipara: [30]
  },
  {
    surahNumber: 105,
    surahName: 'Al-feel',
    totalAayats: 5,
    surahInSipara: [30]
  },
  {
    surahNumber: 106,
    surahName: 'Quraish ',
    totalAayats: 4,
    surahInSipara: [30]
  },
  {
    surahNumber: 107,
    surahName: 'Al-Maun',
    totalAayats: 7,
    surahInSipara: [30]
  },
  {
    surahNumber: 108,
    surahName: 'Al-Kawthar ',
    totalAayats: 3,
    surahInSipara: [30]
  },
  {
    surahNumber: 109,
    surahName: 'Al-Kafiroon',
    totalAayats: 6,
    surahInSipara: [30]
  },
  {
    surahNumber: 110,
    surahName: 'An-Nasr',
    totalAayats: 3,
    surahInSipara: [30]
  },
  {
    surahNumber: 111,
    surahName: 'Al-Masad',
    totalAayats: 5,
    surahInSipara: [30]
  },
  {
    surahNumber: 112,
    surahName: 'Al-Ikhlaas',
    totalAayats: 4,
    surahInSipara: [30]
  },
  {
    surahNumber: 113,
    surahName: 'Al-Falaq',
    totalAayats: 5,
    surahInSipara: [30]
  },
  {
    surahNumber: 114,
    surahName: 'An-Naas',
    totalAayats: 6,
    surahInSipara: [30]
  }
];
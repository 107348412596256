import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import { CommonTable as Table, Donut, ToggleButton } from '@aeblondon-uk/common-ui-components';

import { format } from 'date-fns';
import DashboardWidget from './dashboard-widget/DashboardWidget';
import HasPermissions from '../HasPermissions';
import MetricsHelper from '../../utils/PaymentMetricsHelper';
import { DATE_FORMAT_SHORT_MONTH_YEAR } from '../../utils/DateUtils';
import {
  VIEW_INVOICES_PERMISSION, VIEW_INVOICE_METRICS_PERMISSION, ADD_INVOICES_PERMISSION, EDIT_INVOICES_PERMISSION,
  DELETE_INVOICES_PERMISSION
} from '../../utils/permissions';
import { getCurrentAcademicYear } from '../../utils/AcademicYears';
import AppContext from '../AppContext';
import { useInvoiceAndPaymentData } from '../hooks/useInvoiceAndPaymentData';

const InvoiceAndPaymentMetrics = () => {
  const appContext = useContext(AppContext);
  const {
    fetchMetricData, metricData
  } = useInvoiceAndPaymentData();

  const [selectedYear, setSelectedYear] = useState('');
  const [expectedInvoiceCount, setExpectedInvoiceCount] = useState(0);
  const [invoiceType, setInvoiceType] = useState('Fees');
  const [monthCols, setMonthCols] = useState([]);
  const [incomeData, setIncomeData] = useState([]);

  const { invoices, years, monthlyBreakdown } = metricData ? { ...metricData } : {};

  useEffect(() => {
    const currentAcademicYear = getCurrentAcademicYear(appContext.settings);
    setSelectedYear(currentAcademicYear?.academicYear);
  }, [appContext.settings]);

  useEffect(() => {
    if (selectedYear) {
      fetchMetricData(selectedYear);
    }
  }, [selectedYear]);

  useEffect(() => {
    let invoicesCount = 0;
    if (invoices) {
      if (invoiceType === 'All') {
        invoicesCount = invoices.length;
      } else {
        invoicesCount = invoices.filter((inv) => inv.type === invoiceType).length;
      }
    }

    setExpectedInvoiceCount(invoicesCount);
  }, [invoices, invoiceType]);

  useEffect(() => {
    const months = [];
    if (monthlyBreakdown) {
      monthlyBreakdown.expectedIncome.forEach((item) => {
        if (!months.includes(item.month)) {
          months.push(item.month);
        }
      });
    }

    const cols = [{
      width: 150, title: 'Income', dataKey: 'col1', key: 'col1', resizable: true, frozen: true
    }];
    // generate columns for each months
    months.forEach((item) => {
      cols.push({
        width: 75, title: format(new Date(item), DATE_FORMAT_SHORT_MONTH_YEAR), dataKey: item, key: item, resizable: true
      });
    });
    cols.push({
      width: 125, title: 'Total', dataKey: 'total', key: 'total', resizable: true
    });
    setMonthCols(cols);

    const data = [];
    const expectedData = {};
    let expectedTotal = 0;
    if (monthlyBreakdown) {
      monthlyBreakdown.expectedIncome.forEach((item) => {
        if (!item.type || item.type === invoiceType) {
          expectedData[item.month] = item.expectedIncome;
          expectedTotal += item.expectedIncome;
        } else if (invoiceType === 'All') {
          if (expectedData[item.month]) {
            expectedData[item.month] += item.expectedIncome;
          } else {
            expectedData[item.month] = item.expectedIncome;
          }
          expectedTotal += item.expectedIncome;
        }
      });
    }
    expectedData.total = expectedTotal;
    expectedData.col1 = 'Expected';
    data.push(expectedData);

    const actualData = {};
    let actualTotal = 0;
    if (monthlyBreakdown) {
      monthlyBreakdown.actualIncome.forEach((item) => {
        if (!item.type || item.type === invoiceType) {
          actualData[item.month] = item.actualIncome;
          actualTotal += item.actualIncome;
        } else if (invoiceType === 'All') {
          if (actualData[item.month]) {
            actualData[item.month] += item.actualIncome;
          } else {
            actualData[item.month] = item.actualIncome;
          }
          actualTotal += item.actualIncome;
        }
      });
    }
    actualData.total = actualTotal;
    actualData.col1 = 'Actual';
    data.push(actualData);

    const outstandingData = {};
    // calculate outstanding montly income
    if (monthlyBreakdown) {
      Object.keys(expectedData).forEach((key) => {
        outstandingData[key] = expectedData[key] - (actualData[key] || 0);
      });
    }
    outstandingData.col1 = 'Outstanding';
    data.push(outstandingData);

    setIncomeData(data);
  }, [monthlyBreakdown, invoiceType]);

  const yearSelector = useCallback(() => {
    if (years && years.length > 0) {
      const yearList = [...years];
      yearList.sort();
      // keep only the last 10 years and remove the previous years if any
      yearList.splice(0, yearList.length - 10);
      const yearsArr = [];
      yearList.forEach((year) => {
        yearsArr.push(
          { name: year, label: year, pressed: year === selectedYear }
        );
      });

      return (
        <ToggleButton
          onClick={(year) => setSelectedYear(year)}
          items={yearsArr}
        />
      );
    }
    return null;
  }, [years]);

  const getTitleRow = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {yearSelector()}
        <ToggleButton
          onClick={(invType) => {
            setInvoiceType(invType);
          }}
          items={[
            { name: 'All', label: 'All', pressed: invoiceType === 'All' },
            { name: 'Fees', label: 'Fees', pressed: invoiceType === 'Fees' },
            { name: 'Niyaz', label: 'Niyaz', pressed: invoiceType === 'Niyaz' }]}
        />
      </div>
    );
  };

  const renderOverallPaymentMetrics = (metrics) => {
    return (
      <div className='overall-payment-metrics'>
        <div className='fees-paid-metrics'>
          {renderPaymentMetrics(metrics)}
        </div>
      </div>
    );
  };

  const converToPercent = (val, expected) => {
    return !val ? '0%' : `${parseFloat((val * 100) / expected).toFixed(2)}%`;
  };

  const renderPaymentMetrics = (metrics) => {
    const feesMetrics = metrics.feesMetrics;

    const paymentOptionMetrics = metrics.paymentOptionMetrics;
    const totalInvoices = paymentOptionMetrics['Full Payment'] + paymentOptionMetrics.Installment;

    const paymentBreakdown = metrics.paymentBreakdownMetrics;
    const totalIncome = paymentBreakdown.fees + paymentBreakdown.niyaz;

    const modeAmt = metrics.paymentModeAmountMetrics;
    const totalPaid = modeAmt.Online + modeAmt.Cheque + modeAmt.Cash;
    const feesStatusData = [
      {
        value: feesMetrics.Paid,
        label: feesMetrics.Paid,
        tooltip: converToPercent(feesMetrics.Paid, expectedInvoiceCount),
        color: '#24a335',
        legend: 'Paid'
      },
      {
        value: feesMetrics['Part paid'],
        label: feesMetrics['Part paid'],
        tooltip: converToPercent(feesMetrics['Part paid'], expectedInvoiceCount),
        color: '#f1c21b',
        legend: 'Part paid'
      },
      {
        value: feesMetrics.Unpaid,
        label: feesMetrics.Unpaid,
        tooltip: converToPercent(feesMetrics.Unpaid, expectedInvoiceCount),
        color: '#d91414',
        legend: 'Unpaid'
      },
      {
        value: feesMetrics['Inst. unpaid'],
        label: feesMetrics['Inst. unpaid'],
        tooltip: converToPercent(feesMetrics['Inst. unpaid'], expectedInvoiceCount),
        color: '#e09c1d',
        legend: 'Instalment unpaid'
      },
      {
        value: feesMetrics.Waiver,
        label: feesMetrics.Waiver,
        tooltip: converToPercent(feesMetrics.Waiver, expectedInvoiceCount),
        color: 'purple',
        legend: 'Waiver'
      }
    ];

    const paymentModeData = [
      {
        value: modeAmt.Online,
        label: modeAmt.Online,
        tooltip: converToPercent(modeAmt.Online, totalPaid),
        color: '#8a3ffc',
        legend: 'Online'
      },
      {
        value: modeAmt.Cheque,
        label: modeAmt.Cheque,
        tooltip: converToPercent(modeAmt.Cheque, totalPaid),
        color: '#f1c21b',
        legend: 'Cheque'
      },
      {
        value: modeAmt.Cash,
        label: modeAmt.Cash,
        tooltip: converToPercent(modeAmt.Cash, totalPaid),
        color: '#750e13',
        legend: 'Cash'
      }
    ];

    const paymentOptionData = [
      {
        value: paymentOptionMetrics['Full Payment'],
        label: paymentOptionMetrics['Full Payment'],
        tooltip: converToPercent(paymentOptionMetrics['Full Payment'], totalInvoices),
        color: '#0043ce',
        legend: 'Full payment'
      },
      {
        value: paymentOptionMetrics.Installment,
        label: paymentOptionMetrics.Installment,
        tooltip: converToPercent(paymentOptionMetrics.Installment, totalInvoices),
        color: '#fa4d56',
        legend: 'Instalment'
      }
    ];

    const paymentTypeData = [
      {
        value: paymentBreakdown.fees,
        label: paymentBreakdown.fees,
        tooltip: converToPercent(paymentBreakdown.fees, totalIncome),
        color: '#4589ff',
        legend: 'Fees'
      },
      {
        value: paymentBreakdown.niyaz,
        label: paymentBreakdown.niyaz,
        tooltip: converToPercent(paymentBreakdown.niyaz, totalIncome),
        color: '#750e13',
        legend: 'Niyaz'
      }
    ];

    const width = 225;
    const height = 225;
    const minViewportSize = Math.min(width, height);
    // This sets the radius of the pie chart to fit within
    // the current window size, with some additional padding
    const radius = (minViewportSize * 0.9) / 2;

    return (
      <div className='chart-container'>
        <DashboardWidget title='Payment status'>
          <Donut
            width={width}
            height={height}
            innerRadius={radius * 0.50}
            outerRadius={radius}
            cornerRadius={2}
            padAngle={0.01}
            data={feesStatusData}
          />
        </DashboardWidget>
        <DashboardWidget title='Payment option'>
          <Donut
            width={width}
            height={height}
            innerRadius={radius * 0.50}
            outerRadius={radius}
            cornerRadius={2}
            padAngle={0.01}
            data={paymentOptionData}
          />
        </DashboardWidget>
        <DashboardWidget title='Payment mode'>
          <Donut
            width={width}
            height={height}
            innerRadius={radius * 0.50}
            outerRadius={radius}
            cornerRadius={2}
            padAngle={0.01}
            labelPrefix='£'
            data={paymentModeData}
          />
        </DashboardWidget>
        <DashboardWidget title='Payment type'>
          <Donut
            width={width}
            height={height}
            innerRadius={radius * 0.50}
            outerRadius={radius}
            cornerRadius={2}
            padAngle={0.01}
            labelPrefix='£'
            data={paymentTypeData}
          />
        </DashboardWidget>
      </div>
    );
  };

  const renderMothlyBreakdown = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'col1') {
        return cell.rowData[cell.column.key];
      }
      const val = cell.rowData[cell.column.key];
      return <div>{val ? `£${val}` : 0}</div>;
    };

    return (
      <Table
        fixed
        rowKey='col1'
        rowHeight={32}
        components={{ TableCell }}
        maxHeight={175}
        columns={monthCols}
        data={incomeData}
      />
    );
  };

  const metrics = MetricsHelper.getPaymentMetrics(invoices, invoiceType);
  return (
    <HasPermissions
      permissions={[
        VIEW_INVOICES_PERMISSION, VIEW_INVOICE_METRICS_PERMISSION, ADD_INVOICES_PERMISSION, EDIT_INVOICES_PERMISSION,
        DELETE_INVOICES_PERMISSION
      ]}
    >
      <div className='mbl--payment-metrics'>
        {getTitleRow()}
        {renderOverallPaymentMetrics(metrics)}
        <br />
        {renderMothlyBreakdown()}
      </div>
    </HasPermissions>
  );
};

export default InvoiceAndPaymentMetrics;

import React, {
  useContext, useRef, useMemo, useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { DropDown, TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
// import Notification from '../visualization/Notification';
import Footer from '../visualization/Footer';
import InvoiceHelper from '../../utils/InvoiceHelper';
import { getCurrentAcademicYear } from '../../utils/AcademicYears';

import { useForm } from '../hooks/useForm';
import AppContext from '../AppContext';
import { useEmailTemplateData } from '../hooks/useEmailTemplateData';

const config = {
  uploader: {
    insertImageAsBase64URI: true
  },
  height: '400px'
};

const EMAIL_DATA = {
  to: '',
  subject: '',
  bcc: ''
};

const PaymentEmailForm = ({
  students, teachersEmail, onCancel, onSave, isLoading, showBcc = true
}) => {
  const appContext = useContext(AppContext);
  const { templates } = useEmailTemplateData();
  const [fields, handleFieldChange] = useForm(EMAIL_DATA);
  const [content, setContent] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('SELECT_EMAIL_TEMPLATE');
  const [bcc, setBcc] = useState('none');
  const [includeTeachers, setIncludeTeachers] = useState(false);
  const editor = useRef(null);

  useEffect(() => {
    const emailIds = [];
    if (!showBcc) {
      students.forEach((student) => {
        if (student.father?.email && !emailIds.includes(student.father.email)) {
          emailIds.push(student.father.email);
        }
        if (student.mother?.email && !emailIds.includes(student.mother.email)) {
          emailIds.push(student.mother.email);
        }
      });

      handleFieldChange('bcc', emailIds.join(','));
    }
  }, [students]);

  const currentAcademicYear = useMemo(() => {
    return getCurrentAcademicYear(appContext?.settings)?.academicYear;
  }, [appContext.settings]);

  const showLoading = () => {
    if (isLoading) {
      return (<Loading />);
    }
    return null;
  };

  const getTemplateList = () => {
    const options = [
      <option key='SELECT_EMAIL_TEMPLATE' value='SELECT_EMAIL_TEMPLATE'>----- Select Email Template -----</option>
    ];
    templates.forEach((temp) => {
      options.push(
        <option key={temp._id} value={temp._id}>{temp.name}</option>
      );
    });
    return options;
  };

  const isSaveDisabled = useMemo(() => {
    if (!fields || !fields.to || selectedTemplate === 'SELECT_EMAIL_TEMPLATE' || !fields.subject) {
      return true;
    }
    return false;
  }, [fields, selectedTemplate]);

  const renderForm = () => {
    return (
      <>
        <fieldset className='student-form__section'>
          <div className='row one-two'>
            <DropDown
              label='Email templates'
              value={selectedTemplate}
              onChange={(e) => {
                const template = templates.find((temp) => temp._id === e.target.value);
                console.log(template);
                setSelectedTemplate(e.target.value);
                handleFieldChange('subject', template.subject);
                setContent(template.content);
              }}
            >
              {getTemplateList()}
            </DropDown>
          </div>
          <div className='row one-two'>
            <TextField
              label='To'
              placeholder='Add comma separated list without spaces. Example: abc@yahoo.com,def@gmail.com'
              required={true}
              maxLength={5000}
              value={fields.to}
              onChange={(e) => handleFieldChange('to', e.target.value)}
            />
          </div>
          {
            !showBcc ?
            <div style={{ marginBottom: '1rem' }}>Emails will be sent to the selected students visible in the list</div> :
              null
          }
          {
            showBcc ? (
              <div className='row one-two'>
                <DropDown
                  label='Bcc'
                  value={bcc}
                  onChange={(evt) => {
                    let emailIds = [];
                    if (evt.target.value === 'fees_paid') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Paid', type: 'Fees' }, currentAcademicYear);
                    } else if (evt.target.value === 'fees_unpaid') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Unpaid', type: 'Fees' }, currentAcademicYear);
                    } else if (evt.target.value === 'fees_inst_unpaid') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Inst. unpaid', type: 'Fees' }, currentAcademicYear);
                    } else if (evt.target.value === 'fees_part_paid') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Part paid', type: 'Fees' }, currentAcademicYear);
                    } else if (evt.target.value === 'fees_waiver') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Waiver', type: 'Fees' }, currentAcademicYear);
                    } else if (evt.target.value === 'niyaz_paid') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Paid', type: 'Niyaz' }, currentAcademicYear);
                    } else if (evt.target.value === 'niyaz_unpaid') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Unpaid', type: 'Niyaz' }, currentAcademicYear);
                    } else if (evt.target.value === 'niyaz_inst_unpaid') {
                      emailIds = InvoiceHelper.getEmailIdsForGivenStatus(students, { status: 'Inst. unpaid', type: 'Niyaz' }, currentAcademicYear);
                    } else if (evt.target.value === 'missing_its') {
                      students.forEach((student) => {
                        if (student.itsId > 99999900) {
                          if (student.father?.email && !emailIds.includes(student.father.email)) {
                            emailIds.push(student.father.email);
                          }
                          if (student.mother?.email && !emailIds.includes(student.mother.email)) {
                            emailIds.push(student.mother.email);
                          }
                        }
                      });
                    } else if (evt.target.value === 'all') {
                      students.forEach((student) => {
                        if (student.father?.email && !emailIds.includes(student.father.email)) {
                          emailIds.push(student.father.email);
                        }
                        if (student.mother?.email && !emailIds.includes(student.mother.email)) {
                          emailIds.push(student.mother.email);
                        }
                      });
                    } else if (evt.target.value === 'all_students') {
                      students.forEach((student) => {
                        if (student.mblClass.active) {
                          if (student.father?.email && !emailIds.includes(student.father.email)) {
                            emailIds.push(student.father.email);
                          }
                          if (student.mother?.email && !emailIds.includes(student.mother.email)) {
                            emailIds.push(student.mother.email);
                          }
                        }
                      });
                    }
                    console.log(emailIds.join(','));
                    setBcc(evt.target.value);
                    handleFieldChange('bcc', emailIds.join(','));
                  }}
                >
                  <option key='none' value='none'>None</option>
                  <option key='all' value='all'>All</option>
                  <option key='all_students' value='all_students'>All students</option>
                  <option key='missing_its' value='missing_its'>Missing ITS Id</option>
                  <optgroup label='Fees'>
                    <option key='fees_paid' value='fees_paid'>Paid</option>
                    <option key='fees_unpaid' value='fees_unpaid'>Unpaid</option>
                    <option key='fees_inst_unpaid' value='fees_inst_unpaid'>Instalment unpaid</option>
                    <option key='fees_part_paid' value='fees_part_paid'>Part paid</option>
                    <option key='fees_waiver' value='fees_waiver'>Waiver</option>
                  </optgroup>
                  <optgroup label='Niyaz'>
                    <option key='niyaz_paid' value='niyaz_paid'>Paid</option>
                    <option key='niyaz_unpaid' value='niyaz_unpaid'>Unpaid</option>
                    <option key='niyaz_inst_unpaid' value='niyaz_inst_unpaid'>Instalment unpaid</option>
                  </optgroup>
                </DropDown>
              </div>
            ) :
              null
          }
          <label className='row one-two' style={{ marginBottom: '1rem' }}>
            <input
              type='checkbox'
              className='checkbox'
              onChange={(e) => {
                setIncludeTeachers(e.target.checked);
              }}
              checked={includeTeachers !== false}
            />
            <span>Include teachers</span>
          </label>
          <div className='row one-two'>
            <TextField
              label='Subject'
              required={true}
              maxLength={75}
              value={fields.subject}
              onChange={(e) => handleFieldChange('subject', e.target.value)}
            />
          </div>
          <div>
            <label>
              <span className='mbl--text-field__label-content required'>Content</span>
            </label>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              onChange={(c) => setContent(c)}
            />
          </div>
        </fieldset>
        <p />
        <Footer
          onCancel={onCancel}
          onSave={() => {
            const updatedFields = { ...fields };
            if (includeTeachers) {
              if (updatedFields.bcc) {
                updatedFields.bcc = `${updatedFields.bcc},${teachersEmail}`;
              } else {
                updatedFields.bcc = teachersEmail;
              }
            }
            onSave({ ...updatedFields, content });
          }}
          saveButtonLabel='Send email'
          saveDisabled={isSaveDisabled}
        />
      </>
    );
  };

  return (
    <div className='email-template-list-wrapper'>
      {showLoading()}
      {/* renderNotification() */}
      {renderForm()}
    </div>
  );
};

PaymentEmailForm.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  students: PropTypes.array,
  teachersEmail: PropTypes.string,
  showBcc: PropTypes.bool
};

export default PaymentEmailForm;

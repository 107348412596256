const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const stringToColor = (str, h, s) => {
  return `hsl(${hashCode(str) % 500}, ${h || 100}%, ${s || 80}%)`;
};

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import SlidingPane from 'react-sliding-pane';
import { CommonTable as Table, ToggleButton } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import AttendanceCard from './AttendanceCard';
import AttendanceReportCard from './AttendanceReportCard';
import FaChevronRight from '../../styles/assets/chevron.svg';
import AttendanceChart from '../panel/AttendanceChartPanel';
import UserForm from '../forms/UserForm';
import HasPermissions from '../HasPermissions';
import AppContext from '../AppContext';
import { hasPermissions } from '../../utils/hasPermissions';
import {
  EDIT_USERS_PERMISSION, VIEW_TEACHERS_ATTENDANCE_PERMISSION, TAKE_TEACHERS_ATTENDANCE_PERMISSION
} from '../../utils/permissions';
import { DATE_FORMAT_UTC, DATE_FORMAT_WITH_TIME, DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';

import { useTeacherAttendanceData } from '../hooks/useTeacherAttendanceData';
import { useUserData } from '../hooks/useUserData';

const getRowHeight = () => {
  if (window.innerWidth >= 800) {
    return 75;
  }
  return 190;
};

const TeacherAttendanceList = ({ mode }) => {
  // startDate is used for report panel
  const [startDate, setStartDate] = useState(null);
  const [date, setDate] = useState(format(new Date(), DATE_FORMAT_UTC));
  const [session, setSession] = useState('Morning');
  const [rowHeight, setRowHeight] = useState(getRowHeight());
  const [tableHeight, setTableHeight] = useState(window.innerHeight - 150);
  const [showAttendanceHistory, setShowAttendanceHistory] = useState(false);
  const [showTeacherDetails, setShowTeacherDetails] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState({});

  const canTakeTeachersAttendance = hasPermissions([TAKE_TEACHERS_ATTENDANCE_PERMISSION]);
  const appContext = useContext(AppContext);
  const {
    teachers,
    fetchTeachersAttendance,
    saveTeacherAttendance,
    fetchTeacherAttendanceReport,
    loading
  } = useTeacherAttendanceData();
  const { saveUser } = useUserData(false);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize.bind(this));
  }, []);

  useEffect(() => {
    // setting startDate to null until we do not have the startDate from settings
    const academicYear = appContext?.settings?.academicYears?.find(yr => yr.isCurrent === true);
    setStartDate(academicYear?.startDate || format(new Date(), DATE_FORMAT_UTC));
  }, [appContext.settings]);

  useEffect(() => {
    // fetch once we have the startDate set from settings
    if (mode === 'report' && startDate) {
      fetchTeacherAttendanceReport(format(new Date(startDate), DATE_FORMAT_UTC), date);
    }
  }, [date, startDate]);

  useEffect(() => {
    if (mode !== 'report') {
      fetchTeachersAttendance(date, session);
    }
  }, [date, session]);

  const onWindowResize = () => {
    setRowHeight(getRowHeight());
    setTableHeight(window.innerHeight - 150);
  };

  const renderTeacherDetails = () => {
    const userName = `${selectedTeacher.firstName} ${selectedTeacher.lastName}`;
    const useMode = hasPermissions([EDIT_USERS_PERMISSION]) ? 'edit' : 'view';
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showTeacherDetails}
        title={`${useMode === 'edit' ? 'Edit' : 'View'} user - ${userName}`}
        from='right'
        width='80vw'
        onRequestClose={() => setShowTeacherDetails(false)}
      >
        <UserForm
          user={selectedTeacher}
          mode={useMode}
          onCancel={() => setShowTeacherDetails(false)}
          onSave={(fields) => {
            console.log(fields);
            saveUser(fields, 'edit', () => {
              fetchTeacherAttendanceReport(format(new Date(startDate), DATE_FORMAT_UTC), date);
              setShowTeacherDetails(false);
            });
          }}
        />
      </SlidingPane>
    );
  };

  const renderTeacherAttendanceHistory = () => {
    const userName = `${selectedTeacher.firstName} ${selectedTeacher.lastName}`;
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showAttendanceHistory}
        title={`Attendance History - ${userName}`}
        from='right'
        width='80vw'
        onRequestClose={() => setShowAttendanceHistory(false)}
      >
        <AttendanceChart
          mode='teacher'
          student={selectedTeacher}
          id={selectedTeacher._id}
          startDate={startDate}
          endDate={date}
        />
      </SlidingPane>
    );
  };

  const renderDateSessionSelector = () => {
    return (
      <div className='date-session-strip'>
        <div className='header' />
        <div className='student-count'>
          {`${teachers.length} Teachers`}
        </div>
        <div className='date-wrapper'>
          {
            mode === 'report' ? (
              <>
                <div className='button-strip'>
                  <input
                    type='button'
                    value='Generate report'
                    tabIndex='0'
                    style={{ height: '45px', display: 'none' }}
                    className='mbl-button primary-button'
                    onClick={() => {
                      // downloadReport();
                    }}
                  />
                </div>
                <div className='horizontal-spacer-10' />
                <DatePicker
                  selected={new Date(startDate)}
                  todayButton='Today'
                  dateFormat={DATE_FORMAT_FULL_MONTH}
                  maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='date-picker-input'
                  onChange={(dt) => setStartDate(format(new Date(dt), DATE_FORMAT_UTC))}
                />
                <div className='horizontal-spacer-10' />
              </>
            ) :
              null
          }
          <DatePicker
            selected={new Date(date)}
            todayButton='Today'
            dateFormat={DATE_FORMAT_FULL_MONTH}
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            className='date-picker-input'
            onChange={(dt) => setDate(format(new Date(dt), DATE_FORMAT_UTC))}
          />
          {
            mode !== 'report' ? (
              <>
                <div className='horizontal-spacer-10' />
                <ToggleButton
                  onClick={(value) => setSession(value)}
                  items={[
                    { name: 'Morning', label: 'Morning', pressed: session === 'Morning' },
                    { name: 'Afternoon', label: 'Afternoon', pressed: session === 'Afternoon' }]}
                />
              </>
            ) :
              null
          }
        </div>
      </div>
    );
  };

  const rowRenderer = (data) => {
    const attParams = { ...data.rowData };
    if (mode === 'report') {
      return (
        <AttendanceReportCard
          student={attParams}
          onShowAttendanceHistory={(teacher) => {
            setSelectedTeacher(teacher);
            setShowAttendanceHistory(true);
          }}
          onShowDetails={(teacher) => {
            setSelectedTeacher(teacher);
            setShowTeacherDetails(true);
          }}
        />
      );
    }
    if (!attParams.attendance || attParams.attendance.length === 0) {
      attParams.attendance = [{
        present: false,
        authorised: false,
        late: false
      }];
    }
    return (
      <AttendanceCard
        student={attParams}
        showCoinsBalance={false}
        readOnly={!canTakeTeachersAttendance}
        onAttendanceChange={(fields) => {
          const params = { ...fields };
          if (params.present) {
            params.authorised = false;
            if (params.late) {
              params.time = format(new Date(), DATE_FORMAT_WITH_TIME);
            } else {
              delete params.time;
            }
          } else {
            // if present then set late to false and remove late time
            params.late = false;
            delete params.time;
          }
          params.date = date;
          params.session = session;
          console.log(params);
          const attendance = {};
          teachers.forEach((teacher) => {
            const teacherId = teacher._id;
            attendance[teacherId] = {};
            if (data.rowData._id === teacherId) {
              attendance[teacherId] = params;
            } else {
              attendance[teacherId].present = teacher.attendance && teacher.attendance.length > 0 ?
                teacher.attendance[0].present : false;
              attendance[teacherId].late = teacher.attendance && teacher.attendance.length > 0 ?
                teacher.attendance[0].late : false;
              attendance[teacherId].authorised =
                teacher.attendance && teacher.attendance.length > 0 ?
                  teacher.attendance[0].authorised : false;
              attendance[teacherId].date = teacher.attendance && teacher.attendance.length > 0 ?
                teacher.attendance[0].date : date;
              attendance[teacherId].session = teacher.attendance && teacher.attendance.length > 0 ?
                teacher.attendance[0].session : session;
            }
          });
          saveTeacherAttendance(attendance);
          fetchTeachersAttendance(date, session);
        }}
      />
    );
  };

  const showLoading = () => {
    if (loading) {
      return <Loading />;
    }
    return null;
  };

  const renderTeacherList = () => {
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={rowHeight}
        maxHeight={tableHeight}
        selectable
        rowRenderer={rowRenderer}
        emptyRenderer={<Loading />}
        headerHeight={0}
        columns={[]}
        data={teachers}
      />
    );
  };

  return (
    <HasPermissions
      permissions={[
        VIEW_TEACHERS_ATTENDANCE_PERMISSION, TAKE_TEACHERS_ATTENDANCE_PERMISSION
      ]}
    >
      {showLoading()}
      {renderTeacherDetails()}
      {renderTeacherAttendanceHistory()}
      {renderDateSessionSelector()}
      {renderTeacherList()}
    </HasPermissions>
  );
};

TeacherAttendanceList.propTypes = {
  mode: PropTypes.string
};

export default TeacherAttendanceList;

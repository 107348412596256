import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import MdEdit from '../../styles/assets/edit.svg';
import MdInsertChart from '../../styles/assets/chart.svg';

const AttendanceReportCard = ({
  student,
  showYear,
  onShowAttendanceHistory,
  onShowDetails
}) => {
  const renderActions = () => {
    return (
      <div className='actions'>
        <div className='actions-heading'>Actions</div>
        <div className='actions-icons'>
          <MdInsertChart className='action-icon' onClick={() => onShowAttendanceHistory(student)} />
          <MdEdit className='action-icon' onClick={() => onShowDetails(student)} />
        </div>
      </div>
    );
  };

  const attPercentage = useMemo(() => {
    const percent = (student.attendanceCount * 100) / student.expectedAttendance;
    return isNaN(percent) ? 0 : percent;
  }, [student.attendanceCount, student.expectedAttendance]);

  const status = useMemo(() => {
    let stat = 'green';
    if (student.expectedAttendance > 0) {
      if (attPercentage < 90 && attPercentage > 75) {
        stat = 'amber';
      } else if (attPercentage <= 75) {
        stat = 'red';
      }
    }
    return stat;
  }, [attPercentage, student.expectedAttendance]);

  return (
    <div className='student--attendance-card-wrapper'>
      <div className={`student--attendance-card-status ${ status}`} />
      <div className='student--attendance-card'>
        <div className='student-data'>
          <div className='roll-no'>
            {`ITS Id: ${student.itsId}`}
          </div>
          <div className='name'>
            <span>
              {`${student.firstName} ${student.father ? student.father.firstName : ''} ${student.lastName}`}
            </span>
          </div>
        </div>
        {
          showYear ?
            (
              <div className='student--class'>
                <div className='student--class-heading'>Class</div>
                <div className='student--class-value'>{`${student.mblClass.name } ${ student.mblClass.division }` }</div>
              </div>
            ) :
            null
        }
        <div className='attendance-history'>
          <div className='attendance--history-heading'>Past attendance</div>
          <div className='attendance--percent'>{`${student.attendanceCount } / ${ student.expectedAttendance } (${ Math.round(attPercentage * 100) / 100 }%)` }</div>
        </div>
        {renderActions()}
      </div>
    </div>
  );
};

AttendanceReportCard.propTypes = {
  student: PropTypes.object,
  showYear: PropTypes.bool,
  onShowAttendanceHistory: PropTypes.func,
  onShowDetails: PropTypes.func
};

export default AttendanceReportCard;

export const getTilawatIkhtebarResultList = () => {
  return [
    { label: 'A+', value: 'A+' },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'Pending', value: 'PENDING' }
  ];
};

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { DropDown, NumberInput, TextField } from '@aeblondon-uk/common-ui-components';
import Footer from '../visualization/Footer';
import { DATE_FORMAT_UTC, DATE_FORMAT_FULL_MONTH } from '../../utils/DateUtils';

import { useForm } from '../hooks/useForm';

const GenerateInvoicesForm = ({ invoice, onCancel, onSave }) => {
  const [fields, handleFieldChange] = useForm(invoice);
  return (
    <div className='generate-fees-invoices'>
      <div style={{ marginBottom: '20px' }}>
        The invoices for all the active students will be generated for the
        {' '}
        <b>Academic year</b>
        ,
        <b>Type</b>
        ,
        <b>Amount</b>
        {' '}
        and
        {' '}
        <b>Due date</b>
        {' '}
        specified below
      </div>
      <span className='payment-header'>
        <TextField
          autoFocus={true}
          label='Academic year'
          required={true}
          value={fields.academicYear}
          onChange={(e) => {
            handleFieldChange('academicYear', e.target.value);
          }}
        />
      </span>
      <span className='payment-header'>
        <DropDown
          label='Type'
          value={fields.type}
          onChange={(e) => {
            handleFieldChange('type', e.target.value);
          }}
        >
          <option value='Fees'>Fees</option>
          <option value='Niyaz'>Niyaz</option>
        </DropDown>
      </span>
      <span className='payment-header'>
        <NumberInput
          label='Amount'
          type='number'
          value={fields.amount}
          required={true}
          max={9999}
          onChange={(value) => {
            let amount = fields.amount;
            try {
              amount = parseInt(value || 0, 10);
            } catch (ex) {
              console.log('Incorrect amount value', ex);
            }
            handleFieldChange('amount', amount);
          }}
        />
      </span>
      <span className='payment-header'>
        <div className='mbl--text-field'>Due date</div>
        <DatePicker
          selected={new Date(fields.dueDate)}
          dateFormat={DATE_FORMAT_FULL_MONTH}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          className='date-picker-input'
          onChange={(date) => {
            handleFieldChange('dueDate', format(new Date(date), DATE_FORMAT_UTC));
          }}
        />
      </span>
      <Footer
        onCancel={() => {
          onCancel();
        }}
        onSave={() => {
          onSave(fields);
        }}
      />
    </div>
  );
};

GenerateInvoicesForm.propTypes = {
  invoice: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default GenerateInvoicesForm;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

import MedicalIcon from './MedicalIcon';
import Coin from '../../styles/assets/coin.svg';

const AttendanceCard = ({
  student, onAttendanceChange, showCoinsBalance = true, readOnly, showWarnings, showHifzStatus, onWarningChange = () => {}
}) => {
  const attendance = student.attendance[0];
  const warnings = student.warnings || [];

  const renderAttendanceToggle = () => {
    return (
      <Toggle
        checked={attendance.present}
        onChange={(e) => {
          onAttendanceChange({ ...attendance, present: e.target.checked });
        }}
      />
    );
  };

  const renderLateCheckbox = () => {
    return (
      <>
        <div className='horizontal-spacer-10' />
        <label className='attendance-checkbox'>
          <input
            type='checkbox'
            name='late'
            disabled={!attendance.present}
            checked={attendance.late}
            onChange={(e) => {
              onAttendanceChange({ ...attendance, late: e.target.checked });
            }}
          />
          &nbsp;Late
        </label>
      </>
    );
  };

  const renderAuthorisedCheckbox = () => {
    return (
      <>
        <div className='horizontal-spacer-10' />
        <label className='attendance-checkbox'>
          <input
            type='checkbox'
            name='authorised'
            disabled={attendance.present}
            checked={attendance.authorised}
            onChange={(e) => {
              onAttendanceChange({ ...attendance, authorised: e.target.checked });
            }}
          />
          &nbsp;Authorised
        </label>
      </>
    );
  };

  const getCoinsBalance = (st) => {
    if (st) {
      return st.totalCoinsEarned - st.totalCoinsRedeemed;
    }
    return 0;
  };

  const renderCoinsBalance = () => {
    if (showCoinsBalance) {
      return (
        <div className='coins-balance'>
          <div className='coins-balance--heading'>Coins balance</div>
          <div className='coins-balance--count'>
            <span>{getCoinsBalance(student)}</span>
            <span className='coin-icon-wrapper' title='Coins'>
              <Coin className='coin-icon' />
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderWarnings = () => {
    if (!showWarnings) {
      return null;
    }
    return (
      <div className='mark-attendance warnings'>
        <div className='attendance-heading'>{`Warnings ${student.totalWarnings ? `(${ student.totalWarnings })` : ''}`}</div>
        <div className='attendance--toggle-container'>
          <input
              type='checkbox'
              disabled={readOnly}
              checked={warnings.length > 0 && !!warnings[0]}
              onChange={(e) => {
                if (e.target.checked) {
                  onWarningChange({ action: 'ADD' });
                } else {
                  onWarningChange({ action: 'REMOVE', warning: warnings[0] });
                }
              }}
          />
          <div className='horizontal-spacer-10' />
            <input
              type='checkbox'
              disabled={readOnly}
              checked={warnings.length > 1 && !!warnings[1]}
              onChange={(e) => {
                if (e.target.checked) {
                  onWarningChange({ action: 'ADD' });
                } else {
                  onWarningChange({ action: 'REMOVE', warning: warnings[1] });
                }
              }}
            />
          <div className='horizontal-spacer-10' />
            <input
              type='checkbox'
              disabled={readOnly}
              checked={warnings.length > 2 && !!warnings[2]}
              onChange={(e) => {
                if (e.target.checked) {
                  onWarningChange({ action: 'ADD' });
                } else {
                  onWarningChange({ action: 'REMOVE', warning: warnings[2] });
                }
              }}
            />
        </div>
      </div>
    );
  };

  const attPercentage = useMemo(() => {
    const percent = (student.attendanceCount * 100) / student.expectedAttendance;
    return isNaN(percent) ? 0 : percent;
  }, [student.attendanceCount, student.expectedAttendance]);

  const status = useMemo(() => {
    let stat = 'green';
    if (student.expectedAttendance > 0) {
      if (attPercentage < 90 && attPercentage > 75) {
        stat = 'amber';
      } else if (attPercentage <= 75) {
        stat = 'red';
      }
    }
    return stat;
  }, [attPercentage, student.expectedAttendance]);

  const renderAttendanceSection = () => {
    if (readOnly) {
      return null;
    }
    return (
      <div className='mark-attendance'>
        <div className='attendance-heading'>Attendance</div>
        <div className='attendance--toggle-container'>
          {renderAttendanceToggle()}
          {renderLateCheckbox()}
          {renderAuthorisedCheckbox()}
        </div>
      </div>
    );
  };

  const renderHifzStatus = () => {
    if (!showHifzStatus) {
      return null;
    }
    const hifzTimeline = student.hifzTimeline ? [...student.hifzTimeline] : [];
    let latestHifzStatus = '';
    if (hifzTimeline.length > 0) {
      hifzTimeline.reverse();
      // get latest update entry
      latestHifzStatus = hifzTimeline.find(ht => ht.entryType === 'UPDATE');
    }

    const getStatus = () => {
      if (!latestHifzStatus) {
        return (
          <div className='hifz-status--status'>
            NA
          </div>
        );
      }
      return (
        <>
          <div className='hifz-status--status'>
            {`Surah ${latestHifzStatus.surah}`}
          </div>
          <div className='hifz-status--status'>
            {`Aayat ${latestHifzStatus.aayat}`}
          </div>
        </>
      );
    };

    return (
      <div className='hifz-status'>
        <div className='hifz-status--heading'>Hifz status</div>
        {getStatus()}
      </div>
    );
  };

  return (
    <div className='student--attendance-card-wrapper'>
      <div className={`student--attendance-card-status ${ status}`} />
      <div className='student--attendance-card'>
        <div className='student-data'>
          <div className='roll-no'>
            {`ITS Id: ${student.itsId}`}
          </div>
          <div className='name'>
            <span>
              {`${student.firstName} ${student.father ? student.father.firstName : ''} ${student.lastName}`}
            </span>
            <MedicalIcon student={student} />
          </div>
        </div>
        {renderAttendanceSection()}
        <div className='attendance-history'>
          <div className='attendance--history-heading'>Past attendance</div>
          <div className='attendance--percent'>{`${student.attendanceCount } / ${ student.expectedAttendance } (${ Math.round(attPercentage * 100) / 100 }%)` }</div>
        </div>
        {renderHifzStatus()}
        {renderWarnings()}
        {renderCoinsBalance()}
      </div>
    </div>
  );
};

AttendanceCard.propTypes = {
  student: PropTypes.object,
  onAttendanceChange: PropTypes.func,
  showCoinsBalance: PropTypes.bool,
  readOnly: PropTypes.bool,
  showWarnings: PropTypes.bool,
  showHifzStatus: PropTypes.bool,
  onWarningChange: PropTypes.func
};

export default AttendanceCard;

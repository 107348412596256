import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import {
  Accordion, AccordionItem, CheckboxSet, MultiColumnRow, DropDown
} from '@aeblondon-uk/common-ui-components';

import { SidePanel } from '.';
import useBrowserStorage from '../../hooks/useBrowserStorage';
import { getHifzSanadList } from '../../../utils/getHifzSanadList';
import { getTilawatIkhtebarResultList } from '../../../utils/getTilawatIkhtebarResultList';
import { QURAN_DIRECTORY } from '../../../utils/QuranDirectory';

const messages = defineMessages({
  filters: {
    description: 'Filters sidepanel label',
    defaultMessage: 'Filters'
  },
  stateLabel: {
    description: 'state section label',
    defaultMessage: 'State'
  },
  classLabel: {
    description: 'Class section label',
    defaultMessage: 'Class'
  },
  paymentModeLabel: {
    description: 'Payment mode section label',
    defaultMessage: 'Payment mode'
  },
  paymentOptionLabel: {
    description: 'Payment option section label',
    defaultMessage: 'Payment option'
  },
  paymentTypeLabel: {
    description: 'Payment type section label',
    defaultMessage: 'Payment type'
  },
  creditNoteLabel: {
    description: 'Credit note section label',
    defaultMessage: 'Credit note'
  },
  paymentModeCard: {
    description: 'Payment mode Card checkbox label',
    defaultMessage: 'Card'
  },
  paymentModeCheque: {
    description: 'Payment mode Cheque checkbox label',
    defaultMessage: 'Cheque'
  },
  paymentModeCash: {
    description: 'Payment mode Cash checkbox label',
    defaultMessage: 'Cash'
  },
  paymentModeBankTransfer: {
    description: 'Payment mode Bank transfer checkbox label',
    defaultMessage: 'Bank transfer'
  },
  paymentOptionOneOff: {
    description: 'Payment option one off',
    defaultMessage: 'One off'
  },
  paymentOptionInstalment: {
    description: 'Payment option instalment',
    defaultMessage: 'Instalment'
  },
  otherFiltersAccordionLabel: {
    description: 'Label for Other filters accordion',
    defaultMessage: 'Other filters'
  },
  missingItsLabel: {
    description: 'Missing its label for Other filters accordion',
    defaultMessage: 'Missing ITS'
  },
  medicalConditionLabel: {
    description: 'Medical condition label for Other filters accordion',
    defaultMessage: 'Medical conditions'
  },
  hifzSanadFiltersAccordionLabel: {
    description: 'Hifz sanad filters accordion',
    defaultMessage: 'Hifz sanad'
  },
  tilawatIkhtebarFiltersAccordionLabel: {
    description: 'Tilawat ikhtebar filters accordion',
    defaultMessage: 'Tilawat ikhtebar'
  },
  hifzStatusFiltersAccordionLabel: {
    description: 'Hifz status filters accordion',
    defaultMessage: 'Hifz status'
  }
});

const DEFAULT_FILTER = {
  searchText: '',
  activeFilter: 'Active',
  mblClass: [],
  otherFilter: [],
  sanadFilter: [],
  surahFilter: '',
  siparoFilter: ''
};

const StudentFilterSidepanel = ({
  open, filter, classes, onFilterChange = () => {}, onClose = () => {}
}) => {
  const intl = useIntl();
  const {
    mblClass, surahFilter, siparoFilter, tirFilter
  } = filter;
  const [isClassAccordionOpen, setClassAccordionState] = useBrowserStorage({ key: 'isClassAccordionOpen', initialValue: true });
  const [isOtherFilterAccordionOpen, setOtherFilterAccordionState] = useBrowserStorage({ key: 'isOtherFilterAccordionOpen', initialValue: true });
  const [isHifzSanadFilterAccordionOpen, setHifzSanadFilterAccordionState] = useBrowserStorage({ key: 'isHifzSanadFilterAccordionOpen', initialValue: true });
  const [isHifzStatusFilterAccordionOpen, setHifzStatusFilterAccordionState] = useBrowserStorage({ key: 'isHifzStatusFilterAccordionOpen', initialValue: true });
  const [isTilawatIkhtebarFilterAccordionOpen, setTilawatIkhtebarFilterAccordionState] = useBrowserStorage({ key: 'isTilawatIkhtebarFilterAccordionOpen', initialValue: true });

  const getClassOptions = () => {
    return classes.map(c => {
      return {
        label: `${c.name} ${c.division}`,
        value: c._id,
        checked: mblClass.includes(c._id)
      };
    });
  };

  const getSanadOptions = () => {
    return getHifzSanadList().map(sanad => {
      return {
        label: sanad.label,
        value: sanad.value,
        checked: filter.sanadFilter?.includes(sanad.value)
      };
    });
  };

  const getTilawatIkhtebarOptions = () => {
    return getTilawatIkhtebarResultList().map(tir => {
      return {
        label: tir.label,
        value: tir.value,
        checked: tirFilter?.includes(tir.value)
      };
    });
  };

  const surahNameSelectOptions = useMemo(() => {
    const surahList = [];
    surahList.push(<option key='unselected' value=''>--- select surah ---</option>);
    return surahList.concat(QURAN_DIRECTORY.map(item => (<option key={item.surahName} value={item.surahName}>{item.surahName}</option>)));
  }, []);

  const siparoSelectOptions = useMemo(() => {
    const siparoList = [];
    siparoList.push(<option key='unselected' value=''>--- select siparo ---</option>);
    if (surahFilter) {
      const siparas = QURAN_DIRECTORY.find(q => {
        return q.surahName === decodeURIComponent(surahFilter);
      }).surahInSipara;
      return siparoList.concat(siparas.map(s => <option key={s} value={s}>{s}</option>));
    }
    return siparoList;
  }, [surahFilter]);

  return (
    <SidePanel
      title={intl.formatMessage(messages.filters)}
      open={open}
      onClear={() => {
        onFilterChange({ ...DEFAULT_FILTER, searchText: filter.searchText });
      }}
      onClose={onClose}
    >
      <Accordion>
        <AccordionItem
          key={`isClassAccordionOpen-${isClassAccordionOpen}`}
          title={intl.formatMessage(messages.classLabel)}
          open={isClassAccordionOpen}
          onHeaderClick={() => {
            setClassAccordionState(!isClassAccordionOpen);
          }}
        >
          <MultiColumnRow col={2}>
            <CheckboxSet
              options={getClassOptions()}
              onChange={(values) => {
                onFilterChange({ ...filter, mblClass: values });
              }}
            />
          </MultiColumnRow>
        </AccordionItem>
        <AccordionItem
          key={`isOtherFilterAccordionOpen-${isOtherFilterAccordionOpen}`}
          title={intl.formatMessage(messages.otherFiltersAccordionLabel)}
          open={isOtherFilterAccordionOpen}
          onHeaderClick={() => {
            setOtherFilterAccordionState(!isOtherFilterAccordionOpen);
          }}
        >
          <MultiColumnRow col={2}>
            <CheckboxSet
              options={[
                {
                  label: intl.formatMessage(messages.missingItsLabel),
                  value: 'MISSING_ITS',
                  checked: filter.otherFilter?.includes('MISSING_ITS')
                },
                {
                  label: intl.formatMessage(messages.medicalConditionLabel),
                  value: 'MEDICAL_CONDITION',
                  checked: filter.otherFilter?.includes('MEDICAL_CONDITION')
                }
              ]}
              onChange={(values) => {
                onFilterChange({ ...filter, otherFilter: values });
              }}
            />
          </MultiColumnRow>
        </AccordionItem>
        <AccordionItem
          key={`isHifzSanadFilterAccordionOpen-${isHifzSanadFilterAccordionOpen}`}
          title={intl.formatMessage(messages.hifzSanadFiltersAccordionLabel)}
          open={isHifzSanadFilterAccordionOpen}
          onHeaderClick={() => {
            setHifzSanadFilterAccordionState(!isHifzSanadFilterAccordionOpen);
          }}
        >
          <MultiColumnRow col={2}>
            <CheckboxSet
              options={getSanadOptions()}
              onChange={(values) => {
                onFilterChange({ ...filter, sanadFilter: values });
              }}
            />
          </MultiColumnRow>
        </AccordionItem>
        <AccordionItem
          key={`isHifzStatusFilterAccordionOpen-${isHifzStatusFilterAccordionOpen}`}
          title={intl.formatMessage(messages.hifzStatusFiltersAccordionLabel)}
          open={isHifzStatusFilterAccordionOpen}
          onHeaderClick={() => {
            setHifzStatusFilterAccordionState(!isHifzStatusFilterAccordionOpen);
          }}
        >
          <div>
            <DropDown
              label='Surah'
              value={decodeURIComponent(surahFilter)}
              onChange={(e) => {
                onFilterChange({ ...filter, surahFilter: e.target.value });
              }}
            >
              {surahNameSelectOptions}
            </DropDown>
          </div>
          <div>
            <DropDown
              label='Siparo'
              value={siparoFilter}
              onChange={(e) => {
                onFilterChange({ ...filter, siparoFilter: parseInt(e.target.value, 10) });
              }}
            >
              {siparoSelectOptions}
            </DropDown>
          </div>
        </AccordionItem>
        <AccordionItem
          key={`isTilawatIkhtebarFilterAccordionOpen-${isTilawatIkhtebarFilterAccordionOpen}`}
          title={intl.formatMessage(messages.tilawatIkhtebarFiltersAccordionLabel)}
          open={isTilawatIkhtebarFilterAccordionOpen}
          onHeaderClick={() => {
            setTilawatIkhtebarFilterAccordionState(!isTilawatIkhtebarFilterAccordionOpen);
          }}
        >
          <MultiColumnRow col={3}>
            <CheckboxSet
              options={getTilawatIkhtebarOptions()}
              onChange={(values) => {
                onFilterChange({ ...filter, tirFilter: values });
              }}
            />
          </MultiColumnRow>
        </AccordionItem>
      </Accordion>
    </SidePanel>
  );
};

StudentFilterSidepanel.propTypes = {
  open: PropTypes.bool,
  filter: PropTypes.object,
  classes: PropTypes.array,
  onFilterChange: PropTypes.func,
  onClose: PropTypes.func
};

export default StudentFilterSidepanel;

import { defineMessages } from 'react-intl';

const PERMISSION_PREFIX = 'perm_';

export const VIEW_DASHBOARD_PERMISSION = `${PERMISSION_PREFIX}view_dashboard`;

export const VIEW_STUDENTS_PERMISSION = `${PERMISSION_PREFIX}view_students`;
export const ADD_STUDENTS_PERMISSION = `${PERMISSION_PREFIX}add_students`;
export const EDIT_STUDENTS_PERMISSION = `${PERMISSION_PREFIX}edit_students`;
export const DELETE_STUDENTS_PERMISSION = `${PERMISSION_PREFIX}delete_students`;
export const PROMOTE_STUDENTS_PERMISSION = `${PERMISSION_PREFIX}promote_students`;
export const ADD_STUDENTS_HIFZ_ENTRY = `${PERMISSION_PREFIX}add_students_hifz_entry`;
export const ADD_STUDENTS_HIFZ_SANAD_ENTRY = `${PERMISSION_PREFIX}add_students_hifz_sanad_entry`; // UI side only
export const DELETE_STUDENTS_HIFZ_ENTRY = `${PERMISSION_PREFIX}delete_students_hifz_entry`;

export const VIEW_MY_CLASS_ATTENDANCE_PERMISSION = `${PERMISSION_PREFIX}view_my_class_attendance`;
export const TAKE_MY_CLASS_ATTENDANCE_PERMISSION = `${PERMISSION_PREFIX}take_my_class_attendance`;
export const VIEW_ALL_CLASS_ATTENDANCE_PERMISSION = `${PERMISSION_PREFIX}view_all_class_attendance`;
export const TAKE_ALL_CLASS_ATTENDANCE_PERMISSION = `${PERMISSION_PREFIX}take_all_class_attendance`;

export const VIEW_TEACHERS_ATTENDANCE_PERMISSION = `${PERMISSION_PREFIX}view_teachers_attendance`;
export const TAKE_TEACHERS_ATTENDANCE_PERMISSION = `${PERMISSION_PREFIX}take_teachers_attendance`;

export const VIEW_CLASSES_PERMISSION = `${PERMISSION_PREFIX}view_classes`;
export const ADD_CLASSES_PERMISSION = `${PERMISSION_PREFIX}add_classes`;
export const EDIT_CLASSES_PERMISSION = `${PERMISSION_PREFIX}edit_classes`;
export const DELETE_CLASSES_PERMISSION = `${PERMISSION_PREFIX}delete_classes`;

export const VIEW_USERS_PERMISSION = `${PERMISSION_PREFIX}view_users`;
export const ADD_USERS_PERMISSION = `${PERMISSION_PREFIX}add_users`;
export const EDIT_USERS_PERMISSION = `${PERMISSION_PREFIX}edit_users`;
export const DELETE_USERS_PERMISSION = `${PERMISSION_PREFIX}delete_users`;

export const ADD_REWARDS_PERMISSION = `${PERMISSION_PREFIX}add_rewards`;
export const REDEEM_REWARDS_PERMISSION = `${PERMISSION_PREFIX}redeem_rewards`;

export const VIEW_INVOICES_PERMISSION = `${PERMISSION_PREFIX}view_invoices`;
export const ADD_INVOICES_PERMISSION = `${PERMISSION_PREFIX}add_invoices`;
export const EDIT_INVOICES_PERMISSION = `${PERMISSION_PREFIX}edit_invoices`;
export const DELETE_INVOICES_PERMISSION = `${PERMISSION_PREFIX}delete_invoices`;
export const VIEW_INVOICE_METRICS_PERMISSION = `${PERMISSION_PREFIX}view_invoice_metrics`;

export const VIEW_EMAIL_TEMPLATES_PERMISSION = `${PERMISSION_PREFIX}view_email_templates`;
export const ADD_EMAIL_TEMPLATES_PERMISSION = `${PERMISSION_PREFIX}add_email_templates`;
export const EDIT_EMAIL_TEMPLATES_PERMISSION = `${PERMISSION_PREFIX}edit_email_templates`;
export const DELETE_EMAIL_TEMPLATES_PERMISSION = `${PERMISSION_PREFIX}delete_email_templates`;

export const VIEW_ROLES_PERMISSION = `${PERMISSION_PREFIX}view_roles`;
export const ADD_ROLES_PERMISSION = `${PERMISSION_PREFIX}add_roles`;
export const EDIT_ROLES_PERMISSION = `${PERMISSION_PREFIX}edit_roles`;
export const DELETE_ROLES_PERMISSION = `${PERMISSION_PREFIX}delete_roles`;

export const VIEW_SETTINGS_PERMISSION = `${PERMISSION_PREFIX}view_settings`;
export const EDIT_SETTINGS_PERMISSION = `${PERMISSION_PREFIX}edit_settings`;

export const VIEW_PURCHASE_ORDERS_PERMISSION = `${PERMISSION_PREFIX}view_purchase_orders`;
export const ADD_PURCHASE_ORDERS_PERMISSION = `${PERMISSION_PREFIX}add_purchase_orders`;
export const EDIT_PURCHASE_ORDERS_PERMISSION = `${PERMISSION_PREFIX}edit_purchase_orders`;
export const DELETE_PURCHASE_ORDERS_PERMISSION = `${PERMISSION_PREFIX}delete_purchase_orders`;

export const VIEW_ASSETS_PERMISSION = `${PERMISSION_PREFIX}view_assets`;
export const ADD_ASSETS_PERMISSION = `${PERMISSION_PREFIX}add_assets`;
export const EDIT_ASSETS_PERMISSION = `${PERMISSION_PREFIX}edit_assets`;
export const DELETE_ASSETS_PERMISSION = `${PERMISSION_PREFIX}delete_assets`;

const messages = defineMessages({
  [VIEW_DASHBOARD_PERMISSION]: {
    defaultMessage: 'View dashboard',
    description: 'View dashboard permission'
  },

  [VIEW_STUDENTS_PERMISSION]: {
    defaultMessage: 'View students',
    description: 'View students permission'
  },
  [ADD_STUDENTS_PERMISSION]: {
    defaultMessage: 'Add students',
    description: 'Add students permission'
  },
  [EDIT_STUDENTS_PERMISSION]: {
    defaultMessage: 'Edit students',
    description: 'Edit students permission'
  },
  [DELETE_STUDENTS_PERMISSION]: {
    defaultMessage: 'Delete students',
    description: 'Delete students permission'
  },
  [PROMOTE_STUDENTS_PERMISSION]: {
    defaultMessage: 'Promote students',
    description: 'Promote students permission'
  },
  [ADD_STUDENTS_HIFZ_ENTRY]: {
    defaultMessage: 'Add student hifz entry',
    description: 'Add student hifz entry permission'
  },
  [DELETE_STUDENTS_HIFZ_ENTRY]: {
    defaultMessage: 'Delete student hifz entry',
    description: 'Delete student hifz entry permission'
  },
  [ADD_STUDENTS_HIFZ_SANAD_ENTRY]: {
    defaultMessage: 'Add student hifz sanad entry',
    description: 'Add student hifz sanad entry permission'
  },

  [VIEW_MY_CLASS_ATTENDANCE_PERMISSION]: {
    defaultMessage: 'View my class attendance',
    description: 'View my class attendance permission'
  },
  [TAKE_MY_CLASS_ATTENDANCE_PERMISSION]: {
    defaultMessage: 'Take my class attendance',
    description: 'Take my class attendance permission'
  },
  [VIEW_ALL_CLASS_ATTENDANCE_PERMISSION]: {
    defaultMessage: 'View all class attendance',
    description: 'View all class attendance permission'
  },
  [TAKE_ALL_CLASS_ATTENDANCE_PERMISSION]: {
    defaultMessage: 'Take all class attendance',
    description: 'Take all class attendance permission'
  },

  [VIEW_TEACHERS_ATTENDANCE_PERMISSION]: {
    defaultMessage: 'View teachers attendance',
    description: 'View teachers attendance permission'
  },
  [TAKE_TEACHERS_ATTENDANCE_PERMISSION]: {
    defaultMessage: 'Take teachers attendance',
    description: 'Take teachers attendance permission'
  },

  [VIEW_CLASSES_PERMISSION]: {
    defaultMessage: 'View class',
    description: 'View class permission'
  },
  [ADD_CLASSES_PERMISSION]: {
    defaultMessage: 'Add class',
    description: 'Add class permission'
  },
  [EDIT_CLASSES_PERMISSION]: {
    defaultMessage: 'Edit class',
    description: 'Edit class permission'
  },
  [DELETE_CLASSES_PERMISSION]: {
    defaultMessage: 'Delete class',
    description: 'Delete class permission'
  },

  [VIEW_USERS_PERMISSION]: {
    defaultMessage: 'View user',
    description: 'View user permission'
  },
  [ADD_USERS_PERMISSION]: {
    defaultMessage: 'Add user',
    description: 'Add user permission'
  },
  [EDIT_USERS_PERMISSION]: {
    defaultMessage: 'Edit user',
    description: 'Edit user permission'
  },
  [DELETE_USERS_PERMISSION]: {
    defaultMessage: 'Delete user',
    description: 'Delete user permission'
  },

  [ADD_REWARDS_PERMISSION]: {
    defaultMessage: 'Add rewards',
    description: 'Add rewards permission'
  },
  [REDEEM_REWARDS_PERMISSION]: {
    defaultMessage: 'Redeem rewards',
    description: 'Redeem rewards permission'
  },

  [VIEW_INVOICES_PERMISSION]: {
    defaultMessage: 'View invoice',
    description: 'View invoice permission'
  },
  [VIEW_INVOICE_METRICS_PERMISSION]: {
    defaultMessage: 'View invoice metrics',
    description: 'View invoice metrics permission'
  },
  [ADD_INVOICES_PERMISSION]: {
    defaultMessage: 'Add invoice',
    description: 'Add invoice permission'
  },
  [EDIT_INVOICES_PERMISSION]: {
    defaultMessage: 'Edit invoice',
    description: 'Edit invoice permission'
  },
  [DELETE_INVOICES_PERMISSION]: {
    defaultMessage: 'Delete invoice',
    description: 'Delete invoice permission'
  },

  [VIEW_EMAIL_TEMPLATES_PERMISSION]: {
    defaultMessage: 'View email template',
    description: 'View email template permission'
  },
  [ADD_EMAIL_TEMPLATES_PERMISSION]: {
    defaultMessage: 'Add email template',
    description: 'Add email template permission'
  },
  [EDIT_EMAIL_TEMPLATES_PERMISSION]: {
    defaultMessage: 'Edit email template',
    description: 'Edit email template permission'
  },
  [DELETE_EMAIL_TEMPLATES_PERMISSION]: {
    defaultMessage: 'Delete email template',
    description: 'Delete email template permission'
  },

  [VIEW_ROLES_PERMISSION]: {
    defaultMessage: 'View user roles',
    description: 'View user roles permission'
  },
  [ADD_ROLES_PERMISSION]: {
    defaultMessage: 'Add user roles',
    description: 'Add user roles permission'
  },
  [EDIT_ROLES_PERMISSION]: {
    defaultMessage: 'Edit user roles',
    description: 'Edit user roles permission'
  },
  [DELETE_ROLES_PERMISSION]: {
    defaultMessage: 'Delete user roles',
    description: 'Delete user roles permission'
  },

  [VIEW_SETTINGS_PERMISSION]: {
    defaultMessage: 'View settings',
    description: 'View settings permission'
  },
  [EDIT_SETTINGS_PERMISSION]: {
    defaultMessage: 'Edit settings',
    description: 'Edit settings permission'
  },

  [VIEW_PURCHASE_ORDERS_PERMISSION]: {
    defaultMessage: 'View purchase orders',
    description: 'View purchase orders permission'
  },
  [ADD_PURCHASE_ORDERS_PERMISSION]: {
    defaultMessage: 'Add purchase orders',
    description: 'Add purchase orders permission'
  },
  [EDIT_PURCHASE_ORDERS_PERMISSION]: {
    defaultMessage: 'Edit purchase orders',
    description: 'Edit purchase orders permission'
  },
  [DELETE_PURCHASE_ORDERS_PERMISSION]: {
    defaultMessage: 'Delete purchase orders',
    description: 'Delete purchase orders permission'
  },

  [VIEW_ASSETS_PERMISSION]: {
    defaultMessage: 'View assets',
    description: 'View assets permission'
  },
  [ADD_ASSETS_PERMISSION]: {
    defaultMessage: 'Add assets',
    description: 'Add assets permission'
  },
  [EDIT_ASSETS_PERMISSION]: {
    defaultMessage: 'Edit assets',
    description: 'Edit assets permission'
  },
  [DELETE_ASSETS_PERMISSION]: {
    defaultMessage: 'Delete assets',
    description: 'Delete assets permission'
  },
});

export const getDashboardPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_DASHBOARD_PERMISSION],
      value: VIEW_DASHBOARD_PERMISSION
    }
  ];
};

export const getStudentsPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_STUDENTS_PERMISSION],
      value: VIEW_STUDENTS_PERMISSION
    },
    {
      labelKey: messages[ADD_STUDENTS_PERMISSION],
      value: ADD_STUDENTS_PERMISSION
    },
    {
      labelKey: messages[EDIT_STUDENTS_PERMISSION],
      value: EDIT_STUDENTS_PERMISSION
    },
    {
      labelKey: messages[DELETE_STUDENTS_PERMISSION],
      value: DELETE_STUDENTS_PERMISSION
    },
    {
      labelKey: messages[PROMOTE_STUDENTS_PERMISSION],
      value: PROMOTE_STUDENTS_PERMISSION
    },
    {
      labelKey: messages[ADD_STUDENTS_HIFZ_ENTRY],
      value: ADD_STUDENTS_HIFZ_ENTRY
    },
    {
      labelKey: messages[DELETE_STUDENTS_HIFZ_ENTRY],
      value: DELETE_STUDENTS_HIFZ_ENTRY
    },
    {
      labelKey: messages[ADD_STUDENTS_HIFZ_SANAD_ENTRY],
      value: ADD_STUDENTS_HIFZ_SANAD_ENTRY
    }
  ];
};

export const getClassAttendancePermissions = () => {
  return [
    {
      labelKey: messages[VIEW_MY_CLASS_ATTENDANCE_PERMISSION],
      value: VIEW_MY_CLASS_ATTENDANCE_PERMISSION
    },
    {
      labelKey: messages[TAKE_MY_CLASS_ATTENDANCE_PERMISSION],
      value: TAKE_MY_CLASS_ATTENDANCE_PERMISSION
    },
    {
      labelKey: messages[VIEW_ALL_CLASS_ATTENDANCE_PERMISSION],
      value: VIEW_ALL_CLASS_ATTENDANCE_PERMISSION
    },
    {
      labelKey: messages[TAKE_ALL_CLASS_ATTENDANCE_PERMISSION],
      value: TAKE_ALL_CLASS_ATTENDANCE_PERMISSION
    }
  ];
};

export const getTeachersAttendancePermissions = () => {
  return [
    {
      labelKey: messages[VIEW_TEACHERS_ATTENDANCE_PERMISSION],
      value: VIEW_TEACHERS_ATTENDANCE_PERMISSION
    },
    {
      labelKey: messages[TAKE_TEACHERS_ATTENDANCE_PERMISSION],
      value: TAKE_TEACHERS_ATTENDANCE_PERMISSION
    }
  ];
};

export const getClassPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_CLASSES_PERMISSION],
      value: VIEW_CLASSES_PERMISSION
    },
    {
      labelKey: messages[ADD_CLASSES_PERMISSION],
      value: ADD_CLASSES_PERMISSION
    },
    {
      labelKey: messages[EDIT_CLASSES_PERMISSION],
      value: EDIT_CLASSES_PERMISSION
    },
    {
      labelKey: messages[DELETE_CLASSES_PERMISSION],
      value: DELETE_CLASSES_PERMISSION
    }
  ];
};

export const getUsersPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_USERS_PERMISSION],
      value: VIEW_USERS_PERMISSION
    },
    {
      labelKey: messages[ADD_USERS_PERMISSION],
      value: ADD_USERS_PERMISSION
    },
    {
      labelKey: messages[EDIT_USERS_PERMISSION],
      value: EDIT_USERS_PERMISSION
    },
    {
      labelKey: messages[DELETE_USERS_PERMISSION],
      value: DELETE_USERS_PERMISSION
    }
  ];
};

export const getRewardsPermissions = () => {
  return [
    {
      labelKey: messages[ADD_REWARDS_PERMISSION],
      value: ADD_REWARDS_PERMISSION
    },
    {
      labelKey: messages[REDEEM_REWARDS_PERMISSION],
      value: REDEEM_REWARDS_PERMISSION
    }
  ];
};

export const getInvoicesPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_INVOICES_PERMISSION],
      value: VIEW_INVOICES_PERMISSION
    },
    {
      labelKey: messages[VIEW_INVOICE_METRICS_PERMISSION],
      value: VIEW_INVOICE_METRICS_PERMISSION
    },
    {
      labelKey: messages[ADD_INVOICES_PERMISSION],
      value: ADD_INVOICES_PERMISSION
    },
    {
      labelKey: messages[EDIT_INVOICES_PERMISSION],
      value: EDIT_INVOICES_PERMISSION
    },
    {
      labelKey: messages[DELETE_INVOICES_PERMISSION],
      value: DELETE_INVOICES_PERMISSION
    }
  ];
};

export const getEmailTemplatesPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_EMAIL_TEMPLATES_PERMISSION],
      value: VIEW_EMAIL_TEMPLATES_PERMISSION
    },
    {
      labelKey: messages[ADD_EMAIL_TEMPLATES_PERMISSION],
      value: ADD_EMAIL_TEMPLATES_PERMISSION
    },
    {
      labelKey: messages[EDIT_EMAIL_TEMPLATES_PERMISSION],
      value: EDIT_EMAIL_TEMPLATES_PERMISSION
    },
    {
      labelKey: messages[DELETE_EMAIL_TEMPLATES_PERMISSION],
      value: DELETE_EMAIL_TEMPLATES_PERMISSION
    }
  ];
};

export const getUserRolesPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_ROLES_PERMISSION],
      value: VIEW_ROLES_PERMISSION
    },
    {
      labelKey: messages[ADD_ROLES_PERMISSION],
      value: ADD_ROLES_PERMISSION
    },
    {
      labelKey: messages[EDIT_ROLES_PERMISSION],
      value: EDIT_ROLES_PERMISSION
    },
    {
      labelKey: messages[DELETE_ROLES_PERMISSION],
      value: DELETE_ROLES_PERMISSION
    }
  ];
};

export const getSettingsPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_SETTINGS_PERMISSION],
      value: VIEW_SETTINGS_PERMISSION
    },
    {
      labelKey: messages[EDIT_SETTINGS_PERMISSION],
      value: EDIT_SETTINGS_PERMISSION
    }
  ];
};

export const getPurchaseOrdersPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_PURCHASE_ORDERS_PERMISSION],
      value: VIEW_PURCHASE_ORDERS_PERMISSION
    },
    {
      labelKey: messages[ADD_PURCHASE_ORDERS_PERMISSION],
      value: ADD_PURCHASE_ORDERS_PERMISSION
    },
    {
      labelKey: messages[EDIT_PURCHASE_ORDERS_PERMISSION],
      value: EDIT_PURCHASE_ORDERS_PERMISSION
    },
    {
      labelKey: messages[DELETE_PURCHASE_ORDERS_PERMISSION],
      value: DELETE_PURCHASE_ORDERS_PERMISSION
    }
  ];
};

export const getAssetsPermissions = () => {
  return [
    {
      labelKey: messages[VIEW_ASSETS_PERMISSION],
      value: VIEW_ASSETS_PERMISSION
    },
    {
      labelKey: messages[ADD_ASSETS_PERMISSION],
      value: ADD_ASSETS_PERMISSION
    },
    {
      labelKey: messages[EDIT_ASSETS_PERMISSION],
      value: EDIT_ASSETS_PERMISSION
    },
    {
      labelKey: messages[DELETE_ASSETS_PERMISSION],
      value: DELETE_ASSETS_PERMISSION
    }
  ];
};

export const getPermissions = () => {
  return [
    ...getDashboardPermissions(),
    ...getStudentsPermissions(),
    ...getClassAttendancePermissions(),
    ...getTeachersAttendancePermissions(),
    ...getClassPermissions(),
    ...getUsersPermissions(),
    ...getRewardsPermissions(),
    ...getInvoicesPermissions(),
    ...getEmailTemplatesPermissions(),
    ...getUserRolesPermissions(),
    ...getSettingsPermissions(),
    ...getPurchaseOrdersPermissions(),
    ...getAssetsPermissions()
  ];
};

import { useContext, useState } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useTeacherAttendanceData = () => {
  const appContext = useContext(AppContext);
  const [teachers, setTeachers] = useState([]);
  const [teacherAttendanceHistory, setTeacherAttendanceHistory] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTeachersAttendance = (date, session) => {
    if (!appContext.tenant._id) {
      return;
    }

    setLoading(true);
    const dataService = new DataService();
    dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/teacherattendance/${date}/${session}`)
      .then(dataService.handleResponse)
      .then((response) => {
        setTeachers(response.teachers);
        setLoading(false);
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const saveTeacherAttendance = (attendance) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post(`/api/ui/${appContext.tenant._id}/attendance/v1/teacherAttendance`, attendance)
      .then(dataService.handleResponse)
      .then((response) => {
        console.log('response from save teacher attendance', response);
        setLoading(false);
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const fetchTeacherAttendanceReport = (startDate, endDate) => {
    if (startDate && endDate) {
      setLoading(true);
      const dataService = new DataService();
      dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/teacherattendancereport/${startDate}/${endDate}`)
        .then(dataService.handleResponse)
        .then((response) => {
          setTeachers(response.teachers);
          setLoading(false);
        }).catch((err) => {
          console.log('Error::', err);
          setError(err);
          setLoading(false);
        });
    }
  };
  //
  // const fetchClassAttendanceHistory = (classId, date, endDate) => {
  //   setLoading(true);
  //   const dataService = new DataService();
  //   dataService.getItems(`/api/ui/attendance/v1/classattendancehistory/${classId}/${date}/${endDate}`)
  //     .then(dataService.handleResponse)
  //     .then((response) => {
  //       setAttendanceHistory(response.attendanceHistory);
  //       setLoading(false);
  //     }).catch((err) => {
  //       console.log('Error::', err);
  //       setError(err);
  //       setLoading(false);
  //     });
  // };
  //
  const fetchTeacherAttendanceHistory = (userId, startDate, endDate) => {
    if (userId && startDate && endDate) {
      setLoading(true);
      const dataService = new DataService();
      dataService.getItems(`/api/ui/${appContext.tenant._id}/attendance/v1/teacherattendancehistory/${startDate}/${endDate}/${userId}`)
        .then(dataService.handleResponse)
        .then((response) => {
          setTeacherAttendanceHistory(response);
          setLoading(false);
        }).catch((err) => {
          console.log('Error::', err);
          setError(err);
          setLoading(false);
        });
    }
  };

  return {
    teachers,
    loading,
    error,
    fetchTeachersAttendance,
    saveTeacherAttendance,
    fetchTeacherAttendanceReport,
    fetchTeacherAttendanceHistory,
    teacherAttendanceHistory
  };
};

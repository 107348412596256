import { isAfter } from 'date-fns';

const PaymentMetricsHelper = {
  getPaymentMetrics(invoices, invType) {
    const metrics = {
      feesMetrics: {
        invoiceCount: 0,
        Paid: 0,
        'Part paid': 0,
        Unpaid: 0,
        'Inst. unpaid': 0,
        Waiver: 0
      },
      paymentModeMetrics: {
        Online: 0,
        Cheque: 0,
        Cash: 0
      },
      paymentModeAmountMetrics: {
        Online: 0,
        Cheque: 0,
        Cash: 0
      },
      paymentOptionMetrics: {
        'Full Payment': 0,
        Installment: 0,
        Waiver: 0
      },
      paymentBreakdownMetrics: {
        fees: 0,
        niyaz: 0
      }
    };

    if (invoices) {
      invoices.forEach((invoice) => {
        const summary = this.getInvoiceStatus(invoice, invType);
        if (invoice.type === invType || invType === 'All') {
          metrics.feesMetrics[summary.status] += 1;
          metrics.paymentOptionMetrics[invoice.paymentOption] += 1;

          metrics.paymentModeMetrics.Online += summary.paymentMode.Online;
          metrics.paymentModeMetrics.Cheque += summary.paymentMode.Cheque;
          metrics.paymentModeMetrics.Cash += summary.paymentMode.Cash;
          metrics.paymentBreakdownMetrics.fees += summary.paymentBreakdown.fees;
          metrics.paymentBreakdownMetrics.niyaz += summary.paymentBreakdown.niyaz;
          metrics.paymentModeAmountMetrics.Online += summary.paymentModeAmount.Online;
          metrics.paymentModeAmountMetrics.Cheque += summary.paymentModeAmount.Cheque;
          metrics.paymentModeAmountMetrics.Cash += summary.paymentModeAmount.Cash;

          metrics.feesMetrics.invoiceCount += 1;
        }
      });
    }
    return metrics;
  },

  getInvoiceStatus(invoice, invType = 'Fees') {
    // let status = 'Unpaid';
    const invoiceAmt = invoice.amount;
    let paidAmt = 0;
    const summary = {
      paymentMode: {
        Online: 0,
        Cheque: 0,
        Cash: 0
      },
      paymentModeAmount: {
        Online: 0,
        Cheque: 0,
        Cash: 0
      },
      status: 'Unpaid',
      paymentBreakdown: {
        fees: 0,
        niyaz: 0
      }
    };

    let paymentOverdue = false;
    if (invoice.payments) {
      invoice.payments.forEach((payment) => {
        if (payment.status === 'Paid') {
          paidAmt += payment.amount;
          // set payment breakdown in fees and niyaz
          if (invoice.type === 'Fees') {
            summary.paymentBreakdown.fees += payment.amount;
          } else if (invoice.type !== 'Fees') {
            summary.paymentBreakdown.niyaz += payment.amount;
          }
          // set payment mode
          if (summary.paymentMode[payment.paymentMode] === 0 && (invoice.type === invType || invType === 'All')) {
            summary.paymentMode[payment.paymentMode] += 1;
          }
          summary.paymentModeAmount[payment.paymentMode] += payment.amount;
        } else if (payment.status === 'Unpaid' && isAfter(new Date(), new Date(payment.dueDate))) {
          paymentOverdue = true;
        }
      });
    }

    if (invoice.paymentOption === 'Waiver') {
      summary.status = 'Waiver';
    } else if (paidAmt === 0) {
      summary.status = 'Unpaid';
    } else if (paidAmt < invoiceAmt && !paymentOverdue) {
      summary.status = 'Part paid';
    } else if (paymentOverdue) {
      summary.status = 'Inst. unpaid';
    } else {
      summary.status = 'Paid';
    }
    return summary;
  }
};

export default PaymentMetricsHelper;

import React from 'react';
import PropTypes from 'prop-types';

import AttendanceList from '../visualization/AttendanceList';
import ClassList from '../visualization/ClassList';
import router from '../../Routes';

const StudentAttendancePanel = ({ selectedClass, mode, tenantId }) => {
  const renderClasses = () => {
    return (
      <ClassList
        selectedClass={selectedClass}
        showAllLast={mode === 'report'}
        onClassSelected={(classId) => {
          if (mode === 'report') {
            router.setRoute(`/${tenantId}/attendance/report/${classId}`);
          } else {
            router.setRoute(`/${tenantId}/attendance/mark-attendance/${classId}`);
          }
        }}
      />
    );
  };

  return (
    <section className='student-attendance'>
      {renderClasses()}
      <AttendanceList selectedClass={selectedClass} mode={mode} />
    </section>
  );
};

StudentAttendancePanel.propTypes = {
  selectedClass: PropTypes.string,
  mode: PropTypes.string,
  tenantId: PropTypes.string
};

export default StudentAttendancePanel;

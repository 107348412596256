import React from 'react';
import PropTypes from 'prop-types';

import AssetList from '../visualization/AssetList';

const AssetsPanel = ({ tenantId }) => {
  return (
    <section>
      <AssetList tenantId={tenantId} />
    </section>
  );
};

AssetsPanel.propTypes = {
  tenantId: PropTypes.string
};

export default AssetsPanel;

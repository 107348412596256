import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import {format} from 'date-fns';
import { CommonTable, Search, ToggleButton, ActiveRenderer } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import ClassForm from '../forms/ClassForm';
import Footer from './Footer';
import XLSExporter from '../../utils/XLSExporter';

import MdDelete from '../../styles/assets/delete.svg';
import FaChevronRight from '../../styles/assets/chevron.svg';
import NoData from '../NoData';
import { DATE_FORMAT_UTC } from '../../utils/DateUtils';

import { ClassRenderer, ClassTeacherRenderer } from '../tables/TableCellRenderer';
import HasPermissions from '../HasPermissions';
import { useClassData } from '../hooks/useClassData';
import { MBLClass } from '../hooks/InitialCopies';
import { hasPermissions } from '../../utils/hasPermissions';
import {
  VIEW_CLASSES_PERMISSION, ADD_CLASSES_PERMISSION, EDIT_CLASSES_PERMISSION, DELETE_CLASSES_PERMISSION
} from '../../utils/permissions';

const Classes = () => {
  const {
    classes, deleteClass, saveClass, loading
  } = useClassData();
  const canEditClasses = hasPermissions([EDIT_CLASSES_PERMISSION]);

  const [filteredClasses, setFilteredClasses] = useState(classes);
  const [filter, setFilter] = useState({
    activeFilter: 'Active',
    searchText: ''
  });
  const [showClassPanel, setShowClassPanel] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteClassId, setDeleteClassId] = useState('');
  const [mode, setMode] = useState('add'); // can be add or edit
  const [selectedClass, setSelectedClass] = useState({});

  useEffect(() => {
    const fc = [];
    classes.forEach((cls) => {
      const searchAttribs = [cls.name, cls.division, cls.room,
        cls.teacher.firstName, cls.teacher.lastName
      ];
      if (!filter.searchText || searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
        if (filter.activeFilter === 'All' || (cls.active !== false && filter.activeFilter === 'Active') || (cls.active === false && filter.activeFilter === 'Inactive')) {
          fc.push(cls);
        }
      }
    });
    setFilteredClasses(fc);
  }, [classes, filter]);

  const getTitleRow = () => {
    return (
      <div className='classes--list__title-row'>
        <Search
          placeholder='Search'
          value={filter.searchText}
          onChange={(e) => setFilter({ ...filter, searchText: e.target.value })}
          onClear={() => setFilter({ ...filter, searchText: '' })}
        />
        <div className='count'>
          <span>{`Displaying ${filteredClasses.length} of ${classes.length} classes`}</span>
        </div>
        <div className='buttons-cell'>
          <ToggleButton
            onClick={(active) => {
              setFilter({ ...filter, activeFilter: active });
            }}
            items={[
              { name: 'All', label: 'All', pressed: filter.activeFilter === 'All' },
              { name: 'Active', label: 'Active', pressed: filter.activeFilter === 'Active' },
              { name: 'Inactive', label: 'Inactive', pressed: filter.activeFilter === 'Inactive' }]}
          />
          <HasPermissions
            permissions={[
              ADD_CLASSES_PERMISSION
            ]}
          >
            <button
              className='mbl-button primary-button'
              onClick={() => {
                setMode('add');
                setSelectedClass(MBLClass);
                setShowClassPanel(true);
              }}
            >
              Add class
            </button>
          </HasPermissions>
          <HasPermissions
            permissions={[
              ADD_CLASSES_PERMISSION
            ]}
          >
            <button
              className='mbl-button primary-button'
              onClick={() => {
                handleExportClick();
              }}
            >
              Export
            </button>
          </HasPermissions>
          <div style={{ width: '10px' }} />
        </div>
      </div>
    );
  };

  const handleExportClick = (selected) => {
    if (filteredClasses.length > 0) {
      const classArr = [];
      filteredClasses.forEach((cl) => {
        const cls = {
          'Year': cl.name,
          'Division': cl.division,
          'Room': cl.room,
          'Teacher first name': cl.teacher.firstName,
          'Teacher last name': cl.teacher.lastName,
          'Teacher email': cl.teacher.email
        };
        if (selected) {
          if (selectedIds.includes(cl._id)) {
            classArr.push(cls);
          }
        } else {
          classArr.push(cls);
        }
      });
      XLSExporter.export({
        items: classArr,
        sheetName: 'Classes',
        fileName: `Madrasah-Classes_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`,
        colsWidth: [10, 10, 10, 15, 15, 25]
      });
    }
  };

  let mouseDown = 0;
  const rowEventHandlers = {
    onMouseUp: (e) => {
      const elementClicked = e.event.target.tagName.toLowerCase();
      console.log(elementClicked);
      if (elementClicked !== 'svg' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
        if (canEditClasses) {
          setMode('edit');
        } else {
          setMode('view');
        }
        setSelectedClass(e.rowData);
        setShowClassPanel(true);
      }
    },
    onMouseDown: (e) => {
      mouseDown = e.event.clientX;
    }
  };

  const renderClasses = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'year') {
        return ClassRenderer(cell.rowData);
      } if (cell.column.key === 'active') {
        return ActiveRenderer(cell.rowData);
      } if (cell.column.key === 'actions') {
        return renderActions(cell.rowData._id);
      } if (cell.column.key === 'teacher') {
        return ClassTeacherRenderer(cell.rowData);
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    return (
      <CommonTable
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        selectable
        onSelectAll={() => {}}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        rowEventHandlers={rowEventHandlers}
        columns={[
          {
            width: 75, title: 'Order', dataKey: 'order', key: 'order', resizable: true, frozen: true
          },
          {
            width: 150, title: 'Year', dataKey: 'year', key: 'year', resizable: true, frozen: true
          },
          {
            width: 150, title: 'Division', dataKey: 'division', key: 'division', resizable: true, frozen: true
          },
          {
            width: 100, title: 'Room #', dataKey: 'room', key: 'room', resizable: true
          },
          {
            width: 300, title: 'Class teacher', dataKey: 'teacher', key: 'teacher', resizable: true
          },
          {
            width: 100, title: 'Active', dataKey: 'active', key: 'active', resizable: true
          },
          {
            width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
          }
        ]}
        data={filteredClasses}
      />
    );
  };

  const renderActions = (id) => {
    return (
      <HasPermissions permissions={[DELETE_CLASSES_PERMISSION]}>
        <div className='actions'>
          <div className='actions-icons'>
            <MdDelete
              className='action-icon'
              onClick={() => {
                setDeleteClassId(id);
                setShowDeleteConfirmation(true);
              }}
            />
          </div>
        </div>
      </HasPermissions>
    );
  };

  const getClassName = (id) => {
    let clsName = '';
    const cls = classes.find((c) => c._id === id);
    if (cls) {
      clsName = `${cls.name} ${cls.division}`;
    }
    return clsName;
  };

  const renderDeleteConfirmation = () => {
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showDeleteConfirmation}
        title={`Delete Class - ${getClassName(deleteClassId)}`}
        from='right'
        width='50vw'
        onRequestClose={() => {
          setShowDeleteConfirmation(false);
          setDeleteClassId('');
        }}
      >
        <div style={{ fontSize: '18px' }}>Are you sure you want to delete the class?</div>
        <Footer
          onCancel={() => {
            setShowDeleteConfirmation(false);
            setDeleteClassId('');
          }}
          onSave={async () => {
            await deleteClass(deleteClassId);
            setDeleteClassId('');
            setShowDeleteConfirmation(false);
          }}
        />
      </SlidingPane>
    );
  };

  const renderClassPanel = () => {
    const getTitle = () => {
      let title = 'Add class';
      if (mode === 'edit') {
        title = 'Edit class';
        let clsName = '';
        if (selectedClass) {
          clsName = `${selectedClass.name} ${selectedClass.division}`;
        }
        return `${title} - ${clsName}`;
      }
      return title;
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showClassPanel}
        title={getTitle()}
        from='right'
        width='50vw'
        onRequestClose={() => {
          setSelectedClass({});
          setShowClassPanel(false);
        }}
      >
        <ClassForm
          mblClass={{ ...selectedClass, teacher: selectedClass.teacher?._id }}
          mode={mode}
          onCancel={() => {
            setSelectedClass({});
            setShowClassPanel(false);
          }}
          onSave={async (fields) => {
            await saveClass(fields, mode);
            setShowClassPanel(false);
          }}
        />
      </SlidingPane>
    );
  };

  // const showLoading = () => {
  //   if (isLoading) {
  //     return (<Loading />);
  //   }
  //   return null;
  // };

  return (
    <HasPermissions
      permissions={[
        VIEW_CLASSES_PERMISSION, ADD_CLASSES_PERMISSION, EDIT_CLASSES_PERMISSION, DELETE_CLASSES_PERMISSION
      ]}
    >
      <div className='classes--list'>
        {/* this.showLoading() */}
        {renderDeleteConfirmation()}
        {renderClassPanel()}
        {getTitleRow()}
        {renderClasses()}
      </div>
    </HasPermissions>
  );
};

export default Classes;

import { format, sub } from 'date-fns';
import { DATE_FORMAT_UTC } from '../../utils/DateUtils';

export const today = format(new Date(), DATE_FORMAT_UTC);
export const Student = {
  firstName: '',
  illnessDetails: null,
  allergyDetails: null,
  DOB: '',
  lastName: '',
  itsId: 0,
  specialNeeds: 'No',
  knownAllergies: 'No',
  specialNeedsDetails: null,
  classId: '',
  active: true,
  gpPractice: {
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    postCode: '',
    phone: ''
  },
  knownIllness: 'No',
  school: {
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    postCode: ''
  },
  address: {
    addressLine1: '',
    addressLine2: null,
    city: '',
    county: '',
    postCode: ''
  },
  mother: {
    firstName: '',
    lastName: '',
    itsId: 0,
    email: '',
    phone: ''
  },
  father: {
    firstName: '',
    lastName: '',
    itsId: 0,
    email: '',
    phone: ''
  },
  gender: 'M'
};

export const Tenant = {
  name: '',
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    itsId: 0,
    gender: 'M',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      county: '',
      postCode: '',
      country: 'United Kingdom'
    }
  },
  active: false,
  verified: false
};

export const MBLClass = {
  name: '',
  room: '',
  teacher: '',
  order: 0,
  division: '',
  active: true
};

export const User = {
  itsId: 0,
  firstName: '',
  lastName: '',
  gender: 'M',
  username: '',
  email: '',
  active: true,
  roles: [],
  addressLine1: '',
  addressLine2: '',
  city: '',
  county: '',
  postCode: '',
  country: 'United Kingdom',
  phone: ''
};

export const userRole = {
  name: '',
  description: '',
  active: true,
  permissions: []
};

export const coinsData = {
  studentId: '',
  coins: {
    count: 0,
    date: today,
    academicYear: '',
    classId: '',
    comment: ''
  }
};

export const coinsReport = {
  startDate: format(sub(new Date(), { months: 3 }), DATE_FORMAT_UTC),
  endDate: today
};

export const emailTemplate = {
  name: '',
  description: '',
  content: '',
  active: true,
  subject: ''
};

export const INVOICE_DATA = {
  studentId: '',
  type: 'Fees',
  amount: 300,
  paymentOption: 'None',
  dueDate: today,
  academicYear: '',
  comment: ''
};

export const INVOICE = {
  studentId: '',
  type: 'Fees',
  amount: 0,
  paymentOption: 'None',
  dueDate: today,
  academicYear: '',
  comments: '',
  payments: []
};

export const PAYMENT = {
  orderNumber: 0,
  amount: 0,
  paymentMode: 'NA',
  dueDate: today,
  paidDate: '',
  paidBy: '',
  status: 'Unpaid',
  comment: ''
};

export const SETTINGS = {
  academicYears: [],
  emailServer: {
    host: '',
    port: 0,
    username: '',
    password: ''
  },
  rocketChat: {
    httpUrl: '',
    httpsUrl: '',
    userId: '',
    authToken: ''
  }
};

export const ASSET = {
  name: '',
  description: '',
  category: '',
  type: '',
  purchaseDate: today,
  purchaseCost: 0,
  location: '',
  owner: '',
  state: '',
  active: true
};

export const HIFZ_TIMELINE_ENTRY = {
  entryType: '',
  milestoneType: '',
  date: today,
  surah: '',
  siparo: '',
  aayat: '',
  text: ''
};

import { useContext, useMemo } from 'react';
import AppContext from '../components/AppContext';

export const hasPermissions = (permissions) => {
  const appContext = useContext(AppContext);
  return useMemo(() => {
    if (permissions && permissions.length > 0) {
      const userPermissions = appContext.user?.permissions || [];
      return permissions.some(
        perm => userPermissions.includes(perm)
      );
    }
    return false;
  }, [permissions, appContext.user]);
};

import React from 'react';
import PropTypes from 'prop-types';

import StudentList from '../visualization/RewardStudentList';

const RewardsPanel = ({ selectedClass, tenantId }) => {
  return (
    <section>
      <StudentList selectedClass={selectedClass} tenantId={tenantId} />
    </section>
  );
};

RewardsPanel.propTypes = {
  selectedClass: PropTypes.string,
  tenantId: PropTypes.string
};

export default RewardsPanel;

export const getHifzSanadList = () => {
  return [
    { label: 'Asr', value: 'ASR' },
    { label: 'Balad', value: 'BALAD' },
    { label: 'Inshiqaaq', value: 'INSHIQAAQ' },
    { label: 'Juz Amma', value: 'JUZ_AMMA' },
    { label: 'Sanah Ula', value: 'SANAH_ULA' },
    { label: 'Sanah Saniyah', value: 'SANAH_SANIYAH' },
    { label: 'Sanah Salesa', value: 'SANAH_SALESA' },
    { label: '27 & 26', value: '27_26' },
    { label: '29 & 28', value: '29_28' },
    { label: 'Hafiz', value: 'HAFIZ' }
  ];
};

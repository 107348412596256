import React, { Component } from 'react';

class CollaboratePanel extends Component {
  constructor() {
    super();
  }

  render() {
    console.log('CollaboratePanel...');
    return (
      <iframe width='100%;' height='100%' src='https://collaborate.mbl.com' />
    );
  }
}

export default CollaboratePanel;

import React from 'react';
import { Donut, CommonTable as Table } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
import DashboardWidget from './DashboardWidget';

import { useDashboardData } from '../hooks/useDashboardData';

const Dashboard = () => {
  const {
    teachersStats, studentsStats, pendingAttendance, loading
  } = useDashboardData();

  const showLoading = () => {
    if (loading) {
      return <Loading />;
    }
    return null;
  };

  const renderMissingAttendanceTable = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'year') {
        return `${cell.rowData.name} ${cell.rowData.division}`;
      }
      if (cell.column.key === 'teacher') {
        return `${cell.rowData.teacher.firstName} ${cell.rowData.teacher.lastName}`;
      }
      if (cell.column.key === 'index') {
        return cell.rowIndex + 1;
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    return (
      <Table
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        columns={[
          {
            width: 100, title: '#', dataKey: 'index', key: 'index', resizable: true
          },
          {
            width: 100, title: 'Year', dataKey: 'year', key: 'year', resizable: true
          },
          {
            width: 300, title: 'Teacher', dataKey: 'teacher', key: 'teacher', resizable: true
          },
          {
            width: 100, title: 'Room #', dataKey: 'room', key: 'room', resizable: true
          }
        ]}
        data={pendingAttendance}
      />
    );
  };

  const width = 200;
  const height = 200;
  const minViewportSize = Math.min(width, height);
  // This sets the radius of the pie chart to fit within
  // the current window size, with some additional padding
  const radius = (minViewportSize * 0.9) / 2;

  const studentAttendanceData = [
    {
      value: studentsStats.studentsPresent || 0,
      label: studentsStats.studentsPresent || 0,
      tooltip: studentsStats.studentsPresent || 0,
      color: '#24a335',
      legend: 'Present'
    },
    {
      value: (studentsStats.totalStudents - studentsStats.studentsPresent) || 0,
      label: (studentsStats.totalStudents - studentsStats.studentsPresent) || 0,
      tooltip: (studentsStats.totalStudents - studentsStats.studentsPresent) || 0,
      color: '#d91414',
      legend: 'Absent'
    }
  ];

  const teacherAttendanceData = [
    {
      value: teachersStats.teachersPresent || 0,
      label: teachersStats.teachersPresent || 0,
      tooltip: teachersStats.teachersPresent || 0,
      color: '#24a335',
      legend: 'Present'
    },
    {
      value: (teachersStats.totalTeachers - teachersStats.teachersPresent) || 0,
      label: (teachersStats.totalTeachers - teachersStats.teachersPresent) || 0,
      tooltip: (teachersStats.totalTeachers - teachersStats.teachersPresent) || 0,
      color: '#d91414',
      legend: 'Absent'
    }
  ];

  return (
    <div className='mbl--dashboard'>
      {showLoading()}
      <div className='row row1'>
        <DashboardWidget title='Student attendance'>
          <Donut
            width={width}
            height={height}
            innerRadius={radius * 0.50}
            outerRadius={radius}
            cornerRadius={2}
            padAngle={0.02}
            data={studentAttendanceData}
          />
        </DashboardWidget>
        <DashboardWidget title='Teacher attendance'>
          <Donut
            width={width}
            height={height}
            innerRadius={radius * 0.50}
            outerRadius={radius}
            cornerRadius={2}
            padAngle={0.02}
            data={teacherAttendanceData}
          />
        </DashboardWidget>
        <DashboardWidget title='Missing ITS Id' metric={studentsStats.studentsWithNoITS} />
      </div>
      <div className='row row2'>
        <div className='widget-title-bar'>Pending class attendance</div>
        <div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
          {renderMissingAttendanceTable()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

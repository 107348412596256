import { useContext, useState } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useLogin = () => {
  const appContext = useContext(AppContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loginUser = (username, password) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post('/login', { username, password })
      .then(dataService.handleResponse)
      .then((response) => {
        console.log('::::::::response', response);
        if (response.success === false) {
          setLoggedIn(false);
          setError(response);
        } else {
          setUser(response.user);
          setLoggedIn(true);
        }
        setLoading(false);
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoggedIn(false);
        setLoading(false);
      });
  };

  const checkLogin = () => {
    const dataService = new DataService();
    dataService.getItems('/checklogin')
      .then(dataService.handleResponse)
      .then((response) => {
        if (response.success === false) {
          setLoggedIn(false);
          // setError(response);
        } else {
          setLoggedIn(true);
          setUser(response.user);
        }
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
      });
  };

  const logoutUser = async () => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems('/logout');
      if (resp.ok) {
        await resp.json();
        setLoggedIn(false);
        setUser({});
      } else {
        console.log(':::::Error logout user', resp);
        throw Error(resp);
      }
    } catch (ex) {
      console.log(':::::Exception logout user', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const changePassword = (password, newPassword) => {
    setLoading(true);
    const dataService = new DataService();
    dataService.post(`/api/ui/${appContext.tenant._id}/users/v1/users/action/changePassword`, { password, newPassword })
      .then(dataService.handleResponse)
      .then((response) => {
        console.log('::::::::response from changePassword', response);
        if (!response.success) {
          setError(response.message);
          setChangePasswordSuccess(false);
          setLoading(false);
        } else {
          setChangePasswordSuccess(true);
          setLoading(false);
        }
      }).catch((err) => {
        console.log('Error::', err);
        setError(err);
        setLoading(false);
      });
  };

  const resetPassword = async (username) => {
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.post('/reset-password', { username });
      if (resp.ok) {
        setError(null);
        setResetPasswordSuccess(true);
      } else {
        setResetPasswordSuccess(false);
        console.log(':::::Error resetting password', resp);
        const errResp = await resp.json();
        setError(errResp);
        throw Error(errResp.message);
      }
    } catch (ex) {
      console.log(':::::Exception resetting password', ex);
    }
    setLoading(false);
  };

  return {
    loggedIn,
    user,
    loading,
    error,
    changePasswordSuccess,
    resetPasswordSuccess,
    loginUser,
    logoutUser,
    checkLogin,
    changePassword,
    resetPassword
  };
};

import React from 'react';
import LoginForm from '../forms/LoginForm';

const LoginPanel = () => {
  return (
    <section>
      <LoginForm />
    </section>
  );
};

export default LoginPanel;

import React, {
  useContext, useState, useMemo, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import readXlsxFile from 'read-excel-file';
import { flatten } from 'flat';
import SlidingPane from 'react-sliding-pane';
import {
  CommonTable as Table, OverflowMenu, OverflowMenuItem, Search, ToggleButton,
  ActiveRenderer, CheckboxRenderer, HeaderCheckboxRenderer, Column,
  TextArea
} from '@aeblondon-uk/common-ui-components';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import router from '../../Routes';
import Loading from '../Loading';
import NoData from '../NoData';
// import Notification from './Notification';
import StudentForm from '../forms/StudentForm';
import XLSExporter from '../../utils/XLSExporter';
import { pathToObject } from '../../utils/PathToJSON';
import Footer from './Footer';
import {
  FullNameRenderer, DateRenderer, ClassRenderer
} from '../tables/TableCellRenderer';
import HasPermissions from '../HasPermissions';
import HifzEntryForm from '../forms/HifzEntryForm';

import MdDelete from '../../styles/assets/delete.svg';
import MdQuran from '../../styles/assets/quran1.svg';
import MdMenu from '../../styles/assets/menu.svg';
import FaFilter from '../../styles/assets/filter.svg';
import FaChevronRight from '../../styles/assets/chevron.svg';
import MdEdit from '../../styles/assets/edit.svg';
import MdCertificate from '../../styles/assets/certificate.svg';
import MdTarget from '../../styles/assets/target.svg';
import MdReading from '../../styles/assets/reading.svg';
import ChartIcon from '../../styles/assets/chart.svg';

import { DATE_FORMAT_SHORT_MONTH, DATE_FORMAT_UTC } from '../../utils/DateUtils';
import { hasPermissions } from '../../utils/hasPermissions';
import {
  VIEW_STUDENTS_PERMISSION, ADD_STUDENTS_PERMISSION, EDIT_STUDENTS_PERMISSION,
  DELETE_STUDENTS_PERMISSION, PROMOTE_STUDENTS_PERMISSION, ADD_STUDENTS_HIFZ_ENTRY, DELETE_STUDENTS_HIFZ_ENTRY, ADD_STUDENTS_HIFZ_SANAD_ENTRY,
  VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, VIEW_EMAIL_TEMPLATES_PERMISSION
} from '../../utils/permissions';

import AppContext from '../AppContext';
import useStudentData from '../hooks/useStudentData';
import { useClassData } from '../hooks/useClassData';
import { Student } from '../hooks/InitialCopies';
import AttendanceChart from '../panel/AttendanceChartPanel';

import 'react-sliding-pane/dist/react-sliding-pane.css';
import StudentFilterSidepanel from './sidepanel/StudentFilterSidepanel';
import { getHifzSanadList } from '../../utils/getHifzSanadList';
import { getTilawatIkhtebarResultList } from '../../utils/getTilawatIkhtebarResultList';
import { getCurrentAcademicYear } from '../../utils/AcademicYears';
import PaymentEmailForm from '../forms/PaymentEmailForm';
import { useInvoiceAndPaymentData } from '../hooks/useInvoiceAndPaymentData';

const StudentList = ({
  showFilterPanel, mblClass, activeFilter, sanadFilter, otherFilter, searchText, surahFilter, siparoFilter, tirFilter
}) => {
  const appContext = useContext(AppContext);
  const {
    students, fetchStudents, saveStudent, changeStudentsClass, promoteStudents, deleteStudent, importStudents, addHifzTimelineEntry, removeHifzTimelineEntry,
    loading, addingTimelineEntry
  } = useStudentData();
  const {
    sendEmail, loading: sendingEmail
  } = useInvoiceAndPaymentData(false);

  const { classes } = useClassData();
  const canEditStudents = hasPermissions([EDIT_STUDENTS_PERMISSION]);

  const [selectedStudent, setSelectedStudent] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDeleteConf, setShowDeleteConf] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showStudentDetails, setShowStudentDetails] = useState(false);
  const [showChangeClassPanel, setShowChangeClassPanel] = useState(false);
  const [selectedClass, setSelectedClass] = useState('');
  const [mode, setMode] = useState('view');
  const [showPromoteStudentsConfirmation, setShowPromoteStudentsConfirmation] = useState(false);
  const [showImportStudentsPanel, setShowImportStudentsPanel] = useState(false);
  const [studentImportMode, setStudentImportMode] = useState('add');
  const [importData, setImportData] = useState([]);
  const [showHifzTimeline, setShowHifzTimeline] = useState(false);
  const [showHifzSanadStatus, setShowHifzSanadStatus] = useState(false);
  const [showStudentAttendanceHistory, setShowStudentAttendanceHistory] = useState(false);
  const [showSendEmailPanel, setShowSendEmailPanel] = useState(false);
  const [showBulkSelect, setShowBulkSelect] = useState(false);
  const [selectedItsIds, setSelectedItsIds] = useState('');

  const filter = {
    searchText: searchText || '',
    activeFilter: activeFilter || 'Active',
    mblClass: mblClass ? (decodeURIComponent(mblClass)).split(',') : [],
    otherFilter: otherFilter ? (decodeURIComponent(otherFilter)).split(',') : [],
    sanadFilter: sanadFilter ? (decodeURIComponent(sanadFilter)).split(',') : [],
    tirFilter: tirFilter ? (decodeURIComponent(tirFilter)).split(',') : [],
    surahFilter: surahFilter || '',
    siparoFilter: siparoFilter || '',
  };

  const currentAcademicYear = useMemo(() => {
    return getCurrentAcademicYear(appContext.settings);
  }, [appContext.settings]);

  const hasMatchingSearchText = useCallback((student) => {
    if (!filter.searchText) {
      return true;
    }
    const searchAttribs = [student.itsId, student.firstName, student.lastName];
    if (student.father) {
      searchAttribs.push(student.father.itsId);
      searchAttribs.push(student.father.firstName);
      searchAttribs.push(student.father.email);
      searchAttribs.push(student.father.phone);
    }
    if (student.mother) {
      searchAttribs.push(student.mother.itsId);
      searchAttribs.push(student.mother.firstName);
      searchAttribs.push(student.mother.email);
      searchAttribs.push(student.mother.phone);
    }
    if (student.address) {
      searchAttribs.push(student.address.city);
      searchAttribs.push(student.address.postCode);
    }
    if (searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
      return true;
    }
    return false;
  }, [filter.searchText]);

  const hasMatchingActiveFilter = useCallback((student) => {
    if (filter.activeFilter === 'All' ||
      (filter.activeFilter === 'Active' && student.active !== false) ||
      (filter.activeFilter === 'Inactive' && student.active === false)) {
      return true;
    }
    return false;
  }, [filter.activeFilter]);

  const hasMatchingClassFilter = useCallback((student) => {
    const hasClassFilter = filter.mblClass.length > 0;
    return !hasClassFilter || filter.mblClass.includes(student.mblClass._id);
  }, [filter.mblClass]);

  const hasMedicalCondition = useCallback((student) => {
    if (!filter.otherFilter.includes('MEDICAL_CONDITION')) {
      return true;
    }
    if (filter.otherFilter.includes('MEDICAL_CONDITION') && (student.knownAllergies === 'Yes' || student.knownIllness === 'Yes' || student.specialNeeds === 'Yes')) {
      return true;
    }
    return false;
  }, [filter.otherFilter]);

  const isItsMissing = useCallback((student) => {
    if (!filter.otherFilter.includes('MISSING_ITS')) {
      return true;
    }
    if (filter.otherFilter.includes('MISSING_ITS') && student.itsId > 99999900) {
      return true;
    }
    return false;
  }, [filter.otherFilter]);

  const hasMatchingSanadFilter = useCallback((student) => {
    if (filter.sanadFilter.length === 0) {
      return true;
    }
    const latestSanad = [...(student.hifzTimeline || [])]?.reverse().find(ht => ht.entryType === 'MILESTONE') || {};
    if (filter.sanadFilter.includes(latestSanad.milestoneType)) {
      return true;
    }
    return false;
  }, [filter.sanadFilter]);

  const hasMatchingTirFilter = useCallback((student) => {
    if (filter.tirFilter.length === 0) {
      return true;
    }
    const latestTir = [...(student.hifzTimeline || [])]?.reverse().find(ht => ht.entryType === 'TILAWAT_IKHTEBAR') || {};
    if (filter.tirFilter.includes(latestTir.milestoneResult)) {
      return true;
    }
    return false;
  }, [filter.tirFilter]);

  const hasMatchingSurahFilter = useCallback((student) => {
    if (!filter.surahFilter) {
      return true;
    }
    const latestHifzStatus = [...(student.hifzTimeline || [])]?.reverse().find(ht => ht.entryType === 'UPDATE') || {};
    if (decodeURIComponent(filter.surahFilter) === latestHifzStatus.surah) {
      return true;
    }
    return false;
  }, [filter.surahFilter]);

  const hasMatchingSiparoFilter = useCallback((student) => {
    if (!filter.siparoFilter) {
      return true;
    }
    const latestHifzStatus = [...(student.hifzTimeline || [])]?.reverse().find(ht => ht.entryType === 'UPDATE') || {};
    if (Number(filter.siparoFilter) === latestHifzStatus.siparo) {
      return true;
    }
    return false;
  }, [filter.siparoFilter]);

  useEffect(() => {
    if (Object.keys(selectedStudent || {}).length > 0) {
      setSelectedStudent(students.find(s => s._id === selectedStudent._id));
    }
  }, [students]);

  const filteredStudents = useMemo(() => {
    return students.filter((st) => {
      return hasMatchingSearchText(st) &&
        hasMatchingActiveFilter(st) &&
        isItsMissing(st) &&
        hasMedicalCondition(st) &&
        hasMatchingClassFilter(st) &&
        hasMatchingSanadFilter(st) &&
        hasMatchingSurahFilter(st) &&
        hasMatchingSiparoFilter(st) &&
        hasMatchingTirFilter(st);
    });
  }, [students, filter]);

  const getGenderBreakdown = useMemo(() => {
    let male = 0;
    let female = 0;
    filteredStudents.forEach((student) => {
      if (student.gender === 'M') {
        male++;
      } else {
        female++;
      }
    });
    return { male, female };
  }, [filteredStudents]);

  const getTitleRow = () => {
    return (
      <div className='students--title-row'>
        <div className='leading'>
          <FaFilter
            onClick={() => {
              toggleRouteParam('showFilterPanel');
            }}
            className='overflow-menu__icon'
            focusable={false}
          />
          <Search
            placeholder='Search'
            value={filter.searchText}
            onChange={(e) => {
              const filterParams = getFilterParams({ ...filter, searchText: e.target.value });
              router.routeTo(`${window.location.pathname}?${filterParams}`);
            }}
            onClear={() => {
              const filterParams = getFilterParams({ ...filter, searchText: '' });
              router.routeTo(`${window.location.pathname}?${filterParams}`);
            }}
          />
        </div>
        <div className='student-count'>
          <div>
            {`Displaying ${filteredStudents.length} of ${students.length} students`}
          </div>
          <div style={{ fontSize: '12px' }}>{`(${getGenderBreakdown.male} male / ${getGenderBreakdown.female} female)`}</div>
        </div>
        <div className='buttons-cell'>
          <div style={{ width: '10px' }} />
          <ToggleButton
            onClick={(active) => {
              const filterParams = getFilterParams({ ...filter, activeFilter: active });
              router.routeTo(`${window.location.pathname}?${filterParams}`);
            }}
            items={[
              { name: 'All', label: 'All', pressed: filter.activeFilter === 'All' },
              { name: 'Active', label: 'Active', pressed: filter.activeFilter === 'Active' },
              { name: 'Inactive', label: 'Inactive', pressed: filter.activeFilter === 'Inactive' }]}
          />
          {renderActionsMenu()}
        </div>
      </div>
    );
  };

  const renderStudentAttendanceHistory = () => {
    const studentName = `${selectedStudent?.firstName} ${selectedStudent?.father ? selectedStudent?.father.firstName : ''} ${selectedStudent?.lastName}`;
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showStudentAttendanceHistory}
        title={`Attendance History - ${studentName}`}
        from='right'
        width='80vw'
        onRequestClose={() => {
          setShowStudentAttendanceHistory(false);
        }}
      >
        <AttendanceChart
          student={selectedStudent}
          id={selectedStudent._id}
          startDate={format(new Date(currentAcademicYear?.startDate || null), DATE_FORMAT_UTC)}
          endDate={format(new Date(), DATE_FORMAT_UTC)}
        />
      </SlidingPane>
    );
  };

  const renderActions = (student) => {
    return (
      <div className='actions'>
        <HasPermissions permissions={[DELETE_STUDENTS_PERMISSION]}>
          <div className='actions-icons'>
            <MdDelete
              className='action-icon'
              onClick={() => {
                setShowDeleteConf(true);
                setStudentToDelete(student);
              }}
            />
          </div>
        </HasPermissions>
        <HasPermissions permissions={[ADD_STUDENTS_HIFZ_ENTRY, DELETE_STUDENTS_HIFZ_ENTRY]}>
          <div className='actions-icons'>
            <MdQuran
              className='action-icon'
              onClick={() => {
                setSelectedStudent(student);
                setShowHifzTimeline(true);
              }}
            />
          </div>
        </HasPermissions>
        <HasPermissions permissions={[VIEW_ALL_CLASS_ATTENDANCE_PERMISSION]}>
          <div className='actions-icons'>
            <ChartIcon
              title='Attendance history'
              className='action-icon'
              onClick={() => {
                setSelectedStudent(student);
                setShowStudentAttendanceHistory(true);
              }}
            />
          </div>
        </HasPermissions>
      </div>
    );
  };

  const getTimelineEntryIcon = (type) => {
    switch (type) {
      case 'UPDATE':
        return <MdEdit />;
      case 'TARGET':
        return <MdTarget />;
      case 'MILESTONE':
        return <MdCertificate />;
      case 'TILAWAT_IKHTEBAR':
        return <MdReading />;
      default:
        return <div />;
    }
  };

  const renderTimelineDetails = (timeline) => {
    if (timeline.entryType === 'MILESTONE') {
      return getHifzSanadList().find(sanad => sanad.value === timeline.milestoneType).label;
    }
    if (timeline.entryType === 'TILAWAT_IKHTEBAR') {
      const getResult = () => {
        const result = getTilawatIkhtebarResultList().find(res => res.value === timeline.milestoneResult);
        if (result.value === 'PENDING') {
          return result.label;
        }
        return `Grade ${result.label}`;
      };
      return `Tilawat ikhtebar result: ${getResult()}`;
    }
    return `Surah ${timeline.surah}, Siparo ${timeline.siparo}, Aayat ${timeline.aayat}`;
  };

  const renderHifzTimeline = () => {
    const values = [];
    const timelineEntries = [];
    const title = 'Hifz timeline';
    let studentName = '';

    if (showHifzTimeline && selectedStudent) {
      const student = selectedStudent;
      studentName = `${student.firstName} ${student.father ? student.father.firstName : ''} ${student.lastName}`;

      if (student.hifzTimeline) {
        student.hifzTimeline.forEach((item) => {
          values.push(item);
        });
      }

      values.reverse();
      values.forEach((val, i) => {
        const userFullName = val.addedBy;
        timelineEntries.push(
          <VerticalTimelineElement
            key={i}
            className='mbl--vertical-timeline-element'
            // iconStyle={val.style}
            date={`Added by ${userFullName} on ${format(new Date(val.date), DATE_FORMAT_SHORT_MONTH)}`}
            icon={getTimelineEntryIcon(val.entryType)}
          >
            <HasPermissions permissions={[DELETE_STUDENTS_HIFZ_ENTRY]}>
              <div
                role='button'
                tabIndex='0'
                title='Delete hifz timeline entry'
                className='delete-icon__wrapper'
                onClick={async () => {
                  await removeHifzTimelineEntry(selectedStudent._id, val._id);
                  await fetchStudents();
                }}
              >
                <MdDelete className='icon' />
              </div>
            </HasPermissions>
            <h4 className='vertical-timeline-element-title'>
              {renderTimelineDetails(val)}
            </h4>
            <div className='vertical-timeline-element-subtitle'>
              {val.text}
            </div>
          </VerticalTimelineElement>
        );
      });
    }

    return (
      <SlidingPane
        className='mbl--sliding-pane mbl--sliding-pane__no-padding'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showHifzTimeline}
        title={`${title} - ${studentName}`}
        from='right'
        width={window.innerWidth >= 800 ? '40vw' : '100vw'}
        onRequestClose={() => {
          setShowHifzTimeline(false);
          setSelectedStudent({});
        }}
      >
        <div className='hifz-timeline'>
          <div className='hifz-timeline-entries'>
            {
              values.length > 0 ? (
                <VerticalTimeline
                  layout='1-column-left'
                >
                  {timelineEntries}
                </VerticalTimeline>
              ) :
                <span style={{ padding: '1.5rem' }}>No entries to display</span>
            }
          </div>
          <HasPermissions permissions={[ADD_STUDENTS_HIFZ_ENTRY]}>
            <div className='mbl--hifz-entry-form__wrapper'>
              <HifzEntryForm
                loading={addingTimelineEntry}
                onCancel={() => {
                  setShowHifzTimeline(false);
                  setSelectedStudent({});
                }}
                onSave={async (fields) => {
                  await addHifzTimelineEntry(selectedStudent._id, fields);
                  await fetchStudents();
                }}
              />
            </div>
          </HasPermissions>
        </div>
      </SlidingPane>
    );
  };

  const renderHifzSanadStatus = () => {
    const title = 'Hifz sanad status';
    let sanadData = [];
    let columns = ['Year'].concat(getHifzSanadList().map(sanad => sanad.label));

    if (showHifzSanadStatus) {
      sanadData = getHifzSanadStatus();
      columns = columns.map((col, index) => {
        return {
          width: col === 'Year' ? 150 : 100, title: col, dataKey: col, key: col, align: index === 0 ? Column.Alignment.LEFT : Column.Alignment.CENTER
        };
      });
    }

    return (
      <SlidingPane
        className='mbl--sliding-pane mbl--sliding-pane__no-padding'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showHifzSanadStatus}
        title={`${title}`}
        from='right'
        width='50vw'
        onRequestClose={() => {
          setShowHifzSanadStatus(false);
        }}
      >
        <div className='hifz-sanad-status'>
          <Table
            fixed
            rowKey='Year'
            rowHeight={24}
            components={{
              // eslint-disable-next-line react/no-unstable-nested-components
              TableCell: (cell) => {
                if (cell.rowData.Year === 'Total') {
                  if (cell.column.key !== 'Year') {
                    return <div style={{ fontWeight: 'bold', backgroundColor: 'lightgrey', flex: '1 1 auto' }}>{cell.rowData[cell.column.key]}</div>;
                  }
                  return <div style={{ fontWeight: 'bold', flex: '1 1 auto' }}>{cell.rowData[cell.column.key]}</div>;
                }
                return cell.rowData[cell.column.key];
              }
            }}
            emptyRenderer={loading ? <Loading /> : <NoData />}
            columns={columns}
            data={sanadData}
          />
        </div>
      </SlidingPane>
    );
  };

  const renderActionsMenu = () => {
    if (hasPermissions([VIEW_STUDENTS_PERMISSION, ADD_STUDENTS_PERMISSION, EDIT_STUDENTS_PERMISSION])) {
      return (
        <OverflowMenu
          trigger={<MdMenu className='overflow-menu__icon' focusable={false} />}
          onClick={() => {
            setShowActionsMenu(!showActionsMenu);
          }}
          show={showActionsMenu}
          onHideOverflowMenu={() => {
            setShowActionsMenu(false);
          }}
          onMenuTriggerClick={() => {
            setShowActionsMenu(!showActionsMenu);
          }}
        >
          {
            classes?.length > 0 ?
              (
                <HasPermissions
                  permissions={[ADD_STUDENTS_PERMISSION]}
                >
                  <OverflowMenuItem
                    id='add_student'
                    label='Add student'
                    onClick={() => {
                      setMode('add');
                      setShowStudentDetails(true);
                      setShowActionsMenu(false);
                      setSelectedStudent(Student);
                    }}
                  />
                </HasPermissions>
              ) :
              null
          }
          <OverflowMenuItem
            id='bulk_select_student'
            label='Bulk select student'
            onClick={() => {
              setShowBulkSelect(true);
              setShowActionsMenu(false);
            }}
          />
          <HasPermissions
            permissions={[EDIT_STUDENTS_PERMISSION]}
          >
            <OverflowMenuItem
              id='change_class'
              label='Change class'
              onClick={() => {
                setShowChangeClassPanel(true);
                setShowActionsMenu(false);
              }}
              showDivider={true}
              disabled={selectedIds.length === 0}
            />
          </HasPermissions>
          <OverflowMenuItem
            id='import_template'
            label='Download import template'
            onClick={() => {
              handleImportTemplateClick();
              setShowActionsMenu(false);
            }}
          />
          <HasPermissions
            permissions={[ADD_STUDENTS_PERMISSION, EDIT_STUDENTS_PERMISSION]}
          >
            <OverflowMenuItem
              id='import_students'
              label='Import students'
              onClick={() => {
                setShowImportStudentsPanel(true);
                setShowActionsMenu(false);
              }}
            />
          </HasPermissions>
          <HasPermissions
            permissions={[VIEW_STUDENTS_PERMISSION]}
          >
            <OverflowMenuItem
              id='export_students'
              label='Export students'
              onClick={() => {
                handleExportStudentsClick();
                setShowActionsMenu(false);
              }}
            />
            <OverflowMenuItem
              id='export_selected_students'
              label='Export selected students'
              showDivider={true}
              onClick={() => {
                handleExportStudentsClick(true);
                setShowActionsMenu(false);
              }}
            />
            <HasPermissions
              permissions={[ADD_STUDENTS_HIFZ_ENTRY, DELETE_STUDENTS_HIFZ_ENTRY, ADD_STUDENTS_HIFZ_SANAD_ENTRY]}
            >
              <OverflowMenuItem
                id='show_hifz_sanad_report'
                label='Show hifz sanad report'
                onClick={() => {
                  setShowHifzSanadStatus(true);
                  setShowActionsMenu(false);
                }}
              />
              <OverflowMenuItem
                id='download_hifz_sanad_report'
                label='Download hifz sanad report'
                onClick={() => {
                  handleExportHifzSanadStatusReport(true);
                  setShowActionsMenu(false);
                }}
              />
              <OverflowMenuItem
                id='down_students_hifz_status'
                label='Download hifz status'
                onClick={() => {
                  handleExportStudentHifzStatusReport();
                  setShowActionsMenu(false);
                }}
              />
              <OverflowMenuItem
                id='down_students_tilawat_result'
                label='Download tilawat ikhtebar result'
                showDivider={true}
                onClick={() => {
                  handleExportStudentTilawatIkhtebarResult();
                  setShowActionsMenu(false);
                }}
              />
            </HasPermissions>
          </HasPermissions>
          {
            classes?.length > 0 ?
              (
                <HasPermissions
                  permissions={[PROMOTE_STUDENTS_PERMISSION]}
                >
                  <OverflowMenuItem
                    id='promote_students'
                    label='Promote students'
                    onClick={() => {
                      setShowPromoteStudentsConfirmation(true);
                      setShowActionsMenu(false);
                    }}
                  />
                </HasPermissions>
              ) :
              null
          }
          {
            appContext.settings.isEmailConfigured ? (
              <HasPermissions
                permissions={[VIEW_EMAIL_TEMPLATES_PERMISSION]}
              >
                <OverflowMenuItem
                  id='send_email'
                  label='Send email'
                  disabled={selectedIds.length === 0}
                  onClick={() => {
                    setShowSendEmailPanel(true);
                    setShowActionsMenu(false);
                  }}
                />
              </HasPermissions>
            ) :
              null
          }
        </OverflowMenu>
      );
    }
    return null;
  };

  const renderStudentDetails = () => {
    const getTitle = () => {
      let title = 'View Student';
      let studentName = '';
      if (selectedStudent && selectedStudent.father) {
        studentName = `${selectedStudent.firstName} ${selectedStudent.father.firstName} ${selectedStudent.lastName}`;
      }

      if (mode === 'add') {
        return 'Add student';
      }
      if (mode === 'edit') {
        title = 'Edit student';
      }

      return studentName ? `${title} - ${studentName}` : title;
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showStudentDetails}
        title={getTitle()}
        from='right'
        width='75vw'
        onRequestClose={() => {
          setShowStudentDetails(false);
          setSelectedStudent({});
        }}
      >
        <StudentForm
          student={selectedStudent}
          mode={!hasPermissions([EDIT_STUDENTS_PERMISSION]) ? 'view' : mode}
          classes={classes}
          onCancel={() => {
            setShowStudentDetails(false);
            setSelectedStudent({});
          }}
          onSave={(fields) => {
            saveStudent(fields, mode, () => {
              setShowStudentDetails(false);
              setSelectedStudent({});
            });
          }}
          loading={loading}
        />
      </SlidingPane>
    );
  };

  const renderChangeClassPanel = () => {
    const options = [];
    if (showChangeClassPanel) {
      classes.forEach((obj) => {
        const classId = obj._id;
        options.push(
          <option
            key={classId}
            value={classId}
          >
            {`${obj.name } ${ obj.division ? obj.division : ''}`}
          </option>
        );
      });
    }
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showChangeClassPanel}
        title='Change class for selected students'
        from='right'
        width='25vw'
        onRequestClose={() => {
          setShowChangeClassPanel(false);
        }}
      >
        <div>
          <div>
            <select
              id='student_select'
              onChange={(e) => {
                setSelectedClass(e.target.value);
              }}
            >
              {options}
            </select>
          </div>
          <Footer
            onCancel={() => {
              setShowChangeClassPanel(false);
            }}
            onSave={() => {
              changeStudentsClass(selectedIds, selectedClass, () => {
                setSelectedIds([]);
                setShowChangeClassPanel(false);
              });
            }}
          />
        </div>
      </SlidingPane>
    );
  };

  const handleImportTemplateClick = () => {
    if (filteredStudents.length > 0) {
      const student = filteredStudents[0];
      const cols = {};

      cols.firstName = '';
      cols.lastName = '';
      cols.itsId = '';
      cols.DOB = '';
      cols.knownAllergies = '';
      cols.allergyDetails = '';
      cols.knownIllness = '';
      cols.illnessDetails = '';
      cols.specialNeeds = '';
      cols.specialNeedsDetails = '';
      Object.keys(flatten(student)).forEach((key) => {
        if (key !== '_id' && key !== '__v' && key !== 'active' && key !== 'createdAt' && key !== 'updatedAt'
        && key !== 'classId' && key.indexOf('attendance') === -1 && key.indexOf('mblClass') === -1 && !key.toLowerCase().includes('coins') && !key.includes('hifz')
        && !key.includes('warnings')) {
          if (key === 'father.firstName') {
            // add father's title column before father.firstName
            cols['father.title'] = '';
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!cols.hasOwnProperty(key)) {
            cols[key] = '';
          }
        }
      });
      XLSExporter.export({ items: [cols], sheetName: 'Students', fileName: 'ImportStudents_Template.xlsx' });
    }
  };

  const handleExportStudentsClick = (selected) => {
    if (filteredStudents.length > 0) {
      const studentsArr = [];
      filteredStudents.forEach((student) => {
        const st = {
          'First name': student.firstName,
          'Last name': student.lastName,
          'ITS Id': student.itsId,
          'Date of birth': format(new Date(student.DOB), DATE_FORMAT_UTC),
          Gender: student.gender,
          Year: student.mblClass.name,
          Division: student.mblClass.division,
          'Father first name': student.father && student.father.firstName,
          'Father last name': student.father && student.father.lastName,
          'Father ITS Id': student.father && student.father.itsId,
          'Father email': student.father && student.father.email,
          'Father phone': student.father && student.father.phone,
          'Mother first name': student.mother && student.mother.firstName,
          'Mother last name': student.mother && student.mother.lastName,
          'Mother ITS Id': student.mother && student.mother.itsId,
          'Mother email': student.mother && student.mother.email,
          'Mother phone': student.mother && student.mother.phone,
          'Address line 1': student.address && student.address.addressLine1,
          'Address line 2': student.address && student.address.addressLine2,
          City: student.address && student.address.city,
          County: student.address && student.address.county,
          'Post code': student.address && student.address.postCode
        };
        if (selected) {
          if (selectedIds.includes(student._id)) {
            studentsArr.push(st);
          }
        } else {
          studentsArr.push(st);
        }
      });
      XLSExporter.export({
        items: studentsArr,
        sheetName: 'Students',
        fileName: `Madrasah-Students_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`
      });
    }
  };

  const getSanadHolderCount = (studentsWithSanad, mblCls, sanad) => {
    let count = 0;
    const classStudents = studentsWithSanad.filter(st => st.classId === mblCls);
    classStudents.forEach(student => {
      const hifzTimeline = [...student.hifzTimeline];
      hifzTimeline.reverse();
      if (hifzTimeline.find(ht => ht.entryType === 'MILESTONE').milestoneType === sanad) {
        count++;
      }
    });
    return count || '-';
  };

  const getHifzSanadStatus = () => {
    const sanadData = [];
    if (students.length > 0) {
      const studentsWithSanad = students.filter(student => student.hifzTimeline?.find(ht => ht.entryType === 'MILESTONE'));
      const activeClasses = classes.filter(cls => cls.active !== false);
      activeClasses.forEach(cls => {
        const reportData = {
          Year: `${cls.name} ${cls.division}`,
          Asr: getSanadHolderCount(studentsWithSanad, cls._id, 'ASR'),
          Balad: getSanadHolderCount(studentsWithSanad, cls._id, 'BALAD'),
          Inshiqaaq: getSanadHolderCount(studentsWithSanad, cls._id, 'INSHIQAAQ'),
          'Juz Amma': getSanadHolderCount(studentsWithSanad, cls._id, 'JUZ_AMMA'),
          'Sanah Ula': getSanadHolderCount(studentsWithSanad, cls._id, 'SANAH_ULA'),
          'Sanah Saniyah': getSanadHolderCount(studentsWithSanad, cls._id, 'SANAH_SANIYAH'),
          'Sanah Salesa': getSanadHolderCount(studentsWithSanad, cls._id, 'SANAH_SALESA'),
          '27 & 26': getSanadHolderCount(studentsWithSanad, cls._id, '27_26'),
          '29 & 28': getSanadHolderCount(studentsWithSanad, cls._id, '29_28'),
          Hafiz: getSanadHolderCount(studentsWithSanad, cls._id, 'HAFIZ'),
        };
        sanadData.push(reportData);
      });

      // add total to the bottom
      const total = { Year: 'Total' };
      sanadData.forEach(data => {
        Object.entries(data).forEach(([key, value]) => {
          if (key !== 'Year') {
            if (!total[key]) {
              total[key] = 0;
            }
            if (value !== '-') {
              total[key] += value;
            }
          }
        });
      });
      sanadData.push(total);
      console.log('::::::::::::sanadData', sanadData);
    }
    return sanadData;
  };

  const handleExportHifzSanadStatusReport = () => {
    if (students.length > 0) {
      const sanadData = getHifzSanadStatus();
      XLSExporter.export({
        items: sanadData,
        sheetName: 'Sanad Report',
        fileName: `Madrasah-Sanad-Report_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`,
        colsWidth: [20]
      });
    }
  };

  const getStudentsHifzStatus = () => {
    const hifzStatus = [];
    filteredStudents.forEach((student => {
      const hifzTimeline = student.hifzTimeline ? [...student.hifzTimeline] : [];
      if (hifzTimeline.length > 0) {
        hifzTimeline.reverse();
        // get latest update entry
        const latestHifzStatus = hifzTimeline.find(ht => ht.entryType === 'UPDATE');
        const st = {
          'First name': student.firstName,
          'Last name': student.lastName,
          'ITS Id': student.itsId,
          Year: student.mblClass.name,
          Division: student.mblClass.division,
          'Hifz status': latestHifzStatus ? `Surah ${latestHifzStatus.surah}, Siparo ${latestHifzStatus.siparo}, Aayat ${latestHifzStatus.aayat}` : 'NA'
        };
        hifzStatus.push(st);
      }
    }));
    console.log(hifzStatus);
    return hifzStatus;
  };

  const handleExportStudentHifzStatusReport = () => {
    if (filteredStudents.length > 0) {
      const hifzStatus = getStudentsHifzStatus();
      XLSExporter.export({
        items: hifzStatus,
        sheetName: 'Hifz status',
        fileName: `Madrasah-Students-Hifz-Status-Report_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`,
        colsWidth: [15, 15, 10, 10, 10, 30]
      });
    }
  };

  const handleExportStudentTilawatIkhtebarResult = () => {
    if (filteredStudents.length > 0) {
      const tilawatResult = [];
      filteredStudents.forEach((student => {
        const hifzTimeline = student.hifzTimeline ? [...student.hifzTimeline] : [];
        if (hifzTimeline.length > 0) {
          hifzTimeline.reverse();
          // get latest tilawat ikhtebar entry
          const latestTilawatEntry = hifzTimeline.find(ht => ht.entryType === 'TILAWAT_IKHTEBAR');
          const st = {
            'First name': student.firstName,
            'Last name': student.lastName,
            'ITS Id': student.itsId,
            Year: student.mblClass.name,
            Division: student.mblClass.division,
            'Tilawat result': latestTilawatEntry ? getTilawatIkhtebarResultList().find(tir => tir.value === latestTilawatEntry.milestoneResult).label : ''
          };
          tilawatResult.push(st);
        }
      }));
      console.log(tilawatResult);
      XLSExporter.export({
        items: tilawatResult,
        sheetName: 'Tilawat result',
        fileName: `Madrasah-Students-tilawat-Result_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`,
        colsWidth: [15, 15, 10, 10, 10, 20]
      });
    }
  };

  const renderPromoteStudentsDialog = () => {
    return (
      <div>
        <SlidingPane
          className='mbl--sliding-pane'
          closeIcon={<FaChevronRight className='active-icon' />}
          isOpen={showPromoteStudentsConfirmation}
          title='Promote students'
          from='right'
          width='75vw'
          onRequestClose={() => {
            setShowPromoteStudentsConfirmation(false);
          }}
        >
          {
            loading ?
              <Loading /> :
              null
          }
          <ul>
            <li>This action will move the students that belongs to KG - 9 to the next class.</li>
            <li>This action will not affect the students that belongs to either &apos;New Registration&apos; or &apos;Special&apos; class.</li>
            <li>If the students are moved to their new class and that class is set to inactive, you need to manually set the class to active from the Classes option on the Home page.</li>
          </ul>
          <div style={{ color: 'red' }}>
            <span style={{ fontWeight: 'bold' }}>Note: </span>
            Before you perform this action you need to manually move the students of year 10 to a new class. If you haven&apos;t done so, then cancel this action, move the students from year 10 to a new class and then promote students.
          </div>
          <Footer
            onCancel={() => {
              setShowPromoteStudentsConfirmation(false);
            }}
            onSave={() => {
              promoteStudents(() => {
                setShowPromoteStudentsConfirmation(false);
              });
            }}
          />
        </SlidingPane>
      </div>
    );
  };

  const renderDeleteConfirmation = () => {
    const getTitle = () => {
      if (studentToDelete) {
        if (studentToDelete.father) {
          return `Confirm Delete - ${studentToDelete.firstName} ${studentToDelete.father.firstName} ${studentToDelete.lastName}`;
        }
        return `Confirm Delete - ${studentToDelete.firstName} ${studentToDelete.lastName}`;
      }
      return '';
    };
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showDeleteConf}
        title={getTitle()}
        from='right'
        width='50vw'
        onRequestClose={() => {
          setShowDeleteConf(false);
        }}
      >
        <div style={{ fontSize: '18px' }}>Are you sure you want to delete the student?</div>
        <Footer
          onCancel={() => {
            setShowDeleteConf(false);
          }}
          onSave={() => {
            if (studentToDelete) {
              deleteStudent(studentToDelete._id, () => {
                setShowDeleteConf(false);
                setStudentToDelete(null);
              });
            }
          }}
        />
      </SlidingPane>
    );
  };

  const renderImportStudentsPanel = () => {
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showImportStudentsPanel}
        title='Import students'
        from='right'
        width='75vw'
        onRequestClose={() => {
          setShowImportStudentsPanel(false);
        }}
      >
        <div className='students-import-mapping'>
          <div>
            <ul>
              <li>Select the excel file to import students. Make sure the file is in correct format.</li>
              <li>If you want to verify if the excel file contains the correct column names then you can download the import template.</li>
              <li>Do not forgot to specify whether you want to import as new students or want to update the existing studetns.</li>
            </ul>
          </div>
          <p />
          <input
            className='file-selector'
            type='file'
            id='input'
            onChange={() => {
              // eslint-disable-next-line no-undef
              readXlsxFile(input.files[0]).then((rows) => {
                const cols = [];
                const rowList = [];
                rows.forEach((row, index) => {
                  const rowMap = {};
                  row.forEach((cellValue, colIndex) => {
                    if (index === 0) {
                      cols.push(cellValue);
                    } else if (cols[colIndex].indexOf('.') !== -1) {
                      pathToObject(rowMap, cols[colIndex], cellValue);
                    } else {
                      rowMap[cols[colIndex]] = cellValue;
                    }
                  });

                  let newRegClass = null;
                  if (studentImportMode === 'add') {
                    newRegClass = classes.find((cl) => {
                      return cl.name === 'New' && cl.division === 'Registration';
                    });
                    rowMap.classId = newRegClass ? newRegClass._id : classes[0]._id;
                  }
                  if (appContext?.tenant) {
                    rowMap.tenantId = appContext.tenant._id;
                  }

                  if (index > 0) {
                    rowList.push(rowMap);
                  }
                });
                console.log('rowList+++++++++++++++++++++++', rowList);
                setImportData(rowList);
              });
            }}
          />
          <div>
            <label>
              <input
                type='radio'
                value='add'
                name='import'
                onChange={(e) => {
                  if (e.target.checked) {
                    setStudentImportMode('add');
                  }
                }}
                defaultChecked
              />
              <span>Add students</span>
            </label>
            <label>
              <input
                type='radio'
                value='update'
                name='import'
                onChange={(e) => {
                  if (e.target.checked) {
                    setStudentImportMode('update');
                  }
                }}
              />
              <span>Update students</span>
            </label>
          </div>
        </div>
        <Footer
          onCancel={() => {
            setShowImportStudentsPanel(false);
          }}
          onSave={() => {
            importStudents(importData, studentImportMode, () => {
              setShowImportStudentsPanel(false);
            });
          }}
        />
      </SlidingPane>
    );
  };

  const teachersEmail = useMemo(() => {
    if (classes?.length > 0) {
      const activeClasses = classes.filter(cls => cls.active);
      const emails = new Set(activeClasses.map(cls => cls.teacher.email));
      return Array.from(emails).join(',');
    }
    return '';
  }, [classes]);

  const renderSendEmailPanel = () => {
    const closeSendEmailPanel = () => {
      setShowSendEmailPanel(false);
    };

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showSendEmailPanel}
        title='Send email'
        from='right'
        width='50vw'
        onRequestClose={() => {
          closeSendEmailPanel();
        }}
      >
        <PaymentEmailForm
          isLoading={sendingEmail}
          students={filteredStudents.filter(student => selectedIds.includes(student._id))}
          teachersEmail={teachersEmail}
          showBcc={false}
          onCancel={() => {
            closeSendEmailPanel();
          }}
          onSave={async (fields) => {
            try {
              console.log({ fields });
              await sendEmail(fields);
              closeSendEmailPanel();
            } catch (ex) {
              console.log('Exception sending emails', ex);
            }
          }}
        />
      </SlidingPane>
    );
  };

  const renderBulkStudentSelectionPanel = () => {
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showBulkSelect}
        title='Bulk select students'
        from='right'
        width='50vw'
        onRequestClose={() => {
          setShowBulkSelect(false);
        }}
      >
        <TextArea
          label='Enter students ITS ids (one per line)'
          maxLength={5000}
          rows='20'
          value={selectedItsIds}
          onChange={(e) => setSelectedItsIds(e.target.value)}
        />
        <Footer
          onCancel={() => setShowBulkSelect(false)}
          onSave={() => {
            const selectedItsArray = selectedItsIds.split('\n').filter(Boolean).map(its => Number(its));
            setSelectedIds(students.filter(s => selectedItsArray.includes(s.itsId)).map(s => s._id));
            setShowBulkSelect(false);
          }}
          saveButtonLabel='Select'
        />
      </SlidingPane>
    );
  };

  const getStudentList = () => {
    // eslint-disable-next-line react/no-unstable-nested-components
    const TableCell = (cell) => {
      if (cell.column.key === 'fullName') {
        return FullNameRenderer(cell.rowData);
      } if (cell.column.key === 'DOB') {
        return DateRenderer(cell.rowData.DOB);
      } if (cell.column.key === 'year') {
        return ClassRenderer(cell.rowData);
      } if (cell.column.key === 'active') {
        return ActiveRenderer(cell.rowData);
      } if (cell.column.key === 'actions') {
        return renderActions(cell.rowData);
      } if (cell.column.key === '_id') {
        return CheckboxRenderer(
          cell.rowData,
          (e) => {
            console.log(e);
            const sel = [...selectedIds];
            if (sel.includes(cell.rowData._id)) {
              sel.splice(sel.indexOf(cell.rowData._id), 1);
            } else {
              sel.push(cell.rowData._id);
            }
            setSelectedIds(sel);
          },
          selectedIds.includes(cell.rowData._id)
        );
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    const rowClassName = ({ rowData }) => {
      return selectedIds.includes(rowData._id) ? 'row-selected' : '';
    };

    let mouseDown = 0;
    const rowEventHandlers = {
      onMouseUp: (e) => {
        const elementClicked = e.event.target.tagName.toLowerCase();
        if (elementClicked !== 'label' && elementClicked !== 'input' && elementClicked !== 'svg' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
          setSelectedStudent(e.rowData);
          if (canEditStudents) {
            setMode('edit');
          } else {
            setMode('view');
          }
          setShowStudentDetails(true);
        }
      },
      onMouseDown: (e) => {
        mouseDown = e.event.clientX;
      }
    };

    const headerRenderer = (data) => {
      const rightPlaceholders = [];
      data.columns.forEach((column, idx) => {
        rightPlaceholders.push(data.cells[idx]);
      });

      return (
        <div style={{ display: 'flex' }}>
          <HeaderCheckboxRenderer
            style={{ position: 'absolute', left: '1rem' }}
            onChange={() => {
              setSelectAll(!selectAll);
              const selected = [];
              if (!selectAll) {
                filteredStudents.forEach((student) => {
                  selected.push(student._id);
                });
              }
              setSelectedIds(selected);
            }}
            checked={selectAll}
            label=''
          />
          {rightPlaceholders}
        </div>
      );
    };

    const columns = [
      {
        width: 50, title: '', dataKey: '_id', key: '_id', frozen: true
      },
      {
        width: 100, title: 'ITS Id', dataKey: 'itsId', key: 'itsId', resizable: true, frozen: true
      },
      {
        width: 350, title: 'Full name', dataKey: 'fullName', key: 'fullName', resizable: true
      },
      {
        width: 150, title: 'Date of birth', dataKey: 'DOB', key: 'DOB', resizable: true
      },
      {
        width: 150, title: 'Class', dataKey: 'year', key: 'year', resizable: true
      },
      {
        width: 100, title: 'Active', dataKey: 'active', key: 'active', resizable: true
      }
    ];
    if (hasPermissions([DELETE_STUDENTS_PERMISSION, ADD_STUDENTS_HIFZ_ENTRY, DELETE_STUDENTS_HIFZ_ENTRY, ADD_STUDENTS_HIFZ_SANAD_ENTRY])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        headerRenderer={headerRenderer}
        selectable
        rowClassName={rowClassName}
        rowEventHandlers={rowEventHandlers}
        columns={columns}
        data={filteredStudents}
      />
    );
  };

  const isFilterPanelShowing = useMemo(() => {
    return showFilterPanel === 'true';
  }, [showFilterPanel]);

  const toggleRouteParam = (paramName) => {
    const filterParams = getFilterParams(filter);
    if (filterParams.indexOf(`${paramName}=false`) !== -1) {
      router.routeTo(`${window.location.pathname}?${filterParams.replace(`${paramName}=false`, `${paramName}=true`)}`);
    } else {
      router.routeTo(`${window.location.pathname}?${filterParams.replace(`${paramName}=true`, `${paramName}=false`)}`);
    }
  };

  const getFilterParams = (filterParams) => {
    const params = new URLSearchParams();
    params.set('showFilterPanel', isFilterPanelShowing);

    Object.entries(filterParams).forEach(([key, value]) => {
      if (value?.length > 0) {
        params.set(key, Array.isArray(value) ? value.join(',') : (value));
      } else if (!Array.isArray(value) && value) {
        params.set(key, value);
      }
    });
    return params.toString();
  };

  return (
    <HasPermissions permissions={[
      VIEW_STUDENTS_PERMISSION, ADD_STUDENTS_PERMISSION, EDIT_STUDENTS_PERMISSION,
      DELETE_STUDENTS_PERMISSION, PROMOTE_STUDENTS_PERMISSION
    ]}
    >
      <div className='student-list-wrapper'>
        {getTitleRow()}
        <div className='table-area'>
          <StudentFilterSidepanel
            open={isFilterPanelShowing}
            filter={filter}
            classes={classes?.filter(c => c.active !== false)}
            onFilterChange={(f) => {
              const filterParams = getFilterParams(f);
              router.routeTo(`${window.location.pathname}?${filterParams}`);
            }}
            onClose={() => toggleRouteParam('showFilterPanel')}
          />
          <div className='sidepanel'>
            {getStudentList()}
          </div>
        </div>
        {renderHifzSanadStatus()}
        {renderHifzTimeline()}
        {renderStudentDetails()}
        {renderChangeClassPanel()}
        {renderPromoteStudentsDialog()}
        {renderDeleteConfirmation()}
        {renderImportStudentsPanel()}
        {renderStudentAttendanceHistory()}
        {renderSendEmailPanel()}
        {renderBulkStudentSelectionPanel()}
      </div>
    </HasPermissions>
  );
};

StudentList.propTypes = {
  showFilterPanel: PropTypes.string,
  mblClass: PropTypes.string,
  activeFilter: PropTypes.string,
  sanadFilter: PropTypes.string,
  otherFilter: PropTypes.string,
  searchText: PropTypes.string,
  surahFilter: PropTypes.string,
  siparoFilter: PropTypes.string,
  tirFilter: PropTypes.string
};

export default StudentList;

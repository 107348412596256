import React from 'react';
import NoDataGraphics from '../styles/assets/no-data.svg';
import './no-data.scss';

const NoData = () => {
  return (
    <div className='no-data-wrapper'>
      <NoDataGraphics className='image' />
      <div className='message'>No data to display</div>
    </div>
  );
};

export default NoData;

import React, {
  useContext, useMemo, useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  compareAsc, compareDesc, format, isBefore, isAfter, isEqual
} from 'date-fns';
import SlidingPane from 'react-sliding-pane';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {
  CommonTable as Table, OverflowMenu, OverflowMenuItem, Search, ToggleButton
} from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
// import Notification 				from './Notification';
import RewardsForm from '../forms/RewardsForm';
import CoinsReportForm from '../forms/CoinsReportForm';
import ClassList from './ClassList';
import XLSExporter from '../../utils/XLSExporter';
import HasPermissions from '../HasPermissions';

import Coin from '../../styles/assets/coin.svg';
import Award from '../../styles/assets/medal.svg';
import Gift from '../../styles/assets/giftbox.svg';
import MdMenu from '../../styles/assets/menu.svg';
import FaChevronRight from '../../styles/assets/chevron.svg';
import TimelineIcon from '../../styles/assets/timeline.svg';
import NoData from '../NoData';
import { DATE_FORMAT_UTC, DATE_FORMAT_FULL_MONTH, DATE_FORMAT_MONTH_YEAR } from '../../utils/DateUtils';
import {hasPermissions} from '../../utils/hasPermissions';
import {ADD_REWARDS_PERMISSION, REDEEM_REWARDS_PERMISSION} from '../../utils/permissions';
import { getCurrentAcademicYear } from '../../utils/AcademicYears';

import AppContext from '../AppContext';

import {
  FullNameRenderer, ClassRenderer
} from '../tables/TableCellRenderer';
import { coinsData } from '../hooks/InitialCopies';
import useStudentData from '../hooks/useStudentData';
import { useUserData } from '../hooks/useUserData';
import router from '../../Routes';
import 'react-vertical-timeline-component/style.min.css';

const RewardStudentList = ({ selectedClass, tenantId }) => {
  const appContext = useContext(AppContext);
  const {
    students, addCoins, redeemCoins, loading
  } = useStudentData();
  const { users } = useUserData();

  const [mode, setMode] = useState('add'); // can be add or redeem
  const [filter, setFilter] = useState({
    activeFilter: 'Active',
    searchText: ''
  });
  const [selectedStudent, setSelectedStudent] = useState({});
  const [showUpdateCoinsPanel, setShowUpdateCoinsPanel] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showCoinsReportPanel, setShowCoinsReportPanel] = useState(false);
  const [showTimeline, setShowTimeline] = useState(false);

  const currentAcademicYear = useMemo(() => {
    return getCurrentAcademicYear(appContext.settings);
  }, [appContext.settings]);

  const filteredStudents = useMemo(() => {
    return students.filter((student) => {
      if (selectedClass === 'All' || selectedClass === student.mblClass._id) {
        const searchAttribs = [student.itsId, student.firstName, student.lastName];
        if (!filter.searchText || searchAttribs.join(' ').toLowerCase().includes(filter.searchText.toLowerCase())) {
          if (filter.activeFilter === 'All' || ((student.active || student.active !== false) && filter.activeFilter === 'Active') || (student.active === false && filter.activeFilter === 'Inactive')) {
            return true;
          }
        }
      }
      return false;
    });
  }, [students, filter, selectedClass]);

  const renderUpdateCoinsPanel = () => {
    if (filteredStudents && filteredStudents.length > 0) {
      const student = filteredStudents.find((stu) => {
        return stu._id === selectedStudent._id;
      });

      const getTitle = () => {
        const dialogTitle = mode === 'add' ? 'Add coins' : 'Redeem coins';
        if (student && student.firstName) {
          return `${dialogTitle} - ${student.firstName} ${student.father.firstName} ${student.lastName}`;
        }
        return dialogTitle;
      };

      const closeCoinsPanel = () => {
        setShowUpdateCoinsPanel(false);
        setSelectedStudent({});
      };

      const coins = {...coinsData.coins, academicYear: currentAcademicYear?.academicYear};
      const updatedCoins = { ...coinsData, coins };
      if (student) {
        updatedCoins.studentId = student._id;
        updatedCoins.coins.classId = student.mblClass._id;
      }

      return (
        <SlidingPane
          className='mbl--sliding-pane'
          closeIcon={<FaChevronRight className='active-icon' />}
          isOpen={showUpdateCoinsPanel}
          title={getTitle()}
          from='right'
          width='50vw'
          onRequestClose={() => {
            closeCoinsPanel();
          }}
        >
          <RewardsForm
            coins={updatedCoins}
            mode={mode}
            coinsBalance={getCoinsBalance(student)}
            max={mode === 'add' ? 10 : getCoinsBalance(student)}
            onCancel={() => {
              closeCoinsPanel();
            }}
            onSave={(fields) => {
              if (mode === 'add') {
                addCoins(fields, () => {
                  closeCoinsPanel();
                });
              } else {
                redeemCoins(fields, () => {
                  closeCoinsPanel();
                });
              }
            }}
          />
        </SlidingPane>
      );
    }
    return null;
  };

  const renderActions = (student) => {
      return (
        <div className='actions'>
          <div className='actions-icons'>
            <HasPermissions permissions={[ADD_REWARDS_PERMISSION]}>
              <span title='Add coins'>
                <Award
                  className='action-icon'
                  onClick={() => {
                    setSelectedStudent(student);
                    setShowUpdateCoinsPanel(true);
                    setMode('add');
                  }}
                />
              </span>
            </HasPermissions>
            <HasPermissions permissions={[REDEEM_REWARDS_PERMISSION]}>
              <span title='Redeem coins'>
                <Gift
                  className='action-icon'
                  onClick={() => {
                    setSelectedStudent(student);
                    setShowUpdateCoinsPanel(true);
                    setMode('redeem');
                  }}
                />
              </span>
            </HasPermissions>
            <span title='Timeline'>
              <TimelineIcon
                className='action-icon'
                onClick={() => {
                  setSelectedStudent(student);
                  setShowTimeline(true);
                }}
              />
            </span>
          </div>
        </div>
      );
  };

  const renderRewardsTimeline = () => {
    const values = [];
    const timelineEntries = [];
    const title = 'Rewards timeline';
    let studentName = '';

    if (showTimeline && selectedStudent) {
      const student = selectedStudent;
      studentName = `${student.firstName} ${student.father ? student.father.firstName : ''} ${student.lastName}`;
      if (student.coinsEarned) {
        student.coinsEarned.forEach((item) => {
          values.push({
            type: 'add',
            count: item.count,
            style: { border: '1px solid green', backgroundColor: '#E9F0F5' },
            date: item.date,
            createdBy: item.createdBy,
            comment: item.comment
          });
        });
      }
      if (student.coinsRedeemed) {
        student.coinsRedeemed.forEach((item) => {
          values.push({
            type: 'redeem',
            count: item.count,
            date: item.date,
            style: { border: '1px solid red', backgroundColor: '#FFC7CE' },
            createdBy: item.createdBy,
            comment: item.comment
          });
        });
      }

      values.sort((a, b) => {
        return compareAsc(new Date(b.date), new Date(a.date));
      });

      values.forEach((val, i) => {
        const user = users.find((u) => u.email === val.createdBy);
        const userFullName = user ? `${user.firstName} ${user.lastName}` : val.createdBy;
        timelineEntries.push(
          <VerticalTimelineElement
            key={i}
            className="mbl--vertical-timeline-element"
            iconStyle={val.style}
            date={format(new Date(val.date), DATE_FORMAT_FULL_MONTH)}
            icon={<span>{val.count}</span>}
            iconClassName='mbl--timeline-icon'
          >
            <h4 className="vertical-timeline-element-title">
              {val.type === 'add' ? 'Coins Earned' : 'Coins Redeemed'}
            </h4>
            <div className="vertical-timeline-element-subtitle">
              {`Entry added by ${userFullName}`}
            </div>
          </VerticalTimelineElement>
        );
      });
    }

    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showTimeline}
        title={`${title} - ${studentName}`}
        from='right'
        width='40vw'
        onRequestClose={() => {
          setShowTimeline(false);
          setSelectedStudent({});
        }}
      >
        {
          values.length > 0 ? (
            <VerticalTimeline
              layout='1-column-left'
            >
              {timelineEntries}
            </VerticalTimeline>
          ) :
            <span>No entries to display</span>
        }
      </SlidingPane>
    );
  };

  const getCoinsBalance = (student) => {
    if (student) {
      return student.totalCoinsEarned - student.totalCoinsRedeemed;
    }
    return 0;
  };

  const getStudentList = () => {
    const TableCell = (cell) => {
      if (cell.column.key === 'fullName') {
        return FullNameRenderer(cell.rowData);
      } if (cell.column.key === 'year') {
        return ClassRenderer(cell.rowData);
      } if (cell.column.key === 'actions') {
        return renderActions(cell.rowData);
      } if (cell.column.key === 'coinsBalance') {
        return (
          <div className='coin-column'>
            <Coin className='coin-icon' />
            <span className='coin-count'>{getCoinsBalance(cell.rowData)}</span>
          </div>
        );
      }
      return <div>{cell.rowData[cell.column.key]}</div>;
    };

    const columns = [
      {
        width: 100, title: 'ITS Id', dataKey: 'itsId', key: 'itsId', resizable: true, frozen: true
      },
      {
        width: 350, title: 'Full name', dataKey: 'fullName', key: 'fullName', resizable: true, frozen: true
      },
      {
        width: 150, title: 'Class', dataKey: 'year', key: 'year', resizable: true
      },
      {
        width: 150, title: 'Coins balance', dataKey: 'coinsBalance', key: 'coinsBalance', resizable: true
      }
    ];
    if (hasPermissions([ADD_REWARDS_PERMISSION, REDEEM_REWARDS_PERMISSION])) {
      columns.push({
        width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: true
      });
    }
    return (
      <Table
        fixed
        rowKey='_id'
        rowHeight={32}
        components={{ TableCell }}
        emptyRenderer={loading ? <Loading /> : <NoData />}
        rowClassName='mbl--rewards-list-row'
        selectable
        columns={columns}
        data={filteredStudents}
      />
    );
  };

  const getTitleRow = useCallback(() => {
    return (
      <div className='rewards--title-row'>
        <div>
          <Search
            placeholder='Search'
            value={filter.searchText}
            onChange={(e) => setFilter({ ...filter, searchText: e.target.value })}
            onClear={() => setFilter({ ...filter, searchText: '' })}
          />
        </div>
        <div className='student-count'>
          {`Displaying ${filteredStudents.length} of ${students.length} students`}
        </div>
        <div className='buttons-cell'>
          <div style={{ width: '10px' }} />
          <ToggleButton
            onClick={(active) => {
              setFilter({ ...filter, activeFilter: active });
            }}
            items={[
              { name: 'All', label: 'All', pressed: filter.activeFilter === 'All' },
              { name: 'Active', label: 'Active', pressed: filter.activeFilter === 'Active' },
              { name: 'Inactive', label: 'Inactive', pressed: filter.activeFilter === 'Inactive' }]}
          />
          {renderActionsMenu()}
        </div>
      </div>
    );
  }, [filteredStudents, showActionsMenu]);

  const renderClasses = useCallback(() => {
    return (
      <ClassList
        selectedClass={selectedClass || 'All'}
        showAllFirst={true}
        onClassSelected={(classId) => {
          router.setRoute(`/${tenantId}/rewards/${classId}`);
        }}
      />
    );
  }, [selectedClass]);

  const exportReport = ({ startDate, endDate }) => {
    if (filteredStudents.length > 0) {
      const studentsArr = [];
      const months = [];
      // create an array of list of months
      filteredStudents.forEach((student) => {
        if (student.coinsEarned) {
          student.coinsEarned.forEach((coin) => {
            if (isAfter(new Date(coin.date), new Date(startDate)) && (isBefore(new Date(coin.date), new Date(endDate)) || isEqual(new Date(coin.date), new Date(endDate)))) {
              const month = format(new Date(coin.date), DATE_FORMAT_MONTH_YEAR);
              if (months.indexOf(month) === -1) {
                months.push(month);
              }
            }
          });
        }
      });

      // sort the months array
      months.sort((a, b) => {
        return compareAsc(new Date(a), new Date(b));
      });

      // now update coins for each students per month
      filteredStudents.forEach((student) => {
        const st = {
          'ITS Id': student.itsId,
          'First name': student.firstName,
          'Middle name': student.father.firstName,
          'Last name': student.lastName,
          Year: student.mblClass.name,
          Division: student.mblClass.division
        };
        if (student.coinsEarned) {
          // add all months to the student object as keys with empty value
          months.forEach((mth) => {
            st[mth] = '';
          });
          // now add coins for each month
          student.coinsEarned.forEach((coin) => {
            if (isAfter(new Date(coin.date), new Date(startDate)) && (isBefore(new Date(coin.date), new Date(endDate)) || isEqual(new Date(coin.date), new Date(endDate)))) {
              const month = format(new Date(coin.date), DATE_FORMAT_MONTH_YEAR);
              st[month] = st[month] ? (st[month] + coin.count) : coin.count;
            }
          });
          st.Total = getCoinsBalance(student);
        }
        studentsArr.push(st);
      });

      XLSExporter.export({
        items: studentsArr,
        sheetName: 'Coins Earned',
        fileName: `Madrasah-Coins-Earned-Report_${format(new Date(), DATE_FORMAT_UTC)}.xlsx`,
        colsWidth: [8, 15, 15, 15, 6, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8] // provide width for 12 month cols
      });
    }
  };

  const renderCoinsReportPanel = () => {
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showCoinsReportPanel}
        title='Coins earned report'
        from='right'
        width='50vw'
        onRequestClose={() => {
          setShowCoinsReportPanel(false);
        }}
      >
        <CoinsReportForm
          onCancel={() => {
            setShowCoinsReportPanel(false);
          }}
          onSave={(reportParams) => {
            console.log(reportParams);
            exportReport(reportParams);
          }}
        />
      </SlidingPane>
    );
  };

  const renderActionsMenu = () => {
    return (
      <OverflowMenu
        trigger={<MdMenu className='overflow-menu__icon' focusable={true} />}
        onClick={() => setShowActionsMenu(!showActionsMenu)}
        show={showActionsMenu}
        onHideOverflowMenu={() => {
          setShowActionsMenu(false);
        }}
        onMenuTriggerClick={() => setShowActionsMenu(!showActionsMenu)}
      >
        <OverflowMenuItem
          id='download_coins_report'
          key='download_coins_report'
          label='Download report'
          onClick={() => {
            setShowActionsMenu(false);
            setShowCoinsReportPanel(true);
          }}
        />
      </OverflowMenu>
    );
  };

  return (
    <HasPermissions permissions={[
      ADD_REWARDS_PERMISSION, REDEEM_REWARDS_PERMISSION
    ]} >
      <div className='rewards-student-list-wrapper'>
        {/* showLoading() */}
        {renderRewardsTimeline()}
        {renderCoinsReportPanel()}
        {renderUpdateCoinsPanel()}
        {renderClasses()}
        {getTitleRow()}
        {getStudentList()}
      </div>
    </HasPermissions>
  );
};

RewardStudentList.propTypes = {
  selectedClass: PropTypes.string,
  tenantId: PropTypes.string
};

export default RewardStudentList;

export const getPriroityConversion = (priority) => {
  switch (priority) {
    case 1:
      return { text: 'High', type: 'bad' };
    case 3:
      return { text: 'Medium', type: 'neutral' };
    case 5:
      return { text: 'Low', type: 'good' };
    default:
      return { text: 'Unknown', type: 'bad' };
  }
};

import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({
  onCancel, onSave, saveButtonLabel, saveDisabled
}) => {
  return (
    <div className='mbl--footer-wrapper'>
      <button className='mbl-button secondary-button footer-button' onClick={() => onCancel()}>Cancel</button>
      <button disabled={saveDisabled} className='mbl-button primary-button footer-button' onClick={() => onSave()}>{saveButtonLabel}</button>
      <div className='horizontal-spacer' />
    </div>
  );
};

Footer.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  saveButtonLabel: PropTypes.string,
  saveDisabled: PropTypes.bool
};

Footer.defaultProps = {
  onCancel: () => {},
  onSave: () => {},
  saveButtonLabel: 'Save',
  saveDisabled: false
};

export default Footer;

import { useContext, useState, useEffect } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useEmailTemplateData = () => {
  const appContext = useContext(AppContext);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTemplates = async () => {
    if (!appContext.tenant._id) {
      return;
    }
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/emailtemplates/v1/emailtemplates`);
      const jsonData = await dataService.handleResponse(resp);
      setTemplates(jsonData);
    } catch (ex) {
      console.log(':::::Exception fetching email templages', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const deleteTemplate = async (id) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.delete(`/api/ui/${appContext.tenant._id}/emailtemplates/v1/emailtemplates/${id}`);
      await dataService.handleResponse(resp);
      await fetchTemplates();
    } catch (ex) {
      console.log(':::::Exception deleting email template', ex);
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  const saveTemplate = async (emailTemplate, mode) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      if (mode === 'edit') {
        resp = await dataService.put(`/api/ui/${appContext.tenant._id}/emailtemplates/v1/emailtemplates/${emailTemplate._id}`, emailTemplate);
      } else {
        resp = await dataService.post(`/api/ui/${appContext.tenant._id}/emailtemplates/v1/emailtemplates`, emailTemplate);
      }
      await dataService.handleResponse(resp);
      await fetchTemplates();
      setError(null);
    } catch (ex) {
      console.log(':::::Exception saving email template', mode, ex);
      setLoading(false);
      setError(resp);
      throw Error(ex);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTemplates();
    return () => {
      setTemplates([]);
    };
  }, []);

  return {
    templates,
    loading,
    error,
    deleteTemplate,
    saveTemplate
  };
};

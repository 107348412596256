import React, { useContext } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import HasPermissions from '../HasPermissions';

import Student from '../../styles/assets/student.svg';
import Classroom from '../../styles/assets/classroom.svg';
import Attendance from '../../styles/assets/attendance.svg';
import User from '../../styles/assets/user.svg';
import UserRole from '../../styles/assets/userrole.svg';
import Reward from '../../styles/assets/medal.svg';
import Payment from '../../styles/assets/payment.svg';
import PurchaseOrder from '../../styles/assets/purchaseorder.svg';
import Email from '../../styles/assets/email.svg';
// import Chat from '../../styles/assets/chat.svg';
import Setting from '../../styles/assets/setting.svg';
// import Password from '../../styles/assets/password.svg';
import Dashboard from '../../styles/assets/dashboard.svg';

// import { hasPermissions } from '../../utils/hasPermissions';
import {
  VIEW_DASHBOARD_PERMISSION,
  VIEW_STUDENTS_PERMISSION, ADD_STUDENTS_PERMISSION, EDIT_STUDENTS_PERMISSION,
  DELETE_STUDENTS_PERMISSION, PROMOTE_STUDENTS_PERMISSION,
  VIEW_MY_CLASS_ATTENDANCE_PERMISSION, TAKE_MY_CLASS_ATTENDANCE_PERMISSION,
  VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION,
  VIEW_TEACHERS_ATTENDANCE_PERMISSION, TAKE_TEACHERS_ATTENDANCE_PERMISSION,
  VIEW_CLASSES_PERMISSION, ADD_CLASSES_PERMISSION, EDIT_CLASSES_PERMISSION,
  DELETE_CLASSES_PERMISSION,
  VIEW_USERS_PERMISSION, ADD_USERS_PERMISSION, EDIT_USERS_PERMISSION,
  DELETE_USERS_PERMISSION,
  VIEW_ROLES_PERMISSION, ADD_ROLES_PERMISSION, EDIT_ROLES_PERMISSION,
  DELETE_ROLES_PERMISSION,
  ADD_REWARDS_PERMISSION, REDEEM_REWARDS_PERMISSION,
  VIEW_INVOICES_PERMISSION, VIEW_INVOICE_METRICS_PERMISSION, ADD_INVOICES_PERMISSION, EDIT_INVOICES_PERMISSION,
  DELETE_INVOICES_PERMISSION,
  VIEW_EMAIL_TEMPLATES_PERMISSION, ADD_EMAIL_TEMPLATES_PERMISSION,
  EDIT_EMAIL_TEMPLATES_PERMISSION, DELETE_EMAIL_TEMPLATES_PERMISSION,
  VIEW_SETTINGS_PERMISSION, EDIT_SETTINGS_PERMISSION,
  VIEW_PURCHASE_ORDERS_PERMISSION, ADD_PURCHASE_ORDERS_PERMISSION, EDIT_PURCHASE_ORDERS_PERMISSION,
  DELETE_PURCHASE_ORDERS_PERMISSION,
  VIEW_ASSETS_PERMISSION, ADD_ASSETS_PERMISSION, EDIT_ASSETS_PERMISSION,
  DELETE_ASSETS_PERMISSION
} from '../../utils/permissions';
import router from '../../Routes';

import AppContext from '../AppContext';

const messages = defineMessages({
  dashboard: {
    description: 'Dashboard widget label',
    defaultMessage: 'Dashboard'
  },
  students: {
    description: 'Students widget label',
    defaultMessage: 'Students'
  },
  attendance: {
    description: 'Attendance widget label',
    defaultMessage: 'Attendance'
  },
  classes: {
    description: 'Classes widget label',
    defaultMessage: 'Classes'
  },
  users: {
    description: 'Users widget label',
    defaultMessage: 'Users'
  },
  rolesPermissions: {
    description: 'Roles permissions widget label',
    defaultMessage: 'Roles & permissions'
  },
  rewards: {
    description: 'Rewards widget label',
    defaultMessage: 'Rewards'
  },
  payments: {
    description: 'Payments widget label',
    defaultMessage: 'Payments'
  },
  purchaseOrders: {
    description: 'Purchase orders widget label',
    defaultMessage: 'Purchase orders'
  },
  assets: {
    description: 'Assets widget label',
    defaultMessage: 'Assets'
  },
  emailTemplates: {
    description: 'Email templates widget label',
    defaultMessage: 'Email templates'
  },
  settings: {
    description: 'Settings widget label',
    defaultMessage: 'Settings'
  }
});

const HomePanel = () => {
  const appContext = useContext(AppContext);
  const intl = useIntl();

  const handleFeatureBubbleClick = (location) => {
    if (location.indexOf('http') !== -1) {
      window.open(location);
    } else {
      router.setRoute(location);
    }
  };

  return (
    <section>
      <div className='features'>
        <HasPermissions
          permissions={[VIEW_DASHBOARD_PERMISSION]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/dashboard`)}
          >
            <Dashboard
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.dashboard)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_STUDENTS_PERMISSION, ADD_STUDENTS_PERMISSION, EDIT_STUDENTS_PERMISSION,
            DELETE_STUDENTS_PERMISSION, PROMOTE_STUDENTS_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/students`)}
          >
            <Student
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.students)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_MY_CLASS_ATTENDANCE_PERMISSION, TAKE_MY_CLASS_ATTENDANCE_PERMISSION,
            VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION,
            VIEW_TEACHERS_ATTENDANCE_PERMISSION, TAKE_TEACHERS_ATTENDANCE_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/attendance`)}
          >
            <Attendance className='feature-icon' />
            <span className='feature-text'>{intl.formatMessage(messages.attendance)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_CLASSES_PERMISSION, ADD_CLASSES_PERMISSION, EDIT_CLASSES_PERMISSION,
            DELETE_CLASSES_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/classes`)}
          >
            <Classroom
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.classes)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_USERS_PERMISSION, ADD_USERS_PERMISSION, EDIT_USERS_PERMISSION,
            DELETE_USERS_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/users`)}
          >
            <User
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.users)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_ROLES_PERMISSION, ADD_ROLES_PERMISSION, EDIT_ROLES_PERMISSION,
            DELETE_ROLES_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/user-roles`)}
          >
            <UserRole
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.rolesPermissions)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[ADD_REWARDS_PERMISSION, REDEEM_REWARDS_PERMISSION]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/rewards/All`)}
          >
            <Reward
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.rewards)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_INVOICES_PERMISSION, VIEW_INVOICE_METRICS_PERMISSION, ADD_INVOICES_PERMISSION,
            EDIT_INVOICES_PERMISSION, DELETE_INVOICES_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/payments/all`)}
          >
            <Payment
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.payments)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_PURCHASE_ORDERS_PERMISSION, ADD_PURCHASE_ORDERS_PERMISSION, EDIT_PURCHASE_ORDERS_PERMISSION,
            DELETE_PURCHASE_ORDERS_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/purchase-orders`)}
          >
            <PurchaseOrder
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.purchaseOrders)}</span>
          </div>
        </HasPermissions>
        <HasPermissions
          permissions={[
            VIEW_ASSETS_PERMISSION, ADD_ASSETS_PERMISSION, EDIT_ASSETS_PERMISSION,
            DELETE_ASSETS_PERMISSION
          ]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/assets`)}
          >
            <PurchaseOrder
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.assets)}</span>
          </div>
        </HasPermissions>
        {appContext.settings.isEmailConfigured ? (
          <HasPermissions
            permissions={[
              VIEW_EMAIL_TEMPLATES_PERMISSION, ADD_EMAIL_TEMPLATES_PERMISSION,
              EDIT_EMAIL_TEMPLATES_PERMISSION, DELETE_EMAIL_TEMPLATES_PERMISSION
            ]}
          >
            <div
              className='feature--bubble'
              tabIndex='0'
              role='button'
              onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/email-templates`)}
            >
              <Email
                className='feature-icon'
              />
              <span className='feature-text'>{intl.formatMessage(messages.emailTemplates)}</span>
            </div>
          </HasPermissions>
        ) : null}
        {/*
          appContext.settings.rocketChatHttpUrl && appContext.settings.rocketChatHttpsUrl ? (
            <div
              className='feature--bubble'
              tabIndex='0'
              role='button'
              onClick={handleFeatureBubbleClick.bind(this, 'https://collaborate.mbl.com')}
            >
              <Chat
                className='feature-icon'
              />
              <span className='feature-text'>Collaborate</span>
            </div>
          ) : null
        */}
        <HasPermissions
          permissions={[VIEW_SETTINGS_PERMISSION, EDIT_SETTINGS_PERMISSION]}
        >
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/settings`)}
          >
            <Setting
              className='feature-icon'
            />
            <span className='feature-text'>{intl.formatMessage(messages.settings)}</span>
          </div>
        </HasPermissions>
        {/*
          <div
            className='feature--bubble'
            tabIndex='0'
            role='button'
            onClick={handleFeatureBubbleClick.bind(this, `/${appContext.tenant._id}/change-password`)}
          >
            <Password
              className='feature-icon'
            />
            <span className='feature-text'>Change password</span>
          </div>
        */}
      </div>
    </section>
  );
};

export default HomePanel;

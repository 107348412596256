import React from 'react';
import PropTypes from 'prop-types';

import TeacherAttendanceList from '../visualization/TeacherAttendanceList';

const TeachersAttendancePanel = ({ mode }) => {
  return (
    <section className='student-attendance'>
      <TeacherAttendanceList mode={mode} />
    </section>
  );
};

TeachersAttendancePanel.propTypes = {
  mode: PropTypes.string
};

export default TeachersAttendancePanel;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { TextArea, TextField } from '@aeblondon-uk/common-ui-components';

import Loading from '../Loading';
// import Notification from '../visualization/Notification';
import Footer from '../visualization/Footer';

import { useForm } from '../hooks/useForm';

const config = {
  uploader: {
    insertImageAsBase64URI: true
  },
  height: '400px'
};

const EmailTemplateForm = ({
  mode, template, onCancel, onSave, isLoading
}) => {
  const [fields, handleFieldChange] = useForm(template);
  const [content, setContent] = useState(template.content);
  const editor = useRef(null);

  const showLoading = () => {
    if (isLoading) {
      return (<Loading />);
    }
    return null;
  };

  const renderForm = () => {
    return (
      <>
        <fieldset className='student-form__section'>
          <div className='row one-two'>
            <TextField
              label='Template name'
              disabled={mode === 'view'}
              required={true}
              maxLength={50}
              value={fields.name}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />
          </div>
          <div className='row one-two'>
            <TextArea
              label='Description'
              disabled={mode === 'view'}
              maxLength={500}
              rows='4'
              value={fields.description}
              onChange={(e) => handleFieldChange('description', e.target.value)}
            />
          </div>
          <div className='row one-two'>
            <TextField
              label='Email subject'
              disabled={mode === 'view'}
              required={true}
              maxLength={75}
              value={fields.subject}
              onChange={(e) => handleFieldChange('subject', e.target.value)}
            />
          </div>
          <div>
            <label>
              <span className='required'>Email content</span>
            </label>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              onChange={(c) => setContent(c)}
            />
          </div>
        </fieldset>
        <p />
        {
          mode !== 'view' ? (
            <Footer
              onCancel={onCancel}
              onSave={() => {
                onSave({ ...fields, content });
              }}
            />
          ) :
            null
        }
      </>
    );
  };

  return (
    <div className='student-form'>
      {showLoading()}
      {/* renderNotification() */}
      {renderForm()}
    </div>
  );
};

EmailTemplateForm.propTypes = {
  mode: PropTypes.string,
  template: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool
};

export default EmailTemplateForm;

import React, { useContext, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { compareDesc, format } from 'date-fns';
import {
  CommonTable as Table, NumberInput, TextField, ToggleButton
} from '@aeblondon-uk/common-ui-components';

import Notification from './Notification';
import NoData from '../NoData';
import { DATE_FORMAT_UTC } from '../../utils/DateUtils';
import {
  DateRenderer
} from '../tables/TableCellRenderer';
import FaChevronRight from '../../styles/assets/chevron.svg';
import MdDelete from '../../styles/assets/delete.svg';

import AppContext from '../AppContext';
import { SETTINGS } from '../hooks/InitialCopies';
import { useForm } from '../hooks/useForm';

import SettingsForm from '../forms/SettingsForm';

const Settings = () => {
  const appContext = useContext(AppContext);

  const [fields, handleFieldChange] = useForm(
    Object.keys(appContext.settings).length <= 1 ? SETTINGS : appContext.settings
  );
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
  const [showAcademicYearPanel, setShowAcademicYearPanel] = useState(false);
  const [selectedTab, setSelectedTab] = useState('academicYear');
  const [showNotification, setShowNotification] = useState(false);

  const renderNotification = () => {
    if (showNotification) {
      return (
        <Notification
          text='Settings successfully saved'
          type='success'
          closable={true}
          onClose={() => setShowNotification(false)}
        />
      );
    }
    return null;
  };

  const renderAcademicYearPanel = () => {
    return (
      <SlidingPane
        className='mbl--sliding-pane'
        closeIcon={<FaChevronRight className='active-icon' />}
        isOpen={showAcademicYearPanel}
        title={`${selectedAcademicYear ? 'Edit' : 'Add'} academic year`}
        from='right'
        width='30vw'
        onRequestClose={() => {
          setShowAcademicYearPanel(false);
          setSelectedAcademicYear(null);
        }}
      >
        <SettingsForm
          academicYear={selectedAcademicYear || {
            academicYear: '0',
            startDate: format(new Date(), DATE_FORMAT_UTC),
            endDate: format(new Date(), DATE_FORMAT_UTC),
            isCurrent: true
          }}
          onCancel={() => {
            setShowAcademicYearPanel(false);
          }}
          onSave={(academicYear) => {
            const academicYears = JSON.parse(JSON.stringify(fields.academicYears));
            if (selectedAcademicYear) {
              const index = academicYears.map(yr => yr._id).indexOf(academicYear._id);
              academicYears[index] = academicYear;
            } else {
              academicYears.push(academicYear);
            }
            appContext.saveSettings(appContext.tenant._id, { ...fields, academicYears }, () => {
              setShowNotification(true);
              setShowAcademicYearPanel(false);
              setSelectedAcademicYear(null);
            });
          }}
        />
      </SlidingPane>
    );
  };

  const TableCell = (cell) => {
    if (cell.column.key === 'startDate' || cell.column.key === 'endDate') {
      return DateRenderer(cell.rowData[cell.column.key]);
    }
    if (cell.column.key === 'actions') {
      return (
        <div className='actions'>
          <div className='actions-icons'>
            <MdDelete
              className='action-icon'
              onClick={() => {
                console.log(cell.rowData);
                const academicYears = JSON.parse(JSON.stringify(fields.academicYears));
                const index = academicYears.map(yr => yr._id).indexOf(cell.rowData._id);
                academicYears.splice(index, 1);
                appContext.saveSettings(appContext.tenant._id, { ...fields, academicYears }, () => {
                  setShowNotification(true);
                  setSelectedAcademicYear(null);
                });
              }}
            />
          </div>
        </div>
      );
    }
    if (cell.column.key === 'isCurrent') {
      if (cell.rowData[cell.column.key]) {
        return <div>Yes</div>;
      }

      const trickLint = '#';
      return (
        <div>
          <a
            href={trickLint}
            role='button'
            tabIndex='0'
            onClick={() => {
              console.log('setting current');
              const data = JSON.parse(JSON.stringify(fields.academicYears));
              const academicYears = [];
              data.forEach((yearObj, idx) => {
                academicYears.push(yearObj);
                if (yearObj.academicYear === cell.rowData.academicYear) {
                  academicYears[idx].isCurrent = true;
                } else {
                  academicYears[idx].isCurrent = false;
                }
              });
              appContext.saveSettings(appContext.tenant._id, { ...fields, academicYears }, () => {
                setShowNotification(true);
              });
            }}
          >
            Set current
          </a>
        </div>
      );
    }
    return <div>{cell.rowData[cell.column.key]}</div>;
  };

  let mouseDown = 0;
  const rowEventHandlers = {
    onMouseUp: (e) => {
      const elementClicked = e.event.target.tagName.toLowerCase();
      if (elementClicked !== 'a' && elementClicked !== 'svg' && elementClicked !== 'path' && mouseDown === e.event.clientX && e.event.button === 0) {
        console.log(':::::e.rowData', e.rowData);
        setSelectedAcademicYear(e.rowData);
        setShowAcademicYearPanel(true);
      }
    },
    onMouseDown: (e) => {
      mouseDown = e.event.clientX;
    }
  };

  const columns = [
    {
      width: 120, title: 'Academic year', dataKey: 'academicYear', key: 'academicYear', resizable: true
    },
    {
      width: 150, title: 'Start date', dataKey: 'startDate', key: 'startDate', resizable: true
    },
    {
      width: 150, title: 'End date', dataKey: 'endDate', key: 'endDate', resizable: true
    },
    {
      width: 100, title: 'Current year', dataKey: 'isCurrent', key: 'isCurrent', resizable: true
    },
    {
      width: 100, title: 'Actions', dataKey: 'actions', key: 'actions', resizable: false
    }
  ];

  const renderAcademicTable = () => {
    if (selectedTab === 'academicYear') {
      return (
        <>
          <div style={{ width: '500px', height: '260px' }}>
            <Table
              width={600}
              height={250}
              rowKey='academicYear'
              rowHeight={32}
              components={{ TableCell }}
              emptyRenderer={<NoData />}
              selectable
              rowEventHandlers={rowEventHandlers}
              columns={columns}
              data={fields.academicYears ? fields.academicYears.sort((a, b) => {
                return compareDesc(new Date(a.startDate), new Date(b.startDate));
              }) : []}
            />
          </div>
          <button
            className='mbl-button primary-button footer-button'
            onClick={() => {
              setShowAcademicYearPanel(true);
            }}
          >
            Add academic year
          </button>
        </>
      );
    }
    return null;
  };

  const renderEmailSettings = () => {
    if (selectedTab === 'email') {
      return (
        <div>
          <span>Configure the email server for sending emails (you can usually get this information from your website hosting provider)</span>
          <div>
            <TextField
              label='Host'
              className='textfield-large'
              required={true}
              maxLength={200}
              value={fields.emailServer?.host}
              onChange={(e) => handleFieldChange('emailServer', { ...fields.emailServer, host: e.target.value })}
            />
            <NumberInput
              label='Secure port'
              className='textfield-large'
              required={true}
              maxLength={200}
              value={fields.emailServer?.port || 0}
              onChange={(value) => {
                let port = fields.emailServer.port;
                try {
                  port = parseInt(value || 0, 10);
                } catch (ex) {
                  console.log('Incorrect port value', ex);
                }
                handleFieldChange('emailServer', { ...fields.emailServer, port });
              }}
            />
            <TextField
              label='Username / Email'
              className='textfield-large'
              required={true}
              maxLength={200}
              value={fields.emailServer?.username}
              onChange={(e) => handleFieldChange('emailServer', { ...fields.emailServer, username: e.target.value })}
            />
            <TextField
              label='Password'
              className='textfield-large'
              type='password'
              required={true}
              maxLength={200}
              value={fields.emailServer?.password}
              onChange={(e) => handleFieldChange('emailServer', { ...fields.emailServer, password: e.target.value })}
            />
          </div>
          <button
            className='mbl-button primary-button footer-button'
            onClick={() => {
              appContext.saveSettings(appContext.tenant._id, fields, () => {
                setShowNotification(true);
              });
            }}
          >
            Save email settings
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <ToggleButton
        onClick={(name) => {
          setSelectedTab(name);
        }}
        items={[
          { name: 'academicYear', label: 'Academic year', pressed: selectedTab === 'academicYear' },
          { name: 'email', label: 'Email', pressed: selectedTab === 'email' }
        ]}
      />
      <p />
      {renderAcademicYearPanel()}
      {renderNotification()}
      {renderAcademicTable()}
      {renderEmailSettings()}
    </>
  );
};

export default Settings;

import { useContext, useState, useEffect } from 'react';
import AppContext from '../AppContext';
import DataService from '../../data/DataService';

export const useUserRoles = (fetchOnLoad) => {
  const appContext = useContext(AppContext);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUserRoles = async () => {
    if (!appContext.tenant._id) {
      return;
    }
    setLoading(true);
    const dataService = new DataService();
    try {
      const resp = await dataService.getItems(`/api/ui/${appContext.tenant._id}/roles/v1/roles`);
      const jsonData = await dataService.handleResponse(resp);
      setUserRoles(jsonData);
    } catch (ex) {
      console.log(':::::Exception fetching user roles', ex);
      setError(ex);
    }
    setLoading(false);
  };

  const deleteUserRole = async (id) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      resp = await dataService.delete(`/api/ui/${appContext.tenant._id}/roles/v1/roles/${id}`);
      await dataService.handleResponse(resp);
      await fetchUserRoles();
    } catch (ex) {
      console.log(':::::Exception deleting user roles', ex);
      setLoading(false);
      setError(resp);
      throw Error(resp);
    }
    setLoading(false);
  };

  const saveUserRole = async (userRole, mode) => {
    setLoading(true);
    const dataService = new DataService();
    let resp = null;
    try {
      if (mode === 'edit') {
        resp = await dataService.put(`/api/ui/${appContext.tenant._id}/roles/v1/roles/${userRole._id}`, userRole);
      } else {
        resp = await dataService.post(`/api/ui/${appContext.tenant._id}/roles/v1/roles`, userRole);
      }
      await dataService.handleResponse(resp);
      await fetchUserRoles();
      setError(null);
    } catch (ex) {
      console.log(':::::Exception saving user role', mode, ex);
      setLoading(false);
      setError(resp);
      throw Error(ex);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (fetchOnLoad !== false) {
      fetchUserRoles();
    }
    return () => {
      setUserRoles([]);
    };
  }, []);

  return {
    userRoles,
    loading,
    error,
    deleteUserRole,
    saveUserRole
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  NumberInput, TextArea
} from '@aeblondon-uk/common-ui-components';

import Footer from '../visualization/Footer';
import { useForm } from '../hooks/useForm';

const AssetForm = ({
  asset, mode, onCancel, onSave
}) => {
  const [fields, handleFieldChange] = useForm(asset);

  return (
    <>
      <div>
        {/* showLoading() */}
        {/* showFloatingNotification() */}
        <NumberInput
          label='Coins balance'
          type='number'
          disabled={true}
          value={coinsBalance}
        />
        <NumberInput
          autoFocus={true}
          label={`Coins to ${mode}`}
          type='number'
          required={true}
          max={max}
          value={fields.coins.count}
          onChange={(value) => {
            let count = fields.coins.count;
            try {
              count = parseInt(value || 0, 10);
            } catch (ex) {
              console.log('Incorrect count value', ex);
            }
            handleFieldChange('coins', { ...fields.coins, count });
          }}
        />
        <TextArea
          label='Remarks'
          maxLength={500}
          rows='4'
          value={fields.coins.comment}
          onChange={(e) => handleFieldChange('coins', { ...fields.coins, comment: e.target.value })}
        />
      </div>
      <Footer
        onCancel={() => {
          onCancel();
        }}
        onSave={() => {
          onSave(fields);
        }}
      />
    </>
  );
};

AssetForm.propTypes = {
  mode: PropTypes.string,
  coins: PropTypes.object,
  coinsBalance: PropTypes.number,
  max: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default AssetForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {BarChart} from '@aeblondon-uk/common-ui-components';

import { DATE_FORMAT_SHORT_MONTH_YEAR } from '../../utils/DateUtils';
import { useStudentAttendanceData } from '../hooks/useStudentAttendanceData';
import { useTeacherAttendanceData } from '../hooks/useTeacherAttendanceData';

const AttendanceChartPanel = ({
  mode, id, startDate, endDate, session = 'Morning'
}) => {
  const { studentAttendanceHistory, fetchStudentAttendanceHistory } = useStudentAttendanceData();
  const { teacherAttendanceHistory, fetchTeacherAttendanceHistory } = useTeacherAttendanceData();
  const [data, setData] = useState({});
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();
  }, []);

  const handleResizeWindow = () => {
    setWidth(((window.innerWidth * 80) / 100) - 100);
    setHeight(window.innerHeight - 120);
  };

  useEffect(() => {
    if (mode === 'teacher') {
      fetchTeacherAttendanceHistory(id, startDate, endDate);
    } else {
      fetchStudentAttendanceHistory(id, startDate, endDate, session);
    }
  }, []);

  useEffect(() => {
    const attendanceHistory = mode === 'teacher' ? teacherAttendanceHistory : studentAttendanceHistory;
    const tmpData = {categories: [], columns: []};

    if (attendanceHistory.attCount && attendanceHistory.expAttCount) {
      attendanceHistory.expAttCount.sort((a, b) => {
        return new Date(a._id.year, a._id.month, 1) - new Date(b._id.year, b._id.month, 1);
      });
      attendanceHistory.expAttCount.forEach((obj, i) => {
        if (!tmpData.columns[i]) {
          tmpData.columns[i] = [];
        }
        tmpData.categories.push(format(new Date(`${obj._id.year }-${ obj._id.month}`), DATE_FORMAT_SHORT_MONTH_YEAR));
        tmpData.columns[i][0] = {
          category: format(new Date(`${obj._id.year }-${ obj._id.month}`), DATE_FORMAT_SHORT_MONTH_YEAR),
          color: '#0043ce',
          key: 'Expected',
          value: obj.count,
          label: `${obj.count}`
        };
      });

      attendanceHistory.attCount.sort((a, b) => {
        return new Date(a._id.year, a._id.month, 1) - new Date(b._id.year, b._id.month, 1);
      });
      attendanceHistory.attCount.forEach((obj, i) => {
        tmpData.columns[i][1] = {
          category: format(new Date(`${obj._id.year }-${ obj._id.month}`), DATE_FORMAT_SHORT_MONTH_YEAR),
          color: '#fa4d56',
          key: 'Actual',
          value: obj.count,
          label: `${obj.count}`
        };
      });

      setData(tmpData);
    }
  }, [studentAttendanceHistory, teacherAttendanceHistory]);

  return (
    <div style={{ height: '100%' }}>
      <BarChart
        width={width}
        height={height}
        data={data}
      />
    </div>
  );
};

AttendanceChartPanel.propTypes = {
  id: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  session: PropTypes.string,
  mode: PropTypes.string // can be either 'teacher' or 'student'
};

export default AttendanceChartPanel;

import React, { useContext, useEffect, useState } from 'react';

import HasPermissions from '../HasPermissions';

import FaChartBar from '../../styles/assets/chart.svg';
import RegisterIcon from '../../styles/assets/attendance.svg';
import TeacherIcon from '../../styles/assets/teacher.svg';
import AppContext from '../AppContext';

import {
  VIEW_MY_CLASS_ATTENDANCE_PERMISSION, TAKE_MY_CLASS_ATTENDANCE_PERMISSION,
  VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION,
  VIEW_TEACHERS_ATTENDANCE_PERMISSION, TAKE_TEACHERS_ATTENDANCE_PERMISSION
} from '../../utils/permissions';

import { useClassData } from '../hooks/useClassData';
import router from '../../Routes';

const AttendanceOptionsPanel = () => {
  const appContext = useContext(AppContext);
  const [defaultClass, setDefaultClass] = useState('');
  const { classes } = useClassData();

  useEffect(() => {
    if (classes.length > 0) {
      setDefaultClass(classes.filter((c) => c.active !== false && c.name !== 'New' && c.division !== 'Registration')[0]._id);
    }
  }, [classes]);

  return (
    <section>
      {
        defaultClass ?
          (
            <div className='features'>
              <HasPermissions
                permissions={[
                  VIEW_MY_CLASS_ATTENDANCE_PERMISSION, TAKE_MY_CLASS_ATTENDANCE_PERMISSION,
                  VIEW_ALL_CLASS_ATTENDANCE_PERMISSION, TAKE_ALL_CLASS_ATTENDANCE_PERMISSION
                ]}
              >
                <div
                  className='feature--bubble'
                  tabIndex='0'
                  role='button'
                  onClick={() => router.setRoute(`/${appContext.tenant._id}/attendance/mark-attendance/${defaultClass}`)}
                >
                  <RegisterIcon className='feature-icon' />
                  <span className='feature-text'>Mark student attendance</span>
                </div>
                <div
                  className='feature--bubble'
                  tabIndex='0'
                  role='button'
                  onClick={() => router.setRoute(`/${appContext.tenant._id}/attendance/report/${defaultClass}`)}
                >
                  <FaChartBar
                    className='feature-icon'
                  />
                  <span className='feature-text'>Student attendance report</span>
                </div>
              </HasPermissions>
              <HasPermissions
                permissions={[
                  VIEW_TEACHERS_ATTENDANCE_PERMISSION, TAKE_TEACHERS_ATTENDANCE_PERMISSION,
                ]}
              >
                <div
                  className='feature--bubble'
                  tabIndex='0'
                  role='button'
                  onClick={() => router.setRoute(`/${appContext.tenant._id}/attendance/mark-teachers-attendance`)}
                >
                  <TeacherIcon className='feature-icon' />
                  <span className='feature-text'>Mark teacher attendance</span>
                </div>
                <div
                  className='feature--bubble'
                  tabIndex='0'
                  role='button'
                  onClick={() => router.setRoute(`/${appContext.tenant._id}/attendance/teachers-attendance-report`)}
                >
                  <FaChartBar
                    className='feature-icon'
                  />
                  <span className='feature-text'>Teacher attendance report</span>
                </div>
              </HasPermissions>
            </div>
          ) :
          null
      }
    </section>
  );
};

export default AttendanceOptionsPanel;
